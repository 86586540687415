import React, { useEffect, useState, useCallback } from 'react';
import CommanTable from '../components/CommanTable';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UserForm from '../components/UserForm';
import CloseIcon from '@mui/icons-material/Close';
import InvoiceTable from '../components/InvoiceTable';
import dayjs from 'dayjs';
import InvoiceItemForm from '../components/InvoiceItemForm';
import ItemDataTable from '../components/ItemDataTable';
import MainService from '../../../Common/MainService';
import { uniqueNumber } from '../utils/genrateUniqueNumber';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from '../../../../../utils/formatTime';
import LoadingPage from '../../loadingpage/LoadingPage';
import ConsigneeDetails from '../components/ConsigneeDetails';
import { appendKeysWithValues, removeKey } from '../utils/validateData';

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempProformaInvoiceListDetails = [
  { id: 'invNo', label: 'Invoice Number' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'date', label: 'Date' },
  { id: 'status', label: 'Status' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const tempBuyerData = {
  supplierName: '',
  sup_gstin: '',
  sup_panNo: '',
  sup_email: '',
  supplierContact: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
  supplierState: '',
  supplierStateCode: '',
};

const tempSupplier = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempProformaInvoiceDetailsColumn = [
  { id: 'logo', label: 'Logo', type: 'logo' },
  { id: 'invNo', label: 'Invoice Number' },
  { id: 'purchaseOrderID', label: 'Purchase Order ID' },
  { id: 'invState', label: 'State Name' },
  { id: 'invStateCode', label: 'State Code' },
  { id: 'invDueDate', label: 'Valid Date', type: 'date' },
];
const tempProformaInvoiceDetailsRowData = [
  {
    invNo: 'PO-' + uniqueNumber(),
    purchaseOrderID: '',
    invIssueDate: formattedValue,
    invDueDate: '',
    invState: '',
    invStateCode: '',
  },
];

const tempInvoiceItem = {
  description: '',
  hsn_sac: '',
  quantity: '',
  unit: '',
  price_per_unit: '',
  totalGst: {
    sGst: 0,
    cGst: 0,
    iGst: 0,
  },
};

const tempTranportDetailsLabel = [
  { id: 'transporter', label: 'Transporter' },
  { id: 'vehicleType', label: 'Vehicle Type' },
  { id: 'vehicleNo', label: 'Vehicle No' },
];

const tempTranportDetails = [
  {
    transporter: '',
    vehicleType: '',
    vehicleNo: '',
  },
];

const tempBankdetailsLabel = [
  { id: 'bankAccNo', label: 'Account No.', type: 'number' },
  { id: 'bankName', label: 'Bank Name' },
  { id: 'branchName', label: 'Branch Name' },
  { id: 'bank_IFSC_code', label: 'IFSC Code' },
  { id: 'bank_swift_code', label: 'SWIFT Code' },
];

const tempBankDetailsData = [
  {
    bankName: '',
    branchName: '',
    bankAccNo: '',
    bank_IFSC_code: '',
    bank_swift_code: '',
  },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];
const tempTermConditionValue = [];

const SellerProformaInvoiceManagement = ({ userDetails, proxyId = '' }) => {
  const [addNewProformaInvoice, setAddNewProformaInvoice] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [proformaInvoiceList, setProformaInvoiceList] = useState([]);
  const [imageFile, setImageFile] = useState({});
  const [tempImage, setTempImage] = useState({});
  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);

  const [proformaInvoiceDetails, setProformaInvoiceDetails] = useState([
    {
      invNo: '',
      purchaseOrderID: proxyId,
      invIssueDate: formattedValue,
      invDueDate: '',
      invState: '',
      invStateCode: '',
    },
  ]);

  const [transportDetails, setTransportDetails] = useState(tempTranportDetails);
  const [bankDetails, setBankDetails] = useState(tempBankDetailsData);
  const [invoiceItemDataList, setiInvoiceItemDataList] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [invoiceItem, setInvoiceItem] = useState(tempInvoiceItem);
  const [addInvoiceItemError, setAddInvoiceItemError] = useState(false);
  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );
  const [contentdescription, setContentdescription] = useState('');
  const [isDocumentDetailsLoaded, setIsDocumentDetailsLoaded] = useState(true);
  const [documentDetailsError, setDocumentDetailsError] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');
  const [imageSize, setImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [pickUpDetails, setPickUpDetails] = useState({});
  const [samePickupCheck, setSamePickupCheck] = useState(false);
  const [sameDeliveryCheck, setSameDeliveryCheck] = useState(false);
  const [productIndex, setProductIndex] = useState('');

  const [isFormDataFetched, setIsFormDataFetched] = useState(false);
  const [isValidKeyFetched, setIsValidKeyFetched] = useState([]);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const addNewProformaInvoiceFormHandler = () => {
    setAddNewProformaInvoice(true);
  };

  useEffect(() => {
    setProformaInvoiceDetails((prev) => [
      {
        ...prev[0],
        purchaseOrderID: proxyId,
        invIssueDate: formattedValue,
      },
    ]);
    if (proxyId) {
      setAddNewProformaInvoice(true);
    }
  }, [proxyId]);

  // get details based on rfq id
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchDetailsbyId = async (id) => {
    const response = await MainService.getDocumentsById(id, parentId, username);
    if (response?.status === 200) {
      setIsFormDataFetched(true);
      const { personalDetails, assetDetails } =
        response.data.requestDetails || {};

      setBuyerDetails((prev) => ({
        ...prev,
        supplierName: personalDetails?.name,
        // sup_gstin: prev.sup_gstin,
        sup_panNo: personalDetails?.panNumber === '' ? prev.sup_panNo : '',
        sup_email: personalDetails?.emailId,
        supplierContact: personalDetails?.phoneNo?.toString(),
        supplierAdressline1: `${personalDetails?.name} ${personalDetails?.street}`,
        supplierAdressline2: `${personalDetails?.city} ${personalDetails?.state}`,
        supplierAdressline3: `${personalDetails?.country} ${personalDetails?.pincode}`,
        supplierState: personalDetails?.state,
        supplierStateCode: '',
      }));

      setiInvoiceItemDataList((prev) =>
        assetDetails.map((product) => {
          const { description, hsn, unit, quantity } = product;
          return {
            description: description,
            hsn_sac: hsn,
            quantity: quantity,
            unit: unit,
            price_per_unit: 0,
            totalGst: {
              sGst: 0,
              cGst: 0,
              iGst: 0,
            },
          };
        })
      );

      setProformaInvoiceDetails((prev) => [
        { ...prev[0], purchaseOrderID: id },
      ]);
    }
  };

  const debouncedFetchDetailsById = useCallback(
    debounce(fetchDetailsbyId, 500),
    []
  );

  useEffect(() => {
    if (proxyId || proformaInvoiceDetails[0].purchaseOrderID) {
      debouncedFetchDetailsById(proformaInvoiceDetails[0].purchaseOrderID);
    }
  }, [
    proxyId,
    proformaInvoiceDetails[0].purchaseOrderID,
    debouncedFetchDetailsById,
  ]);

  // check for disable key
  useEffect(() => {
    const processObjects = (...objects) => {
      objects.forEach((object) =>
        appendKeysWithValues(object, setIsValidKeyFetched)
      );
    };

    processObjects(
      buyerDetails,
      supplierDetails,
      ...proformaInvoiceDetails,
      pickUpDetails,
      consigneeDetails
    );
  }, [isFormDataFetched]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setSupplierDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails]);

  // fetching data from server based on document id

  const fetchDocumentDetails = async (docId) => {
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.documentDetailsApi(
        parentId,
        orgid,
        suborgid,
        username,
        docId
      );
      if (!response) {
        setDocumentDetailsError(
          'Document details not found, try refreshing the page.'
        );
      }

      if (response?.status === 200) {
        const {
          receiverDetails,
          consigneeDetails,
          items,
          supplierDetails,
          termsConditions,
          transportDetails,
          invoiceDetails,
          bankDetails,
          stateToShipDetails,
          pickUpDetails,
        } = response.data.doc.proformaInvoiceDataDetails;

        const transformedArray = termsConditions.map((item) => ({
          term: item,
        }));
        setBuyerDetails({
          supplierName: receiverDetails.receiverName,
          sup_gstin: receiverDetails.receiverGstin,
          sup_panNo: receiverDetails.receiverPanNo,
          sup_email: receiverDetails.receiverEmail,
          supplierContact: receiverDetails.receiverContactNo,
          supplierAdressline1: receiverDetails.receiverAdressline1,
          supplierAdressline2: receiverDetails.receiverAdressline2,
          supplierAdressline3: receiverDetails.receiverAdressline3,
          supplierState: receiverDetails.receiverState,
          supplierStateCode: stateToShipDetails.stateToshipCode,
        });
        setSupplierDetails({
          buyerName: supplierDetails.supplierName,
          buyer_cell: {
            buyer_cell1: supplierDetails.sup_cell.sup_cell1,
            buyer_cell2: supplierDetails.sup_cell2,
          },
          buyer_email: supplierDetails.sup_email,
          buyer_website: supplierDetails.sup_website,
          buyer_panNo: supplierDetails.sup_panNo,
          buyer_gstin: supplierDetails.sup_gstin,
          buyerAdressline1: supplierDetails.supplieradressline1,
          buyerAdressline2: supplierDetails.supplieradressline2,
          buyerAdressline3: supplierDetails.supplieradressline3,
        });
        setConsigneeDetails(consigneeDetails);
        setPickUpDetails(pickUpDetails);

        setTermsConditions(transformedArray);
        setProformaInvoiceDetails([invoiceDetails]);
        setBankDetails([bankDetails]);
        setTransportDetails([transportDetails]);
        setiInvoiceItemDataList(items);
      }
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const getDocumentDetailsHander = async ({ invNo, status }) => {
    setAddNewProformaInvoice(true);
    setDocumentStatus(status);
    await fetchDocumentDetails(invNo);
  };

  // fetch purchase order list
  const { isLoading, error, data } = useQuery({
    queryKey: ['proformaInvoice'],
    queryFn: () =>
      MainService.documentListApi(
        parentId,
        orgid,
        suborgid,
        username,
        'proformaInvoice'
      ),
  });

  useEffect(() => {
    if (!isLoading && !error && data) {
      const initialData = data.data.docs.map((item) => {
        const formattedDate = formatDate(item.createdAt);
        return {
          invNo: item.docId,
          buyerName: item.docReceiverName,
          date: formattedDate,
          status: item.status,
          viewLink: item.LocationUrl,
        };
      });
      setProformaInvoiceList(initialData);
    }
    return () => {
      setProformaInvoiceList([]);
    };
  }, [isLoading, error, data]);

  //image file change handler
  const imageFileChangeHandler = (event, columnId) => {
    const { files } = event.target;

    Array.from(files).forEach((file) => {
      if (
        file.size > 1024 * 1024 ||
        !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
      ) {
        setImageSize(file.size > 1024 * 1024);
        setValidImageFormat(
          !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
        );
        setImageFile((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[columnId];
          return updatedData;
        });
        setTempImage((prevData) => {
          const updatedData = { ...prevData };
          updatedData[columnId] = '';
          return updatedData;
        });
        event.target.value = '';
        return;
      }

      setImageFile((prevData) => ({
        ...prevData,
        [columnId]: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setTempImage((prevData) => ({
          ...prevData,
          [columnId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const imageChangeHandler = (columnId) => {
    const fileInput = document.getElementById(columnId);
    fileInput.click();
  };

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  //proforma invoice details change handler
  const proformaInvoiceDetailsChangeHandler = (index, field, value) => {
    setProformaInvoiceDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  //Description change Handler
  const descriptionItemChangeHandler = (
    index,
    field,
    value,
    setDescription
  ) => {
    setDescription((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // address check box change handler
  const addressPickupCheckboxChageHandler = (event) => {
    setSameDeliveryCheck(event.target.checked);
    if (event.target.checked) {
      setPickUpDetails({
        pickUpPlace: supplierDetails.buyerName,
        pickUp_gstin: supplierDetails.buyer_gstin,
        pickUpCell: supplierDetails.buyer_cell.buyer_cell1,
        pickUpAdressline1: supplierDetails.buyerAdressline1,
        pickUpAdressline2: supplierDetails.buyerAdressline2,
        pickUpAdressline3: supplierDetails.buyerAdressline3,
      });
    } else {
      setPickUpDetails({
        pickUpPlace: '',
        pickUp_gstin: '',
        pickUpCell: '',
        pickUpAdressline1: '',
        pickUpAdressline2: '',
        pickUpAdressline3: '',
      });
    }
    removeKey(pickUpDetails, setIsValidKeyFetched);
  };

  const addressDeliveryCheckboxChangeHandler = (event) => {
    setSamePickupCheck(event.target.checked);
    if (event.target.checked) {
      setConsigneeDetails({
        consigneeName: buyerDetails.supplierName,
        consigneeGstin: buyerDetails.sup_gstin,
        consigneePanNo: buyerDetails.sup_panNo,
        consigneeState: buyerDetails.supplierState,
        consigneeEmail: buyerDetails.sup_email,
        consigneeContactNo: buyerDetails.supplierContact,
        consigneeAdressline1: buyerDetails.supplierAdressline1,
        consigneeAdressline2: buyerDetails.supplierAdressline2,
        consigneeAdressline3: buyerDetails.supplierAdressline3,
      });
    } else {
      setConsigneeDetails({
        consigneeName: '',
        consigneeGstin: '',
        consigneePanNo: '',
        consigneeState: '',
        consigneeEmail: '',
        consigneeContactNo: '',
        consigneeAdressline1: '',
        consigneeAdressline2: '',
        consigneeAdressline3: '',
      });
    }
    removeKey(consigneeDetails, setIsValidKeyFetched);
  };

  // add invoice item
  const invoiceItemDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    let updatedItem;
    if (name.includes('totalGst.')) {
      const gstName = name.split('.')[1];

      if (gstName === 'cGst' || gstName === 'sGst') {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            [gstName]: value !== '' ? parseFloat(value) : '',
            iGst: 0,
          },
        };
      } else {
        updatedItem = {
          ...invoiceItem,
          totalGst: {
            ...invoiceItem.totalGst,
            iGst: value !== '' ? parseFloat(value) : '',
            cGst: 0,
            sGst: 0,
          },
        };
      }
    } else {
      updatedItem = {
        ...invoiceItem,
        [name]:
          name === 'quantity' || name === 'price_per_unit'
            ? parseFloat(value)
            : value,
      };
    }
    setInvoiceItem(updatedItem);
  };

  const addInvoiceItemHandler = () => {
    const isObjectEmpty = (obj) =>
      Object.values(obj).some((value) => value === '');
    const isInvoiceItemEmpty = isObjectEmpty(invoiceItem);

    if (isInvoiceItemEmpty) {
      setAddInvoiceItemError(true);
      return;
    }

    if (productIndex.toString()) {
      setiInvoiceItemDataList((prev) => {
        return prev.map((product, index) =>
          index === productIndex
            ? {
                ...invoiceItem,
              }
            : product
        );
      });
      setInvoiceItem(tempInvoiceItem);
      setProductIndex('');
    } else {
      setiInvoiceItemDataList([...invoiceItemDataList, invoiceItem]);
      setInvoiceItem(tempInvoiceItem);
    }
  };

  // edit invoice item
  const editItemHandler = (row, index) => {
    setInvoiceItem(row);
    setProductIndex(index);
  };

  // delete invoice item
  const deleteItemHandler = (row, index) => {
    setiInvoiceItemDataList((prevDtata) => {
      const newDetails = [...prevDtata];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  // document contruction
  const documentFormDataConstructed = () => {
    const isObjectEmpty = (obj, excludedProperties) => {
      const emptyKeys = Object.entries(obj).filter(
        ([key, value]) => !excludedProperties.includes(key) && value === ''
      );
      return emptyKeys.length === 0;
    };

    const isItemEmpty = (item) =>
      Object.values(item).some(
        (value) =>
          value === '' ||
          (typeof value === 'number' && isNaN(value)) ||
          (typeof value === 'string' && value === 'Invalid Date')
      );

    function hasEmptyValue(obj) {
      if (Object.keys(obj).length === 0) {
        return true;
      }
      return Object.values(obj).some((value) => !value);
    }

    const hasInvalidValues = (obj, excludeKeys = []) => {
      const excludeSet = new Set(excludeKeys);

      return Object.entries(obj).some(
        ([key, value]) =>
          !excludeSet.has(key) &&
          (value === '' || value === null || value === undefined)
      );
    };

    const optiontionalProperty = [
      'buyer_cell2',
      'buyer_website',
      'buyer_gstin',
      'sup_gstin',
      'consigneeGstin',
      'pickUp_gstin',
    ];

    const isSupplierDetailsEmpty = hasInvalidValues(
      supplierDetails,
      optiontionalProperty
    );

    const isProformaInvoiceDetailsEmpty = hasInvalidValues(
      proformaInvoiceDetails,
      optiontionalProperty
    );
    const isBuyerDetailsEmpty = hasInvalidValues(
      buyerDetails,
      optiontionalProperty
    );
    const istransportDetailsEmpty = isItemEmpty(transportDetails);
    const isBankDetailsEmpty = hasEmptyValue(bankDetails[0]);
    const isinvoiceItemDataListEmpty = invoiceItemDataList.length === 0;
    const iscontentdescriptionEmpty = contentdescription.length === 0;
    const isPickupPlaceEmpty = hasInvalidValues(
      pickUpDetails,
      optiontionalProperty
    );
    const isConsigneeDetailsEmpty = hasInvalidValues(
      consigneeDetails,
      optiontionalProperty
    );

    if (
      isProformaInvoiceDetailsEmpty ||
      isSupplierDetailsEmpty ||
      isBuyerDetailsEmpty ||
      istransportDetailsEmpty ||
      isBankDetailsEmpty ||
      isinvoiceItemDataListEmpty ||
      iscontentdescriptionEmpty ||
      isPickupPlaceEmpty ||
      isConsigneeDetailsEmpty
    ) {
      // Handle empty fields error, display a message or perform appropriate action
      setSaveButtonStatus(true);
      setFormSubmitError(true);
      setTimeout(() => {
        setFormSubmitError(false);
      }, 2000);
      return { data: {}, formData: {} };
    }

    const proformaInvoiceDataDetails = {
      supplierDetails: {
        supplierName: supplierDetails.buyerName,
        sup_cell: {
          sup_cell1: supplierDetails.buyer_cell.buyer_cell1,
          sup_cell2: supplierDetails.buyer_cell.buyer_cell2,
        },
        sup_email: supplierDetails.buyer_email,
        sup_gstin: supplierDetails.buyer_gstin || 'NA',
        sup_panNo: supplierDetails.buyer_panNo,
        supplieradressline1: supplierDetails.buyerAdressline1,
        supplieradressline2: supplierDetails.buyerAdressline2,
        supplieradressline3: supplierDetails.buyerAdressline3,
        sup_website: supplierDetails.buyer_website,
      },
      receiverDetails: {
        receiverName: buyerDetails.supplierName,
        receiverGstin: buyerDetails.sup_gstin || 'NA',
        receiverPanNo: buyerDetails.sup_panNo,
        receiverEmail: buyerDetails.sup_email,
        receiverState: proformaInvoiceDetails[0].invState,
        receiverContactNo: buyerDetails.supplierContact,
        receiverAdressline1: buyerDetails.supplierAdressline1,
        receiverAdressline2: buyerDetails.supplierAdressline2,
        receiverAdressline3: buyerDetails.supplierAdressline3,
      },
      invoiceDetails: proformaInvoiceDetails[0],
      transportDetails: transportDetails[0],
      isGstPayable: false,
      bankDetails: bankDetails[0],
      stateToShipDetails: {
        stateToshipName: buyerDetails.supplierState,
        stateToshipCode: buyerDetails.supplierStateCode,
      },
      category: 'Livestock',
      paymentMode: 'Online',
      discPercent: discount,
      deliveryType: 'free delivery',
      orderType: 'Buy order',
      items: invoiceItemDataList,
      termsConditions: [...termsConditions.map((obj) => obj.term)],
    };

    if (sameDeliveryCheck) {
      proformaInvoiceDataDetails.consigneeDetails = {
        consigneeName: buyerDetails.supplierName,
        consigneeGstin: buyerDetails.sup_gstin || 'NA',
        consigneePanNo: buyerDetails.sup_panNo,
        consigneeState: proformaInvoiceDetails[0].invState,
        consigneeEmail: buyerDetails.sup_email,
        consigneeContactNo: buyerDetails.supplierContact,
        consigneeAdressline1: buyerDetails.supplierAdressline1,
        consigneeAdressline2: buyerDetails.supplierAdressline2,
        consigneeAdressline3: buyerDetails.supplierAdressline3,
      };
    } else {
      proformaInvoiceDataDetails.consigneeDetails = {
        consigneeName: consigneeDetails.consigneeName,
        consigneeGstin: consigneeDetails.consigneeGstin || 'NA',
        consigneePanNo: consigneeDetails.consigneePanNo,
        consigneeState: consigneeDetails.consigneeState,
        consigneeEmail: consigneeDetails.consigneeEmail,
        consigneeContactNo: consigneeDetails.consigneeContactNo,
        consigneeAdressline1: consigneeDetails.consigneeAdressline1,
        consigneeAdressline2: consigneeDetails.consigneeAdressline2,
        consigneeAdressline3: consigneeDetails.consigneeAdressline3,
      };
    }

    if (samePickupCheck) {
      proformaInvoiceDataDetails.pickUpDetails = {
        pickUpPlace: supplierDetails.buyerName,
        pickUp_gstin: supplierDetails.buyer_gstin || 'NA',
        pickUpCell: buyerDetails.supplierContact,
        pickUpAdressline1: supplierDetails.buyerAdressline1,
        pickUpAdressline2: supplierDetails.buyerAdressline2,
        pickUpAdressline3: supplierDetails.buyerAdressline3,
      };
    } else {
      proformaInvoiceDataDetails.pickUpDetails = {
        pickUpPlace: pickUpDetails.pickUpPlace,
        pickUp_gstin: pickUpDetails.pickUp_gstin || 'NA',
        pickUpCell: pickUpDetails.pickUpCell,
        pickUpAdressline1: pickUpDetails.pickUpAdressline1,
        pickUpAdressline2: pickUpDetails.pickUpAdressline2,
        pickUpAdressline3: pickUpDetails.pickUpAdressline3,
      };
    }

    const data = {
      docId: proformaInvoiceDetails[0].invNo,
      networkId: window.AppConfigData.EnterpriseId,
      parentId: parentId,
      orgId: orgid,
      proxyId: proxyId,
      docCreatorId: username,
      type: 'proformaInvoice',
      docCreatorName: username,
      docCreateDate: proformaInvoiceDetails[0].invIssueDate,
      docReceiverName: buyerDetails.supplierName,
      status: 'created',
      proformaInvoiceDataDetails,
    };

    const contentInfo = {
      contentname: `${proformaInvoiceDetails[0].invNo}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: contentdescription,
      expirydate: proformaInvoiceDetails[0].invDueDate,
      assetdeveloperid: window.AppConfigData.EnterpriseId,
    };

    const generatedDateInfo = {
      docGeneratedDate: formattedValue,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(proformaInvoiceDataDetails));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('generatedDateInfo', JSON.stringify(generatedDateInfo));
    formData.append('proformaInvoiceLogo', imageFile.logo || '');
    formData.append('sup_sign_Img', imageFile.sign || '');
    formData.append('qrCodeImg', imageFile.qrimage || '');

    return { data, formData };
  };

  //post request to save proforma invoice
  const saveProformaDetailsHandler = async ({ data }) => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );
      if (response?.status === 201) {
        const formattedDate = formatDate(
          new Date(response.data.docDetails.docCreateDate),
          'dd-MM-yyyy'
        );
        const tempProformaInvoiceList = {
          viewLink: response.data.docDetails.LocationUrl,
          invNo: proformaInvoiceDetails[0].invNo,
          buyerName: buyerDetails.supplierName,
          date: formattedDate,
          status: response.data.docDetails.status,
        };
        setProformaInvoiceList((prevList) => [
          ...prevList,
          tempProformaInvoiceList,
        ]);
        setDocumentDetailsError('Proforma invoice submitted.');
        setTimeout(() => {
          cancelButtonHandler();
        }, 300);
        return response;
      } else {
        // setErrorMesg(response);
        setDocumentDetailsError(response);
      }

      setIsDocumentDetailsLoaded(true);
      return response;
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
        setDocumentDetailsError(
          'Network connection issue. Please check your internet connection.'
        );
      }
      return error.message;
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const fetchGeneratedDocument = async (formData, type) => {
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );

    if (response?.status === 200) {
      const tempPurchaseList = {
        viewLink: response.data.LocationUrl,
        status: response.data.status,
        // orderId: purchaseOrderDetails.purchaseOrderID,
        invNo: response.data.docId,
        buyerName: buyerDetails.supplierName,
        date: response.data.docGeneratedDate,
      };

      setProformaInvoiceList((prevList) =>
        prevList.map((item) =>
          item.invNo === tempPurchaseList.invNo ? tempPurchaseList : item
        )
      );
      setDocumentDetailsError('Purchase order generated.');
      handleDownload(response.data.LocationUrl, response.data.orderId);
      setTimeout(() => {
        cancelButtonHandler();
      }, 300);
    } else {
      setDocumentDetailsError(response);
    }
    setIsDocumentDetailsLoaded(true);
  };

  // generate purchase order
  const generateDocumentHandler = async ({ data, formData }) => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      if (documentStatus === 'created') {
        const response = await fetchGeneratedDocument(formData, data.type);
      } else {
        // setDocumentStatus('created');
        const saveResponse = await saveProformaDetailsHandler({ data });

        if (saveResponse?.status === 201) {
          setIsDocumentDetailsLoaded(false);
          const response = await fetchGeneratedDocument(formData, data.type);
        }
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
        setDocumentDetailsError(
          'Network connection issue. Please check your internet connection.'
        );
      }
      setDocumentDetailsError(error.message);
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  const cancelButtonHandler = () => {
    setAddNewProformaInvoice(false);
    setShowAlert(false);
    setFormSubmitError(false);
    setSaveButtonStatus(false);
    setImageFile({});
    setTempImage({});
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setProformaInvoiceDetails(tempProformaInvoiceDetailsRowData);
    setTransportDetails(tempTranportDetails);
    setBankDetails(tempBankDetailsData);
    setiInvoiceItemDataList([]);
    setDiscount(0);
    setInvoiceItem({
      description: '',
      hsn_sac: '',
      quantity: '',
      unit: '',
      price_per_unit: '',
      totalGst: {
        sGst: 0,
        cGst: 0,
        iGst: 0,
      },
    });
    setAddInvoiceItemError(false);
    setTermsConditions(tempTermConditionValue);

    setDocumentDetailsError('');
    setDocumentStatus('');
    setConsigneeDetails({});
    setPickUpDetails({});
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  const deleteTerrmHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  // snackbar action handler
  const snackbarActionHandler = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => setDocumentDetailsError('')}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );
  if (isLoading) return <LoadingPage />;

  return (
    <>
      {!addNewProformaInvoice && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Proforma Invoice Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempProformaInvoiceListDetails}
            rowDataList={proformaInvoiceList}
            documentDetailsHander={getDocumentDetailsHander}
          />
          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewProformaInvoiceFormHandler}>
                Add Proforma Invoice
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {addNewProformaInvoice && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Proforma Invoice
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box>
            <Box>
              <CommanTable
                columnsData={tempProformaInvoiceDetailsColumn}
                rowsData={proformaInvoiceDetails}
                image={tempImage}
                validKey={isValidKeyFetched}
                imageChangeHandler={imageChangeHandler}
                imageFileChangeHandler={imageFileChangeHandler}
                inputFieldChangeHandler={proformaInvoiceDetailsChangeHandler}
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box
              component='form'
              sx={{
                // background: 'lightblue',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                p: '1rem',
                // width: { xs: '100%', md: '70%', lg: '50%' },
                mx: 'auto',
                mb: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > * ': { background: '#fff', alignItems: 'center' },
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '1.5rem',
                }}
              >
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  Proforma Description
                </Typography>

                <TextField
                  error={saveButtonStatus && contentdescription.length === 0}
                  id='contentdescription'
                  label='Please enter proforma description'
                  variant='standard'
                  sx={{ display: 'flex', flex: 1, '&>*': { width: '100%' } }}
                  onChange={(event) =>
                    setContentdescription(event.target.value)
                  }
                />
              </Box>
            </Box>
            <Box>
              <UserForm
                saveButtonStatus={saveButtonStatus}
                buyerDetails={supplierDetails}
                supplierDetails={buyerDetails}
                validKey={isValidKeyFetched}
                userInputBuyerChangehandler={(event) =>
                  formUserInputChangeHandler(event, setSupplierDetails)
                }
                userInputSupplierChangehandler={(event) =>
                  formUserInputChangeHandler(event, setBuyerDetails)
                }
                invoice={true}
              />
            </Box>
            <Box
              sx={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                mb: '1rem',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  // required
                  control={<Checkbox />}
                  label='Is the address the same as the current pickup location?'
                  onChange={addressPickupCheckboxChageHandler}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  // required
                  control={<Checkbox />}
                  label='Is the address the same as the current delivery location?'
                  onChange={addressDeliveryCheckboxChangeHandler}
                />
              </FormGroup>
            </Box>

            <Box
              sx={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                mb: '1rem',
                padding: '1rem',
              }}
            >
              <Box sx={{ display: 'flex', gap: '1.5rem', '&>*': { flex: 1 } }}>
                <ConsigneeDetails
                  saveButtonStatus={saveButtonStatus}
                  consigneeDetailsData={pickUpDetails}
                  validKey={isValidKeyFetched}
                  userInputBuyerChangehandler={(event) =>
                    formUserInputChangeHandler(event, setPickUpDetails)
                  }
                  componentType='pickup'
                />
                <ConsigneeDetails
                  saveButtonStatus={saveButtonStatus}
                  consigneeDetailsData={consigneeDetails}
                  validKey={isValidKeyFetched}
                  userInputBuyerChangehandler={(event) =>
                    formUserInputChangeHandler(event, setConsigneeDetails)
                  }
                  componentType='billedTo'
                />
              </Box>
            </Box>

            <Box>
              <CommanTable
                columnsData={tempTranportDetailsLabel}
                rowsData={transportDetails}
                validKey={isValidKeyFetched}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setTransportDetails
                  )
                }
                saveButtonStatus={false}
              />
            </Box>
            <Box>
              <CommanTable
                columnsData={tempBankdetailsLabel}
                rowsData={bankDetails}
                validKey={isValidKeyFetched}
                inputFieldChangeHandler={(index, field, value) =>
                  descriptionItemChangeHandler(
                    index,
                    field,
                    value,
                    setBankDetails
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
            </Box>
            <Box>
              <InvoiceItemForm
                invoiceItem={invoiceItem}
                invoiceItemDetailsChangeHandler={
                  invoiceItemDetailsChangeHandler
                }
                addInvoiceItemHandler={addInvoiceItemHandler}
                addInvoiceItemError={addInvoiceItemError}
                saveButtonStatus={saveButtonStatus}
                invoiceType={true}
              />
              <ItemDataTable
                discount={discount}
                invoiceItemDataList={invoiceItemDataList}
                deleteItemHandler={deleteItemHandler}
                editItemHandler={editItemHandler}
                setDiscount={setDiscount}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: '1rem', '&>*': { flex: 1 } }}>
              <CommanTable
                columnsData={tempTermConditionLabel}
                rowsData={termsConditions}
                validKey={isValidKeyFetched}
                tableType={'editable'}
                addItemHandler={addTermConditionHandler}
                inputFieldChangeHandler={termsConditionsChangeHandler}
                deleteItemHandler={(rowData, index) =>
                  deleteTerrmHandler(
                    index,
                    termsConditions,
                    setTermsConditions,
                    false
                  )
                }
                saveButtonStatus={saveButtonStatus}
              />
              <Box>
                <Box
                  component='form'
                  sx={{
                    padding: '1rem',
                    borderRadius: '1rem',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    mx: 'auto',
                    '& > *:not(:last-child)': { paddingBottom: '0.5rem' },
                    background: '#F6F4F3',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', md: 'row' },
                      '&>*': {
                        flex: 1,
                        height: '10rem',
                        borderRadius: '0.5rem',
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid',
                      }}
                    >
                      {tempImage['qrimage'] && (
                        <img
                          src={tempImage.qrimage}
                          style={{ width: '100%', height: '100%' }}
                          onClick={() => imageChangeHandler('qrimage')}
                        />
                      )}
                      {!tempImage['qrimage'] && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='qrimage'
                        >
                          Please choose qrcode
                        </label>
                      )}

                      <input
                        id='qrimage'
                        name='qrimage'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={(event) =>
                          imageFileChangeHandler(event, 'qrimage')
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid',
                      }}
                    >
                      {tempImage['sign'] && (
                        <img
                          src={tempImage.sign}
                          style={{ width: '100%', height: '100%' }}
                          onClick={() => imageChangeHandler('sign')}
                        />
                      )}
                      {!tempImage['sign'] && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='sign'
                        >
                          Please choose signature
                        </label>
                      )}

                      <input
                        id='sign'
                        name='sign'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={(event) =>
                          imageFileChangeHandler(event, 'sign')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {showAlert && (
              <Alert severity='success' sx={{ mt: '0.5rem' }}>
                Proforma Invoice successfully submitted.
              </Alert>
            )}
            {formSubmitError && (
              <Alert severity='error' sx={{ mt: '0.5rem' }}>
                Please fill the required field.
              </Alert>
            )}

            <Box>
              <Stack>
                <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={cancelButtonHandler}>Cancel</Button>
                  <Button
                    onClick={() =>
                      saveProformaDetailsHandler(documentFormDataConstructed())
                    }
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() =>
                      generateDocumentHandler(documentFormDataConstructed())
                    }
                  >
                    Generate
                  </Button>
                </ListItem>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={documentDetailsError ? true : false}
        autoHideDuration={5000}
        onClose={() => setDocumentDetailsError('')}
        message={documentDetailsError}
        action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={imageSize}
        autoHideDuration={2000}
        onClose={() => setImageSize(false)}
        message={'image size is too large, please select below 1 mb.'}
        // action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={validImageFormat}
        autoHideDuration={2000}
        onClose={() => setValidImageFormat(false)}
        message={
          ' Invalid image format. Please select a JPEG, PNG, or GIF image.'
        }
      />

      {!isDocumentDetailsLoaded && <LoadingPage />}
    </>
  );
};

export default SellerProformaInvoiceManagement;
