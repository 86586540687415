import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import SigninPage from './Signin/SigninPageComponent.js';
import SignupPage from './Signup/SignupPageComponent.js';
import ForgotPassword from './ForgotPassword/ForgotPasswordComponent.js';


export default class AuthRouteComponent extends React.Component{   
    render(){
        return(
            <div>
                <Switch>
                    <Route exact path={this.props.match.url+"/:code/signin" } component={(args) => <SigninPage {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/signup" } component={(args) => <SignupPage {...args}/>} />
                    <Route exact path={this.props.match.url+"/:code/forgot" } component={(args) => <ForgotPassword {...args}/>} />

                    <Route component={(args) => <Redirect to={this.props.match.url+"/1/signin"} />} />
                </Switch>
            </div>
        )
    }
}