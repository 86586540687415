import React, { useState, useEffect } from "react";
import Map from "../Map";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Typography, TextField } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// Styling for the container div
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
};

// Styling for the text and input container
const inputContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1rem",
};

// Styling for the input field
const inputFieldStyle = {
  marginRight: "1rem",
  width: "10rem",
};

// Styling for the submit button
const submitButtonStyle = {
  minWidth: "8rem",
};

const ConsciousnessChecker = ({ location, markerPostion, cardData }) => {
  console.log(location, "locationprops............");
  console.log(cardData, "CARD DATA;;;;;;;;;;;")
  const [randomNumber, setRandomNumber] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isConscious, setIsConscious] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [getMap, setGetMap] = useState(false);
  const [startGameButton, setStartGameButton] = useState(true);
  const [same, setSame] = useState(false);

  const history = useHistory();

  const generateRandomNumber = () => {
    // Generate a random 10-digit number
    const random = Math.floor(Math.random() * 10000000000);
    setRandomNumber(random.toString().padStart(10, "0"));
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };
  console.log(location, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
  const handleSubmit = () => {
    // Check if the user has typed the entire number correctly
    if (userInput === randomNumber) {
      setIsConscious(true);
      setIsActive(false);
      clearTimeout(timeoutId);

      setSame(true);
      setGetMap(true);
    }
  };

  const startGame = () => {
    setIsActive(true);
    setIsConscious(false);
    generateRandomNumber();
    setStartGameButton(false);
  };
  const [timer, setTimer] = useState(20);

  // useEffect to update the timer every second
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setIsActive(false);
      clearInterval(intervalId);

      history.push("/main/dashboardForDriver");
      // window.history.back();
    }
    // Clear the interval when component unmounts or isActive becomes false
    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, timer, history]);

  useEffect(() => {
    // Reset the timer to 10 seconds whenever the game starts
    if (isActive) {
      setTimer(20);
    }
  }, [isActive]);
  return (
    <>
      {startGameButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button variant="contained" color="primary" onClick={startGame}>
            Click here to Start the game
          </Button>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ maxWidth: "400px" }}>
          {isActive && (
            <div style={containerStyle}>
              <Typography variant="body1">
                Type the following number within {timer} seconds:
              </Typography>
              <Typography variant="h4">{randomNumber}</Typography>
              <div style={inputContainerStyle}>
                <TextField
                  variant="outlined"
                  value={userInput}
                  onChange={handleUserInput}
                  style={inputFieldStyle}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={submitButtonStyle}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>

      {isConscious && getMap && (
        <div style={{ marginTop: "2rem" }}>
          <Map
            location={location}
            MarkerMap={markerPostion}
            cardData={cardData}
            startTripBtn={true}
          />
        </div>
      )}
    </>
  );
};

export default ConsciousnessChecker;
