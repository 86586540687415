import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography } from "@mui/material";
import MapAutoComplete from "react-google-autocomplete";
import VehicleList from "../../../../../Containers/vehiclelist/VehicleList";
import LiveMonitorMap from "./livemonitormap/LiveMonitorMap";
import MainService from "../../../Common/MainService";
import { io } from "socket.io-client";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const LiveMonitor = () => {
  const [vehicle, setVehicle] = useState({});
  const [tripDetails, setTripDetails] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const { user } = MainService.getLocalData();
  const { username ,parentId} = user;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [address, setAddress] = useState(null);
  const [map_type, setMapType] = useState(null);
  const [geo, setGeo] = useState({});
  const [polyPath, setPolyPath] = useState([]);
  const [marker_position, setMarkerPosition] = useState({
    lat: 12.936350000000001,
    lng: 77.69044000000001,
  });
  const [open, setOpen] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [geoLocations, setGeoLocations] = useState([]);

  const [places, setPlaces] = useState([
    { lat: 12.936350000000001, lng: 77.69044000000001 },
    { lat: 12.938350000000001, lng: 77.69044000000001 },
  ]);

  //fetch all the vehicle locations:
  useEffect(() => {
    const fetchVehicleLocations = async () => {
      try {
        const response = await MainService.allLastLocDetails(username);
        // Assuming the response structure you provided
        const vehicleDataList = response.vehicleDataList || [];

        // Extract the geolocations from the vehicleDataList
        const extractedGeoLocations = vehicleDataList.map((vehicleData) => ({
          lat: vehicleData.geoLocation.lat,
          lng: vehicleData.geoLocation.lng,
        }));
        console.log(extractedGeoLocations, "extracted geolocations:");

        // Set the geolocations in state
        setGeoLocations(extractedGeoLocations);
      } catch (error) {
        console.error("Error fetching vehicle locations:", error);
      }
    };

    fetchVehicleLocations();
  }, [username]);

  //get vehicle list
  useEffect(() => {
    const fetchVehicleList = async () => {
      try {
        const response = await MainService.getVehicleList(parentId,username);
        // Assuming the response contains a property "assets" which is an array
        const assetData = response.assets || [];
        setVehicleList(assetData);
        console.log(vehicleList, "vehiclelistt:::");
      } catch (error) {
        console.error("Error fetching vehicle list:", error);
      }
    };

    fetchVehicleList();
  }, [username]);

  // Extract asset IDs from the vehicleList array
  const assetIds = vehicleList.map((asset) => asset.assetId);

  console.log(assetIds, "assetidsss/////:::");
  const openMap = () => {
    setOpen(!open);
  };

  const indvVehiclePos = (data) => {
    localStorage.setItem("vehicle_id", data);
    window.open("/vehicles/status/" + data);
  };

  const onPlaceSelected = (places) => {
    if (places && places.geometry && places.geometry.location) {
      const newMarkerPosition = {
        lat: places.geometry.location.lat(),
        lng: places.geometry.location.lng(),
      };
      setMarkerPosition(newMarkerPosition);
    } else {
      console.error("Invalid places data:", places);
    }
  };

  const theme = createTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #ddd",
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="#1976d2"
            sx={{
              textTransform: "uppercase",
              letterSpacing: "2px",
            }}
          >
            FLEET Monitoring
          </Typography>
        </Box>

        <Box>
          <div style={{ marginTop: "2%" }}>
            <LiveMonitorMap geoLocations={geoLocations} assetIds={assetIds} />

            <div
              style={{
                position: "absolute",
                top: "30%",
                left: "85%",
                width: "500px",
              }}
            >
              <VehicleList assetIds={assetIds}/>
            </div>
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default LiveMonitor;
