import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
// import VehicleStatus from "../../components/Main/Broker/LiveMonitoring/VehicleStatus/VehicleStatus";

const VehicleList = ({ assetIds }) => {
  console.log("Received Asset IDs:", assetIds);

  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "10px",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
      }}
    >
      <h2>Asset IDs</h2>
      <ul>
        {assetIds.map((assetId) => (
          <Link
            target="_blank"
            to={{
              pathname: `/main/brokerLiveMonitoring?assetId=${assetId}`,

              state: { fromDashboard: true },
            }}
          >
          <h4>{assetId}</h4>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default VehicleList;
