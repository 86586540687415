import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { event } from 'react-ga';
import {
  capitalizeFirstLetter,
  isValidUserFormData,
} from '../Invoicecomponents/utils/genrateUniqueNumber';
import { setDate } from 'date-fns';
import dayjs from 'dayjs';
import MainService from '../../Common/MainService';
import LoadingPage from '../loadingpage/LoadingPage';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)({
  padding: '5px 10px ',
});

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

export const listOfQuestion = (projectOverviewDetails) => {
  const {
    produceType,
    nameOfassetType,
    sponsoredCompany,
    serviceProviderName,
  } = projectOverviewDetails;
  return [
    {
      key: 'additionOfFarmer',
      field: `Is the Farmers added for this month? If yes, please provide details`,
    },
    {
      key: 'productExtracted',
      field: `Is ${produceType} extracted from ${nameOfassetType}  If Yes, Provide details'`,
    },
    {
      key: 'productSafe',
      field: `Is ${produceType} stored in safe and secure place, if yes, please mention place where ${produceType} is stored`,
    },
    {
      key: 'assetSold',
      field: `Is any ${nameOfassetType} sold. If yes, please provides details about the ${nameOfassetType}  in remarks section`,
    },
    {
      key: 'organicCertified',
      field: 'Is organic certification done. If Yes, please mention details',
    },
    {
      key: 'trainingDone',
      field: 'Is training given to the assigned farmer?',
    },
    {
      key: 'taskGiven',
      field:
        'Any necessary tasks assigned to the farmer If yes, please mention details',
    },
    {
      key: 'taskItemOpen',
      field: 'Any tasks/action item is open.',
    },
    {
      key: 'taskItemClose',
      field: 'Any tasks/action item is close.',
    },
    {
      key: 'requestFromFarmer',
      field:
        'Any requests and/or concerns of farmer. If yes, please mention details',
    },
    {
      key: 'siteHealthStatusCaptured',
      field:
        'Is Health Status report is captured. If yes, please mention details',
    },
    {
      key: 'farmComplianceDone',
      field: `Is ${nameOfassetType} Farm compliance done If yes, please attach report.`,
    },
    {
      key: 'assetRemoteMonitoring',
      field: `Is remote monitoring of ${nameOfassetType} in place. If yes, please share.`,
    },
    {
      key: 'newAssetBirth',
      field: `Any birth of new ${nameOfassetType} If yes, please mention details`,
    },
    {
      key: 'assetHealthStatusCaptured',
      field: `Is health status of ${nameOfassetType} captured. If yes, provide details in remarks.`,
    },
    {
      key: 'assetLost',
      field: `Any ${nameOfassetType} lost or damaged?. If yes, please mention action taken.`,
    },
    {
      key: 'insuranceDone',
      field: `Is insurance of ${nameOfassetType} done?`,
    },
    {
      key: 'privateInsurerAvailable',
      field: 'Is private insurer identified.',
    },
    {
      key: 'pestsControlled',
      field:
        'Is the farmer adopting practices and controls to keep "pests" at an acceptable level.',
    },
    {
      key: 'migrationDone',
      field:
        'Any migration done within this month. If yes, please mention details.',
    },
    {
      key: 'operatorWeeklyReportMaintain',
      field: `Is operator providing weekly report about ${nameOfassetType} to ${serviceProviderName} and ${sponsoredCompany}?`,
    },
    {
      key: 'weeklyReportMaintain',
      field: 'Is the weekly report maintained',
    },
    {
      key: 'farmerWellPerform',
      field: 'Is the farmer performing well',
    },
    {
      key: 'farmerGuidelinesDone',
      field:
        'Is Guidelines for the farmer prepared. If yes, please mention details',
    },
    {
      key: 'assetAttendanceOccur',
      field: `Is the attendance of ${nameOfassetType} once a week happening. If yes, provide details.`,
    },
    {
      key: 'assetPhotosOccur',
      field: `Is the photos of ${nameOfassetType} recorded for this month`,
    },
    {
      key: 'farmerWeeklyReportMaintain',
      field: `Is the farmer providing weekly report about the farm and ${nameOfassetType}`,
    },
    {
      key: 'envCaptured',
      field:
        'Are environmental parameters like temperature, humidity, etc., of colonies captured once a week. If yes, please provide details',
    },
    {
      key: 'assetFeedDone',
      field: `Any feed provided. If yes, provide feed details about ${nameOfassetType} with the name of feeder and date.`,
    },
    {
      key: 'assetLocationProvided',
      field: `Provide location of ${nameOfassetType} for this month`,
    },
    {
      key: 'assetMigrationDone',
      field: 'If migration happens, provide migration site address in remarks',
    },
    {
      key: 'assetWeightDone',
      field: `Is ${nameOfassetType} weight recorded? If yes, provide details.`,
    },
    {
      key: 'operationalIssue',
      field: 'Is there any operational issue? If yes, please mention.',
    },
    {
      key: 'farmerRaisedIssue',
      field: `Any issue of the farm and/or ${nameOfassetType} raised by the farmer. If yes, please mention`,
    },
    {
      key: 'operationDonePerfect',
      field: 'Are the operations managed professionally.',
    },
    {
      key: 'farmerHasPhone',
      field:
        'Does the operator/farmer have an Android phone? If yes, please mention details',
    },
    {
      key: 'sOWDone',
      field:
        'Any milestones as per SOW completed this month. If yes, please mention',
    },
    {
      key: 'farmerInRisk',
      field: 'Any risk identified with the farmer. If yes, please mention',
    },
    {
      key: 'assetInRisk',
      field: `Any risk identified with ${nameOfassetType}. If yes, please mention with asset number`,
    },
  ];
};

const GenrateNewReport = ({
  activeContentHandler,
  reportDetailsData,
  reportDetailsDataStateHandler,
  reportDataList,
  reportdataListHandler,
  proxyId = '',
}) => {
  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();
  const [checkPointMenuAnchorEl, setCheckPointMenuAnchorEl] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState('');
  const [checkpointDataObj, setCheckpointDataObj] = useState([]);
  const [genrate, setGenrate] = useState(false);

  const [reportDetails, setReportDetails] = useState({
    nameOfReportPreparedBy: '',
    nameOfReportReviewedBy: parentId,
    nameOfReportPreparedByContactNo: '',
    nameOfReporter: '',
    nameofReporterEmail: '',
  });

  const [
    approvedProductionManagerDetails,
    setApprovedProductionManagerDetails,
  ] = useState({
    approvedProductionManagerName: '',
    approvedProductionManagerPhoneNumber: '',
    approvedProductionManagerEmail: '',
  });
  const [approvedFarmerOperatorDetails, setApprovedFarmerOperatorDetails] =
    useState({
      approvedFarmerOperatorName: '',
      approvedFarmerOperatorPhoneNumber: '',
      approvedFarmerOperatorEmail: '',
    });

  const [projectOverviewDetails, setProjectOverviewDetails] = useState({
    projectId: '',
    serviceProviderName: '',
    sponsoredCompany: '',
    nameOfassetType: '',
    nameOfBreed: '',
    produceType: '',
    noOfUnits: 0,
    reportId: '',
    orderId: '',
  });

  const [reportDuration, setReportDuration] = useState({
    startDate: '',
    endDate: '',
  });
  const [projectDuration, setProjectDuration] = useState({
    projectStartDate: '',
    projectEndDate: '',
    projectModifiedEndDate: '',
  });

  const [menuItemRemark, setMenuItemRemark] = useState('');
  const [reportEmptyFieldName, setReportEmptyFieldName] = useState(null);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const checkboxMenuOpen = Boolean(checkPointMenuAnchorEl);

  const checkboxMenuOpenHandler = (event, index) => {
    setCheckPointMenuAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  // check box menu item handler
  const checkboxMenuItemOptionHandler = (index, option) => {
    console.log('breaking', index, option);
    if (index === null) {
      return;
    }
    setCheckpointDataObj((prev) => ({
      ...prev,
      [`${capitalizeFirstLetter(
        listOfQuestion(projectOverviewDetails)[index].key
      )}`]: option,
    }));
    checkboxMenuCloseHandler();
  };

  // menu item remark change handler
  const menuItemRemarkChangeHandler = (event, index) => {
    const key = listOfQuestion(projectOverviewDetails)[index].key;
    const fieldValue = event.target.value;
    setMenuItemRemark(fieldValue);
    setCheckpointDataObj((prev) => ({
      ...prev,
      [`${key}Details`]: fieldValue,
    }));
  };

  const checkboxMenuCloseHandler = () => {
    setCheckPointMenuAnchorEl(null);
    setSelectedItemIndex(null);
  };

  // change handler to get the user input
  const userInputChangeHandler = (event, setStatehandler) => {
    const name = event.target.name;
    const value = event.target.value;
    setStatehandler((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitFormHandler = async (props) => {
    setIsSubmitButtonClicked(true);
    const formattedData = {};
    for (const item of listOfQuestion(projectOverviewDetails)) {
      const key = item.key;
      const option = checkpointDataObj[capitalizeFirstLetter(key)];
      const remark = checkpointDataObj[`remark${key}`];
      formattedData[capitalizeFirstLetter(key)] = option;
      formattedData[`${key}Details`] = remark;
    }
    const reportDataDetails = {
      nameOfReportPreparedBy: reportDetails.nameOfReportPreparedBy,
      nameOfReportReviewedBy: reportDetails.nameOfReportReviewedBy,
      networkId: window.AppConfigData.EnterpriseId,
      orgId: orgid,
      projectOverviewDetails: {
        ...projectOverviewDetails,
        farmerName: approvedFarmerOperatorDetails.approvedFarmerOperatorName,
      },
      reportDuration: {
        startDate: reportDuration.startDate,
        endDate: reportDuration.endDate,
      },
      projectDuration,
      checkListDetails: checkpointDataObj,
      approvedProductionManagerDetails: approvedProductionManagerDetails,
      approvedFarmerOperatorDetails: approvedFarmerOperatorDetails,
    };

    const data = {
      docId: projectOverviewDetails.reportId,
      networkId: window.AppConfigData.EnterpriseId,
      orgId: orgid,
      parentId: parentId,
      proxyId: '',
      docCreatorId: username,
      type: 'report',
      docCreatorName: username,
      docCreateDate: reportDuration.startDate,
      docReceiverName: parentId,
      // "reportReviewerName": "",
      // "reportReviewDate": "",
      status: 'created',
      reportDataDetails: reportDataDetails,
    };

    const isStartDateInvalid = !!(reportDuration.startDate === 'Invalid Date');
    const isEndDateInvalid = !!(reportDuration.endDate === 'Invalid Date');
    const allIsFieldsHaveYesNoNa = !Object.keys(formattedData)
      .filter((key) => key.startsWith('is'))
      .every((key) => ['Yes', 'No', 'NA'].includes(formattedData[key]));

    const isReportDetailsEmpty = isValidUserFormData(
      reportDetails,
      setReportEmptyFieldName
    );
    const isProjectOverviewDetailsEmpty = isValidUserFormData(
      projectOverviewDetails,
      setReportEmptyFieldName
    );
    const isApprovedProductionManagerDetailsEmpty = isValidUserFormData(
      approvedProductionManagerDetails,
      setReportEmptyFieldName
    );

    const isApprovedFarmerOperatorDetailsEmpty = isValidUserFormData(
      approvedFarmerOperatorDetails,
      setReportEmptyFieldName
    );

    if (
      !isReportDetailsEmpty ||
      !isProjectOverviewDetailsEmpty ||
      !isApprovedProductionManagerDetailsEmpty ||
      !isApprovedFarmerOperatorDetailsEmpty ||
      isStartDateInvalid ||
      isEndDateInvalid ||
      allIsFieldsHaveYesNoNa
    ) {
      // Set error state or handle invalid form
      return;
    }
    setIsLoading(true);

    if (props === 'update') {
      try {
        const response = await MainService.updateDocumentsApi(
          parentId,
          orgid,
          suborgid,
          username,
          { reportDataDetails, docUpdatedDate: formattedValue },
          projectOverviewDetails.reportId,
          'report'
        );
        console.log('report response---------------', response);
        if (response?.status === 201) {
          const { status, docCreateDate } = response.data.docDetails;
          const tempReportObject = {
            id: reportDataList.length + 1,
            reportid: projectOverviewDetails.reportId,
            docReceiverName: parentId,
            date: docCreateDate,
            status: status,
          };
          reportdataListHandler((prev) => [...prev, tempReportObject]);
        }
        setTimeout(() => {
          cancelReportHandler();
        }, 300);
      } catch (error) {
        console.log(error);
        setReportEmptyFieldName('Report has not been genrated.');
      } finally {
        setIsLoading(false);
      }
    } else {
    }

    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );
      console.log('report response', response);
      if (response.includes(409)) {
        setReportEmptyFieldName('Duplicate content provide unique report id');
        return;
      }
      if (response?.status === 201) {
        const { status, docCreateDate } = response.data.docDetails;
        const tempReportObject = {
          id: reportDataList.length + 1,
          reportId: projectOverviewDetails.reportId,
          docReceiverName: parentId,
          date: docCreateDate,
          status: status,
        };
        reportdataListHandler((prev) => [...prev, tempReportObject]);
      }
      setTimeout(() => {
        cancelReportHandler();
      }, 300);
    } catch (error) {
      console.log('error--------------------', error);
      setReportEmptyFieldName('Report has not been genrated.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitButtonClicked) {
      const reportValid = isValidUserFormData(
        reportDetails,
        setReportEmptyFieldName
      );
      if (reportValid) {
        setReportEmptyFieldName(null);
        const projectValid = isValidUserFormData(
          projectOverviewDetails,
          setReportEmptyFieldName
        );
        if (projectValid) {
          const productionManagerValid = isValidUserFormData(
            approvedProductionManagerDetails,
            setReportEmptyFieldName
          );
          if (productionManagerValid) {
            const farmerOperatorValid = isValidUserFormData(
              approvedFarmerOperatorDetails,
              setReportEmptyFieldName
            );
            if (farmerOperatorValid) {
              const isStartDateInvalid = !!(
                reportDuration.startDate === 'Invalid Date'
              );
              if (isStartDateInvalid) {
                setReportEmptyFieldName('startDate');
              }
            }
          }
        }
      }

      console.log('reportValid', reportValid);
      // if (projectValid) {
      //   setReportEmptyFieldName(null);
      // }
    }
  }, [
    isSubmitButtonClicked,
    reportDetails,
    projectOverviewDetails,
    approvedProductionManagerDetails,
    approvedFarmerOperatorDetails,
    reportDuration,
  ]);

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const { username: userName, firstname, lastname, email, phone } = data[0];

    setReportDetails((prev) => ({
      ...prev,
      nameOfReportPreparedBy: userName,
      nameOfReportPreparedByContactNo: phone,
      nameOfReporter: `${firstname} ${lastname}`,
      nameofReporterEmail: email,
    }));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (Object.keys(reportDetailsData).length > 0) {
      const {
        reportDataDetails: {
          nameOfReportPreparedBy,
          nameOfReportReviewedBy,
          checkListDetails,
          approvedProductionManagerDetails,
          approvedFarmerOperatorDetails,
          projectOverviewDetails,
          reportDuration,
          projectDuration,
        },
      } = reportDetailsData;

      setReportDetails({
        nameOfReportPreparedBy: nameOfReportPreparedBy,
        nameOfReportReviewedBy: nameOfReportReviewedBy,
      });

      setCheckpointDataObj({ ...checkListDetails });
      setApprovedProductionManagerDetails(approvedProductionManagerDetails);
      setApprovedFarmerOperatorDetails(approvedFarmerOperatorDetails);
      setProjectOverviewDetails(projectOverviewDetails);
      setReportDuration(reportDuration);
      setProjectDuration(projectDuration);
      console.log('project duration', reportDetailsData);
    }
  }, [reportDetailsData]);

  const fetchGeneratedDocument = async (formData, type) => {
    console.log('before genrate', formData);
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );

    // if (response?.status === 200) {
    //   const tempPurchaseList = {
    //     viewLink: response.data.LocationUrl,
    //     status: response.data.status,
    //     orderId: purchaseOrderDetails.purchaseOrderID,
    //     buyerName: buyerDetails.buyerName,
    //   };
    // } else {
    //   console.log('error');
    // }

    console.log('already created', response);
    return response;
  };

  // review report handler
  const reivewReportHandler = async () => {
    const data = {
      docReceiverId: username,
      docReceiverName: reportDetails.nameOfReporter,
      docReceiveDate: formattedValue,
      status: 'reviewed',
    };
    setIsLoading(true);
    try {
      const response = await MainService.reviewDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data,
        projectOverviewDetails.reportId,
        'report'
      );
      console.log('report response', response);
      if (response?.status === 200) {
        const { docId, message } = response.data;
        const tempReportObject = {
          status: 'reviewed',
        };
        reportdataListHandler((prevReportDataList) =>
          prevReportDataList.map((item) => {
            console.log('is it match', item.reportId === docId);
            if (item.reportId === docId) {
              return {
                ...item,
                status: 'reviewed',
              };
            }
            return item;
          })
        );
      }

      setTimeout(() => {
        cancelReportHandler();
      }, 300);
    } catch (error) {
      console.log(error);
      setReportEmptyFieldName('Report has not been genrated.');
    } finally {
      setIsLoading(false);
    }
  };

  const genrateReportHandler = async () => {
    const formattedData = {};
    for (const item of listOfQuestion(projectOverviewDetails)) {
      const key = item.key;
      const option = checkpointDataObj[capitalizeFirstLetter(key)];
      const remark = checkpointDataObj[`remark${key}`];
      formattedData[capitalizeFirstLetter(key)] = option;
      formattedData[`${key}Details`] = remark;
    }

    const reportDataDetails = {
      reportDataDetails: {
        nameOfReportPreparedBy: reportDetails.nameOfReportPreparedBy,
        nameOfReportReviewedBy: reportDetails.nameOfReportReviewedBy,
        networkId: window.AppConfigData.EnterpriseId,
        orgId: orgid,
        projectOverviewDetails: {
          ...projectOverviewDetails,
          farmerName: approvedFarmerOperatorDetails.approvedFarmerOperatorName,
        },
        reportDuration,
        projectDuration,
        checkListDetails: checkpointDataObj,
        approvedProductionManagerDetails,
        approvedFarmerOperatorDetails,
      },
      contentInfo: {
        contentname: projectOverviewDetails.reportId,
        contentcreator: username,
        contentownerid: username,
        contentdescription: 'report content',
        expirydate: reportDuration.endDate,
        assetdeveloperid: window.AppConfigData.EnterpriseId,
      },
      generatedDateInfo: {
        docGeneratedDate: formattedValue,
      },
    };
    const isDataValid = !isValidUserFormData(
      reportDataDetails,
      setReportEmptyFieldName
    );
    console.log('isdatavalid', isDataValid, reportDataList);
    setIsLoading(true);
    try {
      const response = await fetchGeneratedDocument(
        reportDataDetails,
        'report'
      );
      console.log('genratereport', response);
      if (response?.status === 200) {
        const { docId, status, LocationUrl } = response.data;

        reportdataListHandler((prevReportDataList) =>
          prevReportDataList.map((item) => {
            console.log('is it match', item.reportId === docId);
            if (item.reportId === docId) {
              return {
                ...item,
                docReceiverName: reportDetails.nameOfReportReviewedBy,
                status: status,
                viewLink: LocationUrl,
              };
            }
            return item;
          })
        );
      }
      setTimeout(() => {
        cancelReportHandler();
      }, 300);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // cancel report button handler
  const cancelReportHandler = () => {
    setCheckpointDataObj([]);
    setReportDetails({
      nameOfReportPreparedBy: '',
      nameOfReportReviewedBy: '',
      nameOfReportPreparedByContactNo: '',
      nameOfReporter: '',
      nameofReporterEmail: '',
    });
    setProjectOverviewDetails({
      serviceProviderName: '',
      sponsoredCompany: '',
      projectId: '',
      nameOfassetType: '',
      noOfUnits: 0,
      nameOfBreed: '',
      produceType: '',
    });
    reportDetailsDataStateHandler({});
    setApprovedProductionManagerDetails({
      approvedProductionManagerName: '',
      approvedProductionManagerPhoneNumber: '',
      approvedProductionManagerEmail: '',
    });
    setApprovedFarmerOperatorDetails({
      approvedFarmerOperatorName: '',
      approvedFarmerOperatorPhoneNumber: '',
      approvedFarmerOperatorEmail: '',
    });
    setReportDuration({
      startDate: '',
      endDate: '',
    });
    activeContentHandler('reportList');
  };

  // snackbar action handler
  const snackbarActionHandler = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => setReportEmptyFieldName('')}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  {
    console.log('checkpointDataObj', checkpointDataObj);
  }

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <Box>
        <Box sx={{ borderBottom: '2px solid', mb: 2 }}>
          <Typography
            sx={{
              p: 1,
              fontSize: '1.6rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Monthly Report
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: '1.4rem' }}>
            Report Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'space-between',
              '&>*': { flex: 1 },
              mb: 1,
            }}
          >
            <Box>
              <TextField
                variant='standard'
                label='Report Prepared By'
                name='nameOfReportPreparedBy'
                error={'nameOfReportPreparedBy' === reportEmptyFieldName}
                sx={{ display: 'flex' }}
                value={reportDetails.nameOfReportPreparedBy}
                onChange={(event) =>
                  userInputChangeHandler(event, setReportDetails)
                }
                disabled
              />
            </Box>

            <Box>
              <TextField
                variant='standard'
                label='Report Reviewed By'
                name='nameOfReportReviewedBy'
                error={'nameOfReportReviewedBy' === reportEmptyFieldName}
                value={reportDetails.nameOfReportReviewedBy}
                sx={{ display: 'flex' }}
                onChange={(event) =>
                  userInputChangeHandler(event, setReportDetails)
                }
                disabled
              />
            </Box>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.4rem' }}>
              Overview of project
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextField
                  variant='standard'
                  label='Project id'
                  name='projectId'
                  error={'projectId' === reportEmptyFieldName}
                  value={projectOverviewDetails.projectId}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Order id'
                  name='orderId'
                  error={'orderId' === reportEmptyFieldName}
                  value={projectOverviewDetails.orderId}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Report id'
                  name='reportId'
                  error={'reportId' === reportEmptyFieldName}
                  value={projectOverviewDetails.reportId}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Service provider name'
                  name='serviceProviderName'
                  error={'serviceProviderName' === reportEmptyFieldName}
                  value={projectOverviewDetails.serviceProviderName}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Sponsored company name'
                  name='sponsoredCompany'
                  error={'sponsoredCompany' === reportEmptyFieldName}
                  value={projectOverviewDetails.sponsoredCompany}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextField
                  variant='standard'
                  label='Name of asset type'
                  name='nameOfassetType'
                  error={'nameOfassetType' === reportEmptyFieldName}
                  value={projectOverviewDetails.nameOfassetType}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Name of breed'
                  name='nameOfBreed'
                  error={'nameOfBreed' === reportEmptyFieldName}
                  value={projectOverviewDetails.nameOfBreed}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Produce type'
                  name='produceType'
                  error={'produceType' === reportEmptyFieldName}
                  value={projectOverviewDetails.produceType}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
                <TextField
                  variant='standard'
                  label='Number of unit'
                  name='noOfUnits'
                  error={'noOfUnits' === reportEmptyFieldName}
                  type='number'
                  InputProps={{ inputProps: { min: 0 } }}
                  value={projectOverviewDetails.noOfUnits}
                  onChange={(event) =>
                    userInputChangeHandler(event, setProjectOverviewDetails)
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.4rem' }}>
              Operator & Farmer details
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextField
                  variant='standard'
                  label='Operator name'
                  name='approvedProductionManagerName'
                  error={
                    'approvedProductionManagerName' === reportEmptyFieldName
                  }
                  value={
                    approvedProductionManagerDetails.approvedProductionManagerName
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedProductionManagerDetails
                    )
                  }
                />
                <TextField
                  variant='standard'
                  label='Operator number'
                  name='approvedProductionManagerPhoneNumber'
                  error={
                    'approvedProductionManagerPhoneNumber' ===
                    reportEmptyFieldName
                  }
                  value={
                    approvedProductionManagerDetails.approvedProductionManagerPhoneNumber
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedProductionManagerDetails
                    )
                  }
                />
                <TextField
                  variant='standard'
                  label='Operator email'
                  name='approvedProductionManagerEmail'
                  error={
                    'approvedProductionManagerEmail' === reportEmptyFieldName
                  }
                  value={
                    approvedProductionManagerDetails.approvedProductionManagerEmail
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedProductionManagerDetails
                    )
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TextField
                  variant='standard'
                  label='Farmer name'
                  name='approvedFarmerOperatorName'
                  error={'approvedFarmerOperatorName' === reportEmptyFieldName}
                  value={
                    approvedFarmerOperatorDetails.approvedFarmerOperatorName
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedFarmerOperatorDetails
                    )
                  }
                />
                <TextField
                  variant='standard'
                  label='Farmer number'
                  name='approvedFarmerOperatorPhoneNumber'
                  error={
                    'approvedFarmerOperatorPhoneNumber' === reportEmptyFieldName
                  }
                  value={
                    approvedFarmerOperatorDetails.approvedFarmerOperatorPhoneNumber
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedFarmerOperatorDetails
                    )
                  }
                />
                <TextField
                  variant='standard'
                  label='Farmer email'
                  name='approvedFarmerOperatorEmail'
                  error={'approvedFarmerOperatorEmail' === reportEmptyFieldName}
                  value={
                    approvedFarmerOperatorDetails.approvedFarmerOperatorEmail
                  }
                  onChange={(event) =>
                    userInputChangeHandler(
                      event,
                      setApprovedFarmerOperatorDetails
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, mb: '10px' }}>
            <Box>
              <Typography>Select Report Month</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name='startDate'
                  // error={'startDate' === reportEmptyFieldName}
                  value={
                    reportDuration.startDate
                      ? dayjs(reportDuration.startDate)
                      : ''
                  }
                  onChange={(value, event) => {
                    const formattedValue = dayjs(value).format('YYYY-MM-DD');
                    setReportDuration((prev) => ({
                      ...prev,
                      startDate: formattedValue,
                      endDate: '',
                    }));
                  }}
                />
              </LocalizationProvider>
            </Box>
            {console.log('reportDuration', projectDuration)}
            <Box>
              <Typography>End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  minDate={
                    reportDuration?.startDate
                      ? dayjs(reportDuration?.startDate).add(1, 'day')
                      : ''
                  }
                  name='endDate'
                  value={
                    reportDuration.endDate ? dayjs(reportDuration.endDate) : ''
                  }
                  onChange={(value, event) => {
                    const formattedValue = dayjs(value).format('YYYY-MM-DD');
                    setReportDuration((prev) => ({
                      ...prev,
                      endDate: formattedValue,
                    }));
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Typography>Select Project Duration</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name='startDate'
                  // error={'startDate' === reportEmptyFieldName}
                  value={
                    projectDuration.projectStartDate
                      ? dayjs(projectDuration.projectStartDate)
                      : ''
                  }
                  onChange={(value, event) => {
                    const formattedValue = dayjs(value).format('YYYY-MM-DD');
                    setProjectDuration((prev) => ({
                      ...prev,
                      projectStartDate: formattedValue,
                      projectEndDate: '',
                      projectModifiedEndDate: '',
                    }));
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <Typography>End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  minDate={
                    projectDuration?.projectStartDate
                      ? dayjs(projectDuration?.projectStartDate).add(1, 'day')
                      : ''
                  }
                  name='endDate'
                  value={
                    projectDuration?.projectEndDate
                      ? dayjs(projectDuration.projectEndDate)
                      : ''
                  }
                  onChange={(value, event) => {
                    const formattedValue = dayjs(value).format('YYYY-MM-DD');
                    setProjectDuration((prev) => ({
                      ...prev,
                      projectEndDate: formattedValue,
                    }));
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <Typography>Revised End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  minDate={
                    projectDuration?.projectStartDate
                      ? dayjs(projectDuration?.projectStartDate).add(1, 'day')
                      : ''
                  }
                  name='endDate'
                  value={
                    projectDuration.projectModifiedEndDate
                      ? dayjs(projectDuration.projectModifiedEndDate)
                      : ''
                  }
                  onChange={(value, event) => {
                    const formattedValue = dayjs(value).format('YYYY-MM-DD');
                    setProjectDuration((prev) => ({
                      ...prev,
                      projectModifiedEndDate: formattedValue,
                    }));
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 500 }}>
              Checkpoint Report
            </Typography>
          </Box>

          <Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Monthly Check Points</TableCell>
                      <TableCell align='right'>Yes/No</TableCell>
                      <TableCell align='right'>
                        Remarks & details about execution
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listOfQuestion(projectOverviewDetails)?.map(
                      (li, index) => {
                        const isSelected = ['Yes', 'No', 'NA'].includes(
                          checkpointDataObj[capitalizeFirstLetter(li.key)]
                        );
                        const shouldHighlight =
                          isSubmitButtonClicked && !isSelected;
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              background: shouldHighlight ? '#fd6e6e61' : '',
                            }}
                          >
                            <StyledTableCell
                              sx={{
                                width: '10px',
                                textAlign: 'center',
                                fontWeight: '500',
                              }}
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: '260px' }}>
                              <Typography>{li.field}</Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'end' }}>
                              <Button
                                id={index}
                                onClick={(event) =>
                                  checkboxMenuOpenHandler(event, index)
                                }
                              >
                                {checkpointDataObj[
                                  `${capitalizeFirstLetter(
                                    listOfQuestion(projectOverviewDetails)[
                                      index
                                    ].key
                                  )}`
                                ] || 'Select'}
                              </Button>
                            </StyledTableCell>
                            <StyledTableCell>
                              <TextField
                                sx={{ width: '100%' }}
                                value={
                                  checkpointDataObj[
                                    `${
                                      listOfQuestion(projectOverviewDetails)[
                                        index
                                      ].key
                                    }Details`
                                  ]
                                }
                                name={li.name}
                                onChange={(event) =>
                                  menuItemRemarkChangeHandler(event, index)
                                }
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              {console.log('reportDetailsData', reportDetailsData)}
              <Button
                variant='outlined'
                color='error'
                onClick={() => cancelReportHandler()}
              >
                Cancel
              </Button>
              {!Object.keys(reportDetailsData)?.length > 0 && (
                <Button variant='outlined' onClick={submitFormHandler}>
                  Submit
                </Button>
              )}

              {Object.keys(reportDetailsData).length > 0 && (
                <Button
                  variant='outlined'
                  onClick={() => submitFormHandler('update')}
                >
                  Update
                </Button>
              )}

              {/* {Object.keys(reportDetailsData).length > 0 &&
                reportDetailsData.status !== 'genrated' &&
                reportDetailsData.docReceiverName === username && (
                  <Button variant='outlined' onClick={reivewReportHandler}>
                    Review
                  </Button>
                )} */}

              {Object.keys(reportDetailsData).length > 0 &&
                reportDetailsData.status === 'updated' &&
                reportDetailsData?.reportDataDetails?.nameOfReportReviewedBy ===
                  username && (
                  <Button variant='outlined' onClick={genrateReportHandler}>
                    Generate
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={checkPointMenuAnchorEl}
        open={checkboxMenuOpen}
        onClose={checkboxMenuCloseHandler}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() =>
            checkboxMenuItemOptionHandler(selectedItemIndex, 'Yes')
          }
        >
          Yes
        </MenuItem>
        <MenuItem
          onClick={() => checkboxMenuItemOptionHandler(selectedItemIndex, 'No')}
        >
          No
        </MenuItem>
        <MenuItem
          onClick={() => checkboxMenuItemOptionHandler(selectedItemIndex, 'NA')}
        >
          NA
        </MenuItem>
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={reportEmptyFieldName ? true : false}
        autoHideDuration={5000}
        onClose={() => setReportEmptyFieldName('')}
        message={reportEmptyFieldName}
        action={snackbarActionHandler}
      />
    </>
  );
};

export default GenrateNewReport;
