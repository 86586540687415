import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { validateGSTIN } from '../utils/validation';

const ConsigneeDetails = ({
  saveButtonStatus,
  consigneeDetailsData,
  userInputBuyerChangehandler,
  componentType,
  validKey,
}) => {
  const [formValidationError, setFormValidationError] = useState('');

  const {
    consigneeName = '',
    pickUpPlace = '',
    consigneeContactNo = '',
    pickUpCell = '',
    consigneeGstin = '',
    consigneePanNo = '',
    pickUp_gstin = '',
    consigneeEmail = '',
    consigneeAdressline1 = '',
    consigneeAdressline2 = '',
    consigneeAdressline3 = '',
    pickUpAdressline1 = '',
    pickUpAdressline2 = '',
    pickUpAdressline3 = '',
    consigneeState = '',
  } = consigneeDetailsData || {};

  useEffect(() => {
    if (consigneeDetailsData && saveButtonStatus) {
      let fields;

      if (componentType === 'pickup') {
        fields = [
          'pickUpPlace',
          'pickUpCell',
          
          'pickUpAdressline1',
          'pickUpAdressline2',
          'pickUpAdressline3',
        ];
      } else if (componentType === 'billedTo') {
        fields = [
          'consigneeName',
          'consigneeContactNo',
          
          'consigneePanNo',
          'consigneeEmail',
          'consigneeAdressline1',
          'consigneeAdressline2',
          'consigneeAdressline3',
          'consigneeState',
        ];
      } else {
        // If componentType is undefined, set default fields
        fields = [
          'consigneeContactNo',
          'consigneeAdressline1',
          'consigneeAdressline2',
          'consigneeAdressline3',
        ];
      }

      for (const field of fields) {
        if (!consigneeDetailsData[field]) {
          setFormValidationError(field);
          return;
        }
      }

      setFormValidationError('');
    }
  }, [consigneeDetailsData, saveButtonStatus, componentType]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '& > * ': { background: '#fff', flex: 1 },
        flexDirection: { xs: 'column', md: 'row' },
        gap: '1.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          flexDirection: 'column',
        }}
      >
        {componentType === 'billedTo' && (
          <>
            <TextField
              error={formValidationError === 'consigneeName'}
              id='consigneeName'
              label='Consignee name'
              placeholder='Please enter your consignee name'
              variant='standard'
              value={consigneeName}
              InputProps={{
                readOnly: validKey?.includes('consigneeName'),
              }}
              name='consigneeName'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeContactNo'}
              id='consigneeContactNo'
              label='Consignee contact no'
              placeholder='Please enter contact no'
              variant='standard'
              value={consigneeContactNo}
              InputProps={{
                readOnly: validKey?.includes('consigneeContactNo'),
              }}
              name='consigneeContactNo'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeGstin'}
              id='consigneeGstin'
              label='consignee consignee GSTIN'
              placeholder='Please enter your consignee GSTIN'
              variant='standard'
              value={consigneeGstin}
              InputProps={{
                readOnly: validKey?.includes('consigneeGstin'),
              }}
              name='consigneeGstin'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneePanNo'}
              id='consigneePanNo'
              label='consignee consignee PAN'
              placeholder='Please enter your consignee PAN'
              variant='standard'
              value={consigneePanNo}
              InputProps={{
                readOnly: validKey?.includes('consigneePanNo'),
              }}
              name='consigneePanNo'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeEmail'}
              id='consigneeEmail'
              label='consignee email'
              placeholder='Please enter your consignee email'
              variant='standard'
              value={consigneeEmail}
              InputProps={{
                readOnly: validKey?.includes('consigneeEmail'),
              }}
              name='consigneeEmail'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
          </>
        )}
        {/* // pickUpCell */}
        {!componentType && (
          <>
            <TextField
              error={formValidationError === 'consigneeContactNo'}
              id='consigneeContactNo'
              label='Consignee contact no'
              placeholder='Please enter contact no'
              variant='standard'
              value={consigneeContactNo}
              InputProps={{
                readOnly: validKey?.includes('consigneeContactNo'),
              }}
              name='consigneeContactNo'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeAdressline1'}
              id='consigneeAdressline1'
              label='Address line 1'
              placeholder='Please enter your address line 1'
              variant='standard'
              value={consigneeAdressline1}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline1'),
              }}
              name='consigneeAdressline1'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeAdressline2'}
              id='consigneeAdressline2'
              label='Address line 2'
              placeholder='Please enter your address line 2'
              variant='standard'
              value={consigneeAdressline2}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline2'),
              }}
              name='consigneeAdressline2'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeAdressline3'}
              id='pickUpAdressline3'
              label='Address line 3'
              placeholder='Please enter your address line 3'
              variant='standard'
              value={consigneeAdressline3}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline3'),
              }}
              name='consigneeAdressline3'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
          </>
        )}
        {componentType === 'pickup' && (
          <>
            <TextField
              error={formValidationError === 'pickUpPlace'}
              id='pickUpPlace'
              label='Place name'
              placeholder='Please enter your pickUp place name'
              variant='standard'
              value={pickUpPlace}
              InputProps={{
                readOnly: validKey?.includes('pickUpPlace'),
              }}
              name='pickUpPlace'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'pickUpCell'}
              id='pickUpCell'
              label='Contact No'
              placeholder='Please enter your contact no'
              variant='standard'
              value={pickUpCell}
              InputProps={{
                readOnly: validKey?.includes('pickUpCell'),
              }}
              name='pickUpCell'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'pickUp_gstin'}
              id='pickUp_gstin'
              label='GSTIN'
              placeholder='Please enter your GSTIN'
              variant='standard'
              value={pickUp_gstin}
              InputProps={{
                readOnly: validKey?.includes('pickUp_gstin'),
              }}
              name='pickUp_gstin'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'pickUpAdressline1'}
              id='pickUpAdressline1'
              label='Address line 1'
              placeholder='Please enter your address line 1'
              variant='standard'
              value={pickUpAdressline1}
              InputProps={{
                readOnly: validKey?.includes('pickUpAdressline1'),
              }}
              name='pickUpAdressline1'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'pickUpAdressline2'}
              id='pickUpAdressline2'
              label='Address line 2'
              placeholder='Please enter your address line 2'
              variant='standard'
              value={pickUpAdressline2}
              InputProps={{
                readOnly: validKey?.includes('pickUpAdressline2'),
              }}
              name='pickUpAdressline2'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'pickUpAdressline3'}
              id='pickUpAdressline3'
              label='Address line 3'
              placeholder='Please enter your address line 3'
              variant='standard'
              value={pickUpAdressline3}
              InputProps={{
                readOnly: validKey?.includes('pickUpAdressline3'),
              }}
              name='pickUpAdressline3'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
          </>
        )}

        {componentType === 'billedTo' && (
          <>
            <TextField
              error={formValidationError === 'consigneeAdressline1'}
              id='consigneeAdressline1'
              label='Address line 1'
              placeholder='Please enter your address line 1'
              variant='standard'
              value={consigneeAdressline1}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline1'),
              }}
              name='consigneeAdressline1'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeAdressline2'}
              id='consigneeAdressline2'
              label='Address line 2'
              placeholder='Please enter your address line 2'
              variant='standard'
              value={consigneeAdressline2}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline2'),
              }}
              name='consigneeAdressline2'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeAdressline3'}
              id='consigneeAdressline3'
              label='Address line 3'
              placeholder='Please enter your address line 3'
              variant='standard'
              value={consigneeAdressline3}
              InputProps={{
                readOnly: validKey?.includes('consigneeAdressline3'),
              }}
              name='consigneeAdressline3'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
            <TextField
              error={formValidationError === 'consigneeState'}
              id='consigneeState'
              label='consignee state'
              placeholder='Please enter consignee state'
              variant='standard'
              value={consigneeState}
              InputProps={{
                readOnly: validKey?.includes('consigneeState'),
              }}
              name='consigneeState'
              onChange={userInputBuyerChangehandler}
              sx={{
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ConsigneeDetails;
