import React, { useState, useEffect, useRef } from "react";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./LegacyAssetManagementComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import socketIOClient from "socket.io-client";
import LocationMap from "../../Common/LiveMonitorMap";
import Map from "../../Common/googleMap/Map";
import { DialogContentText, IconButton, Modal } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Menu, ListSubheader } from "@mui/material";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import QRBuilder from "../../documentComponents/qrGenerator/QRBuilder";

// require("dotenv").config();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: "100vw",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },
  table: {
    maxWidth: "100%",
    overflowX: "scroll",
  },
  oddRow: {
    backgroundColor: theme.palette.background.default,
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  noAssetsText: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  loadingContainer: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

export default function LegacyAssetManagementComponent(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("NULL");
  const [selectedSiteName, setSelectedSiteName] = useState("NULL");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const [createPointPopup, setCreatePointPopup] = useState(false);
  const [mapPointPopup, setMapPointPopup] = useState(false);
  const [unmapPointPopup, setUnmapPointPopup] = useState(false);
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [selectedSolution,setSelectedSolution] = useState("");
  const [selectedPointId, setSelectedPointId] = useState("");
  const [solutionDevicesList, setSolutionDevicesList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [getUserTypeValue, setUserTypeValue] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [assetDomainValue, setAssetDomainValue] = useState(username);
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [lotIdValue, setLotIdValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [mapPopUp, setMapPopUp] = useState(false);
  const [ unmapAssetDialog,setUnmapAssetDialog] =useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [selectedSolutionId, setSelectedSolutionId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetSiteIdValue, setAssetSiteIdValue] = useState("");
  const [assetSiteNameValue, setAssetSiteNameValue] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [modelNameVal, setModelNameVal] = useState("");
  const [modelTypeVal, setModelTypeVal] = useState("");
  const [modelUrlVal, setModelUrlVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [ageVal, setAgeVal] = useState("");
  const [weightVal, setWeightVal] = useState("");
  const [genderVal, setGenderVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assetsListView, setAssetsListView] = useState(false);
  const [assetsGridView, setAssetsGridView] = useState(true);
  const [assetsMapView, setAssetsMapView] = useState(false);
  const [pointsListView, setPointsListView] = useState(false);
  const [pointsGridView, setPointsGridView] = useState(false);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [assetInfoUpdatePopup, setAssetInfoUpdatePopup] = useState(false);
  const [assetPointsList, setAssetPointsList] = useState([]);
  const [filteredAssetPointsList, setFilteredAssetPointsList] = useState([]);
  /* Asset Live Monoitoring */
  const [liveMonitorMapPopUp, setLiveMonitorMapPopUp] = useState(false);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [assetPostionList, setAssetPositionList] = useState({});
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [assetEnvData, setAssetEnvData] = useState({});
  const [assetEnvPopup, setAssetEnvPopup] = useState(false);
  /*****************      Asset Info                  ***************/
  const [getAssetInfoObj, setAssetInfoObj] = useState({});
  const [assetInfoPopup, setAssetInfoPopUp] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [pointIdValue, setPointIdValue] = useState("");
  const [pointNameValue, setPointNameValue] = useState("");
  /* QR Code */
  const [assetDeletePopup, setAssetDeletePopup] = useState(false);
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [qrBody, SetQrBody] = useState({});
  const [qrUrlValue, setQrUrlValue] = useState("");
  const [selectedAsset, setSelectedAsset] = useState({});
  /* Points */
  const [deletePointId, setDeletePointId] = useState("");
  const [deletePointAssetId, setDeletePointAssetId] = useState("");
  const [deletePointPopup, setDeletePointPopup] = useState(false);
  const [updatePointId, setUpdatePointId] = useState("");
  const [updatePointAssetId, setUpdatePointAssetId] = useState("");
  const [updatePointName, setUpdatePointName] = useState("");
  const [updatePointPopup, setUpdatePointPopup] = useState(false);
  const [error, setError] = useState(false);
  const [assetNameError, setAssetNameError] = useState(false);
  const [assetIdError, setAssetIdError] = useState(false);
  /*  */
  const userType = localStorage.getItem("userType");
  const UserRole = localStorage.getItem("UserRole");
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [usersList, setUsersList] = useState([]);
  const initialValues = useRef({
    selectedSolutionId: '',
    selectedSiteId: ''
  });

  useEffect(() => {
    // Store initial values
    initialValues.current = {
      selectedSolutionId,
      selectedSiteId
    };
  }, []);

  const handleBackButtonClick = () => {
    // Define paths based on user types
    const paths = {
      "AssetDeveloper": "./dashboardForAD",
      "CBO": "./dashboardForCBO",
      "LogisticsProvider": "./logisticproviderdashboard"
    };
    // Get the path based on user type
    const path = paths[userType] || "./profile"; // Default path if user type not recognized

    // Redirect to the determined path
    history.push(path);
  };
  
  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };
  useEffect(() => {
    getUserType();
  }, []);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("getUserTypeValue1:", data);
        setUserTypeValue(data);
        getAssetVerticalsIdList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("Internal Server Error");
      });
  };

  const getAssetVerticalsIdList = (getUserTypeValue) => {
    setIsLoading(true);
    if (getUserTypeValue) {
      MainService.getAssetVerticalsIdList(window.AppConfigData.EnterpriseId)
        .then((data) => {
          if (data.length > 0) {
            var filteredAssetVerticals;
            if (getUserTypeValue === "AssetDeveloper") {
              if (UserRole === "PREMIUM " || UserRole === "ADMIN") {
                filteredAssetVerticals = data.filter((row) => {
                  return (
                    row.friendlyName === "ANIMAL"
                    //row.friendlyName === "AGRICULTURE" ||
                    // row.friendlyName === "TRANSPORTATION"
                  );
                });
              } else {
                filteredAssetVerticals = data.filter((row) => {
                  return (
                    row.friendlyName === "ANIMAL"
                    //row.friendlyName === "AGRICULTURE"
                  );
                });
              }
            } else if (getUserTypeValue === "FOOD-PROCESSOR") {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "ANIMAL" ||
                  row.friendlyName === "AGRICULTURE"
                );
              });
            } else if (getUserTypeValue === "ENERGY-PROCESSOR") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRICULTURE";
              });
            } else if (getUserTypeValue === "LogisticsTransportUser") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "TRANSPORTATION";
              });
            } else if (getUserTypeValue === "AgriAssetDistributor") {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "ANIMAL" ||
                  row.friendlyName === "AGRI-CULTURE" ||
                  row.friendlyName === "AQUA-CULTURE"
                );
              });
            } else if (getUserTypeValue === "FMC") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRI-MACHINERY";
              });
            } else if (getUserTypeValue === "PMC") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "PROCESSING-MACHINERY";
              });
            } else if (getUserTypeValue === "FMCDealer") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "AGRI-MACHINERY";
              });
            } else if (getUserTypeValue === "PMCDealer") {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "PROCESSING-MACHINERY";
              });
            } else if (getUserTypeValue === "OFC") {
              filteredAssetVerticals = data.filter((row) => {
                return (
                  row.friendlyName === "AGRICULTURE" ||
                  row.friendlyName === "AGRI-MACHINERY"
                );
              });
            } else {
              filteredAssetVerticals = data.filter((row) => {
                return row.friendlyName === "ANIMAL";
              });
            }
            console.log("filteredAssetVerticals", filteredAssetVerticals);
            setVerticalsIdList(filteredAssetVerticals);
            setSelectedVerticalsId(filteredAssetVerticals[0].assetVerticalId);
            setSelectedVerticalName(filteredAssetVerticals[0].friendlyName);
          } else {
            setSelectedVerticalsId("");
            setSelectedVerticalName("");
            setVerticalsIdList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("Internal Server Error");
        });
    }
  };

  useEffect(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getCategoryTypesList = () => {
    setIsLoading(true);
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          var filteredCategories;
          if (data.length > 0) {
            if (selectedVerticalsId) {
              if (
                getUserTypeValue === "AssetDeveloper" ||
                getUserTypeValue === "CBO"
              ) {
                filteredCategories = data.filter((row) => {
                  return row.categoryType === "INSECTS";
                });
                setSelectedCategory(filteredCategories[0].categoryType);
                setCategoryTypesList(filteredCategories);
              } else {
                setSelectedCategory(data[0].categoryType);
                setCategoryTypesList(data);
              }
            } else {
              setSelectedCategory("");
              setCategoryTypesList([]);
              setIsLoading(false);
            }
          } else {
            setSelectedCategory("");
            setCategoryTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("Internal Server Error");
        });
    } else {
      setSelectedCategory("");
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useEffect(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        window.AppConfigData.EnterpriseId
      )
        .then((data) => {
          if (data.length > 0) {
            var filteredAssetTypes;
            if (selectedVerticalsId && selectedCategory) {
              if (
                getUserTypeValue === "AssetDeveloper" ||
                getUserTypeValue === "CBO"
              ) {
                filteredAssetTypes = data.filter((row) => {
                  return row.assetType === "BEE";
                });
                setSelectedAssertTypes(filteredAssetTypes[0].assetType);
                setAssertTypesList(filteredAssetTypes);
              } else {
                setSelectedAssertTypes(data[0].assetType);
                setAssertTypesList(data);
              }
            } else {
              setSelectedAssertTypes("");
              setAssertTypesList([]);
              setIsLoading(false);
            }
          } else {
            setSelectedAssertTypes("");
            setAssertTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("Internal Server Error");
        });
    } else {
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useEffect(() => {
    getModelsList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        username
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("Internal Server Error");
        });
    }
  };

  useEffect(() => {
    getAllAssetsBasedonModel();
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel    
  ]);

  const getAllAssetsBasedonModel = () => {
    if (
      selectedVerticalsId &&
      selectedCategory &&
      selectedAssertTypes &&
      selectedModel
    ) {
      MainService.getAllAssetsBasedonModel(
        username,
        selectedAssertTypes.replace(" ", ""),
        selectedModel.replace(" ", ""),
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", "")
      )
        .then((data) => {
          setIsLoading(true);
          if (data.length > 0) {
            console.log("asssets data:", data);
            setAssetsList(data);
            getFilterUsers(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAssetsList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error("Internal Server Error");
        });
    } else {
      setIsLoading(false);
      setAssetsList([]);
    }
  };

  const getAllSites = () => {
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          let farmSites; // Define farmSites variable outside the if blocks

          // Filter sites with sitetype "Farm" or "Space" based on userType
          if (userType === "AssetDeveloper") {
            farmSites = data.filter((site) => site.sitetype === "Farm");
          } else if (userType === "LogisticsProvider") {
            farmSites = data.filter((site) => site.sitetype === "Space");
          } else {
            farmSites = data;
          }
          if (farmSites.length > 0) {
            setSelectedSite(farmSites[0].siteid);
            setSelectedSiteName(farmSites[0].sitename);
            setSitesList(farmSites);
          } else {
            setSelectedSite("");
            setSelectedSiteName("");
            setSitesList([]);
            setIsLoading(false);
          }
        } else {
          setSelectedSite("");
          setSelectedSiteName("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("Internal Server Error");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setSelectedSiteHandler = (e) => {
    setSelectedSite(e.target.value);
    let catname = sitesList.filter((data) => {
      return data.siteid === e.target.value;
    });
    setSelectedSiteName(catname[0].sitename);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
  };

  const setSelectedCategoryHandler = (event) => {
    setSelectedCategory(event.target.value);
  };

  const setSelectedAssertTypeHandler = (event) => {
    setSelectedAssertTypes(event.target.value);
  };

  const setSelectedModelHandler = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleAssetIdValueChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Regex to remove whitespace characters
    setAssetIdValue(sanitizedValue);
    setAssetIdError(value !== sanitizedValue ? true : false);
  };
  const handleAssetNameValueChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Regex to remove whitespace characters
    setLegacyAssetFriendlyNameValue(sanitizedValue);
    setAssetNameError(value !== sanitizedValue ? true : false);
  };
  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue.replace(/\s/g, ""),
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: username,
      area:"Field",
      siteId: selectedSiteId,
      siteName: selectedSiteName,
      lotId: lotNameValue,
      lotName: lotNameValue,
      assetdeveloperId: username,
      assetqrcodeUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: selectedModel,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: getUserTypeValue,
      consumerAdminId: 'consumer2',
      suborgId: username
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    if (
      legacyAssetFriendlyNameValue &&
      assetIdValue &&
      acquireCostValue &&
      selectedModel
    ) {
      MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
        .then((res) => {
          getAllAssetsBasedonModel();
          setLatitudeValue("");
          setLongitudeValue("");
          setAcquireCostValue("");
          setSelectedSite("");
          setSiteNameValue("");
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e || "Internal Server Error");
        });
      setShowAssetPopup(!assetPopup);
    } else {
      return NotificationManager.error("please fill all required fields");
    }
  };

  const assetCreateClcik = () => {
    //getAllSites();
    setShowAssetPopup(!assetPopup);
  };

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetStatus.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetStatus.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  /*List Filter */
  const requestPointsSearch = (searchedVal) => {
    const filteredRows = filteredAssetPointsList.filter((row) => {
      return row.pointname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetPointsList(filteredRows);
  };

  const cancelPointsSearch = () => {
    setSearched("");
    requestPointsSearch(searched);
  };

  /*Grid Filter */
  const requestPointsGridSearch = (searchedVal) => {
    const filteredRows = filteredAssetPointsList.filter((row) => {
      return row.pointname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetPointsList(filteredRows);
  };

  const cancelPointsGridSearch = () => {
    setFarmGridSearched("");
    requestPointsGridSearch(farmgridsearched);
  };
  /*Create Asset */
  const createAssetModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleCreateAsset = "Create Asset";
  const assetCreationBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID* :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="GBRBB00501"
          value={assetIdValue}
          onChange={(e) => handleAssetIdValueChange(e)}
        />
        {assetIdError && (
          <span style={{ color: "red" }}>Space not allowed.</span>
        )}
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name *:
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => handleAssetNameValueChange(e)}
        />
        {assetNameError && (
          <span style={{ color: "red" }}>Space not allowed.</span>
        )}
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost *:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="number"
          inputProps={{
            step: "any",
            "aria-valuemax": "9999999999",
            "aria-valuemin": "0",
          }}
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      {/* <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSiteHandler(e);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          disabled
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(event) => {
            setSelectedVerticalsIdHandler(event);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4 style={{ textAlign: "center" }}></h4>
          )}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Select
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Select
          disabled
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Select
          disabled
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => setSelectedModel(event.target.value)}
          label="Select Model"
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* Asset Live Monitoring */

  /* const createSocketLoc = () => {
    const ENDPOINT = window.socketUrl;
    const socket = socketIOClient(ENDPOINT, { reconnection: false });
    console.log("location socket url:", socket, ENDPOINT);
    // Add a function to disconnect the socket
    const disconnectSocketLoc = () => {
      console.log("Before disconnection:", socket);
      socket.disconnect();
      console.log("After disconnection:", socket);
    };

    return { socket, disconnectSocketLoc };
  }; */

  const assetMonitoringHandler = (siteId, assetId, lat1, lng1) => {
    setMoreAnchorEl(null);
    setSelectedAssetId(assetId);
    setAssetsListView(false);
    setAssetsGridView(false);
    setAssetMonitoringView(true);
    setAssetsMapView(false);
    setPointsListView(false);
    setPointsGridView(false);
    // const { socket, disconnectSocketLoc } = createSocketLoc();
    MainService.assetCurrentLocation(parentId, username, assetId)
      .then((res) => {
        console.log("current Location monitor:", res);

        if (res && res.geoLocation) {
          setSelectedAssetId(res.assetId);
          setAssetMonitoringStatus(res.status);
          setMarkers([...markers, { geoLocation: res.geoLocation }]);
          setAssetMonitorTimestamp(res.ts);

          /* socket.on(
            `tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`,
            (data) => {
              const liveData = JSON.parse(data.payload);
              console.log("live data", liveData.geoLocation);
              setMarkers([...markers, { geoLocation: liveData.geoLocation }]);
            }
          ); */

          return () => {
            // disconnectSocketLoc(); // Disconnect the socket when the component unmounts
          };
        } else {
          console.log("No geoLocation data available. Disconnecting socket.");
          // Optionally, add further handling or notifications for no geoLocation data.
          // Disconnect socket here.
          //disconnectSocketLoc();
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("Location not found");
      });
  };

  // Now, you can call assetMonitoringHandler whenever you need the socket to be created.

  const getLocationHandler = (props) => {
    console.log(props);
  };

  /* Mapping Functionality */

  const mapPopUpHandler = (assetId, categoryType, solutionId,siteId, modelid,assettype) => {
    getAllSites();
    setSelectedSiteId(siteId);
    setSelectedAssertTypes(assettype);
    setSelectedModel(modelid);
    setSelectedSolutionId(solutionId);
    setSelectedAssetId(assetId);
    setSelectedCategory(categoryType);
    setMapPopUp(!mapPopUp);
  };
  const closeMapModal = (
    <span
      onClick={() => {
        setMapPopUp(!mapPopUp);
      }}
    >
      X
    </span>
  );
  const mapAssetToSite = () => {
    const hasSiteChanged = selectedSiteId !== initialValues.current.selectedSiteId;

    const isValidValue = (value) => value !== undefined && value !== null && value !== 'NULL' && value !== '';

    if (isValidValue(selectedSolutionId) || isValidValue(selectedSiteId)) {
      const SiteData = {
        siteid: selectedSiteId,
        sitename: sitesList.find(site => site.siteid === selectedSiteId)?.sitename,
        assetid:selectedAssetId,
      };
      const promises = [];
      if (hasSiteChanged) {
        const sitePromise = MainService.mapAssetToSite(
          username, selectedAssetId, selectedCategory, JSON.stringify(SiteData)
        ).then((res) => {
          const updateAssignStatusData = {
            assetid:selectedAssetId,
            assignstatus : "mapped"
          };
          const data = JSON.stringify(updateAssignStatusData);
      
          MainService.updateAssignStatus(data)
            .then((res) => {           
             
            })
            .catch((e) => {
              return NotificationManager.error("Internal Server Error");
            });
          NotificationManager.success("Asset Mapped to Site successfully.");
        }).catch(() => {
          NotificationManager.error("Failed to create site mapping.");
        });

        promises.push(sitePromise);
      }

      Promise.all(promises)
        .then(() => {
          getAllAssetsBasedonModel();
        })
        .catch(() => {
          NotificationManager.error("Internal Server Error");
        });

    } else {
      NotificationManager.error("No valid changes detected.");
    }

    setMapPopUp(!mapPopUp);
  };


  const siteHandler = (e) => {
    setSelectedSiteId(e);
    let catname = sitesList.filter((data) => {
      return data.siteid === e;
    });
    setSelectedSiteName(catname[0].sitename);
  };
  const titleMapAsset = "Map With Site";
  const mapModelBody = (
    <div>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name</InputLabel>
        <Select
          id="selectedModel"
          value={selectedSiteId}
          onChange={(event) => siteHandler(event.target.value)}
          label="Select Model"
        >
          {sitesList &&
            sitesList?.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
        <Input
          disabled
          id="assetId"
          name="assetId"
          type="text"
          value={selectedAssetId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={mapAssetToSite}
        >
          Map
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setMapPopUp(!mapPopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Mapping Functionality */

  /* UnMap Asset Handler Functionality */
    
  const unMapAssetHandler = (assetId, categoryType, siteId, solutionId) => {
   setSelectedAssetId(assetId);
   setSelectedCategory(categoryType);
   setSelectedSiteId(siteId);
   setSelectedSolutionId(solutionId);
   setUnmapAssetDialog(!unmapAssetDialog);
  };
  const onConfirm = (choice)=>{
    const siteData = {
      siteid: selectedSiteId,
    };
  
    const data = JSON.stringify(siteData);
    const apiCall = choice === 'site'
      ? MainService.unMapAssetFromSite(selectedAssetId, selectedCategory, data, selectedSolutionId)
      : MainService.unMapAssetFromSolution(selectedAssetId, selectedCategory, data, selectedSolutionId,selectedSolutionId);

    apiCall
      .then((res) => {
        const updateAssignStatusData = {
          assetid:selectedAssetId,
          assignstatus : "unmapped"
        };
        const data = JSON.stringify(updateAssignStatusData);
    
        MainService.updateAssignStatus(data)
          .then((res) => {           
           
          })
          .catch((e) => {
            return NotificationManager.error("Internal Server Error");
          });
        getAllAssetsBasedonModel();
        NotificationManager.success("Asset Unmapped");
      })
      .catch(() => {
        NotificationManager.error("Internal Server Error");
      })
      .finally(() => {
        setUnmapAssetDialog(!unmapAssetDialog);
      });
  };
  
  /* End UnMap Asset Handler Functionality */

  /* Update Legacy Asset */
  /* End Update Legacy Asset Info */
  const updateAssetStatusHandler = () => {
    if (updateAssetStatus === "") {
      return NotificationManager.error("please change the asset status");
    } else {
      if (
        updateAssetStatus === "null" ||
        updateAssetStatus === "readyForOnboard"
      ) {
        if (whiteLabelVal !== "") {
          updateWhitelabelCostHandler();
        } else {
          return NotificationManager.error(
            "Please provide the Whitelabel cost"
          );
        }
      } else if (
        updateAssetStatus === "leased" ||
        updateAssetStatus === "prematureSale" ||
        updateAssetStatus === "verified"
      ) {
        if (sellingCostVal !== "") {
          updateSellingCostHandler();
        } else {
          return NotificationManager.error("Please provide the Selling cost");
        }
      } else {
        updateWhitelabelCostHandler();
      }

      setUpdateAssetStatus("");
    }
  };

  const updateLegacyAssetHandler = (assets) => {
    setMoreAnchorEl(null);
    setAssetVerticalId(assets.assetverticalId);
    setAssetTypeVal(assets.assetType);
    setAssetIdVal(assets.assetId);
    setAssetFriName(assets.friendlyName);
    setModelIdVal(assets.modelId);
    setCatgeryVal(assets.categoryType);
    setUdnVal(assets.udn);
    setAcquireCostValue(assets.acquireCost);
    setWhiteLabelVal(assets.whitelabelCost);
    setSellingCostVal(assets.sellingCost);
    setExAssetStatus(assets.assetStatus);

    setLegacyUpdate(!legacyUpdate);
  };

  const udateAssetInfoClick = (assets) => {
    setMoreAnchorEl(null);
    console.log("asset info before update:", assets);
    setAssetSiteIdValue(assets.siteId);
    setAssetSiteNameValue(assets.siteName);
    setAssetVerticalId(assets.assetverticalId);
    setAssetTypeVal(assets.assetType);
    setAssetIdVal(assets.assetId);
    setAssetFriName(assets.friendlyName);
    setModelIdVal(assets.modelId);
    setModelNameVal(assets.modelName);
    setModelTypeVal(assets.modelType);
    setModelUrlVal(assets.modelUrl);
    setCatgeryVal(assets.categoryType);
    setUdnVal(assets.udn);
    setUpcValue(assets.upc);
    setManufacturerUrlValue(assets.manufacturUrl);
    setSerialNoValue(assets.serialNo);
    setLatitudeValue(assets.latitude);
    setLongitudeValue(assets.longitude);
    setAltitudeValue(assets.altitude);
    setAcquireCostValue(assets.acquireCost);
    setWhiteLabelVal(assets.whitelabelCost);
    setSellingCostVal(assets.sellingCost);
    setLotIdValue(assets.lotid);
    setLotNameValue(assets.lotname);
    setFatherBreedValue(assets.fatherbreed);
    setMotherBreedValue(assets.motherbreed);
    setAgeVal(assets.age);
    setWeightVal(assets.weight);
    setGenderVal(assets.gender);

    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  const updateAssetInfoSubmitClick = () => {
    const updateAssetInfoData = {
      friendlyname: assetFriName,
      udn: udnVal,
      upc: upcValue,
      whitelabelcost: whiteLabelVal,
      sellingcost: sellingCostVal,
      acquirecost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      manufacturerurl: manufacturerUrlValue,
      serialno: serialNoValue,
      motherbreed: motherBreedValue,
      fatherbreed: fatherBreedValue,
      description: descriptionValue,
      lotid: lotIdValue,
      lotname: lotNameValue,
      siteid: assetSiteIdValue,
      sitename: assetSiteNameValue,
      motherbreed: motherBreedValue,
      fatherbreed: fatherBreedValue,
      category: categoryVal,
      assettype: assetTypeVal,
      assetverticalid: assetVeriticalId,
      assetdomain: assetDomainValue,
      modelid: modelIdVal,
      modelname: modelNameVal,
      modeltype: modelTypeVal,
      modelurl: modelUrlVal,
    };
    const data = JSON.stringify(updateAssetInfoData);

    MainService.updateAssetInfo(data, assetIdVal)
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus("");
        return NotificationManager.error("Internal Server Error");
      });
    setAssetVerticalId("");
    setAssetTypeVal("");
    setAssetIdVal("");
    setAssetFriName("");
    setModelIdVal("");
    setCatgeryVal("");
    setUdnVal("");
    setUpcValue("");
    setManufacturerUrlValue("");
    setSerialNoValue("");
    setLatitudeValue("");
    setLongitudeValue("");
    setAltitudeValue("");
    setAcquireCostValue("");
    setWhiteLabelVal("");
    setSellingCostVal("");
    setAgeVal("");
    setWeightVal("");
    setGenderVal("");
    setLotIdValue("");
    setLotNameValue("");
    setFatherBreedValue("");
    setMotherBreedValue("");
    setModelNameVal("");
    setModelTypeVal("");
    setModelUrlVal("");
    setAssetSiteIdValue("");
    setAssetSiteNameValue("");

    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  /* End of asset status update */
  /* update selling cost */
  const updateSellingCostHandler = () => {
    const updateAssetSellingCostData = {
      assetid: assetIdVal,
      assetfriendlyname: assetFriName,
      parentid: parentId,
      userid: username,
      sellingcost: sellingCostVal,
    };
    const data = JSON.stringify(updateAssetSellingCostData);

    MainService.updateSellingCost(updateAssetSellingCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus("");
        return NotificationManager.error("Internal Server Error");
      });

    const statusData = {
      status: updateAssetStatus,
    };
    const statusDataStr = JSON.stringify(statusData);
    MainService.assetStatusUpdate(assetIdVal, statusDataStr)
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
  };
  /* End of update selling cost */
  /* update Whitelabel cost */
  const updateWhitelabelCostHandler = () => {
    const updateAssetSellignCostData = {
      assetid: assetIdVal,
      whitelabelcost: whiteLabelVal,
      assetstatus: updateAssetStatus,
    };
    const data = JSON.stringify(updateAssetSellignCostData);

    MainService.updateWhitelabelCost(updateAssetSellignCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error("Internal Server Error");
      });
  };
  /* End of update whitelabel cost */

  /* Update Legacy Asset Status*/
  const closeUpdateAssetStatusModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );
  const updateLegacyAsset = "Update Asset Status";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">ORG ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input disabled id="udn" name="udn" type="text" value={udnVal} />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ACQUIRE COST:</InputLabel>
        <Input
          disabled
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
        />
      </FormControl>
      {updateAssetStatus === "readyForOnboard" ||
        updateAssetStatus === "null" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
            onChange={(e) => {
              setWhiteLabelVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            disabled
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
          />
        </FormControl>
      )}
      {updateAssetStatus === "prematureSale" ||
        updateAssetStatus === "leased" ||
        updateAssetStatus === "verified" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
            onChange={(e) => {
              setSellingCostVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            disabled
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
          />
        </FormControl>
      )}

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>

      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATE ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
          <MenuItem key="readyForOnboard" value="readyForOnboard">
            ReadyForOnboard --- initial state(aquired)
          </MenuItem>
          <MenuItem key="null" value="null">
            Null --- Ready for whitelabel(onboarded)
          </MenuItem>
          <MenuItem key="whitelabelled" value="untested">
            Whitelabelled
          </MenuItem>
          <MenuItem key="prematureSale" value="prematureSale">
            Premature Sale
          </MenuItem>
          <MenuItem key="verified" value="verified">
            Verified
          </MenuItem>
          <MenuItem key="sold" value="sold">
            Sold
          </MenuItem>
          <MenuItem key="leased" value="leased">
            Leased
          </MenuItem>
          <MenuItem key="lost" value="lost">
            Lost
          </MenuItem>
          <MenuItem key="damaged" value="damaged">
            Damaged
          </MenuItem>
          <MenuItem key="under-maintenance" value="under-maintenance">
            Under maintenance
          </MenuItem>
          <MenuItem key="deboard" value="deboard">
            Deboard
          </MenuItem>
        </Select>
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetStatusHandler}
        >
          Update Status
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
          setUpdateAssetStatus("");
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset Status*/

  /* Update Legacy Asset Info*/
  const closeUpdateAssetInfoModel = (
    <span
      onClick={() => {
        setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
      }}
    >
      X
    </span>
  );
  const updateLegacyAssetInfo = "Update Asset Information";
  const updateLegacyAssetInfoData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
          onChange={(e) => {
            setAssetFriName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnVal}
          onChange={(e) => {
            setUdnVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UPC:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">LOT ID:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={lotIdValue}
          onChange={(e) => {
            setLotIdValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">LOT NAME:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={lotNameValue}
          onChange={(e) => {
            setLotNameValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">Assetqrcode URL:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">SERIAL NO:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ACQUIRE COST:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
          onChange={(e) => {
            setWhiteLabelVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
          onChange={(e) => {
            setSellingCostVal(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">LATITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">LONGITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ALTITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>
      {/* <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">AGE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={ageVal}
          onChange={(e) => {
            setAgeVal(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">WEIGHT:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={weightVal}
          onChange={(e) => {
            setWeightVal(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">GENDER:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={genderVal}
          onChange={(e) => {
            setGenderVal(e.target.value);
          }}
        />
        </FormControl> */}
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">MOTHER BREED:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={motherBreedValue}
          onChange={(e) => {
            setMotherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">FATHER BREED:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={fatherBreedValue}
          onChange={(e) => {
            setFatherBreedValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">DESCRIPTION:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetInfoSubmitClick}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* Asset Info */
  const getAssetInfo = (assetid) => {
    MainService.getAssetInfo(username, assetid)
      .then((res) => {
        setAssetInfoObj(res);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const assetInfoDialogClick = (assetid) => {
    setMoreAnchorEl(null);
    getAssetInfo(assetid);
    setAssetInfoPopUp(!assetInfoPopup);
  };
  const closeSitAssetInfoModal = (
    <span
      onClick={() => {
        setAssetInfoPopUp(!assetInfoPopup);
      }}
    >
      X
    </span>
  );
  const assetInfoHeader = "Asset Details";
  const assetInfoBody = (
    <div style={{ paddingBottom: 50 }}>
      {Object.entries(getAssetInfoObj).map(([key, value]) => (
        <p key={key}>
          <b>{key}:</b> {value}
        </p>
      ))}
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          setAssetInfoPopUp(!assetInfoPopup);
        }}
      >
        cancel
      </Button>
    </div>
  );

  /* End of Asset Info */

  /* Asset Points */
  const viewPointsHandler = (assetId, viewType) => {
    setMoreAnchorEl(null);
    setSelectedAssetId(assetId);
    MainService.getPoints(username, assetId)
      .then((data) => {
        setAssetPointsList(data);
        setFilteredAssetPointsList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error("Internal Server Error");
      });
    if (viewType === "List") {
      setAssetsGridView(false);
      setAssetsListView(false);
      setAssetMonitoringView(false);
      setAssetsMapView(false);
      setPointsListView(true);
      setPointsGridView(false);
    } else if (viewType === "Grid") {
      setAssetsGridView(false);
      setAssetsListView(false);
      setAssetMonitoringView(false);
      setAssetsMapView(false);
      setPointsListView(false);
      setPointsGridView(true);
    }
  };
  /* End of Asset Points */
  /* Asset Delete Handler */

  const deleteAssetHandler = (
    assetIds,
    assetTypes,
    modelIds,
    assetverticalIds,
    categoryTypes
  ) => {
    setMoreAnchorEl(null);
    setAssetDeletePopup(!assetDeletePopup);

    setSelectedAssertTypes(assetTypes);
    setSelectedModel(modelIds);
    setSelectedVerticalsId(assetverticalIds);
    setSelectedAssetId(assetIds);
    setSelectedCategory(categoryTypes);
  };
  const deleteAssetSubmitHandler = () => {
    MainService.assetDelete(
      selectedAssetId,
      selectedAssertTypes,
      selectedModel,
      selectedVerticalsId,
      selectedCategory,
      username
    )
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success("successfully deleted");
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
    setAssetDeletePopup(!assetDeletePopup);
  };
  const deleteAssetPointsHandler = () => {
    MainService.assetPointsDelete(deletePointAssetId, deletePointId, username)
      .then((res) => {
        viewPointsHandler(selectedAssetId, "Grid");
        return NotificationManager.success("successfully deleted");
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
    setDeletePointPopup(!deletePointPopup);
  };

  const updatePointNameSubmitHandler = () => {
    let pointData = {
      pointid: updatePointId,
      pointname: updatePointName,
    };
    const pointDataString = JSON.stringify(pointData);
    MainService.updatePointName(pointDataString, username, updatePointAssetId)
      .then((res) => {
        viewPointsHandler(selectedAssetId, "Grid");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
    setUpdatePointPopup(!updatePointPopup);
  };

  const deleteNodeWithPointHandler = (assetId, pointId) => {
    setDeletePointAssetId(assetId);
    setDeletePointId(pointId);
    setDeletePointPopup(!deletePointPopup);
  };

  const updatePointNameHandler = (assetId, pointId, pointName) => {
    setUpdatePointAssetId(assetId);
    setUpdatePointId(pointId);
    setUpdatePointName(pointName);
    setUpdatePointPopup(!updatePointPopup);
  };

  /*End Asset Delete Handler */
  /* Create Point */
  const handlePointIdValueChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Regex to remove whitespace characters
    setPointIdValue(sanitizedValue);
    setError(value !== sanitizedValue ? true : false);
  };

  const createPointSubmitHandler = () => {
    let pointData = {
      solutionid: username,
      assetid: selectedAssetId,
      pointid: pointIdValue,
      pointname: pointNameValue.replace(/\s/g, ""),
    };
    const pointDataString = JSON.stringify(pointData);
    MainService.createPoint(pointDataString, username)
      .then((res) => {
        viewPointsHandler(selectedAssetId, "Grid");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
    setCreatePointPopup(!createPointPopup);
  };
  const selectedDeviceIdHandler = (event) => {
    // const filteredVerticalName = solutionDevicesList.filter(function (item) {
    //   return item.deviceid === event.target.value;
    // });
    //setSelectedDeviceName(filteredVerticalName[0].deviceName);
    setSelectedDeviceId(event.target.value);
  };
  const mapNodeWithPointHandler = (assetId, pointId) => {
  
    setSelectedAssetId(assetId);
    setSelectedPointId(pointId);
    getAllSolutionList()
    
    setMapPointPopup(!mapPointPopup);
  };
  const unMapNodeWithPointHandler = (assetId, pointId, deviceid) => {
    setSelectedAssetId(assetId);
    setSelectedPointId(pointId);
    setSelectedDeviceId(deviceid);
    setUnmapPointPopup(!unmapPointPopup);
  };
  const getAllSolutionDevices = (solutionname) => {
    MainService.getSolutionDevices(parentId, solutionname)
      .then((res) => {
        setSolutionDevicesList(res);
      })
      .catch((e) => {
        setSolutionDevicesList([]);
        return NotificationManager.error("Internal Server Error");
      });
  };
  const getAllSolutionList = async() => {

    MainService.getAllSolutions(parentId, username)
      .then((res) => {
        setSolutionList(res);
        setSelectedSolution(res[0].solutionname);
       
      })
      .catch((e) => {
        setSolutionList([]);
        setSelectedSolution("");
        return NotificationManager.error("Internal Server Error");
      });
  };
  const mapPointWithNodeSubmitHandler = () => {
    MainService.mapPointWithNode(
      username,
      selectedPointId,
      selectedDeviceId,
      selectedAssetId
    )
      .then((res) => {
        viewPointsHandler(selectedAssetId, "Grid");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e||"Internal Server Error");
      });
    setMapPointPopup(!mapPointPopup);
  };
  const unmapPointWithNodeSubmitHandler = () => {
    MainService.unmapPointWithNode(
      username,
      selectedPointId,
      selectedDeviceId,
      selectedAssetId
    )
      .then((res) => {
        viewPointsHandler(selectedAssetId, "Grid");
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e||"Internal Server Error");
      });
    setUnmapPointPopup(!unmapPointPopup);
  };
  /* End of Create point */
  /* backToHome */
  const backToHome = () => {
    setAssetsListView(false);
    setAssetsGridView(true);
    setAssetMonitoringView(false);
    setAssetsMapView(false);
    setPointsListView(false);
    setPointsGridView(false);
    // const { disconnectSocketLoc } = createSocketLoc();
    // disconnectSocketLoc();
    setMarkers([]);
  };
  /* Generate QR Code */
  const qrCodeDialogHandler = (asset) => {
    setSelectedAsset(asset.assetId);
    setQrCodeDialog(!qrCodeDialog);
    setIsLoading(false);

    let qrBody = {
      type: "asset",
      assetverticalid: asset.assetverticalId,
      avname: selectedVerticalName,
      acategory: asset.categoryType,
      atype: asset.assetType,
      modelid: asset.modelId,
      id: asset.assetId,
      url: qrUrlValue,
      assetFriendlyName: asset.friendlyName,
    };

    SetQrBody(qrBody);
    console.log("qr body for Asset:", qrBody);

  };
  const qrResponseHandler = (res) => {
    getAllAssetsBasedonModel();
    setQrCodeDialog(!qrCodeDialog);
  }

  /* End of Generate QR Code */
  /* Asset Env */

  const assetEnvClick = (assetId, deviceType) => {
    setMoreAnchorEl(null);
    MainService.getAssetEnvInfo(parentId, username, assetId, deviceType)
      .then((res) => {
        const ENDPOINT = window.liveEnvUrl;
        const socket = socketIOClient(ENDPOINT);
        if (res) {
          setAssetEnvData(res);

          console.log("liveEnvUrl url:", window.liveEnvUrl);

          console.log("socket :", socket);

          // Only establish socket connection if res has valid data
          socket.on("livedata", (data) => {
            console.log("from api data:", data);
            setAssetEnvData(data);
          });
          setAssetEnvPopup(!assetEnvPopup);
          // Optionally, close the socket connection here if necessary
          return () => {
            socket.disconnect();
          };
        } else {
          console.log(
            "No environmental data available. Closing socket connection."
          );
          // Optionally, add further handling or notifications for no environmental data.
          // Disconnect socket here if necessary.
          socket.disconnect();
          return NotificationManager.info("No Data Available");
        }
      })
      .catch((e) => {
        console.error(e);
        setAssetEnvData({});
        return NotificationManager.error("No Data availabale");
        // return NotificationManager.error("Env data not found");
      });
  };

  /* End ofAsset Env */

  const AssetManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={assetsList.index}
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {assetsList.map((value, index) => {
          return (value.latitude !== "null" && value.latitude !== "" && value.longitude !== "null" && value.longitude !== "") ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            null
          );
        })}
      </GoogleMap>
    );
  });

  return (
    <>
      <div className="container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={handleBackButtonClick}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ margin: 0 }}>IAA Management</h2>
        </div>
        {assetsListView || assetsGridView ? (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Asset"
            className={classes.fab}
            onClick={() => assetCreateClcik()}
          >
            <AddIcon></AddIcon>
          </Fab>
        ) : (
          <></>
        )}
        {pointsGridView || pointsListView ? (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Create Point"
            className={classes.fab}
            onClick={() => setCreatePointPopup(!createPointPopup)}
          >
            <AddIcon></AddIcon>
          </Fab>
        ) : (
          <></>
        )}

        <div className="topHeadersWrapper">
          <div style={{ order: 2 }}>
           
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4 style={{ textAlign: "center" }}></h4>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategoryHandler(event);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypeHandler(event);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModelHandler(event);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(true);
                setAssetsListView(false);
                setAssetMonitoringView(false);
                setAssetsMapView(false);
                setPointsListView(false);
                setPointsGridView(false);
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(false);
                setAssetsListView(true);
                setAssetMonitoringView(false);
                setAssetsMapView(false);
                setPointsListView(false);
                setPointsGridView(false);
              }}
            ></Button>
            <Button
              startIcon={<MapIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(false);
                setAssetsListView(false);
                setAssetMonitoringView(false);
                setAssetsMapView(true);
                setPointsListView(false);
                setPointsGridView(false);
              }}
            ></Button>
          </div>
        </div>
        <div>
          {/* Asset List */}
          {assetsListView === true ? (
            <div>
              <>
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                    <SearchBar
                      placeholder="Search by Asset Status"
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />

                    {assetsList.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableHeader}>
                                  Asset Id
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Asset Status
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Assign Status
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Asset Type
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Asset Friendly Name
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Org Id
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Category Type
                                </TableCell>

                                <TableCell className={classes.tableHeader}>
                                  ModelId
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Model Type
                                </TableCell>

                                <TableCell className={classes.tableHeader}>
                                  Site Name
                                </TableCell>

                                <TableCell className={classes.tableHeader}>
                                  QR Code
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  More
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetsList.length > 0 ? (
                                assetsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((asset, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{asset.assetId}</TableCell>
                                      <TableCell>{asset.assetStatus}</TableCell>
                                      <TableCell>{asset.assignStatus}</TableCell>
                                      <TableCell>{asset.assetType}</TableCell>
                                      <TableCell>
                                        {asset.friendlyName}
                                      </TableCell>
                                      <TableCell>
                                        {asset.assetdeveloperid}
                                      </TableCell>
                                      <TableCell>
                                        {asset.categoryType}
                                      </TableCell>

                                      <TableCell>{asset.modelId}</TableCell>
                                      <TableCell>{asset.modelType}</TableCell>

                                      <TableCell>{asset.siteName}</TableCell>

                                      <TableCell align="center">
                                        {asset.assetqrcodelink !== "" &&
                                          asset.assetqrcodelink !== "null" ? (
                                          <div>
                                            <img
                                              src={asset.assetqrcodelink}
                                              alt="img"
                                            ></img>
                                            <br />
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="registerBtn"
                                            >
                                              <a
                                                className="downloadDocument"
                                                href={asset.assetqrcodelink}
                                                target="_blank"
                                                download
                                              >
                                                Download
                                              </a>
                                            </Button>
                                          </div>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className="registerBtn"
                                            type="submit"
                                            onClick={(e) =>
                                              qrCodeDialogHandler(asset)
                                            }
                                          >
                                            Generate
                                          </Button>
                                        )}
                                      </TableCell>

                                      <TableCell align="center">
                                        <Button
                                          variant="contained"
                                          type="button"
                                          onClick={handleMoreClick}
                                        >
                                          <MoreVertIcon />
                                        </Button>
                                        <Menu
                                          id={`more-menu-${index}`}
                                          anchorEl={moreAnchorEl}
                                          keepMounted
                                          open={Boolean(moreAnchorEl)}
                                          onClose={handleMoreClose}
                                        >
                                          <ListSubheader>View</ListSubheader>
                                          <MenuItem
                                            onClick={() =>
                                              assetInfoDialogClick(
                                                asset.assetId
                                              )
                                            }
                                          >
                                            Asset Info
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              assetMonitoringHandler(
                                                asset.siteId,
                                                asset.assetId,
                                                asset.latitude,
                                                asset.longitude
                                              )
                                            }
                                          >
                                            Asset Monitoring
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              assetEnvClick(
                                                asset.assetId,
                                                asset.assetType
                                              )
                                            }
                                          >
                                            Asset Env
                                          </MenuItem>
                                          <ListSubheader>Operate</ListSubheader>
                                          {(asset.siteId === "null" ||
                                              asset.siteId === "NULL") &&
                                              (asset.siteName === "null" ||
                                                asset.siteName === "NULL")  ? (
                                              <MenuItem
                                                style={{ marginRight: "10px" }}
                                                size="small"
                                                variant="contained"
                                                onClick={() =>
                                                  mapPopUpHandler(
                                                    asset.assetId,
                                                    asset.categoryType,
                                                    asset.assetdeveloperid,
                                                    asset.siteId,
                                                    asset.modelId,
                                                    asset.assetType
                                                  )
                                                }
                                              >
                                                Map
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                style={{ marginRight: "10px" }}
                                                size="small"
                                                variant="contained"
                                                onClick={() =>
                                                  unMapAssetHandler(
                                                    asset.assetId,
                                                    asset.categoryType,
                                                    asset.siteId,
                                                    asset.assetdeveloperid
                                                  )
                                                }
                                              >
                                                UnMap
                                              </MenuItem>
                                            )}
                                          <MenuItem
                                            onClick={() =>
                                              updateLegacyAssetHandler(asset)
                                            }
                                          >
                                            Update Status
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              udateAssetInfoClick(asset)
                                            }
                                          >
                                            Update Info
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              deleteAssetHandler(
                                                asset.assetId,
                                                asset.assetType,
                                                asset.modelId,
                                                asset.assetverticalId,
                                                asset.categoryType
                                              )
                                            }
                                          >
                                            Delete
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              viewPointsHandler(
                                                asset.assetId,
                                                "List"
                                              )
                                            }
                                          >
                                            View Points
                                          </MenuItem>
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  ))
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Assets not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>Assets not found.</h4>
                    )}
                  </Box>
                )}
              </>
            </div>
          ) : null}

          {assetsGridView === true ? (
            <Box style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                placeholder="Search by Asset Status"
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />

              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Assets
                  </h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>Assign Status:</b>
                              {assets.assignStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            <p>
                              <b>Blocking Status:</b>
                              {assets.blockingStatus}
                            </p>
                            <p>
                              <b>Category Type:</b>
                              {assets.categoryType}
                            </p>
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>

                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>
                            <p>
                              <b>OrgId:</b>
                              {assets.assetdeveloperid}
                            </p>
                            <p>
                              <b>SiteId:</b>
                              {assets.siteId}
                            </p>
                            <p>
                              <b>Site Name:</b>
                              {assets.siteName}
                            </p>
                            <p>
                              <b>UDN:</b>
                              {assets.udn}
                            </p>
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Acquire Cost:</b>
                              {assets.acquireCost}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>Latitude:</b>
                              {assets.latitude}
                            </p>
                            <p>
                              <b>Longitude:</b>
                              {assets.longitude}
                            </p>

                            {assets.assetqrcodelink !== "" &&
                              assets.assetqrcodelink !== "null" ? (
                              <div>
                                <img
                                  src={assets.assetqrcodelink}
                                  alt="img"
                                ></img>
                                <br />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                >
                                  <a
                                    className="downloadDocument"
                                    href={assets.assetqrcodelink}
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </Button>
                              </div>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                className="registerBtn"
                                type="submit"
                                onClick={(e) => qrCodeDialogHandler(assets)}
                              >
                                Generate
                              </Button>
                            )}

                            <h4 className="cardactiontitle">View</h4>

                            <div>
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetInfoDialogClick(assets.assetId)
                                  }
                                >
                                  Asset Info
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetMonitoringHandler(
                                      assets.siteId,
                                      assets.assetId,
                                      assets.latitude,
                                      assets.longitude
                                    )
                                  }
                                >
                                  Asset Monitoring
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() => {
                                    assetEnvClick(
                                      assets.assetId,
                                      assets.assetType
                                    );
                                  }}
                                >
                                  Env
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                            </div>

                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">Operate</h4>
                              <div>
                                {(assets.siteId === "null" ||
                                  assets.siteId === "NULL") &&
                                  (assets.siteName === "null" ||
                                    assets.siteName === "NULL")
                                   ? (
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      mapPopUpHandler(
                                        assets.assetId,
                                        assets.categoryType,
                                        assets.assetdeveloperid,
                                        assets.siteId,
                                        assets.modelId,
                                        assets.assetType
                                      )
                                    }
                                  >
                                    Map
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    variant="contained"
                                    onClick={() =>
                                      unMapAssetHandler(
                                        assets.assetId,
                                        assets.categoryType,
                                        assets.siteId,
                                        assets.assetdeveloperid
                                      )
                                    }
                                  >
                                    UnMap
                                  </Button>
                                )}
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateLegacyAssetHandler(assets)
                                  }
                                >
                                  Update Status
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => udateAssetInfoClick(assets)}
                                >
                                  Update Info
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    deleteAssetHandler(
                                      assets.assetId,
                                      assets.assetType,
                                      assets.modelId,
                                      assets.assetverticalId,
                                      assets.categoryType
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    viewPointsHandler(assets.assetId, "Grid")
                                  }
                                >
                                  View Points
                                </Button>
                              </div>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <h4 style={{ textAlign: "center" }}>
                          No Assets Found.
                        </h4>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Box>
          ) : null}

          {assetsMapView === true ? (
            <Box style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {assetsList.length > 0 ? (
                <AssetManagementGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4 style={{ textAlign: "center" }}>Assets Not Found</h4>
              )}
            </Box>
          ) : null}
          {/* Points */}

          {pointsListView === true ? (
            <Box style={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box style={{ width: "100%" }}>
                    <SearchBar
                      placeholder="Search by Point Name"
                      value={searched}
                      onChange={(searchVal) => requestPointsSearch(searchVal)}
                      onCancelSearch={() => cancelPointsSearch()}
                    />
                    <br />
                    <Button
                      style={{ margin: "5px" }}
                      variant="contained"
                      onClick={() => {
                        backToHome();
                      }}
                    >
                      Back
                    </Button>

                    {assetPointsList.length > 0 ? (
                      <Paper style={{ width: "100%" }}>
                        <TableContainer style={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={5}>
                                  Points
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Point Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Point Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  End Node
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetPointsList.length > 0 ? (
                                assetPointsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((assets, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {assets.assetid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.pointid}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.pointname}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.endnode}
                                        </TableCell>
                                        {assets.endnode === "null" ? (
                                          <TableCell align="center">
                                            <Button
                                              style={{
                                                margin: "5px",
                                                width: "50%",
                                              }}
                                              size="small"
                                              variant="contained"
                                              onClick={() => {
                                                mapNodeWithPointHandler(
                                                  assets.assetid,
                                                  assets.pointid,
                                            
                                                );
                                              }}
                                            >
                                              Map
                                            </Button>
                                          </TableCell>
                                        ) : (
                                          <TableCell align="center">
                                            <Button
                                              style={{
                                                margin: "5px",
                                                width: "50%",
                                              }}
                                              size="small"
                                              variant="contained"
                                              onClick={() => {
                                                unMapNodeWithPointHandler(
                                                  assets.assetid,
                                                  assets.pointid,
                                                  assets.endnode
                                                );
                                              }}
                                            >
                                              UnMap
                                            </Button>
                                          </TableCell>
                                        )}
                                        <TableCell align="center">
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "50%",
                                            }}
                                            size="small"
                                            variant="contained"
                                            onClick={() => {
                                              deleteNodeWithPointHandler(
                                                assets.assetid,
                                                assets.pointid
                                              );
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "50%",
                                            }}
                                            size="small"
                                            variant="contained"
                                            onClick={() => {
                                              updatePointNameHandler(
                                                assets.assetid,
                                                assets.pointid,
                                                assets.pointname
                                              );
                                            }}
                                          >
                                            Update
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={4}>
                                    Points not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetPointsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>Points not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}

          {pointsGridView === true ? (
            <Box style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                placeholder="Search Point Name"
                value={farmgridsearched}
                onChange={(searchVal) => requestPointsGridSearch(searchVal)}
                onCancelSearch={() => cancelPointsGridSearch()}
              />
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Points
                  </h4>

                  {assetPointsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetPointsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetid}
                            </p>
                            <p>
                              <b>Point Id:</b>
                              {assets.pointid}
                            </p>
                            <p>
                              <b>Point Name:</b>
                              {assets.pointname}
                            </p>
                            <p>
                              <b>End Node:</b>
                              {assets.endnode}
                            </p>
                          </span>
                          <div
                            style={{
                              width: "100%",
                              margin: "5px",
                              display: "flex",
                            }}
                          >
                            {assets.endnode === "null" ? (
                              <Button
                                style={{ margin: "5px", width: "50%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  mapNodeWithPointHandler(
                                    assets.assetid,
                                    assets.pointid
                                  );
                                }}
                              >
                                Map
                              </Button>
                            ) : (
                              <Button
                                style={{ margin: "5px", width: "50%" }}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  unMapNodeWithPointHandler(
                                    assets.assetid,
                                    assets.pointid,
                                    assets.endnode
                                  );
                                }}
                              >
                                UnMap
                              </Button>
                            )}
                            <Button
                              style={{ margin: "5px", width: "50%" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                deleteNodeWithPointHandler(
                                  assets.assetid,
                                  assets.pointid
                                );
                              }}
                            >
                              Delete
                            </Button>
                            <Button
                              style={{ margin: "5px", width: "50%" }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                updatePointNameHandler(
                                  assets.assetid,
                                  assets.pointid,
                                  assets.pointname
                                );
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="cardStyleForDashboardItems">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <h4 style={{ textAlign: "center" }}>
                          No Points Found.
                        </h4>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            <Box style={{ width: "100%", marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />
              <Button
                style={{ margin: "5px" }}
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>
              <Map
                routeLocationData={markers}
                getLocationHandler={getLocationHandler}
              />
            </Box>
          ) : null}
        </div>
      </div>

      {/* Asset Monitoring */}
      <Dialog
        fullScreen={fullScreen}
        open={liveMonitorMapPopUp}
        onClose={() => {
          setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Live Monitoring"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          {/* <AssetLiveGoogleMap
            containerElement={
              <div style={{ height: `500px`, width: "100%" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          /> */}
          <Map
            routeLocationData={markers}
            getLocationHandler={getLocationHandler}
          ></Map>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset Env  */}
      <Dialog
        fullScreen={fullScreen}
        open={assetEnvPopup}
        onClose={() => {
          setAssetEnvPopup(!assetEnvPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Environment Info"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          {Object.keys(assetEnvData).length > 0 ? (
            <>
              <p>
                <b>Asset Id :</b>
                {assetEnvData.assetid}
              </p>
              <p>
                <b>Device Id :</b>
                {assetEnvData.deviceid}
              </p>
              <p>
                <b>Device Type :</b>
                {assetEnvData.devicetype}
              </p>
              <p>
                <b>Network Id :</b>
                {assetEnvData.networkid}
              </p>
              <p>
                <b>Site Id :</b>
                {assetEnvData.siteid}
              </p>
              <p>
                <b>Updated Date :</b>
                {assetEnvData.updatedat}
              </p>
              <p>
                <b>Number of Properties : </b>
                {assetEnvData.NumberofProperties}
              </p>

              {assetEnvData.properties !== undefined ? (
                assetEnvData.properties.map((prop, index) => {
                  return (
                    <div key={`${index} `}>
                      <p>
                        <b>Property : &nbsp;</b>
                        {prop.property} &nbsp;&nbsp;{prop.value}
                      </p>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </>
          ) : (
            <h4 style={{ textAlign: "center" }}>Data not found</h4>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setAssetEnvPopup(!assetEnvPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset Delete  */}
      <Dialog
        fullScreen={fullScreen}
        open={assetDeletePopup}
        onClose={() => {
          setAssetDeletePopup(!assetDeletePopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Asset Delete"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <h4 style={{ textAlign: "center" }}>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              deleteAssetSubmitHandler();
            }}
          >
            Delete
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setAssetDeletePopup(!assetDeletePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* create point  */}
      <Dialog
        fullScreen={fullScreen}
        open={createPointPopup}
        onClose={() => {
          setCreatePointPopup(!createPointPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Create Point"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="creator">Creator:</InputLabel>
            <Input
              disabled
              id="creator"
              name="creator"
              type="text"
              value={username}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="phone">Asset Id:</InputLabel>
            <Input
              id="phone"
              name="phone"
              type="text"
              value={selectedAssetId}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="pointid">Point Id:</InputLabel>
            <Input
              style={{ minwidth: 200 }}
              value={pointIdValue}
              onChange={(e) => handlePointIdValueChange(e)}
            />
            {error && <span style={{ color: "red" }}>Space not allowed.</span>}
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="pointname">Point Name:</InputLabel>
            <Input
              style={{ minwidth: 200 }}
              value={pointNameValue}
              onChange={(e) => {
                setPointNameValue(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              createPointSubmitHandler();
            }}
          >
            Create
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setCreatePointPopup(!createPointPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Map point to device */}
      <Dialog
        fullScreen={fullScreen}
        open={mapPointPopup}
        onClose={() => {
          setMapPointPopup(!mapPointPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Map Device With Point"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="creator">Creator:</InputLabel>
            <Input
              disabled
              id="creator"
              name="creator"
              type="text"
              value={username}
            />
          </FormControl>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="phone">Asset Id:</InputLabel>
            <Input
              id="phone"
              name="phone"
              type="text"
              value={selectedAssetId}
            />
          </FormControl>

          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="pointid">Point Id:</InputLabel>
            <Input style={{ minwidth: 200 }} value={selectedPointId} />
          </FormControl>
          {/* <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="pointname">Point Name:</InputLabel>
            <Input
              style={{ minwidth: 200 }}
              value={selectedPointId}
              onChange={(e) => {
                setPointNameValue(e.target.value);
              }}
            />
          </FormControl> */}
          <FormControl fullWidth>
            <InputLabel>Choose Solution</InputLabel>
            <Select
              id="solution"
              value={selectedSolution}
              onChange={(event) => {
                setSelectedSolution(event.target.value);
                getAllSolutionDevices(event.target.value);
              }}
              label="solution"
            >
              {solutionList &&
                solutionList.map((idObj, index) => (
                  <MenuItem key={`${index} `} value={idObj.solutionid}>
                    {idObj.solutionname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Solution DeviceId</InputLabel>
            <Select
              id="selectedVerticalsId"
              value={selectedDeviceId}
              onChange={(event) => {
                selectedDeviceIdHandler(event);
              }}
              label="Select Verticals"
            >
              {solutionDevicesList &&
                solutionDevicesList.map((idObj, index) => (
                  <MenuItem key={`${index} `} value={idObj.deviceid}>
                    {idObj.deviceid}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              mapPointWithNodeSubmitHandler();
            }}
          >
            Create
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setMapPointPopup(!mapPointPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* unMap point to device */}
      <Dialog
        fullScreen={fullScreen}
        open={unmapPointPopup}
        onClose={() => {
          setUnmapPointPopup(!unmapPointPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"unMap Device From Point"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <p>Are you sure want to unmap device from point?</p>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              unmapPointWithNodeSubmitHandler();
            }}
          >
            Unmap
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setUnmapPointPopup(!unmapPointPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset point delete */}
      <Dialog
        fullScreen={fullScreen}
        open={deletePointPopup}
        onClose={() => {
          setAssetDeletePopup(!deletePointPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Points Delete"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <h4 style={{ textAlign: "center" }}>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              deleteAssetPointsHandler();
            }}
          >
            Delete
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setDeletePointPopup(!deletePointPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset point update */}
      <Dialog
        fullScreen={fullScreen}
        open={updatePointPopup}
        onClose={() => {
          setUpdatePointPopup(!updatePointPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Update Point"}</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <FormControl fullWidth={true} className="loginFields">
            <InputLabel htmlFor="Update">Update Point Name:</InputLabel>
            <Input
              id="updatepointName"
              name="updatepointName"
              type="text"
              value={updatePointName}
              onChange={(e) => setUpdatePointName(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              updatePointNameSubmitHandler();
            }}
          >
            Update
          </Button>{" "}
          &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setUpdatePointPopup(!updatePointPopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Qr code Generate */}
      <Modal
        open={qrCodeDialog}
        onClose={() => {
          setQrCodeDialog(!qrCodeDialog);
        }}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="modal-description"
      >
        <div>
          <QRBuilder qrFieldData={qrBody} qrResponseHandler={qrResponseHandler} />
        </div>
      </Modal>
      {/* UnMap Asset Confirmation Dialog */}
      <Dialog open={unmapAssetDialog} onClose={()=>{
        setUnmapAssetDialog(!unmapAssetDialog)
      }}>
        <DialogTitle>Unmap Asset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unmap the asset from the Site ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onConfirm('site')} color="primary">
            Yes
          </Button>
         {/*  <Button onClick={() => onConfirm('solution')} color="primary">
            Solution
          </Button> */}
          <Button onClick={()=>{
        setUnmapAssetDialog(!unmapAssetDialog)
      }} color="default">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <CustomPopup
        closeButton={closeSitAssetInfoModal}
        isOpen={assetInfoPopup}
        closeModal={assetInfoPopup}
        content={assetInfoBody}
        title={assetInfoHeader}
      />
      <CustomPopup
        closeButton={createAssetModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={assetCreationBody}
        title={titleCreateAsset}
      />
      <CustomPopup
        closeButton={closeMapModal}
        isOpen={mapPopUp}
        closeModal={mapPopUp}
        content={mapModelBody}
        title={titleMapAsset}
      />
      <CustomPopup
        closeButton={closeUpdateAssetStatusModel}
        isOpen={legacyUpdate}
        closeModal={legacyUpdate}
        content={updateLegacyAssetData}
        title={updateLegacyAsset}
      />
      <CustomPopup
        closeButton={closeUpdateAssetInfoModel}
        isOpen={assetInfoUpdatePopup}
        closeModal={assetInfoUpdatePopup}
        content={updateLegacyAssetInfoData}
        title={updateLegacyAssetInfo}
      />
      
    </>
  );
}
