import React, { useState, useEffect } from "react";
import ViewOrders from "./ViewOrders";
import AddBooking from "./AddBooking";
import { Button } from "@mui/material";

const BookingMainComp = ({ orderId, activeComponent: initialActiveComponent = "ViewOrders" }) => {
  const [activeComponent, setActiveComponent] = useState(initialActiveComponent);

  useEffect(() => {
    setActiveComponent(initialActiveComponent);
  }, [initialActiveComponent]);

  const handleBooking = () => {
    setActiveComponent("ViewOrders");
  };

  const addBookingHandler = () => {
    setActiveComponent("AddBooking");
  };

  return (
    <>
      <div className="topHeadersWrapper">
        <div className="topHeaders">
          <Button
            color="primary"
            size="large"
            variant='contained'
            onClick={handleBooking}
          >
            BOOKING 
          </Button>
        </div>
      </div>

      {activeComponent === "ViewOrders" && <ViewOrders addBookingHandler={addBookingHandler} />}
      {activeComponent === "AddBooking" && <AddBooking handleBooking={handleBooking} orderId={orderId} />}
    </>
  );
};

export default BookingMainComp;
