import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import LorryReceipt from './lorryReceipt/LorryReceipt';
import EwayBill from './ewaybill/EwayBill';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainService from '../../Common/MainService';
import DeliveryChallan from './DeliveryChallan';
import DeliveryReceipt from './DeliveryReceipt';

const componentNamesField = {
  lorryreceipt: 'Lorry Receipt',
  challan: 'Delivery Challan',
  receipt: 'Delivery Receipt',
};

const TransportDocumentManagenment = ({
  proxyId = '',
  componentNames = [],
  activeComponent = 'invoice',
}) => {
  const queryClient = new QueryClient();
  const [activeButton, setActiveButton] = useState(
    componentNames.includes(activeComponent) ? activeComponent : ''
  );
  const [userDetails, setUserDetails] = useState({});

  const [proxyid, setProxyid] = useState('');

  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  // useEffect(() => {
  //   if (componentName === '') {
  //     setActiveButton('lorryreceipt');
  //   }
  //   setProxyid(proxyId);
  // }, [proxyId, componentName]);

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  // button active
  const buttonStatusHandler = (btnName) => {};

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.7rem',
          mb: '1rem',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        {/* {componentNames?.length === 0 && (
          <>
            <Button
              variant={
                activeButton === 'lorryreceipt' ? 'contained' : 'outlined'
              }
              onClick={() => setActiveButton('lorryreceipt')}
            >
              Lorry Receipt
            </Button>

            <Button
              variant={activeButton === 'challan' ? 'contained' : 'outlined'}
              onClick={() => setActiveButton('challan')}
            >
              Delivery Challan
            </Button>
            <Button
              variant={activeButton === 'receipt' ? 'contained' : 'outlined'}
              onClick={() => setActiveButton('receipt')}
            >
              Delivery Receipt
            </Button> */}
        {/* <Button
              variant={activeButton === 'ewaybill' ? 'contained' : 'outlined'}
              onClick={() => setActiveButton('ewaybill')}
            >
              e-Way bill
            </Button> */}
        {/* </>
        )} */}

        {componentNames?.map((name, index) => (
          <Button
            key={index}
            variant={activeButton === name ? 'contained' : 'outlined'}
            onClick={() => setActiveButton(name)}
          >
            {componentNamesField[name]}
          </Button>
        ))}

        {/* {componentName === 'lorryreceipt' && (
          <Button
            variant={activeButton === 'lorryreceipt' ? 'contained' : 'outlined'}
            onClick={() => setActiveButton('lorryreceipt')}
          >
            Lorry Receipt
          </Button>
        )}
        {componentName === 'challan' && (
          <Button
            variant={activeButton === 'challan' ? 'contained' : 'outlined'}
            onClick={() => setActiveButton('challan')}
          >
            Delivery Challan
          </Button>
        )}
        {componentName === 'receipt' && (
          <Button
            variant={activeButton === 'receipt' ? 'contained' : 'outlined'}
            onClick={() => setActiveButton('receipt')}
          >
            Delivery Receipt
          </Button>
        )} */}
      </Box>
      {activeButton === 'lorryreceipt' && (
        <QueryClientProvider client={queryClient}>
          <LorryReceipt userDetails={userDetails} />
        </QueryClientProvider>
      )}
      {activeButton === 'challan' && (
        <DeliveryChallan userDetails={userDetails} proxyId={proxyid} />
      )}
      {activeButton === 'receipt' && (
        <DeliveryReceipt userDetails={userDetails} proxyId={proxyid} />
      )}

      {activeButton === 'ewaybill' && <EwayBill />}
    </Box>
  );
};

export default TransportDocumentManagenment;
