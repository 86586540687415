import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import GenrateNewReport from '../genrateReport/GenrateNewReport';
import MainService from '../../Common/MainService';
import { formatDate } from '../../../../utils/formatTime';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { event } from 'react-ga';
import LoadingPage from '../loadingpage/LoadingPage';

const Report = ({ creatorName }) => {
  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const [activeContent, setActiveContent] = useState('reportList');
  const [columnData, setColumnData] = useState([]);
  const [reportDataList, setReportDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentDetailsError, setDocumentDetailsError] = useState('');
  const [reportDetails, setReportDetails] = useState({});
  const [reportIndex, setReportIndex] = useState('');
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const openActionMenu = Boolean(actionMenuAnchorEl);

  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    // setIsLoading(true);
    // const fetchRowData = async () => {
    //   try {
    //     const tempRowData = testData.map((obj, index) => ({
    //       id: index + 1,
    //       ...obj,
    //     }));
    //     setReportDataList(tempRowData);
    //   } catch (error) {
    //     console.error('Error fetching row data:', error);
    //   } finally {
    //     if (mountedRef.current) {
    //       setIsLoading(false);
    //     }
    //   }
    // };
    // fetchRowData();

    setColumnData(columns);
    fetchReportList();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchReportList = useCallback(async () => {
    setIsLoading(true);
    setDocumentDetailsError('');
    const user = creatorName ? creatorName : username;
    try {
      const response = await MainService.documentListApi(
        parentId,
        orgid,
        suborgid,
        user,
        'report'
      );
      console.log('reportdsfjd', response);
      if (response && response.data && response.data.docs) {
        const initialData = response.data.docs.map((item, index) => ({
          id: index + 1,
          reportId: item.docId,
          docReceiverName: item.docReceiverName,
          date: formatDate(item.createdAt),
          status: item.status,
          viewLink: item.LocationUrl,
        }));
        // setReportDataList((prevData) => [...prevData, ...initialData]);
        setReportDataList(initialData);
      }
    } catch (error) {
      console.error('Error fetching invoice list:', error);
      setDocumentDetailsError(
        'An error occurred while fetching the invoice list.'
      );
    } finally {
      setIsLoading(false);
    }
  }, [parentId, username, orgid, suborgid]);

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Sl No.', width: 70 },
      {
        field: 'reportId',
        headerName: 'Report Id',
        width: 200,
      },
      { field: 'docReceiverName', headerName: 'Reviewed by', width: 250 },
      {
        field: 'date',
        headerName: 'Date',
        width: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
      },
      {
        field: 'button',
        headerName: 'Action',
        width: 100,
        headerAlign: 'center',
        width: 150,
        // flex: 1,
        align: 'center',
        renderCell: (params) => {
          return (
            <Fragment>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {params?.row?.status === 'generated' && (
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={!params?.row?.viewLink}
                    onClick={() => viewDocHandler(params)}
                  >
                    View
                  </Button>
                )}
                {params?.row?.status !== 'generated' && (
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => editReportHandler('edit', params)}
                  >
                    Edit
                  </Button>
                )}

                <MoreVertIcon
                  variant='outlined'
                  color={params?.row.status !== 'generated' ? 'primary' : ''}
                  // sx={{
                  //   cursor: params?.row.status !== 'generated' ? 'pointer' : '',
                  // }}
                  onClick={(event) => actionMenuOpenHandler(event, params?.row)}
                />
              </Box>
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const viewDocHandler = ({ row }) => {
    console.log('props', row.viewLink);
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = row.viewLink;
      link.setAttribute('download', 'Bill_PO.pdf');
      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  // genrate new report
  const genrateNewReportHandler = () => {
    setActiveContent('addReport');
  };

  // fetch document report details
  const fetchDocumentDetails = async (docId) => {
    try {
      const response = await MainService.documentDetailsApi(
        parentId,
        orgid,
        suborgid,
        username,
        docId
      );
      if (!response) {
        console.log('error');
        setDocumentDetailsError(
          'Document details not found, try refreshing the page.'
        );
      }
      setReportDetails(response?.data?.doc || {});
      setActiveContent('addReport');
    } catch (error) {
      console.log('error', error);
    }
  };

  // open action menu
  const actionMenuOpenHandler = (event, rowData) => {
    if (rowData.status === 'generated') {
      return;
    }
    setActionMenuAnchorEl(event.currentTarget);
    setReportIndex(rowData);
    console.log('action menu ', event.currentTarget, rowData);
  };

  // close action menu
  const closeActionMenuHandler = (event) => {
    setActionMenuAnchorEl(null);
  };

  // edit report handler
  const editReportHandler = (name, params) => {
    fetchDocumentDetails(params?.row?.reportId);
    setActiveContent('addReport');
    setActionMenuAnchorEl(null);
  };

  // review report handler
  const reviewReportHandler = (props) => {
    console.log('props********************', props, reportIndex);
    fetchDocumentDetails(reportIndex.reportId);
    setActiveContent('addReport');
    setActionMenuAnchorEl(null);
  };

  const fetchGeneratedDocument = async (formData, type) => {
    console.log('before genrate', formData);
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );
    console.log('already created', response);
    return response;
  };

  // delete report handler
  const deleteReportHandler = async () => {
    try {
      const response = await MainService.deleteDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        reportIndex.reportId
      );
      console.log('genratereport', response);
      if (response?.status === 200) {
        setReportDataList((prev) =>
          prev.filter((item) => item.reportId !== reportIndex.reportId)
        );
      }
      setActionMenuAnchorEl(null);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
      setActionMenuAnchorEl(null);
    }
    // setReportDataList((prev) =>
    //   prev.filter((item) => item.reportId !== reportIndex.reportId)
    // );
  };

  console.log('reportDataList', reportDetails);

  if (isLoading) return <LoadingPage />;

  return (
    <>
      {activeContent === 'reportList' && (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.6rem' }}>Report List</Typography>
            <Button variant='outlined' onClick={genrateNewReportHandler}>
              Create New Report
            </Button>
          </Box>
          <Box sx={{ width: '100%' }}>
            {!isLoading && (
              <DataGrid
                columns={columnData}
                rows={reportDataList}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                isRowSelectable={() => false}
              />
            )}
          </Box>
        </Box>
      )}

      {activeContent === 'addReport' && (
        <Box>
          <GenrateNewReport
            activeContentHandler={setActiveContent}
            reportDetailsData={reportDetails}
            reportDetailsDataStateHandler={setReportDetails}
            reportDataList={reportDataList}
            reportdataListHandler={setReportDataList}
          />
        </Box>
      )}

      <Menu
        id='action_menu'
        anchorEl={actionMenuAnchorEl}
        open={openActionMenu}
        onClose={closeActionMenuHandler}
        MenuListProps={{
          'aria-labelledby': 'action_menu-button',
        }}
      >
        <MenuItem
          onClick={() => editReportHandler('edit')}
          sx={{ color: '#2196f3' }}
        >
          Edit
        </MenuItem>

        {reportIndex?.docReceiverName === username && (
          <MenuItem
            sx={{ color: '#2196f3' }}
            onClick={() => reviewReportHandler('review')}
          >
            Review
          </MenuItem>
        )}

        <MenuItem sx={{ color: '#d32f2f' }} onClick={deleteReportHandler}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default Report;
