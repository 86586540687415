import React, { useEffect, useState } from "react";
import MatTable from "../../../../../Containers/Table/Table";
import SearchBar from "../../../../../Containers/SearchBar/SearchBar";
import MainService from "../../../Common/MainService";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#bf42f5",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  border: "none",
  borderRadius: "10px",
  boxShadow: 24,
  color: "black",
  p: 4,
};

const fields = [
  "",
  "Trip Id",
  "Driver ID",
  "Vehicle ID",
  "Route Id",
  "Trip State",
  "Start Time",
];

const TripManagement = ({ tripHandler1 }) => {


  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(10);
  const [tripDetails, setTripDetails] = useState({
    tripList: [],
    tripCount: 0,
  });
  const [searchBy, setSearchBy] = useState("");
  const [tripCurrentStatus, setTripCurrentStatus] = useState("");
  const [selected_items, setSelected_items] = useState("");
  const [tripStatus, setTripStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [confirm_delete, setConfirm_delete] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [searchValue, setSearchValue] = useState("");
  const [testrouteid, setTestrouteid] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  

  const history = useHistory();

  //update trip status
  const updateTripStatus = async () => {
    try {
      const response = await MainService.updateTripStatus(username,selected_items,tripCurrentStatus);
console.log(response,"TRIP STATUS RESPONSE:::::::::;")
      setTripStatus(false);

      getTripInfo();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  //ROUTE DETAILS BASED ON ROUTEID:
  const callApiWithRouteId = async (routeId) => {
    try {
      const response = await MainService.getDetailsofRouteId(parentId,username, routeId);
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error calling API:", error);
    }
  };

  //get search result:
  const getSearchResult = async (searchValue) => {
    try {
      const urls = `start=0&limit=40&searchBy=${searchBy}&fromDate=${fromdate}&toDate=${todate}&text=${searchValue}`;
      const response = await MainService.tripSearch(parentId,username, urls);

      const searchResults = response.tripList;

      // Update the tripDetails state with the search results
      setTripDetails({
        tripCount: response.tripCount,
        tripList: searchResults,
      });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 1);
    var firstMonth = firstDay.getMonth() + 1;
    var lastMonth = lastDay.getMonth() + 1;
    setFromdate(
      firstDay.getDate() + "-" + firstMonth + "-" + firstDay.getFullYear()
    );
    setTodate(
      lastDay.getDate() + "-" + lastMonth + "-" + lastDay.getFullYear()
    );
    fetchData();
  }, []);

  const fetchData = async () => {
    await getTripInfo();
    await updateTripStatus();
  };

  const setDate = (val, type) => {
    const date = val.split("-").reverse().join("-");
    if (type === "start") {
      setFromdate(date);
    } else {
      setTodate(date);
    }
  };

  useEffect(() => {
    getTripInfo();
    // fetchData();
  }, [fromdate, todate, startDate, endDate]);

 

  const getSearchOption = (event) => {
    setSearchBy(event.target.value);
  };

  const getTripInfo = async () => {
    try {
      let urls = `fromDate=${fromdate}&toDate=${todate}&start=0&limit=100`;
      const response = await MainService.getTripInfo(parentId,username, urls);

      setTripDetails({
        tripCount: response.tripCount,
        tripList: response.tripList,
      });
    } catch (error) {
      console.error("Error fetching trip info:", error);
    }
  };

  const deleteItems = (items) => {
    setConfirm_delete(true);
  };

  const onSelect = (items) => {
    console.log("itemmmmmmmmmmmmmmmmmmmmmm", tripDetails.tripList);
    if (selected_items === items) {
      setSelected_items("");
    } else {
      const filterData = tripDetails.tripList.filter(
        (item) => item.tripId === items
      );
      setTestrouteid((prev) => [...prev, filterData[0].routeId]);

      callApiWithRouteId(filterData[0].routeId);
      setSelected_items(items);
      setTripCurrentStatus(filterData[0].status);
    }
  };

  const onClose = (val) => {
    if (val) {
    } else {
      this.setState({ confirm_delete: false });
      setConfirm_delete(false);
    }
  };

  const modifyTripStatus = () => {
    setTripStatus(true);
  };

  const closeTripModal = () => {
    setTripStatus(false);
  };

  const setCurrentStatus = (evt) => {
    console.log("Event:", evt);

    if (evt && evt.target) {
      setTripCurrentStatus(evt.target.value);
    }
  };

  const loadMoreTrip = () => {
    setVisible(visible + 10);
  };

  const delTripHandler = async () => {
    let selecteditems = selected_items;
    const deleteTrip = await MainService.delTrip(username, selecteditems);

    if (deleteTrip) {
      findItem();
    }
  };

  const findItem = () => {
    const items = tripDetails?.tripList;
    const filterdata = items.filter((item) => item.tripId !== selected_items);
    setTripDetails({ tripList: filterdata });
    setOpen(false);
  };

  const closeModelHandler = () => {
    setOpen(false);
  };

  const actionChangeHandler = (event) => {
    if (event.target.value === "delete") {
      setOpen(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteHandler = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const isTripCompleted = () => {
    const selectedTrip = tripDetails.tripList.find(
      (item) => item.tripId === selected_items
    );

    return selectedTrip && selectedTrip.status === "COMPLETED";
  };

  //..................handle trip details....
  const handleModifyDetails = async (event) => {
    if (selected_items) {
      try {
        const tripDetails = await MainService.getDetailsOfTripId(
          parentId,
          username,
          selected_items
        );
        console.log("Trip Details of particular trip.....", tripDetails);

        tripHandler1(tripDetails);

        console.log("Modify details for:", selected_items, event.target.value);
      } catch (error) {
        console.error("Error fetching trip details:", error);
      }
    } else {
      console.log("No item selected for modification");
    }

    handleClose();
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setFromdate(newStartDate);
    setDate(newStartDate, "start");
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setTodate(newEndDate);
    setDate(newEndDate, "end");
    setEndDate(newEndDate);
  };

  //add dynamic handler
  const addDynamicHandler = () => {
    console.log(selected_items, "selected items//////////////");
    console.log("))))))))))))_________________", testrouteid);
    if (selected_items) {
      const routeIdsParam = testrouteid.join(",");
      console.log(routeIdsParam, "routeidparam///////");
      history.push(`/main/addDynamic/${selected_items}/${testrouteid[0]}`);
    } else {
      console.log("No item selected for dynamic routing");
    }
  };

  const handleTripMonitoringClick = () => {
    if (selected_items && tripCurrentStatus === "INPROGRESS") {
      const selectedTrip = tripDetails.tripList.find(
        (item) => item.tripId === selected_items
      );

      history.push({
        pathname: "/main/tripmonitoring",
        state: { tripData: selectedTrip },
      });
    } else {
      console.log("No item selected or the selected trip is not in progress");
    }
  };

  return (
    <>
    <ThemeProvider theme={theme}>
    <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #ddd",
                    marginBottom: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color="#1976d2"
                    sx={{
                      // textTransform: "uppercase",
                      letterSpacing: "2px",
                    }}
                  >
                    Trip Management
                  </Typography>
                </Box>

      <Card sx={{ m: 2, background: "aliceBlue" }}>
        <CardContent>
          <Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "stretch", md: "center" }}
              justifyContent="space-between"
              marginBottom="1%"
            >
              <Box flexGrow={1} marginBottom={{ xs: 2, md: 0 }}>
                <SearchBar
                  placeholder={"Search"}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onSubmit={getSearchResult}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                marginLeft={{ xs: 0, md: 6 }}
                marginBottom={{ xs: 2, md: 0 }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    style={{ width: 100 }}
                    value={searchBy}
                    onChange={getSearchOption}
                  >
                    <MenuItem value="trip">Trip</MenuItem>
                    <MenuItem value="vehicle">Vehicle</MenuItem>
                    <MenuItem value="driver">Driver</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                marginLeft={{ xs: 0, md: 2 }}
              >
                <TextField
                  id="start-date"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleStartDateChange}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                marginLeft={{ xs: 0, md: 2 }}
              >
                <TextField
                  id="end-date"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEndDateChange}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                marginLeft={{ xs: 2, md: 0 }}
              >
                <Fab
                  color="primary"
                  size="large"
                  aria-label="add"
                  cursor="pointer"
                  title="Add Location"
                  sx={{ margin: "1rem" }}
                  onClick={tripHandler1}
                >
                  <AddIcon />
                </Fab>

                <Fab
                  id="basic-button"
                  aria-controls={openAction ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAction ? "true" : undefined}
                  onClick={handleClick}
                  color="secondary"
                  disabled={selected_items === ""}
                >
                  <MoreVertIcon />
                </Fab>

                <Menu
                  color="primary"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openAction}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {tripCurrentStatus === "INPROGRESS" ? (
                    <MenuItem onClick={handleTripMonitoringClick}>
                      Trip Monitoring
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>Trip Monitoring</MenuItem>
                  )}

                  <MenuItem onClick={handleModifyDetails}>
                    Modify Details
                  </MenuItem>
                  <MenuItem onClick={() => setTripStatus(true)}>
                    Modify Trip Status
                  </MenuItem>
                  <MenuItem onClick={addDynamicHandler}>
                    Add Dynamic Routing
                  </MenuItem>
                  <MenuItem onClick={deleteHandler}>Delete</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Box sx={{ marginTop: "1rem" }}>
        <MatTable
          fields={fields}
          rows={tripDetails.tripList.slice(0, visible)}
          total={tripDetails.tripCount}
          keys={[
            "tripId",
            "driverId",
            "vehicleId",
            "routeId",
            "status",
            "startTime",
            "startDate",
          ]}
          unique="tripId"
          onSelect={onSelect}
          selected={selected_items}
          change_page={getTripInfo}
        />
      </Box>
      <Box sx={{ mt: 2, width: "100%" }}>
        {" "}
        <Button
          variant="outlined"
          color="primary"
          // fullWidth
          onClick={loadMoreTrip}
        >
          Load More
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={closeModelHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            CONFIRM DELETE
          </Typography>
          <hr />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <hr />

          <Box sx={{ marginTop: "0.5rem", display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={delTripHandler}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* trip status modal */}
      <Modal open={tripStatus} onClose={() => setTripStatus(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: "white",
            border: "none",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Modify Trip State</Typography>
            <Button onClick={() => setTripStatus(false)}>
              <CloseIcon />
            </Button>
          </Box>
          <Box>
            <Typography variant="body1">Select State</Typography>
            <FormControl fullWidth>
              <InputLabel id="select-state-label">State</InputLabel>
              <Select
                labelId="select-state-label"
                label="State"
                onChange={(e) => setCurrentStatus(e)}
              >
                {/* <MenuItem value="CREATED">Created</MenuItem> */}
                <MenuItem value="INPROGRESS">In Progress</MenuItem>
                <MenuItem value="CANCEL">Cancel</MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => setTripStatus(false)} color="error">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={updateTripStatus}
              color="primary"
              sx={{ ml: 2 }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      </ThemeProvider>
    </>
  );
};

export default TripManagement;
