import React, { useState, useMemo, lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
const CreateTradeDoc = lazy(() => import("./CreateTradeDoc"));
const ProfileTable = lazy(() => import("./ProfileTable"));

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const ParentDoc = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [postPopup, setShowPostPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("USER-AGREEMENT");
  const [postList, setPostList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const theme = useTheme();

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        if (data.length > 0) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getMyCategoryTender(window.AppConfigData.EnterpriseId, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setIsLoading(false);
          setPostList(data);
        } else {
          setPostList(data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  return (
    <>
      <Box className="container" id="mainContentList">
        <Box className="MuiGrid-root">
          <Suspense fallback={<Loading />}>
            <ProfileTable data={postList} isLoading={isLoading} />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default ParentDoc;
