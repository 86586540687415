import React, { useEffect, useState } from "react";
import MainService from "../../../Common/MainService";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography, Modal } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DashbordForDriverComponent from "../../../LogisticUser/DashboardForDriver/DashbordForDriverComponent";

const MappedAssetsComponent = () => {
  const [assets, setAssets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { user } = MainService.getLocalData();
  const { username } = user;

  const history = useHistory();
  const location = useLocation();
  const { nodeId } = location.state || {};

  const getMappedAssets = async () => {
    try {
      const response = await MainService.mappedAssetsWithNode(username, nodeId);
      console.log(response, "all mapped assets:::::::::");
      setAssets(response.assets || []);
    } catch (error) {
      console.error("Error fetching mapped assets:", error);
    }
  };

  useEffect(() => {
    getMappedAssets();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          borderBottom: 'inset',
          mb: 2,
          pb: 1,
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Button startIcon={<ArrowBackIcon />} onClick={() => history.push('./nodeManagement')}>
              Back
            </Button>
          </Grid>
          <Grid item xs={10} container justifyContent="center">
            <Typography
              sx={{ fontSize: 'xx-large', fontWeight: 600, fontFamily: 'Arial' }}
            >
              List of Mapped Assets
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>

      <Box sx={{ padding: 2 }}>
        {assets.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '20px' }}>
            No mapped assets found
          </Typography>
        ) : (
          <Grid container spacing={3} justifyContent={assets.length === 1 ? 'center' : 'flex-start'}>
            {assets.map((asset) => (
              <Grid item key={asset.assetId} xs={12} sm={assets.length === 1 ? 6 : 4}>
                <Card sx={{ borderRadius: '16px', boxShadow: 3, transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image="https://st4.depositphotos.com/1020618/21282/i/450/depositphotos_212828960-stock-photo-truck-with-container-on-road.jpg"
                    alt="Asset image"
                  />
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                          borderRadius: '12px',
                          padding: '4px 12px',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                        }}
                      >
                        <Typography variant="body2">{asset.assetStatus}</Typography>
                      </Box>
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3f51b5', mb: 1 }}>Asset ID: {asset.assetId}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1,fontSize:'large' }}>UserId: {asset.assetdeveloperid}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1, fontSize:'large' }}>Site ID: {asset.siteId}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1, fontSize:'large' }}>Category: {asset.category}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1, fontSize:'large' }}>Asset Type: {asset.assetType}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1, fontSize:'large' }}>Model ID: {asset.modelId}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1 , fontSize:'large'}}>Latitude: {asset.latitude || 'N/A'}</Typography>
                    <Typography variant="body2" sx={{ color: '#757575', mb: 1 , fontSize:'large'}}>Longitude: {asset.longitude || 'N/A'}</Typography>
                    <Box>
                      <Button variant="contained" color="success" onClick={handleOpenModal}>
                        Driver app
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '90vw',
            height: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Driver Interface
          </Typography> */}
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <DashbordForDriverComponent />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MappedAssetsComponent;
