import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";
import RouteMap from "./RouteMap/RouteMap";
import MainService from "../../../Common/MainService";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  styled,
} from "@mui/material";

const google = window.google;

const useStyles = styled((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  cardData: {
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(2),
  },
}));

const AddRoute = (props) => {
  console.log(props.routeData, "routedta in addroutess.../////}}");

  const classes = useStyles();
  const [locArray, setLocArray] = useState([]);
  const [routeDetails, setRouteDetails] = useState({});
  const [places, setPlaces] = useState([]);
  const [loc, setLoc] = useState({});
  const [steps, setSteps] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const tenantId = localStorage.getItem("tenantId");
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [geo, setGeo] = useState({});
  const [polyPath, setPolyPath] = useState([]);
  const [defaultRadius, setDefaultRadius] = useState(0);
  const [drawingType, setDrawingType] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");
  const [routeData, setRouteData] = useState({});
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [selectedRouteId, setSelectedRouteId] = useState(null);
  const [isStartLocDisabled, setIsStartLocDisabled] = useState(false);
  const [isEndLocDisabled, setIsEndLocDisabled] = useState(false);

  useEffect(() => {
    getLocationInfo();
  }, []);

  useEffect(() => {
    if (props.routeData) {
      setIsStartLocDisabled(true);
      setIsEndLocDisabled(true);
      setSelectedRouteId(props.routeData.routeId);
      console.log(props.routeData, "routeeeeedataaaa............");
      setRouteDetails({
        routeId: props.routeData.routeId,
        routeName: props.routeData.routeName,
        startLocId: props.routeData.startLocId,
        endLocId: props.routeData.endLocId,
        // Add other fields here as needed
      });
      console.log(routeDetails.routeId, "routeiddddd/////");

      // Set initial values for start and end addresses if needed
      setStartAddress(props.routeData.startLocId); // Assuming you have a function to fetch address based on locId
      setEndAddress(props.routeData.endLocId); // Assuming you have a function to fetch address based on locId
    }
  }, [props.routeData]);

  const getLocationInfo = async () => {
    try {
      const data = await MainService.getLocationInfo(parentId,username);
      setLocArray(data.locList);
      console.log(locArray, "location infoooooooo.............");
    } catch (err) {
      console.error("error fetching locations", err);
    }
  };

  const onSubmit = (value) => {
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setMarkerPosition({ lat, lng });
        setGeo({ lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
    setSearchValue(value);
  };

  const getAddress = (value) => {
    setAddress(value);
  };

  const setMapType = (evt) => {
    setDrawingType(evt.target.value);
  };

  const setPath = (lat, lng) => {
    let currentPath = { lat, lng };
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      setAddress(results[0].formatted_address);
    });
    setMarkerPosition(currentPath);
  };

  const clearDrawing = () => {
    setPolyPath([]);
    setDefaultRadius(0);
  };

  const undoDrawing = () => {
    let polyPath = [...polyPath];
    polyPath.pop();
    setPolyPath(polyPath);
  };

  const submitForm = async () => {
    const routeData = { ...routeDetails };
    console.log(routeData, "roteeeeee''/////");
    routeData["geoPath"] = steps;

    if (!routeDetails.routeName) {
      displaySnackbar("Please provide a suitable route tag");
      return;
    }

    if (!routeDetails.startLocId) {
      displaySnackbar("Please select start location");
      return;
    }

    if (!routeDetails.endLocId) {
      displaySnackbar("Please select end location");
      return;
    }
    console.log("routeID", routeDetails.routeId);

    try {
      if (props.routeData) {
        await MainService.updateRouteName(
          parentId,
          username,
          { routeName: routeDetails.routeName },
          props.routeData.routeId
        );
      } else {
        const data = await MainService.addRouteApi(parentId,username, routeData);
        console.log("new route added", data);
      }

      props.routeHandler();
    } catch (error) {
      console.log("error while fetching route", error);
    }
  };

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const changeData = async (evt) => {
    const route = { ...routeDetails };
    const locArr = locArray;
    const place = [...places];
    const lst = locArr.filter((loc) => loc.locId === evt.target.value);
    const location = { ...loc };

    if (lst.length > 0) {
      if (evt.target.name === "startLocId") {
        place[0] = {
          latitude: lst[0].geoLocation.lat,
          longitude: lst[0].geoLocation.lng,
        };

        console.log("Geocoding Start Address...");
        Geocode.fromLatLng(lst[0].geoLocation.lat, lst[0].geoLocation.lng).then(
          (response) => {
            const formattedAddress = response.results[0].formatted_address;
            console.log("Start Address:", formattedAddress);
            setStartAddress(formattedAddress);
          },
          (error) => {
            console.error("Geocoding Error:", error);
          }
        );
      } else if (evt.target.name === "endLocId" && place.length > 0) {
        place[1] = {
          latitude: lst[0].geoLocation.lat,
          longitude: lst[0].geoLocation.lng,
        };

        console.log("Geocoding End Address...");
        Geocode.fromLatLng(lst[0].geoLocation.lat, lst[0].geoLocation.lng).then(
          (response) => {
            const formattedAddress = response.results[0].formatted_address;
            console.log("End Address:", formattedAddress);
            setEndAddress(formattedAddress);
          },
          (error) => {
            console.error("Geocoding Error:", error);
          }
        );
      }
    }

    route[evt.target.name] = evt.target.value;
    setRouteDetails(route);
    setPlaces(place);
    setLoc(location);
  };

  const getSteps = (steps) => {
    setSteps(steps);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.routeData ? (
            <Button
              variant="outlined"
              sx={{ fontSize: "large", textAlign: "left" }}
            >
              SELECTED ROUTE ID: {selectedRouteId}
            </Button>
          ) : (
            <Box />
          )}
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "5px" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => props.routeHandler()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={submitForm}>
              Submit
            </Button>
          </Box>
        </Box>

        <Card elevation={3} sx={{ p: 2, mt: 2 }} className={classes.cardData}>
          <Box
            sx={{ display: "flex", gap: 2, flexWrap: "wrap", width: "100%" }}
          >
            <TextField
              name="routeName"
              value={routeDetails.routeName}
              onChange={changeData}
              label="Enter a Route tag"
            />
          </Box>

          <Box sx={{ display: "flex", mt: 2, gap: 2, "&>*": { flex: "1" } }}>
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  value={routeDetails.startLocId}
                  onChange={changeData}
                  name="startLocId"
                  inputProps={{
                    id: "age-native-required",
                  }}
                  disabled={isStartLocDisabled}
                >
                  <option value="">Start Location</option>
                  {locArray.map((loc) => {
                    return <option value={loc.locId}>{loc.locId}</option>;
                  })}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl required fullWidth>
                <Select
                  native
                  value={routeDetails.endLocId}
                  onChange={changeData}
                  name="endLocId"
                  inputProps={{
                    id: "age-native-required",
                  }}
                  disabled={isEndLocDisabled}
                >
                  <option value="">End Location</option>
                  {locArray.map((loc) => {
                    return <option value={loc.locId}>{loc.locId}</option>;
                  })}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 2, gap: 2, "&>*": { flex: "1" } }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                Start Location : {startAddress}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                End Location : {endAddress}
              </Typography>
            </Box>
          </Box>
        </Card>

        <Box sx={{ mt: 2 }}>
          <RouteMap
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o&libraries=geometry,drawing,places"
            }
            markers={places}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: "60vh" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            getSteps={getSteps}
            selectedPlace={setPath}
          />
        </Box>
      </Box>
    </>
  );
};

export default AddRoute;
