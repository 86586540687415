import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fab,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
  CircularProgress

} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import MainService from "../../Common/MainService";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MapIcon from "@mui/icons-material/Map";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DeleteIcon from "@mui/icons-material/Delete";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const headerStyle = {
  textAlign: "center",
  backgroundColor: "#3ECF8E",
  color: "white",
};
const bodyStyle = {
  textAlign: "center",
};

const initialFormData = {
  parentid: "",
  nodeownerid: "",
  id: "",
  username: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  district: "",
  pincode: "",
  state: "",
  city: "",
  address: "",
  longitude: "",
  latitude: "",
  accounttype: "local",
  usertype: "LogisticsTransportUser",
  assettype: "tempo",
  categorytype: "tempo",
  numberofassets: "",
  areasize: "",
  malegendernumber: "",
  femalegendernumber: "",
  overallage: "",
  nodetype: "Space",
  assetverticalid: "07a34ce0-bb09-421d-b144-03fcfe473311",
  assetverticalfriendlyname: "TRANSPORTATION",
};

const initialMapFormData = {
  Sector: "",
  Categorytype: "",
  Assettype: "",
  modelId: "",
  ChooseAsset: "",
  nodeId: "",
};

const NodeManagementComponent = () => {
  const [openModal, setOpenModal] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [openMapModal, setOpenMapModal] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [mapFormData, setMapFormData] = useState(initialMapFormData);
  const [assetVerticals, setAssetVerticals] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nodeIdToDelete, setNodeIdToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (nodeId) => {
    setOpenDeleteConfirm(true);
    setNodeIdToDelete(nodeId);
  };

  const confirmDelete = async () => {
    try {
      await MainService.deleteNode(nodeIdToDelete, username);
      console.log("Node deleted successfully");
      setSnackbarMessage("Node deleted successfully");
      setSnackbarOpen(true);
      await getConsumerNodeInfo(); 
    } catch (error) {
      console.error("Error deleting node:", error);
    } finally {
      setOpenDeleteConfirm(false);
      setNodeIdToDelete(null);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    setNodeIdToDelete(null);
  };

  const history = useHistory();

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setFormData((prevFormData) => ({
      ...initialFormData,
      parentid: prevFormData.parentid,
      nodeownerid: prevFormData.nodeownerid,
      id: prevFormData.id,
      username: prevFormData.username,
      email: prevFormData.email,
    }));
  };

  const handleMapClose = () => {
    setOpenMapModal(false);
    setSelectedNodeId(null);
    setMapFormData({
      Sector: "",
      Categorytype: "",
      Assettype: "",
      modelId: "",
      ChooseAsset: "",
      nodeId: "",
    });
    setAssetList([]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleMapFormChange = async (e) => {
    const { name, value } = e.target;
    const updatedMapFormData = { ...mapFormData, [name]: value };
    setMapFormData(updatedMapFormData);

    if (name === "Sector") {
      const fetchedCategoryTypes = await MainService.getCategoryTypesList(
        value,
        window.AppConfigData.EnterpriseId
      );
      setCategoryTypes(fetchedCategoryTypes);
    }

    if (name === "Categorytype") {
      const fetchedAssetTypes = await MainService.getAssetTypesList(
        updatedMapFormData.Sector,
        value,
        window.AppConfigData.EnterpriseId
      );
      setAssetTypes(fetchedAssetTypes);
    }

    if (name === "Assettype") {
      const fetchedModelList = await MainService.getModelsList(
        updatedMapFormData.Sector,
        updatedMapFormData.Categorytype,
        value,
        username
      );
      setModelList(fetchedModelList);
    }

    if (name === "modelId") {
      const fetchedAssetList = await MainService.getVehiclesBasedOnModel(
        username,
        value,
        updatedMapFormData.Sector
      );
      setAssetList(fetchedAssetList);
    }
  };

  const getConsumerNodeInfo = async () => {
    setLoading(true);

    try {
      const NodeInfo = await MainService.getConsumerNodeInfo(username);
      setNodes(NodeInfo);
    
    } catch (error) {
      console.error("Error fetching node list:", error);
    } finally {
      setLoading(false);
    }
  };

  const getConsumerUserInfo = async () => {
    try {
      const UserInfo = await MainService.getConsumerUserInfo(
        parentId,
        username
      );
      if (UserInfo && UserInfo.length > 0) {
        const user = UserInfo[0];
        setUserInfo(user);
        setFormData((prevFormData) => ({
          ...prevFormData,
          parentid: user.domain,
          username: user.username,
          email: user.username,
          id: user.username,

          // suborgid: username,
          nodeownerid: username,
        }));
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const nodeData = {
        ...formData,
        json: {},
      };
      await MainService.createNode(JSON.stringify(nodeData));
      console.log("Node added successfully");
      await getConsumerNodeInfo();

      handleClose();

      setSnackbarMessage("Node created successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding node:", error);
    }
  };

  const handleMappedAssets = (nodeId) => {
    history.push({
      pathname: "./mappedAssets",
      state: { nodeId },
    });
  };

  useEffect(() => {
    const fetchAssetVerticalsIdList = async () => {
      try {
        const verticals = await MainService.getAssetVerticalsIdList(
          window.AppConfigData.EnterpriseId
        );
        console.log(verticals, "ASSET VERTICALS:::::::::");
        setAssetVerticals(verticals);
      } catch (error) {
        console.error("Error fetching asset verticals:", error);
      }
    };
    fetchAssetVerticalsIdList();
  }, []);

  const handleMapButtonClick = async (nodeId) => {
    setSelectedNodeId(nodeId);
    setMapFormData((prevMapFormData) => ({
      ...prevMapFormData,
      nodeId: nodeId,
    }));

    setOpenMapModal(true);
  };

  const handleMapSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        siteid: selectedNodeId,
      };
      const response = await MainService.MapLegacyAssetToNode(
        username,
        mapFormData.ChooseAsset,
        data
      );
      console.log("Mapped asset to node successfully:::::::::::::", response);
      handleMapClose();
      setSnackbarMessage("Asset mapped to node successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error mapping asset to node:", error);
    }
  };

  //ASSET VERTICAL LIST(SECTOR)
 

  useEffect(() => {
    getConsumerNodeInfo();
    getConsumerUserInfo();
  }, []);

  // Filter nodes based on selected status
  useEffect(() => {
    filterNodesByStatus();
  }, [selectedFilter, nodes]);

  const filterNodesByStatus = () => {
    if (selectedFilter === "All") {
      setFilteredNodes(nodes);
    } else {
      const filtered = nodes.filter(
        (node) => node.nodestatus === selectedFilter
      );
      setFilteredNodes(filtered);
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          borderBottom: "inset",
          color: "#007bff",
        }}
      >
        <Typography
          sx={{ fontSize: "xx-large", fontWeight: 600, fontFamily: "emoji" }}
        >
          LIST OF NODES
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          pl: 6,
          pr: 2,
        }}
      >
        {/* Filter Dropdown */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Status</InputLabel>
          <Select
            value={selectedFilter}
            onChange={handleFilterChange}
            label="Filter by Status"
            inputProps={{ "aria-label": "Filter by Status" }}
          >
            <MenuItem value="All">
              <em>Show All</em>
            </MenuItem>
            <MenuItem value="authentication-pending">
              Authentication Pending
            </MenuItem>
            <MenuItem value="authenticated">Authenticated</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
          </Select>
        </FormControl>

        {/* Add Node Button */}
        <Fab color="primary" title="Create Node" onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </Box>

      {/* Create Node MODAL */}
      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            maxHeight: "90vh",
            width: "80vw",
            maxWidth: "800px",
          }}
        >
          <Box>
            <Tooltip title="Close">
              <IconButton
                onClick={handleClose}
                sx={{ alignSelf: "flex-start" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography
            sx={{
              borderBottom: "ridge",
              display: "flex",
              justifyContent: "center",
              fontSize: "2rem",
              fontWeight: "bold",
              fontFamily: "auto",
              mb: 3,
            }}
            variant="h4"
            component="h2"
            id="form-modal-title"
          >
            CREATE NODE
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {Object.entries(formData).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <FormControl fullWidth>
                    <FormLabel>{key}</FormLabel>
                    <Input
                      name={key}
                      value={value}
                      onChange={handleChange}
                      required
                      type={
                        key === "numberofassets" ||
                        key === "overallage" ||
                        key === "malegendernumber" ||
                        key === "femalegendernumber"
                          ? "number"
                          : "text"
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button type="submit" variant="contained" sx={{ mr: 2 }}>
                Submit
              </Button>
              <Button variant="contained" color="error" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* Map Asset to Node MODAL */}
      <Modal
        open={openMapModal}
        aria-labelledby="map-modal-title"
        aria-describedby="map-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            maxHeight: "90vh",
            width: "80vw",
            maxWidth: "800px",
          }}
        >
          <Box>
            <Tooltip title="Close">
              <IconButton
                onClick={handleMapClose}
                sx={{ alignSelf: "flex-start" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography
            sx={{
              borderBottom: "ridge",
              display: "flex",
              justifyContent: "center",
              fontSize: "2rem",
              fontWeight: "bold",
              fontFamily: "auto",
              mb: 3,
            }}
            variant="h4"
            component="h2"
            id="map-modal-title"
          >
            MAP ASSET TO NODE
          </Typography>
          <form onSubmit={handleMapSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Sector</InputLabel>
                  <Select
                    id="Sector"
                    name="Sector"
                    value={mapFormData.Sector}
                    required
                    onChange={handleMapFormChange}
                    // disabled
                  >
                    {assetVerticals.map((vertical) => (
                      <MenuItem
                        key={vertical.assetVerticalId}
                        value={vertical.assetVerticalId}
                      >
                        {vertical.friendlyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Category Type</InputLabel>
                  <Select
                    name="Categorytype"
                    id="Categorytype"
                    value={mapFormData.Categorytype}
                    onChange={handleMapFormChange}
                    required
                  >
                    {categoryTypes.map((category) => (
                      <MenuItem
                        key={category.categoryType}
                        value={category.categoryType}
                      >
                        {category.categoryType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="Assettype">Asset Type</InputLabel>
                  <Select
                    id="Assettype"
                    name="Assettype"
                    value={mapFormData.Assettype}
                    onChange={handleMapFormChange}
                    required
                  >
                    {assetTypes.map((asset) => (
                      <MenuItem key={asset.assetType} value={asset.assetType}>
                        {asset.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="modelId">Model ID</InputLabel>
                  <Select
                    id="modelId"
                    name="modelId"
                    value={mapFormData.modelId}
                    onChange={handleMapFormChange}
                    required
                  >
                    {modelList.map((model) => (
                      <MenuItem key={model.modelid} value={model.modelid}>
                        {model.modelid}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="ChooseAsset">Choose Asset</InputLabel>
                  <Select
                    id="ChooseAsset"
                    name="ChooseAsset"
                    value={mapFormData.ChooseAsset}
                    onChange={handleMapFormChange}
                    required
                  >
                    {assetList.map((asset) => (
                      <MenuItem key={asset.assetId} value={asset.assetId}>
                        {asset.assetId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Node ID</InputLabel>
                  <Input
                    name="nodeId"
                    value={mapFormData.nodeId}
                    onChange={handleMapFormChange}
                    required
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button type="submit" variant="contained" sx={{ mr: 2 }}>
                Submit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleMapClose}
              >
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Modal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this node?
          </Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="error"
              onClick={confirmDelete}
              sx={{ mr: 2 }}
            >
              Delete
            </Button>
            <Button variant="contained" onClick={handleCloseDeleteConfirm}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box mt={3}>
        {loading ? (
          <Backdrop open={loading} sx={{ zIndex: theme.zIndex.drawer + 2 }}>
               <CircularProgress color="inherit"/>
          </Backdrop>
        ) : filteredNodes.length === 0 ? (
          <Typography variant="h5" sx={{display:'flex', justifyContent:'center', alignItems:'center'}} color="textSecondary">
            No Nodes found
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>Sr.No.</TableCell>
                  <TableCell sx={headerStyle}>Node Id</TableCell>
                  <TableCell sx={headerStyle}>Email</TableCell>
                  <TableCell sx={headerStyle}>Node Type</TableCell>
                  <TableCell sx={headerStyle}>Node Status</TableCell>
                  <TableCell sx={headerStyle}>Timestamp</TableCell>
                  <TableCell sx={headerStyle}>More Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredNodes.map((node, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(odd)": {
                        // backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell sx={bodyStyle}>
                      <Typography variant="body1">Node {index + 1}</Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Typography variant="body1">{node.nodeid}</Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Typography variant="body1">{node.email}</Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Typography variant="body1">{node.nodetype}</Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Typography>
                        <div
                          style={{
                            borderRadius: "2rem",
                            color: "white",
                            backgroundColor:
                              node.nodestatus === "approved"
                                ? "green"
                                : node.nodestatus === "authenticated"
                                ? "palevioletred"
                                : node.nodestatus === "authentication-pending"
                                ? "orange"
                                : "blue",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          {node.nodestatus === "approved" && (
                            <CheckCircleIcon sx={{ marginRight: "0.2em" }} />
                          )}
                          {node.nodestatus === "authentication-Pending" && (
                            <HourglassBottomRoundedIcon
                              sx={{ marginRight: "0.2em" }}
                            />
                          )}
                          {node.nodestatus === "authenticated" && (
                            <AutorenewRoundedIcon
                              sx={{ marginRight: "0.2em" }}
                            />
                          )}
                          {node.nodestatus}
                        </div>
                      </Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Typography variant="body1">{node.timestamp}</Typography>
                    </TableCell>
                    <TableCell sx={bodyStyle}>
                      <Tooltip
                        title="Map Assets to node"
                        placement="top-start"
                        arrow
                      >
                        <Button
                          sx={{ mr: 1 }}
                          color="primary"
                          variant="outlined"
                          onClick={() => handleMapButtonClick(node.nodeid)}
                        >
                          <MapIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="MappedAssets" placement="top-start" arrow>
                        <Button
                          sx={{ mr: 1 }}
                          color="primary"
                          variant="outlined"
                          onClick={() => handleMappedAssets(node.nodeid)}
                          endIcon={<ArrowForwardIcon />}
                        >
                          <DirectionsCarIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Delete Node" placement="top-start" arrow>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => handleDelete(node.nodeid)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
    </ThemeProvider>
  );
};

export default NodeManagementComponent;
