import React from 'react';

import './CustomPopupStyle.css';
import { Box } from '@mui/material';

function CustomPopup(props) {
  const { isOpen, content, title, closeButton } = props;

  return (
    <>
      {/* <Box className={isOpen ? 'modal' : 'modal closed'} id='CustomPopup'>
        <Box className='CustomPopup-header'>
          <div className='CustomPopup-header__title'>{title}</div>
          <span className='closeButton'>{closeButton}</span>
        </Box>

        <Box className='modal-guts'>{content}</Box>
      </Box>
      <Box
        className={isOpen ? 'modal-overlay' : 'modal-overlay closed'}
        id='CustomPopup-overlay'
      ></Box> */}

      <Box
        className={isOpen ? 'modal' : 'modal closed'}
        id='CustomPopup'
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 700,
            fontSize: '1.6rem',
            pb: 0.5,
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
            fontSize: '1.6rem',
            cursor: 'pointer',
          }}
        >
          {closeButton}
        </Box>
        <Box sx={{ padding: '0 1rem 1rem 1rem' }}>{content}</Box>
      </Box>
      <Box
        className={isOpen ? 'modal-overlay' : 'modal-overlay closed'}
        id='CustomPopup-overlay'
      ></Box>
    </>
  );
}

export default CustomPopup;
