import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useTheme } from "@material-ui/core/styles";
import { Box, Button } from "@mui/material";
import { getCategoryID } from "../../Common/CommonContent/ContentedRelatedCommonFun";
import Loading from "../../AssetDeveloperRole/ModelProductManagement/Loading";
const CreateTradeDoc = lazy(() => import("./CreateTradeDoc"));
const ProfileTable = lazy(() => import("./ProfileTable"));
const CreateContentSubCategory = lazy(() =>
  import("../../Common/CommonContent/CreateContentSubCategory")
);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

const UserTradeDocs = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [postPopup, setShowPostPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] =
    useState("USER-AGREEMENT");
  const [postList, setPostList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [subcategorydialog, setSubCategoryDialog] = useState(false);
  const theme = useTheme();

  useMemo(async () => {
    await getCategoryID(selectedCategoryName)
      .then((data) => {
        if (data.length > 0) {
          setSelectedCategoryId(data);
        } else {
          setSelectedCategoryId("");
        }
      })
      .catch((e) => {
        return NotificationManager.error("error");
      });
  }, []);

  const getAllContentsList = () => {
    setIsLoading(true);
    MainService.getMyCategoryTender(username, selectedCategoryId)
      .then((data) => {
        if (data.length > 0) {
          setIsLoading(false);
          setPostList(data);
        } else {
          setPostList(data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        return NotificationManager.error("");
      });
  };

  useMemo(() => {
    if (!selectedCategoryId) return;
    if (!username) return;
    getAllContentsList();
  }, [selectedCategoryId]);

  /*Create Post */
  const closeCreateDocModelHandler = () => {
    setShowPostPopup(!postPopup);
  };

  const createSubCategoryClick = () => {
    setSubCategoryDialog(!subcategorydialog);
  };

  return (
    <>
      <Box className="container" id="mainContentList">
        <Fab
          color="primary"
          size={"large"}
          aria-label="add"
          title="Create Advertisement"
          className={classes.fab}
          onClick={() => {
            closeCreateDocModelHandler();
          }}
        >
          <AddIcon></AddIcon>
        </Fab>

        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              bgcolor: "#002e34",
              margin: "2px",
              width: { xs: "100%", sm: "70%", md: "auto" },
              "&:hover": {
                bgcolor: "#002e34",
              },
            }}
            variant="contained"
            onClick={() => {
              createSubCategoryClick();
            }}
          >
            Create Sub-Category
          </Button>
        </Box>

        <Box className="MuiGrid-root">
          <Suspense fallback={<Loading />}>
            <ProfileTable data={postList} isLoading={isLoading} />
          </Suspense>
        </Box>

        <Suspense fallback={<Loading />}>
          <CreateTradeDoc
            categoryId={selectedCategoryId}
            categoryName={selectedCategoryName}
            closeModel={closeCreateDocModelHandler}
            openModel={postPopup}
            reload={getAllContentsList}
            parent={username}
            // userType={localStorage.getItem("userType")}
            docType="usertrade"
            users={username}
          />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <CreateContentSubCategory
            reLoad={createSubCategoryClick}
            userName={username}
            categoryId={selectedCategoryId}
            categoryName={selectedCategoryName}
            parentID={parentId}
            onOpen={subcategorydialog}
            onClose={createSubCategoryClick}
          />
        </Suspense>
      </Box>
    </>
  );
};

export default UserTradeDocs;
