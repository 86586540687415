import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./UploadKYCDocumentsComponent.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function UploadKYCDocumentsComponent(props) {

  const [KYCList, setKYCList] = useState([]);

  const { user } = MainService.getLocalData();
  const { username } = user;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [KYCViewDialog, setKYCViewDialog] = React.useState(false);
  const [uploadKYCDialog, setUploadKYCDialog] = React.useState(false);
  const [KYCDocumentLink, setKYCDocumentLink] = React.useState(null);
  const [parentId,setParentId] = React.useState("");
  const [kycType, setKycType] = useState("");
  const [kycFile, setKYCFile] = useState(null);
  const [filteredKYCList, setFilteredKYCList] = useState([]);
  const [searched, setSearched] = useState("");
  /* Agreent view and update status */
  const [isLoading,setIsLoading] = useState(false);
  const [viewSignedAgreementDialog, setViewSignedAgreementDialog] =
  useState(false);
const [viewSignedDocumentList, setViewSignedDocumentList] = useState([]);
const [AgreementsDocumentLink, setAgreementsDocumentLink] = useState(null);
const [KYCDocumentStatus, setKYCDocumentStatus] = useState("");
const [updateKYCStatusDialog, setUpdateKYCStatusDialog] =
  useState(false);
const [KYCStatusValue, setKYCStatusValue] = useState("");
const [selectedDocType, setSelectedDocType] = useState("");
const [selectedConsumerUserName, setSelectedConsumerUserName] = useState("");
/*End of  Agreent view and update status */

  useEffect(() => {
    if(props.selectedUser !== "profile")
    {
      getUsersKYCDocuments(props.selectedUser);
    }else{
     
      getAllKYCDocuments(username);
    }
    
    getParentId();
  }, [props.selectedUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const getParentId = () =>{
    MainService.getParentId(username)
    .then((data) => {
      setParentId(data);
    })
    .catch((e) => {
      console.error(e);
      return NotificationManager.error(e);
    });
  }
  /* View All KYCDocs */
  const getAllKYCDocuments = (username) => {
    MainService.getKYCDocuments(username)
      .then((data) => {
        setKYCList(data);
        setFilteredKYCList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  const getUsersKYCDocuments = (user) => {
    MainService.getUserKYCDocuments(username, user)
      .then((data) => {
        setKYCList(data);
        setFilteredKYCList(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };
  /* End All KYCDocs */

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {};
  const KYCViewDialogClick = (link) => {
    setKYCDocumentLink(link);
    setKYCViewDialog(!KYCViewDialog);
  };
  const KYCSubmit = () => {
    if(kycFile && kycType)
    {
      let kycData = new FormData();
      kycData.append("file",kycFile);
      kycData.append("mimetype",kycFile.type);
     
      MainService.uploadKYC(username, kycType,kycData)
        .then((res) => {
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
    }else{
      return NotificationManager.error("please choose file");
    }
    
      setUploadKYCDialog(!uploadKYCDialog);
  };

  const uploadKYCClick = () => {
    setUploadKYCDialog(!uploadKYCDialog);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredKYCList.filter((row) => {
      return row.documenttype.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setKYCList(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const KYCUpdateDialogClick = (docType, status) => {
    setSelectedDocType(docType);
    setKYCDocumentStatus(status);
    
    setUpdateKYCStatusDialog(!updateKYCStatusDialog);
  };
  const updateKYCStatusHandler = () => {
    MainService.updateDocumentStatus(
      KYCStatusValue,
      username,
      props.selectedUser,
      selectedDocType
    )
      .then((res) => {
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
    setUpdateKYCStatusDialog(!updateKYCStatusDialog);
  };
  return (
    <>
      <div className="container" id="mainContentList">
        <div className="topHeadersWrapper">
          {
            props.selectedUser === "profile"&&
            <div className="topHeaders">
            <Button
              color="primary"
              className="registerBtn"
              variant="contained"
              onClick={() => {
                uploadKYCClick();
              }}
            >
              Upload KYC
            </Button>
          </div>
          }
          
        </div>
        <div className="MuiGrid-root">
          <h2>KYC Documents</h2>
          <Paper sx={{ width: "100%" }}>
          <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={5}>
                      List of KYC Documents
                    </TableCell>
                  </TableRow>
                  <TableRow align="center">
                    <TableCell align="center" colSpan={1}>
                      Document Type
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Document Status
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Consent By User?
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      Option
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {KYCList.length > 0 ? (
                    KYCList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((KYCDocs, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align="center">
                            {KYCDocs.documenttype}
                          </TableCell>
                          <TableCell align="center">
                            {KYCDocs.documentstatus}
                          </TableCell>
                          <TableCell align="center">
                            {KYCDocs.consent}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                KYCViewDialogClick(KYCDocs.documentlink);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                          {props.selectedUser !== "profile" &&
                          <TableCell align="center">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              KYCUpdateDialogClick(KYCDocs.documenttype,KYCDocs.documentstatus);
                            }}
                          >
                            Update
                          </Button>
                        </TableCell>}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow align="center">
                      <TableCell align="center" colSpan={12}>
                        KYC Docs Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={KYCList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        {/* View KYC */}
        <Dialog
          fullScreen={fullScreen}
          open={KYCViewDialog}
          onClose={handleClose}
          aria-labelledby="viewKYC"
        >
          <DialogTitle id="viewKYC">{"View KYC Document"}</DialogTitle>
          <DialogContent>
            <embed
              accept="image/*,.pdf"
              className="viewWidthHeight"
              src={KYCDocumentLink}
            ></embed>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setKYCViewDialog(!KYCViewDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Upload KYC */}
        <Dialog
          fullScreen={fullScreen}
          open={uploadKYCDialog}
          onClose={handleClose}
          aria-labelledby="uploadKYC"
        >
          <DialogTitle id="uploadKYC">{"Upload KYC"}</DialogTitle>
          <DialogContent>
          <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="parentId">Parent Id :</InputLabel>
              <Input
                disabled  
                id="parentId"
                name="parentId"
                type="text"
                value={parentId}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="parentId">User Name :</InputLabel>
              <Input
                disabled  
                id="parentId"
                name="parentId"
                type="text"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="kycLabel">KYC Type</InputLabel>
              <Select
                id="kycLabel"
                value={kycType}
                label="KYC"
                onChange={(e) => {
                  setKycType(e.target.value);
                }}
              >
                <MenuItem value="Pan">PAN</MenuItem>
                <MenuItem value="Aadhar">Aadhar</MenuItem>
                <MenuItem value="Passport">Passport</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="doc">file :</InputLabel>
              <Input
                id="doc"
                name="doc"
                type="file"
                onChange={(e) => {
                  setKYCFile(e.target.files[0]);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
          <Button
              color="primary"
              variant="contained"
              onClick={() => {
                KYCSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUploadKYCDialog(!uploadKYCDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
         {/* update KYC status*/}
         <Dialog
          fullWidth={true}
          fullScreen={fullScreen}
          open={updateKYCStatusDialog}
          aria-labelledby="viewAgreements"
        >
          <DialogTitle id="viewAgreements">
            {"Update KYC Status"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="existingStatus">
                Existing Status :
              </InputLabel>
              <Input
                disabled
                id="existingStatus"
                name="existingStatus"
                type="text"
                value={KYCDocumentStatus}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status">Choose Status *</InputLabel>
              <Select
                id="status"
                value={KYCStatusValue}
                label="Choose Status"
                onChange={(e) => {
                  setKYCStatusValue(e.target.value);
                }}
              >
                <MenuItem value="Agreement-Verification-In-Progress">
                  KYC-Verification-In-Progress
                </MenuItem>
                <MenuItem value="Agreement-Verified">
                  KYC-Verified
                </MenuItem>
                <MenuItem value="Agreement-Rejected">
                  KYC-Rejected
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateKYCStatusHandler();
              }}
            >
              Update
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateKYCStatusDialog(!updateKYCStatusDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
