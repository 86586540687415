import React, { memo, useEffect, useState } from 'react';
import MainService from '../../../Common/MainService';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import PoManagement from './PoManagement';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BuyerQutationManagement from './BuyerQutationManagement';

const BuyerAccounts = ({
  proxyId = '',
  componentNames = [],
  activeComponent = '',
}) => {
  const queryClient = new QueryClient();
  const [activeButton, setActiveButton] = useState(
    componentNames.includes(activeComponent) ? activeComponent : ''
  );
  const [userDetails, setUserDetails] = useState({});
  const [proxyid, setProxyid] = useState(proxyId);

  // useEffect(() => {
  //   if (componentName === '') {
  //     setActiveButton('rfq');
  //   }
  //   setProxyid(proxyId);
  // }, [proxyId, componentName]);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.7rem',
          mb: '1rem',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        {componentNames?.length === 0 && (
          <>
            <Button
              variant={activeButton === 'rfq' ? 'contained' : 'outlined'}
              onClick={() => setActiveButton('rfq')}
            >
              RFQ Management
            </Button>
            <Button
              variant={activeButton === 'po' ? 'contained' : 'outlined'}
              onClick={() => setActiveButton('po')}
            >
              PO Management
            </Button>
          </>
        )}
        {/* {componentName === 'rfq' && (
          <Button
            variant={activeButton === 'rfq' ? 'contained' : 'outlined'}
            onClick={() => setActiveButton('rfq')}
          >
            RFQ Management
          </Button>
        )}
        {componentName === 'po' && (
          <Button
            variant={activeButton === 'po' ? 'contained' : 'outlined'}
            onClick={() => setActiveButton('po')}
          >
            PO Management
          </Button>
        )} */}
        {componentNames?.map((name, index) => (
          <Button
            key={index}
            variant={activeButton === name ? 'contained' : 'outlined'}
            onClick={() => setActiveButton(name)}
          >
            {name} Management
          </Button>
        ))}
      </Box>

      {activeButton === 'rfq' && (
        <QueryClientProvider client={queryClient}>
          <BuyerQutationManagement
            userDetails={userDetails}
            proxyId={proxyid}
          />
        </QueryClientProvider>
      )}
      {activeButton === 'po' && (
        <QueryClientProvider client={queryClient}>
          <PoManagement userDetails={userDetails} proxyId={proxyid} />
        </QueryClientProvider>
      )}
    </Box>
  );
};

export default memo(BuyerAccounts);
