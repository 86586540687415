import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const defaultCenter = {
  lat: 12.971599, 
  lng:  77.594566,
};

const LiveMonitorMap = ({ geoLocations = [], assetIds = [] }) => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={geoLocations[0] || defaultCenter}
          zoom={5}
        >
          {geoLocations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
              label={{
                text: assetIds[index],
                color:'white',
                className: "custom-marker-label",
              }}
              icon={{
                url: " ", // Empty string to use custom label only
                labelOrigin: { x: 0, y: 0 },
              }}
            />
          ))}
        </GoogleMap>
      </Box>
      <style>
        {`
          .custom-marker-label {
            font-family: Arial, sans-serif;
            font-weight: bold;
            font-size: 14px;
            color: 'white';
            background: blue;
            padding: 5px; 
            border-radius: 3px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); 
            white-space: nowrap;
            margin: 0;
            line-height: 1.2;
          }
        `}
      </style>
    </ThemeProvider>
  );
};

export default LiveMonitorMap;
