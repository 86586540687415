import React, { useState, useEffect } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Geocode from "react-geocode";
import MainService from "../../../Common/MainService";
import Map from "../../googlemap/Map";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Axios from "axios";
const google = window.google;

Geocode.setApiKey("YOUR_GOOGLE_MAPS_API_KEY");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.enableDebug();

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const AddTrip = (props) => {
  console.log(props.tripData, "tripdata in addtripsssssssss...///");

  const [searchValue, setSearchValue] = useState(undefined);
  const [address, setAddress] = useState(null);
  const [geo, setGeo] = useState({});
  const [endLocationRouteMap, setEndLocationRouteMap] = useState({});
  const [markerPosition, setMarkerPosition] = useState({
    lat: 12.9362082,
    lng: 77.6883844,
  });
  const [isEmpty, setIsEmpty] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [transportUsers, setTransportUsers] = useState([]);
  const [locations, setLocations] = useState({ locList: [] });
  const [routeData, setRouteData] = useState({ routeList: [] });
  const [locationPath, setLocationPath] = useState({});
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [location, setLocation] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [openModal, setOpenModal] = useState(false);
  const [fieldError, setFieldError] = useState({
    start: false,
    end: false,
    routeId: false,
    startTime: false,
    username: false,
    assetId: false,
  });

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [scheduleData, setScheduleData] = useState([]);
  const [isGeoFenceChecked, setIsGeoFenceChecked] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [roleName, setRoleName] = useState("");
  const [driverResources, setDriverResources] = useState([]);

  useEffect(() => {
    const fetchRoleAndResources = async () => {
      try {
        // Fetch role name
        const role = await MainService.getRoleNameofOperator(
          parentId,
          username
        );
        console.log(role, "ROLE NAME::::::::::::");
        const roleName = role;
        setRoleName(role);

        // Fetch resources based on role name
        const resourceName = await MainService.getRoleResources(
          parentId,
          roleName
        );

        const resources = resourceName.role.resources;
        setDriverResources(resources);
        console.log(resources, "RESOURCESSSS...............");
      } catch (error) {
        console.log(error, "error fetching role and resource");
      }
    };

    fetchRoleAndResources();
  }, [parentId, username]);

  const history = useHistory();

  const handleCheckboxChange = (event) => {
    setIsGeoFenceChecked(event.target.checked);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(currentDate.getDate() + 7);

    const formattedStartDate = formatDate(currentDate);
    const formattedEndDate = formatDate(sevenDaysLater);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  useEffect(() => {
    getLocationInfo();
    getRouteInfo();
    getDrivers();
    getTransportUser();
    updateTripDetails();

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    setFromDate(
      `${firstDay.getDate()}-${firstDay.getMonth()}-${firstDay.getFullYear()}`
    );

    setToDate(
      `${lastDay.getDate()}-${lastDay.getMonth()}-${lastDay.getFullYear()}`
    );
  }, []);

  useEffect(() => {
    console.log(props.tripData, "updating state based on props.tripdata....");
    if (props.tripData) {
      setSelectedTripId(props.tripData.tripId);
      setLocationPath((prevLocationPath) => ({
        ...prevLocationPath,
        start: props.tripData.startLocId,
        end: props.tripData.endLocId,
        routeId: props.tripData.routeId,
        startTime: props.tripData.startTime,
        username: props.tripData.driverId,
        assetId: props.tripData.vehicleId,
        status: props.tripData.status,
      }));
    }
  }, [props.tripData]);

  //update trip details:
  const updateTripDetails = async (data, tripId, status) => {
    try {
      const updatedTrip = await MainService.updateTripDetails(
        parentId,
        username,
        data,
        tripId,
        status
      );
      console.log("update trip details,......", updatedTrip);
    } catch (error) {
      console.log("error updating trips", error);
    }
  };
  const getLocationInfo = async () => {
    try {
      const locList = await MainService.getLocationInfo(parentId, username);
      setLocations(locList);
    } catch (err) {
      console.error(err, "error fetching location info:::::");
    }
  };

  const getRouteInfo = async () => {
    try {
      const { routeList } = await MainService.getRouteInfo(parentId, username);
      console.log(routeList, "ROUTE LISTT...........");

      const uniqueRouteList = routeList.filter(
        (route, index, self) =>
          route.routeId &&
          self.findIndex((r) => r.routeId === route.routeId) === index
      );

      setRouteData({ routeList: uniqueRouteList });
      console.log(routeData, "..........ROUTE DATA...........");
    } catch (err) {
      console.error(err, "error fetching route info::::");
    }
  };

  const getDrivers = async () => {
    try {
      const response = await MainService.getDrivers(parentId, "Driver");

      setDrivers(response);
    } catch (err) {
      console.error("UNABLE TO FETCH DRIVERS:::::::", err);
    }
  };

  const getTransportUser = async () => {
    try {
      const response = await MainService.getDrivers(
        parentId,
        "LogisticsTransportUser"
      );

      setTransportUsers(response);
    } catch (err) {
      console.error("UNABLE TO FETCH TRANSPORT USERS::::::::", err);
    }
  };

  const changeLocationTag = (event) => {
    const { name, value } = event.target;
    const { routeList } = routeData;
    const filterData = routeList.filter((item) => item.startLocId === value);

    const hashmap = filterData.reduce((acc, item) => {
      acc[item.routeId] = item;
      return acc;
    }, {});

    if (name === "end") {
      if (!hashmap[value]) {
        const filteredItem = routeList.find((item) => item.endLocId === value);
        setSelectedRoutes([...selectedRoutes, filteredItem]);

        if (filteredItem) {
          console.log(
            "Updating endLocationRouteMap:",
            value,
            filteredItem.routeId
          );
          setEndLocationRouteMap((prevMap) => ({
            ...prevMap,
            [value]: filteredItem.routeId,
          }));

          // Filter out the selected route from the dropdown options
          const remainingRouteList = routeList.filter(
            (item) => item.routeId !== filteredItem.routeId
          );
          setRouteData({ routeList: remainingRouteList });
        } else {
          console.log("filteredItem is undefined.");
        }
      } else {
        setEndLocationRouteMap((prevMap) => ({
          ...prevMap,
          [value]: undefined,
        }));
      }
    }
    // Update the start address when the start location is selected
    if (name === "start") {
      const selectedStartLocation = locations.locList.find(
        (loc) => loc.locId === value
      );

      if (selectedStartLocation) {
        setAddress(selectedStartLocation.streetName);
      }
    }
    // Enable/disable the Route List dropdown based on whether both start and end locations are selected
    if (locationPath.start && locationPath.end) {
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        routeId: false,
      }));
    } else {
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        routeId: true,
      }));
    }
  };

  const selectDriver = (event) => {
    const selectedDriver = event.target.value;
    setLocationPath((prevLocationPath) => ({
      ...prevLocationPath,
      username: selectedDriver,
    }));
  };

  const selectVehicle = (val) => {
    const selectedLoc = { ...locationPath };
    if (val) {
      selectedLoc["assetId"] = val;
    } else {
      selectedLoc["assetId"] = null;
    }
    setLocationPath(selectedLoc);
    // fetchVehicleSchedule(val, fromDate, toDate);
  };

  const setTime = (evt) => {
    const date = new Date(evt.target.value);
    const month = date.getMonth() + 1;
    const selectedLoc = { ...locationPath };
    selectedLoc["startTime"] = evt.target.value;
    selectedLoc["startTimeTemp"] =
      date.getDate() +
      "-" +
      month +
      "-" +
      date.getFullYear() +
      "T" +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds() +
      ".000";

    setLocationPath(selectedLoc);
  };

  const submitForm = async (evt) => {
    evt.preventDefault();

    const data = {
      driverId: locationPath.username,
      geoFenceDist: 0,
      routeId: selectedRoutes[0]?.routeId,
      startTime: locationPath.startTimeTemp,
      vehicleId: locationPath.assetId,
      status: props.tripData.status,
    };
    console.log("Submit Data:", Object.keys(props.tripData));
    try {
      if (props.tripData.status) {
        const tripId = props.tripData.tripId;
        const status = props.tripData.status;
        // Call the function to update trip details
        await updateTripDetails(data, tripId, status);
        console.log("Trip details updated successfully");
      } else {
        // Add new trip
        const addTripApi = await MainService.addTripApi(
          parentId,
          username,
          data
        );
        console.log("New trip added:", addTripApi);
      }
      props.tripHandler();
    } catch (error) {
      console.log("Error while handling trip:", error);
    }
  };

  const onPlaceSelected = (place) => {
    const addressArray = place.address_components;
    let address = "";
    addressArray.map((each_name) => {
      address += " " + each_name.long_name;
    });

    let currentPath = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      setAddress(results[0].formatted_address);
      setSearchValue(address);
    });

    setMarkerPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setGeo({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const getLocationHandler = (props) => {
    console.log("addtripppppppppppppp..............");
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.tripData.tripId ? (
            <Button
              variant="outlined"
              sx={{ fontSize: "large", textAlign: "left" }}
            >
              SELECTED TRIP ID: {selectedTripId}
            </Button>
          ) : (
            <Box />
          )}

          <Box sx={{ display: "flex", gap: "1rem", marginTop: "5px" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => props.tripHandler()}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" onClick={submitForm}>
              Submit
            </Button>
          </Box>
        </Box>

        <Card sx={{ p: 2, m: 2 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                "& > *": { flex: "0 0 30%", maxWidth: "33%" },
              }}
            >
              <Box>
                <FormControl required fullWidth>
                  <InputLabel>Start Location</InputLabel>
                  <Select
                    label="Start Location"
                    id="start"
                    onChange={changeLocationTag}
                    name="start"
                    value={locationPath.start}
                  >
                    <MenuItem value="">
                      <em>Select Start Location</em>
                    </MenuItem>

                    {locations.locList.map((loc) => (
                      <MenuItem key={loc.locId} value={loc.locId}>
                        {loc.tagName}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl required fullWidth>
                  <InputLabel>End Location</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={changeLocationTag}
                    label="End Location"
                    name="end"
                    value={locationPath.end}
                  >
                    <MenuItem value="">
                      <em> Select End Location</em>
                    </MenuItem>
                    {locations.locList.map((loc) => (
                      <MenuItem key={loc.locId} value={loc.locId}>
                        {loc.tagName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl required fullWidth>
                  <InputLabel>Route List</InputLabel>
                  {console.log(selectedRoutes, "loccaaaaa...////")}
                  <Select
                    labelId="demo-simple-select-label"
                    value={selectedRoutes.routeId}
                    onChange={changeLocationTag}
                    name="routeId"
                    id="demo-simple-select"
                  >
                    <MenuItem value="">
                      <em>Select Route</em>
                    </MenuItem>
                    {selectedRoutes.map((routes) => (
                      <MenuItem
                        key={routes?.routeId}
                        value={routes?.routeId}
                        disabled={
                          endLocationRouteMap[locationPath.end] ===routes?.routeId
                        }
                      >
                        {routes?.routeId}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl required fullWidth>
                  <TextField
                    id="datetime-local"
                    label="Start Time"
                    type="datetime-local"
                    name="start"
                    onChange={setTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={locationPath.startTime}
                  />
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl required fullWidth>
                  <TextField
                    id="datetime-local"
                    label="End Time"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </Box>

              <Box>
                <FormControl required fullWidth>
                  <InputLabel>Select Driver</InputLabel>
                  <Select
                    name="driverId"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select111"
                    value={locationPath.username}
                    onChange={selectDriver}
                    label="Select Driver"
                  >
                    <MenuItem value="">
                      <em>Select Driver</em>
                    </MenuItem>
                    {driverResources
                      .filter((resource) => resource.resourcetype === "Driver")
                      .map((driver) => (
                        <MenuItem
                          key={driver.resourceid}
                          value={driver.resourcename}
                        >
                          {driver.resourcename}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>Required</FormHelperText>

                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push({
                        pathname: "/main/viewdriverschedule",
                        state: {
                          selectedDriver: locationPath.username,
                        },
                      });
                    }}
                    disabled={!locationPath.username}
                  >
                    View Schedule
                  </Button>
                </FormControl>
              </Box>

              <Box>
                <FormControl required fullWidth>
                  <InputLabel>Select Vehicle</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="vehicleId"
                    id="demo-simple-select"
                    value={locationPath.assetId}
                    label="Select Vehicle"
                    onChange={(event) => selectVehicle(event.target.value)}
                  >
                    <MenuItem value="">
                      <em>Select Vehicle</em>
                    </MenuItem>
                    {driverResources
                      .filter((resource) => resource.resourcetype === "tempo")
                      .map((vehicle) => (
                        <MenuItem
                          key={vehicle.resourceid}
                          value={vehicle.resourcename}
                        >
                          {vehicle.resourceid}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>Required</FormHelperText>

                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push({
                        pathname: "/main/viewVehicleschedule",
                        state: {
                          selectedVehicle: locationPath.assetId,
                        },
                      });
                    }}
                    disabled={!locationPath.assetId}
                  >
                    View Schedule
                  </Button>
                </FormControl>
              </Box>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isGeoFenceChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Geofence"
                  labelPlacement="start"
                />

                <div>
                  <TextField
                    label="Enter radius (km)"
                    value={locationPath.geoFenceDist}
                    variant="filled"
                    name="geoFenceDist"
                    disabled={!isGeoFenceChecked}
                  />
                </div>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ marginTop: "2px", display: "flex" }}>
          <Map
            getLocationHandler={getLocationHandler}
            routeLocationData={location}
            tripRoute={selectedRoutes[0]?.geoPath}
            isMarkerShown
          />
        </Box>

        {/* .................DIALOG.............. */}
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle sx={{ color: "blue" }}>DRIVER SCHEDULE:</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default AddTrip;
