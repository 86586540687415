import React, { useEffect, useState } from "react";
import Map from "../../googlemap/Map";
import "./location.css";
import MainService from "../../../Common/MainService";

import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const getAppConfig = () => window.AppConfigData;

const AddLocation = ({ locationHandler, locData }) => {
  // console.log(locData, "location details of a locid:.//////////");

  const [selectedAddress, setSelectedAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState([]);
  const [street, setStreet] = useState([]);
  const [locationTag, setLocationTag] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  //populate locdata//////////
  useEffect(() => {
    if (locData) {
      setLocationTag(locData.tagName);
      setLatitude(locData.geoLocation.lat);
      setLongitude(locData.geoLocation.lng);
      setSelectedAddress(locData.address.city);
      setStreet(locData.address.city);
    }
    console.log(street, "streettt dataaaaaaaaaaaa::");
  }, [locData]);

  const submitLocation = async () => {
    const updateLocData = {
      tagName: locationTag,
    };
    const datafile = {
      tagName: locationTag,
      geoLocation: {
        lat: latitude,
        lng: longitude,
      },

      address: {
        city: street,
      },
    };

    try {
      if (locData) {
        // Update existing location
        await MainService.updateLocation(
          parentId,
          username,
          updateLocData,
          locData.locId
        );
      } else {
        // Add new location
        await MainService.addLocationData(parentId,username, datafile);
      }

      // After successful submission or update
      locationHandler();
    } catch (error) {
      displaySnackbar("An error occurred.", "error");
      console.log(error, "error adding location.......")
    }
  };

  const getLocationHandler = (props) => {
    console.log("props55555555", props[0]);
    const geo = {
      lat: props[0]?.geometry?.location?.lat(),
      lng: props[0]?.geometry?.location?.lng(),
    };
    setLocation([...location, { geoLocation: geo }]);
    setStreet(...street, props[0].formatted_address);
    console.log(street, "STREET::::::::::");

    setLatitude(geo.lat); // Set latitude in the state
    setLongitude(geo.lng); // Set longitude in the state
    setSelectedAddress(props[0].formatted_address);
    console.log(selectedAddress, "SELECTED ADDRESS::::::");
  };

  const locationTagChangeHandler = (e) => {
    setLocationTag(e.target.value);
  };
  const addLocationHandler = () => {
    // Validate the location tag before submitting
    if (locationTag.trim() === "") {
      displaySnackbar("Please enter a location tag.", "error");
      return;
    }
    if (typeof street !== "string" || street.trim() === "") {
      displaySnackbar("Please enter a location.", "error");
      return;
    }
    submitLocation();
  };

  const displaySnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={closeSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Box>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {locData ? (
                <Button
                  variant="outlined"
                  sx={{ fontSize: "large", textAlign: "left" }}
                >
                  SELECTED LOCATION ID: {locData.locId}
                </Button>
              ) : (
                <Box />
              )}
              <Box sx={{ display: "flex", gap: "1rem", marginTop: "5px" }}>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={locationHandler}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={addLocationHandler}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Card elevation={3} sx={{ p: 2, mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              width: "100%",
              // '& > *': { flex: '0 0 30%', maxWidth: '100%' },
            }}
          >
            <TextField
              // width="10px"
              id="location-tag"
              label="Enter a location tag"
              value={locationTag}
              onChange={locationTagChangeHandler}
            />

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "400", color: "#1976d2", fontSize: "19px" }}
                >
                  <strong>Latitude:</strong> {latitude} &nbsp; &nbsp;{" "}
                  <strong> Longitude: </strong>
                  {longitude}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  sx={{ color: "#1976d2", fontSize: "19px" }}
                >
                  {" "}
                  <strong>Address: </strong> {selectedAddress}
                </Typography>
                {/* <Typography variant="body1" sx={{ color: "#1976d2" }}> */}

                {/* </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Box sx={{ mt: 2, display: "flex" }}>
          <Map
            getLocationHandler={getLocationHandler}
            routeLocationData={location}
          />
        </Box>
      </Box>
    </>
  );
};
export default AddLocation;
