import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import Geocode from "react-geocode";
import MainService from "../../../Common/MainService";
import LiveMonitorMap from "../../livemonitormap/GeofenceMap";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const google = window.google;

Geocode.setApiKey("YOUR_API_KEY");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.enableDebug();

const GeofenceDetails = (props) => {
  const [searchValue, setSearchValue] = useState(null);
  const [address, setAddress] = useState(null);
  const [map_type, setMap_type] = useState(null);
  const [geo, setGeo] = useState({});
  const [polyPath, setPolyPath] = useState([]);
  const [marker_position, setMarker_position] = useState({
    lat: 12.9362082,
    lng: 77.6883844,
  });
  const [drawing_type, setDrawing_type] = useState("");
  const [defaultRadius, setDefaultRadius] = useState(5000);
  const [vehicleId, setVehicleId] = useState("");
  const [currentMarker, setCurrentMarker] = useState([]);
  const [shapes, setShapes] = useState({
    markers: [],
    polylines: [],
    circles: [],
    rectangles: [],
    polygons: [],
  });
  const [polygonData, setPolygonData] = useState([]);
  const [tempGeoData, setTempGeoData] = useState([]);
  const [tempShape, setTempShape] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [polygonCreated, setPolygonCreated] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const open = Boolean(anchorEl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { user } = MainService.getLocalData();
  const { username,parentId } = user;

  useEffect(() => {
    getGeofenceLocation();
   }, []);

  const clearDrawing = () => {
    setPolyPath([]);
    setDefaultRadius(0);
    setPolygonCreated(false);
  };

  const undoDrawing = () => {
    let polyPath = [...polyPath];
    polyPath.pop();
    setPolyPath(polyPath);
  };

  const submitForm = async () => {
    if (
      !drawing_type ||
      (drawing_type !== "circle" && drawing_type !== "polygon")
    ) {
      handleSnackbarOpen();
      return;
    }


    console.log("Pathname:", props.history?.location?.pathname);
    const vehicle = props.history?.location?.pathname.split("/");
    


    if (!vehicle || vehicle.length === 0) {
      console.error("Invalid vehicle data");
      return;
    }

    const vehicleId = vehicle[vehicle.length - 1];

    const rec = {
      fencingType: drawing_type === "circle" ? "circular" : "polygon",
      circularFencing: {
        geoLocation: {
          lat: currentMarker?.geoLocation?.lat,
          lng: currentMarker?.geoLocation?.lng,
        },
        radius: defaultRadius,
      },
      polygonFencing: {
        geoLocation: {
          lat: 0,
          lng: 0,
        },
        geoLocations: shapes.polylines,
      },
      vehicleGroupId: "0",
    };

    if (drawing_type === "circle") {
      rec["circularFencing"] = { geoLocation: marker_position };
      rec["circularFencing"].radius = defaultRadius;
    } else if (drawing_type === "polygon") {
      rec["polygonFencing"] = {
        geoLocation: marker_position,
        geoLocations: polyPath,
      };
    }

    try {
      const data = await MainService.createGeofence(parentId,username, rec, vehicleId);
      console.log(data, "fencing data:::");

      
      console.log("data .............////", data._id);
      if (data._id.length>0){
        
        console.log("inside 200 OK condition")
      

        const geofenceTypes = [];

        if (drawing_type === "circle" && drawing_type === "polygon") {
          geofenceTypes.push("circular,polygon");
        } else {
          if (drawing_type === "circle") {
            geofenceTypes.push("circular");
          } 
          if (drawing_type === "polygon") {
            geofenceTypes.push("polygon");
          }
        }

        // Create the geofenceType by joining all geofence types with a comma
        const geofenceType = geofenceTypes.join(',');
        const dataToUpdate = {
          geofence: geofenceType,
        };
        const response = await MainService.updateGeofenceOfAssets(
          parentId,
          username,
          dataToUpdate, 
          vehicleId
        );
        console.log(response, " update Geofence of assets/////////////");
      }

      props.history.push("/main/main/geofence");
    } catch (error) {
      console.error("Error updating geofence", error);
    }
  };

  const onPlaceSelected = (place) => {
    console.log("on place selected", place);


    const addressArray = place.address_components;
    let address = "";
    addressArray.map((each_name) => {
      address += " " + each_name.long_name;
    });


    let currentPath = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: currentPath }, (results, status) => {
      setAddress(results[0].formatted_address);
      setSearchValue(address);
    
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
    });

    setMarker_position({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setGeo({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const radiusChangeHandler = (event) => {
    setDefaultRadius(event.target.value * 1000);
  };

  const getLocationHandler = (props) => {
    console.log("rpoerueoi", props);
    const geo = {
      lat: props[0]?.geometry?.location?.lat(),
      lng: props[0]?.geometry?.location?.lng(),
    };
    setCurrentMarker({ geoLocation: geo });
  };

  const getNewRadius = (props) => {
    setDefaultRadius(props);
  };

  const getGeofenceLocation = async () => {
    try {
        let vehicleId = "";
        if (props.selectedItem) {
            vehicleId = props.selectedItem;
        } else {
            const vehicle = props.history.location.pathname.split("/");
            vehicleId = vehicle[vehicle.length - 1];
        }
        if (vehicleId) {
            // Assuming parentId and username are defined somewhere
            const data = await MainService.geofenceDetails(parentId, username, vehicleId);
            console.log("geofenceLocation;;;;;;;;;;;;;;", data);

            if (data && data.circularFencing) {
                setCurrentMarker(data.circularFencing);
                setDefaultRadius(parseFloat(data.circularFencing.radius));
            }
            if (data && data.polygonFencing) {
                console.log("polygon", data.polygonFencing.geoLocation);
                setTempGeoData(data);
            }
        } else {
            console.error("No vehicleId found.");
        }
    } catch (error) {
        console.error("Error fetching geofence details:", error);
    }
};


  const selectShapeData = (shapeData, shapeType) => {
    console.log("skdfjksdf", shapeType);
    if (shapeType === "polygon") {
      setPolygonData(shapeData?.geoLocations);
    } else if (shapeType === "circle") {
      clearShapes();
      setPolygonData({
        geoLocation: {
          lat: parseFloat(shapeData?.geoLocation?.lat),
          lng: parseFloat(shapeData?.geoLocation?.lng),
        },
      });
      setDefaultRadius(shapeData?.radius);
    }
  };

  const handleShapeAdd = (shape, type) => {
    if (drawing_type !== "polygon") {
      return;
    }
    clearShapes();
    setShapes((prevState) => {
      const newShapes = {
        [type]: [...prevState[type], shape],
      };
      setTempShape(shape);
      if (type === "polylines") {
        const polylines = newShapes[type];
        const polylineCoords = polylines.reduce((coords, polyline) => {
          const path = polyline.getPath();
          const pathCoords = path.getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));
          return [...coords, ...pathCoords];
        }, []);

        // Update the polyPath state with the new polylineCoords
        setPolyPath(polylineCoords);
        return {
          ...newShapes,
          polylines: polylineCoords,
        };
      }

      return newShapes;
    });
  };

  const handlePolyShapeAdd = (props) => {
    console.log("-------------+++++++++++++++++++", props);
    setPolygonData(props);
    setShapes({ polylines: props });
  };

  const clearShapes = () => {
   

    const shapes = [tempShape];
    console.log("shapes", shapes);
    // Remove all shapes from the map
    if (shapes[0]) {
      console.log("shapes", shapes[0]);
      shapes.forEach((polyline) => polyline.setMap(null));
    }
    setShapes({
      markers: [],
      polylines: [],
      circles: [],
      rectangles: [],
      polygons: [],
    });
    setPolygonData([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    setAnchorEl(null);
    if (type === "circle") {
      setDrawing_type("circle");
      selectShapeData(tempGeoData?.circularFencing, "circle");
    } else if (type === "polygon") {
      setDrawing_type("polygon");
      setPolygonCreated(true);
      selectShapeData(tempGeoData?.polygonFencing, "polygon");
    }
  };

  //handlesnackbar
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const curlocation = useLocation();

  const cancelBtnHandler = () => {
    console.log("hello", curlocation.pathname);
    if (curlocation.pathname === "/main/fleetServices") {
      props.cancelHandler();
    } else {
      props.history.push("/main/main/geofence");
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            mt: 2,
            "&>*": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}
        >
          <Box sx={{ gap: "1rem", display: "flex" }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="outlined"
                >
                  {drawing_type ? drawing_type : "Select Option"}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => handleClose("circle")}>
                    Circle
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose("polygon")}
                    
                  >
                    Polygon
                  </MenuItem>
                </Menu>
              </Box>

              <Box>
                {drawing_type !== "polygon" ? (
                  <Button onClick={clearDrawing}>
                    {/* <DeleteOutlineOutlinedIcon sx={{ color: "red" }} /> */}
                  </Button>
                ) : (
                  <Button onClick={(event) => clearShapes(event)}>
                    <DeleteOutlineOutlinedIcon sx={{ color: "red" }} />
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={
                () => cancelBtnHandler()
             
              }
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={submitForm}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>

      <Card sx={{ m: 2, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            {drawing_type === "circle" && (
              <Box sx={{ marginTop: "1rem" }}>
                <TextField
                  label="Radius (in km)"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  placeholder="Enter radius"
                  type="number"
                  value={parseInt(defaultRadius / 1000)}
                  onChange={radiusChangeHandler}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ marginTop: "1rem" }}>
        <LiveMonitorMap
          routeLocationData={[currentMarker]}
          defaultRadius={defaultRadius}
          getLocationHandler={getLocationHandler}
          getNewRadius={getNewRadius}
          drawing_type={drawing_type}
          onPlaceSelected={onPlaceSelected}
          onShapeAdd={handleShapeAdd}
          clearShapes={clearShapes}
          polyShapes={polygonData || []}
          handlePolyShapeAdd={handlePolyShapeAdd}
        />
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="error"
        >
          Please create a circle or polygon before submitting.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default GeofenceDetails;
