export const modalOuterBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '70%',
  minWidth: '400px',
  bgcolor: '#c3bfed',
  boxShadow: 24,
  p: 4,
};

export const modalInnerBox = {
  background: '#FEFEFEFF',
  borderRadius: 4,
  p: 3,
  display: 'flex',
  gap: 3,
  '&>*': { flex: 1 },
};

export const InnerLeft = {};

export const innerLeftContent = {
  display: 'flex',
  flexDirection: 'column',
};

export const innerRight = {
  background: '#4A49EFFF',
  position: 'relative',
};

export const innerRightContent = {
  padding: '0 4rem',
  display: 'flex',
  flexDirection: 'column',
};

export const colorBox = {
  zIndex: 100,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
