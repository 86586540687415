import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import Geocode from "react-geocode";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Snackbar,
  Box,
  Grid,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MainService from "../../Common/MainService";
import { useHistory } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const typographyStyle = {
  fontWeight: "bold",
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
};

const containerStyle = {
  marginTop: "4rem",
};

const AddBooking = ({ orderId, handleBooking }) => {
  console.log(orderId, "ORDERID:::::::::");

  let history = useHistory();

  const [bookingDetails, setBookingDetails] = useState({ userInfo: {} });
  const [locationPath, setLocationPath] = useState({});
  const [vehicles, setVehicle] = useState({ assets: [] });
  const [drivers, setDrivers] = useState({ users: [] });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  const [editPage, setEditPage] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fetchedUserName, setFetchedUserName] = useState("");
  const [fetchedLatitude, setFetchedLatitude] = useState("");
  const [fetchedLongitude, setFetchedLongitude] = useState("");
  const [fetchedConsignorAddress, setFetchedConsignorAddress] = useState("");
  // Set the `orderId` if it's passed as a prop
  useEffect(() => {
    if (orderId) {
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        oid: orderId,
      }));
    }
  }, [orderId]);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyDzJglX1KEZkksRUr_zBWrHAG3ZYeUZt4o");
  }, []);

  useEffect(() => {
    getDrivers();
    getVehicleList();
    fetchUserDetails();

    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    setFromDate(
      `${firstDay.getDate()}-${firstDay.getMonth()}-${firstDay.getFullYear()}`
    );
    setToDate(
      `${lastDay.getDate()}-${lastDay.getMonth()}-${lastDay.getFullYear()}`
    );
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  //FETCH USER DETAILS:
  const fetchUserDetails = async () => {
    try {
      const data = await MainService.getConsumerUserInfo(parentId, username);
      console.log(data, "USER DETAILS:::::::::::");
      if (data && data[0].username) {
        const fetchedUsername = data[0].username;
        setFetchedUserName(fetchedUsername); // Set the fetched username into state
        // Update the Consignor field (adid) in bookingDetails state
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          adid: fetchedUsername,
        }));
      }

      if (data && data[0].address) {
        const fetchedAddress = data[0].address;
        setFetchedConsignorAddress(fetchedAddress);
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          consignoradddress: fetchedAddress,
        }));
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  //get drivers
  const getDrivers = async () => {
    const { users } = await MainService.getDrivers(username);
    setDrivers({ users });
  };

  //get vehiclelist
  const getVehicleList = async () => {
    const { assets } = await MainService.getVehicleList(parentId,username);
    setVehicle({ assets });
  };

  const selectVehicle = (val) => {
    const selectedLoc = { ...locationPath };
    const selectedVehicle = val;
    if (selectedVehicle) {
      selectedLoc["assetId"] = selectedVehicle.assetId;
    } else {
      selectedLoc["assetId"] = null;
    }
    setLocationPath(selectedLoc);
  };

  const setTime = (evt) => {
    const date = new Date(evt.target.value);
    const month = date.getMonth() + 1;
    const selectedLoc = { ...locationPath };
    selectedLoc["startTime"] = evt.target.value;
    selectedLoc[
      "startTimeTemp"
    ] = `${date.getDate()}-${month}-${date.getFullYear()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.000`;
    setLocationPath(selectedLoc);
  };

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    // Initialize locationPath.startTime with the current date
    setLocationPath((prevLocationPath) => ({
      ...prevLocationPath,
      startTime: getCurrentDate(),
    }));
  }, []);

  const setData = (type, evt) => {
    const formData = { ...bookingDetails };
    if (type !== "none") {
      formData[type][evt.target.name] = evt.target.value;
    } else {
      formData[evt.target.name] = evt.target.value;
    }
    setBookingDetails(formData);
  };

  //from location
  const handleLocationChange = async (evt) => {
    const location = evt.target.value;

    try {
      const response = await Geocode.fromAddress(location);
      console.log(response, "geocoordinates...............  ");
      const { lat, lng } = response.results[0].geometry.location;

      setBookingDetails((prevBookingDetails) => ({
        ...prevBookingDetails,
        fromlat: lat,
        fromlong: lng,
        fromlocation: location,
      }));
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  //to location
  const handleToLocationChange = async (evt) => {
    const location = evt.target.value;

    try {
      const response = await Geocode.fromAddress(location);
      const { lat, lng } = response.results[0].geometry.location;

      setBookingDetails((prevBookingDetails) => ({
        ...prevBookingDetails,
        tolat: lat,
        tolong: lng,
        tolocation: location,
      }));
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const createBooking = async (evt) => {
    evt.preventDefault();
    const timeStamp = new Date();
    console.log("ghgfhf");

    const data = {
      consignor: bookingDetails.adid,
      consignee: bookingDetails.asid,
      vendor: bookingDetails.vendor,
      bookingDate: locationPath.startTime,
      transportationMode: bookingDetails.tmode,
      billPaidBy: bookingDetails.billpaidby,
      taxPaidBy: bookingDetails.taxpaidby,
      fromLocationlat: parseFloat(bookingDetails.fromlat),
      fromLocationlng: parseFloat(bookingDetails.fromlong),
      lat: parseFloat(bookingDetails.tolat),
      lng: parseFloat(bookingDetails.tolong),
      itemName: bookingDetails.itemname,
      quantity: bookingDetails.quantity,
      actualWeight: bookingDetails.actualweight,
      grossWeight: bookingDetails.grossweight,
      weightType: bookingDetails.weighttype,
      tax: bookingDetails.tax,
      rateAsPer: bookingDetails.ratetype,
      rate: bookingDetails.rate,
      freight: bookingDetails.freight,
      cnCharge: bookingDetails.cncharge,
      pickupCharge: bookingDetails.pickupcharge,
      stCharge: bookingDetails.stcharge,
      ewayBillNumber: bookingDetails.ewaybillno,
      ewayBillDate: locationPath.ewaybilldate,
      ewayExpiryDate: locationPath.ewayexpirydate,
      assetId: locationPath.assetId,
      vehicleNumber: bookingDetails.vehiclenumber,
      deliveryType: bookingDetails.deliverytype,
      shippingCharge: bookingDetails.shippingcharge,
      paymentTerms: bookingDetails.paymentterms,
      paymentMode: bookingDetails.paymentmode,
      additionalCharges: bookingDetails.additionalcharges,
      totalBillAmount: bookingDetails.totalbillamount,
      advanceAmount: bookingDetails.advance,
      dueBalanceAmount: bookingDetails.duebalance,
      consignorAdddress: bookingDetails.consignoradddress,
      consigneeAdddress: bookingDetails.consigneeadddress,
      shippingAdddress: bookingDetails.shippingadddress,
      orderId: bookingDetails.oid,
      pickupSiteAddress: bookingDetails.pickupSiteAddress,
      deliverySiteAddress: bookingDetails.deliverySiteAddress,
      invoiceUrl: bookingDetails.invoiceUrl,
      timestamp: timeStamp,
    };

    if (!bookingDetails.adid) {
      setOpen(true);
      setMessage("Please enter consignor");
      return false;
    }
    if (!bookingDetails.asid) {
      setOpen(true);
      setMessage("Please enter consignee");
      return false;
    }

    try {
      const booking = await MainService.createBooking(data, username);
      console.log(booking, "createbookings................");
      handleBooking();
    } catch (error) {
      if (error.response && error.status === 409) {
        setOpen(true);
        setMessage("Booking already created for this orderId");
      } else {
        setOpen(true);
        setMessage("Error creating booking");
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Snackbar to display error messages */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error">
            {message}
          </Alert>
        </Snackbar>

        <form onSubmit={createBooking}>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop:'2rem', gap: 1 }}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={()=>handleBooking()}
            >
              cancel
            </Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              marginBottom: theme.spacing(2),
              paddingBottom: theme.spacing(1),
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color="#1976d2"
              sx={{
                textTransform: "uppercase",
                letterSpacing: "2px",
              }}
            >
              NEW BOOKING
            </Typography>
          </Box>

          {/* BOOKING DETAILS */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Booking Details</Typography>
          </Box>
          <Grid container spacing={4} sx={{mt:1}}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                name="adid"
                label="Consignor"
                required
                value={bookingDetails.adid}
                onChange={(evt) => setData("none", evt)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                name="asid"
                label="Consignee"
                required
                value={bookingDetails.asid}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                name="vendor"
                label="vendor"
                required
                value={bookingDetails.vendor}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl required fullWidth>
                <TextField
                  id="datetime-local"
                  label="Booking Date"
                  type="datetime-local"
                  name="startTime"
                  required
                  onChange={setTime}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={locationPath.startTime}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="tmode"
                label="Transportation Mode"
                required
                value={bookingDetails.tmode}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Paid By (Bill To)
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  value={bookingDetails.billpaidby}
                  onChange={(evt) => setData("none", evt)}
                  name="billpaidby"
                  inputProps={{
                    id: "",
                  }}
                >
                  <option value="">Paid By (Bill To) ----------</option>
                  <option value="Consignor">Consignor</option>
                  <option value="Consignee">Consignee</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Tax Paid By
                </InputLabel>
                <Select
                  native
                  value={bookingDetails.taxpaidby}
                  onChange={(evt) => setData("none", evt)}
                  variant="standard"
                  name="taxpaidby"
                  inputProps={{
                    id: "",
                  }}
                >
                  <option value="">Tax Paid By ----------</option>
                  <option value="Consignor">Consignor</option>
                  <option value="Consignee">Consignee</option>
                  <option value="Transporter">Transporter</option>
                  <option value="Vendor">Vendor</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* ORDER DETAILS: */}

          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Order Details</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="itemName"
                label="Item Name"
                value={bookingDetails.itemName}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="quantity"
                label="quantity"
                required
                value={bookingDetails.quantity}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="actualweight"
                label="actual Weight"
                required
                value={bookingDetails.actualweight}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="grossweight"
                required
                label="gross Weight"
                value={bookingDetails.grossweight}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Weight Type
                </InputLabel>
                <Select
                  native
                  value={bookingDetails.weighttype}
                  onChange={(evt) => setData("none", evt)}
                  variant="standard"
                  name="weighttype"
                  inputProps={{
                    id: "age-native-",
                  }}
                >
                  <option value="">Weight Type ----------</option>
                  <option value="kilograms">Kilograms (KGS)</option>
                  <option value="quantals">Quantals (QTS)</option>
                  <option value="litres">Litres (LTS)</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Tax
                </InputLabel>
                <Select
                  native
                  value={bookingDetails.tax}
                  onChange={(evt) => setData("none", evt)}
                  variant="standard"
                  name="tax"
                  inputProps={{
                    id: "age-native-",
                  }}
                >
                  <option value="">Tax ----------</option>
                  <option value="5%">5% GST</option>
                  <option value="12%">12% GST</option>
                  <option value="18%">18% GST</option>
                  <option value="tax-free">Tax Free</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Rate as per
                </InputLabel>
                <Select
                  native
                  value={bookingDetails.ratetype}
                  onChange={(evt) => setData("none", evt)}
                  variant="standard"
                  name="ratetype"
                  inputProps={{
                    id: "age-native-",
                  }}
                >
                  <option value="">Rate as per ----------</option>
                  <option value="Weight">Weight</option>
                  <option value="Size">Volume/Size</option>
                  <option value="Quantity">Quantity</option>
                  <option value="Distance">Distance</option>
                  <option value="Hours">Hours</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="rate"
                label="rate"
                value={bookingDetails.rate}
                required
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* SHIPPING SUMMARY */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Shipping Summary</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="oid"
                required
                label="Order Id"
                value={bookingDetails.oid}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl required fullWidth>
                <InputLabel id="asset-label">Select Asset</InputLabel>
                <Select
                  labelId="asset-label"
                  id="combo-box-demo"
                  value={
                    vehicles.assets.find(
                      (v) => v.assetId === locationPath.assetId
                    ) || {}
                  }
                  onChange={(event) => selectVehicle(event.target.value)}
                  style={{ width: 150 }}
                  name="assetId"
                  required
                  variant="standard"
                >
                  {vehicles.assets.map((option) => (
                    <MenuItem key={option.assetId} value={option}>
                      {option.assetId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                variant="standard"
                name="vehiclenumber"
                label="Vehicle Number"
                value={bookingDetails.vehiclenumber}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Delivery Type
                </InputLabel>
                <Select
                  native
                  value={bookingDetails.deliverytype}
                  onChange={(evt) => setData("none", evt)}
                  variant="standard"
                  name="deliverytype"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value="">Delivery Type ----------</option>
                  <option value="godown-delivery">Godown Delivery</option>
                  <option value="door-delivery">Door Delivery</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="standard"
                name="shippingcharge"
                label="Shipping Charge"
                required
                value={bookingDetails.shippingcharge}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* FROM LOCATION */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>From Location</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="fromlocation"
                label="Enter Start location"
                required
                value={bookingDetails.fromlocation}
                onChange={handleLocationChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ClearIcon
                      style={{ cursor: "pointer", width: "1rem" }}
                      onClick={() =>
                        setBookingDetails((prevBookingDetails) => ({
                          ...prevBookingDetails,
                          fromlocation: "",
                          fromlat: "",
                          fromlong: "",
                        }))
                      }
                    />
                  ),
                }}
                disabled={
                  bookingDetails.fromlocation &&
                  bookingDetails.fromlocation.length > 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                id="standard-read-only-input"
                InputProps={{
                  readOnly: true,
                }}
                name="fromlat"
                label="From Latitude"
                required
                value={bookingDetails.fromlat}
                onChange={(evt) => setData("none", evt)}
                focused
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="fromlong"
                id="standard-read-only-input"
                InputProps={{
                  readOnly: true,
                }}
                label="From Longitude"
                required
                value={bookingDetails.fromlong}
                onChange={(evt) => setData("none", evt)}
                focused
              />
            </Grid>
          </Grid>

          {/* TO LOCATION */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>To Location</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="tolocation"
                label="Enter End location"
                required
                value={bookingDetails.tolocation}
                onChange={handleToLocationChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ClearIcon
                      style={{ cursor: "pointer", width: "1rem" }}
                      onClick={() =>
                        setBookingDetails((prevBookingDetails) => ({
                          ...prevBookingDetails,
                          tolocation: "",
                          tolat: "",
                          tolong: "",
                        }))
                      }
                    />
                  ),
                }}
                disabled={
                  bookingDetails.tolocation &&
                  bookingDetails.tolocation.length > 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="tolat"
                id="standard-read-only-input"
                InputProps={{
                  readOnly: true,
                }}
                label="To Latitude"
                value={bookingDetails.tolat}
                required
                onChange={(evt) => setData("none", evt)}
                focused
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                id="standard-read-only-input"
                InputProps={{
                  readOnly: true,
                }}
                name="tolong"
                label="To Longitude"
                required
                value={bookingDetails.tolong}
                onChange={(evt) => setData("none", evt)}
                focused
              />
            </Grid>
          </Grid>

          {/* ADDITIONAL CHARGES */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Additional Charges</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="freight"
                label="freight"
                value={bookingDetails.freight}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="cncharge"
                label="cnCharge"
                value={bookingDetails.cncharge}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="pickupcharge"
                label="pickupCharge"
                value={bookingDetails.pickupcharge}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="standard"
                name="stcharge"
                label="stCharge"
                value={bookingDetails.stcharge}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* BOOKING SUMMARY */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Booking Summary</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                name="ewaybillno"
                label="ewayBillNumber"
                value={bookingDetails.ewaybillno}
                onChange={(evt) => setData("none", evt)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <TextField
                  id="datetime-local"
                  label="E-Way Bill Date"
                  type="datetime-local"
                  name="ewaybilldate"
                  variant="standard"
                  onChange={setTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={locationPath.ewaybilldate}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <TextField
                  id="datetime-local"
                  label="E-Way Expiry Date"
                  type="datetime-local"
                  name="ewayexpirydate"
                  variant="standard"
                  onChange={setTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={locationPath.ewayexpirydate}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* BILLING SUMMARY */}
          <Box sx={containerStyle} >
            <Typography sx={typographyStyle}>Billing Summary</Typography>
          </Box>
          <Grid container spacing={2} sx={{mt:2}}>
            <Grid item xs={12} sm={2}>
              <FormControl required fullWidth >
                <InputLabel htmlFor="" shrink={true}>
                  Payment Terms
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  value={bookingDetails.paymentterms}
                  onChange={(evt) => setData("none", evt)}
                  name="paymentterms"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value="">Payment Terms ----------</option>
                  <option value="paid">Paid</option>
                  <option value="to-pay">To Pay</option>
                  <option value="to-be-billed">To Be Billed</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="" shrink={true}>
                  Payment Mode
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  value={bookingDetails.paymentmode}
                  onChange={(evt) => setData("none", evt)}
                  name="paymentmode"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value="">Payment Mode ----------</option>
                  <option value="paid">Paid</option>
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                variant="standard"
                label="Additional Charges"
                name="additionalcharges"
                value={bookingDetails.additionalcharges}
                onChange={(evt) => setData("none", evt)}
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Total Bill Amount"
                name="totalbillamount"
                required
                value={bookingDetails.totalbillamount}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Advance/Received"
                name="advance"
                value={bookingDetails.advance}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Due Balance Amount"
                name="duebalance"
                value={bookingDetails.duebalance}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
          </Grid>

          {/* DELIVERY SUMMARY */}
          <Box sx={containerStyle}>
            <Typography sx={typographyStyle}>Delivery Summary</Typography>
          </Box>
          <Grid container spacing={2} sx={{mt:2}}>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Consignor Address"
                name="consignoradddress"
                variant="standard"
                required
                value={bookingDetails.consignoradddress}
                onChange={(evt) => setData("none", evt)}
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Consignee Address"
                name="consigneeadddress"
                required
                value={bookingDetails.consigneeadddress}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Shipping Address"
                name="shippingadddress"
                variant="standard"
                required
                value={bookingDetails.shippingadddress}
                onChange={(evt) => setData("none", evt)}
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Pick up SiteAddress"
                name="pickupSiteAddress"
                required
                value={bookingDetails.pickupSiteAddress}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Delivery Site Address"
                name="deliverySiteAddress"
                required
                value={bookingDetails.deliverySiteAddress}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Invoice Url"
                name="invoiceUrl"
                required
                value={bookingDetails.invoiceUrl}
                onChange={(evt) => setData("none", evt)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={editPage}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default AddBooking;
