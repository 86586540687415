import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
// import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';

import * as serviceWorker from './serviceWorker';
import AuthRoute from './components/Auth/AuthRoute';
import MainRoute from './components/Main/MainRoute';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import SigninPageComponent from './components/Auth/Signin/SigninPageComponent';
import './index.css';
import MainService from '../src/components/Main/Common/MainService';
import "./config";

/* const { user } = MainService.getLocalData();
const { parentId } = user;

window.AppConfigData = {
  ConsumerAdmin: 'consumer2',
  RedirectUrl: '/',
  Realm: parentId,
  EnterpriseId: 'consumer2',
  ClientName: 'GBR1',
  ClientId: '4f4bb68c-f310-4742-9dbd-dcbbc396f9a3',
  ClientSecretKey: '56b50768-f088-40e5-b015-9ec5eefdb213',
  AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
  AssetVerticalFriendlyName: 'ANIMAL',
  ApiUrlMapp: window.appConfig,
  analytics: {
    google: window.googleAnalytics,
  },
}; */

const { user } = MainService.getLocalData();
const {  username,parentId } = user;

let ConsumerAdmin;
let ClientId;
let ClientSecretKey;
let ClientName;



if(process.env.REACT_APP_DEVELOPMENT_ENV === "production"){
    ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION
    ClientId = process.env.REACT_APP_CLIENT_ID_PRODUCTION
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_PRODUCTION
    ClientName = process.env.REACT_APP_NAME_PRODUCTION
   
 }
 else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging"){
    ConsumerAdmin =  process.env.REACT_APP_CONSUMER_ADMIN_STAGING
    ClientId = process.env.REACT_APP_CLIENT_ID_STAGING
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_STAGING
    ClientName = process.env.REACT_APP_NAME_STAGING
  

 }

 window.AppConfigData = {
  ConsumerAdmin:ConsumerAdmin,
  ClientName: ClientName,
  ClientId:ClientId,
  RedirectUrl: '/',
  LoginUser: username,
  Realm: parentId,
  EnterpriseId: ConsumerAdmin,
  ClientSecretKey: ClientSecretKey,
  ApiUrlMapp: window.appConfig,
  analytics: {
      google: window.googleAnalytics
  }

}

const theme = createTheme({
  palette: {
    primary: { main: '#3ECF8E' },
    secondary: { main: '#2F80ED' },
    danger: { main: '#BF360C', shadow: 'rgba(191,54,12 ,.3)' },
  },
  status: {
    danger: 'orange',
  },
});

try {
  ReactGA.initialize(window.AppConfigData.analytics.google.accountId, {
    debug: false,
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
  //console.log(window.location.pathname + window.location.search);
} catch (e) {
  console.error(e);
}

class IndexComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // history: useHistory()
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route
              exact
              path='/'
              component={(args) => <SigninPageComponent {...args} />}
            />

            <Route path='/auth' component={(args) => <AuthRoute {...args} />} />
            <Route path='/main' component={(args) => <MainRoute {...args} />} />

            <Route component={(args) => <Redirect to={'/'} />} />
          </Switch>
        </Router>
        <NotificationContainer />
      </ThemeProvider>
    );
  }
}

// ReactDOM.render(<IndexComponent />, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<IndexComponent />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
