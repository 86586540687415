import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InfoIcon from "@material-ui/icons/Info";
import FenceIcon from "@mui/icons-material/Fence";
import CategoryIcon from "@material-ui/icons/Category";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorageIcon from "@material-ui/icons/Storage";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MoneyIcon from "@material-ui/icons/Money";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AppsIcon from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TenderManagementIcon from "@material-ui/icons/AutorenewRounded";
import TenderSiteManagementIcon from "@material-ui/icons/EditLocationRounded";
import PostManagementIcon from "@material-ui/icons/InsertCommentRounded";
import FarmSiteManagementIcon from "@material-ui/icons/PersonPinCircle";
import UserManagementIcon from "@material-ui/icons/PeopleAlt";
import SiteManagementIcon from "@material-ui/icons/PlaceRounded";
import LegacyAssetMappingIcon from "@material-ui/icons/PetsRounded";
import AssetOEMIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UploadDocumentsIcon from "@material-ui/icons/CloudUpload";
import StatisticsIcon from "@material-ui/icons/Assessment";
import StatusIcon from "@material-ui/icons/QueryBuilder";
import SettingsIcon from "@material-ui/icons/Settings";
import SupportICon from "@material-ui/icons/ContactSupport";
import UserIcon from "@material-ui/icons/Person";
import ProductionManagerIcon from "@material-ui/icons/AccountBox";
import PeopleGroupIcon from "@material-ui/icons/Group";
import WorkerIcon from "@material-ui/icons/AccessibilityNew";
import BrokerIcon from "@material-ui/icons/ContactPhone";
import EventsIcon from "@material-ui/icons/Event";
import CommuteIcon from "@mui/icons-material/Commute";
import UpdateIcon from "@material-ui/icons/Update";
import PriceIcon from "@material-ui/icons/MoneyOutlined";
import BuyInputIcon from "@material-ui/icons/Storefront";
import AdviceIcon from "@material-ui/icons/EmojiObjects";
import DoctorIcon from "@material-ui/icons/AssignmentInd";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import WorkIcon from "@material-ui/icons/Work";
import ForumIcon from "@mui/icons-material/Forum";
import ViewListIcon from "@mui/icons-material/ViewList";
import PresentToAllTwoToneIcon from "@material-ui/icons/PresentToAllTwoTone";
import CachedIcon from "@material-ui/icons/Cached";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MonitorIcon from "@mui/icons-material/Monitor";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';

import sha256 from "sha256";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useMediaPredicate } from "react-media-hook";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Main from "../Common/MainService";
import "react-notifications/lib/notifications.css";
import "./MainPageComponent.css";
import AppData from "../../../data.json";
import MainDrawer from "./MainDrawer";
import MainService from "../Common/MainService";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "#FFF",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MainPageComponent(props) {
  const { user } = Main.getLocalData();
  const { username, parentId } = user;
  const [userTypeValue, setUserTypeValue] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const [userTypeRoleProfile, setUserTypeRoleProfile] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  // const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [menuList, setMenuList] = React.useState([]);
  const [redirecTo, setRedirecTo] = React.useState("");
  const [showChangePwd, setShowChangePwd] = React.useState(false);
  const [currentMenu, setCurrentMenu] = React.useState(
    props.location.pathname.replace("/main", "")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [mobileOpen, setMobileOpen] = useState(false); 
  

  const iconComponents = {
    FeaturedVideoIcon: FeaturedVideoIcon,
    AccountTreeIcon: AccountTreeIcon,
    WorkIcon: WorkIcon,
    PresentToAllTwoToneIcon: PresentToAllTwoToneIcon,
    DashboardIcon: DashboardIcon,
    CloudUploadIcon: CloudUploadIcon,
    CategoryIcon: CategoryIcon,
    AssignmentIndIcon: AssignmentIndIcon,
    AssignmentTurnedInIcon: AssignmentTurnedInIcon,
    AccessibilityNewIcon: AccessibilityNewIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    AssignmentIcon: AssignmentIcon,
    StorageIcon: StorageIcon,
    FeedbackIcon: FeedbackIcon,
    MoneyIcon: MoneyIcon,
    CloudQueueIcon: CloudQueueIcon,
    ManageAccountsIcon: ManageAccountsIcon,
    DriveEtaIcon: DriveEtaIcon,
    AssessmentIcon: AssessmentIcon,
    InfoIcon: InfoIcon,
    TenderManagementIcon: TenderManagementIcon,
    TenderSiteManagementIcon: TenderSiteManagementIcon,
    PostManagementIcon: PostManagementIcon,
    FarmSiteManagementIcon: FarmSiteManagementIcon,
    UserManagementIcon: UserManagementIcon,
    SiteManagementIcon: SiteManagementIcon,
    LegacyAssetMappingIcon: LegacyAssetMappingIcon,
    AssetOEMIcon: AssetOEMIcon,
    UploadDocumentsIcon: UploadDocumentsIcon,
    StatisticsIcon: StatisticsIcon,
    StatusIcon: StatusIcon,
    SettingsIcon: SettingsIcon,
    SupportICon: SupportICon,
    UserIcon: UserIcon,
    ProductionManagerIcon: ProductionManagerIcon,
    PeopleGroupIcon: PeopleGroupIcon,
    WorkerIcon: WorkerIcon,
    BrokerIcon: BrokerIcon,
    ForumIcon: ForumIcon,
    EventsIcon: EventsIcon,
    UpdateIcon: UpdateIcon,
    PriceIcon: PriceIcon,
    BuyInputIcon: BuyInputIcon,
    AdviceIcon: AdviceIcon,
    DoctorIcon: DoctorIcon,
    CommuteIcon: CommuteIcon,
    SpaceDashboardIcon: SpaceDashboardIcon,
    AccountCircleIcon: AccountCircleIcon,
    MonitorIcon: MonitorIcon,
    FenceIcon: FenceIcon,
    ViewListIcon: ViewListIcon,
    PaymentIcon: PaymentIcon,
    DescriptionIcon: DescriptionIcon,
  };

  const biggerThan639 = useMediaPredicate("(min-width: 639px)");

  useEffect(() => {
    getUserTypeRole();
    getUserType();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (biggerThan639) {
      setOpen(true);
    }
    //getProfile();
    getUserTypeRoleProfile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userTypeValue !== "") getRoleAccessList();
  }, [userTypeValue]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setCurrentMenu(props.location.pathname.replace("/main", ""));
  }, [props.location]);

 
  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        console.log("get consumer user type:",data);
        localStorage.setItem("userType", data);
        setUserTypeValue(data);
        getUserTypeRole(data);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const defaultNavItems = [
    {
      "NULL": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
      ],
      "BASIC": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
        
      ],
      "STANDARD": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
      ],
      "PREFFERED": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
      ],
      "PREMIUM": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
      ],
      "ADMIN": [
        {
          "acc_menu": "PROFILE",
          "acc_menuUrl": "/profile",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Profile page."
        },
        {
          "acc_menu": "ROLE UPGRADATION REQUEST",
          "acc_menuUrl": "/roleUpdationRequest",
          "acc_icon": "InfoIcon",
          "acc_desc": "This is the Role Updation page."
        }
      ]
    }
  ]
  const getUserTypeRole = (userType) => {
    Main.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        if (data?.length === 0) {
          setUserTypeRole("NULL");
          getRoleAccessList(userType, "NULL");
          localStorage.setItem("UserRole", "NULL");
        } else {
          setUserTypeRole(data[0]?.name);
          getRoleAccessList(userType, data[0]?.name);
          localStorage.setItem("UserRole", data[0]?.name);
        }
      })
      .catch((error) => {
        console.error(error);
        return NotificationManager.error(error);
      });
  };
  
  const getUserTypeRoleProfile = () => {
    Main.getConsumerUserTypeRoleProfile(parentId, username)
      .then((data) => {
        console.log("getConsumerUserTypeRole profile in mainpage:", data);
        setUserTypeRoleProfile(data);
      })
      .catch((e) => {
        setUserTypeRole(e);
      });
  };


 const getUserTypeForDataJson = (userType) =>{
    const object = {
      
      LogisticsTransportUser:"LogisticsTransportUser",
      LogisticsManager:"LogisticsManager",
      Driver:"Driver",
      
    };
    if (object[userType]) {
      return object[userType]
    } else {
      return (null)
    }

  }

  const getRoleAccessList = (userTypeValue,packageType) => {   
    const userType = getUserTypeForDataJson(userTypeValue);
    console.log("navigation usertype:", userType);
    console.log("navigation role:", packageType);

      const navitems = AppData[userType] && AppData[userType][0][packageType];
      console.log("navitems:", navitems);
     
      if (userType === null && packageType === undefined) {
        setMenuList(defaultNavItems[0]["NULL"]);

      } else {
        setMenuList(navitems);
      }
   
   
  };
  const logout = () => {
    Main.doLogout();
  };
  

  if (Main.isLoggedIn() !== true) {
    logout();
    return <>Unauthorised.</>;
  }
  const toggleChangePassword = () => {
    setShowChangePwd(!showChangePwd);
  };

  const reLoad = () => {
    window.location.reload();
  };

  const getMenuList = () => {
    return (
      <List>
        {menuList?.map((menu, index) => {
          const IconTagName = iconComponents[menu.acc_icon || "InfoIcon"];
          return (
            <ListItem
              selected={currentMenu === menu.acc_menuUrl}
              button
              key={index}
              onClick={() => setRedirecTo(`/main${menu.acc_menuUrl}`)}
            >
              <ListItemIcon title={menu.acc_menu}>
                <IconTagName />
              </ListItemIcon>
              <ListItemText primary={menu.acc_menu} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const mainDashboardItem = () => {
    if (userTypeRole) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      );
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <h4>Loading...</h4>
        </main>
      );
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    return mobileOpen;
  };

  const doChangePwd = (evt) => {
    evt.preventDefault();
    const oldPwd = document.getElementById("oldPwd").value;
    const newPwd = document.getElementById("newPwd").value;
    const email = document.getElementById("mail").value;
    if (!oldPwd || !newPwd) {
      NotificationManager.error("Please enter valid input.");
      return;
    }
    const oldpassword = sha256(oldPwd);
    const password = oldpassword;
    const newpassword = sha256(newPwd);
    const pwd = newpassword;
    setIsLoading(true);
    Main.doChangePwd({
      username,
      email,
      oldpassword: password,
      newpassword: pwd,
    })
      .then((res) => {
        setIsLoading(false);
        NotificationManager.success("Password changed successfully.");

        toggleChangePassword();
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error(e);
      });
  };
  const changePwdBody = (
    <Grid container spacing={0}>
      <Grid item xs={11} sm={5} md={4} lg={3} className="loginOuterContainer">
        <div className="forgotPwdContainer">
          <form onSubmit={doChangePwd}>
            <h2>Change Password</h2>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="mail">Email *</InputLabel>
              <Input
                id="mail"
                type="mail"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">Current Password *</InputLabel>
              <Input
                id="oldPwd"
                type="password"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="username">New Password *</InputLabel>
              <Input
                id="newPwd"
                type="password"
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> vpn_key </i>{" "}
                  </InputAdornment>
                }
                require="true"
                // onChange={this.onFieldChange}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Reset
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleChangePassword}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  return (
    // <ThemeProvider theme={darkTheme}>
    <>
      <CssBaseline />
      <div className={classes.root}>
        {redirecTo === "" ? "" : <Redirect to={redirecTo} />}
        <MainDrawer
          getMenuListHandler={getMenuList}
          mainDashboardItemHandler={mainDashboardItem}
          reLoadHandler={reLoad}
          toggleChangePasswordHandler={toggleChangePassword}
          logoutHandler={logout}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Modal
          open={showChangePwd}
          onClose={toggleChangePassword}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {changePwdBody}
        </Modal>
        <NotificationContainer />
      </div>
      {/* </ThemeProvider> */}
    </>
  );
}
