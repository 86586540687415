import React, { useEffect, useState } from "react";
import OrderDetails from "../TransportUser/components/TripOrders/TripOrders";
import TripDetail from "../TransportUser/components/TripDetails/TripDetail";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import MainService from "../../Common/MainService";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid black",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    textAlign: "center",
    border: "none",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#3F51B5",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  button: {
    backgroundColor: "grey",
    color: "#fff",
    borderRadius: "5px",
    padding: "8px 16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#3ECF8E",
    },
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    width: 400,
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    left: "40%",
    top: "10%",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
}));

function formatDateforApiCall(dateString) {
  return dateString?.split('-').reverse().join('-') || ''; 
}


function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const MyTrips = ({store}) => {
  // console.log(startDate,endDate,store, "ALL PROPSS received biplobbbbb:::")

 const history= useHistory();
  
  const classes = useStyles();
  const [orderData, setOrderData] = useState([]);
  const [cardData, setCardData] = useState("");
  const [showTrips, setShowTrips] = useState(true);
  // const [start_date, setSdate] = useState(startDate);
  // const [end_date, setEdate] = useState(endDate);
  const [dash, setDash] = useState(false);
  const [trip, setTrip] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [getToDash, setGetToDash] = useState(false);
  const [data, setData] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tripDetail, setTripDetail] = useState(false);
  const [orderDetails, setOrderDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isInitialView, setIsInitialView] = useState(true);

  // const [store, setStore] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(currentDate.getDate() + 7);

    const formattedStartDate = formatDate(currentDate);
    const formattedEndDate = formatDate(sevenDaysLater);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  const getDriverSchedule = async () => {
    const urls = `fromDate=${formatDateforApiCall(startDate)}&toDate=${formatDateforApiCall(endDate)}&start=0&limit=100`;
    console.log("API URL: ", urls); // Log the URL
    const { user: { username } } = JSON.parse(atob(localStorage.getItem("CBN")));
    try {
      const data = await MainService.getDriverSchedule(username, urls);
      setOrderData(data.tripList || []);
    } catch (error) {
      console.error("Error fetching driver schedule: ", error); // Handle and log the error
    }
  };
  

  // useEffect(() => {
  //   setSdate(startDate);
  //   setEdate(endDate);
  // }, [startDate, endDate]);

  useEffect(() => {
    getDriverSchedule();
  }, [startDate, endDate]);

  const getTripDetailsDriver = async (trip) => {
    const data = await MainService.getTripDetailsDriver(trip);
    setData(data);
  };

  const handleClick = (props) => {
    setOpenCalendar(true);
    setCardData(props);
    setShowTrips(false);
    setTripDetail(false);
    setDash(true);
    setOrderDetails(true);
    setGetToDash(true);
    getTripDetailsDriver(props);
    setIsInitialView(false);
  };

  const backHandler = () => {
    setIsInitialView(true); 
    setShowTrips(true);
    setOrderDetails(false);
    setTripDetail(false);
    setOpenCalendar(false);
    setGetToDash(false);
  };

  const tripDetailHandler = (props) => {
    setIsInitialView(false);
    setOpenCalendar(true);
    setTrip(props);
    setDash(true);
    setTripDetail(true);
    setShowTrips(true);
    setOpen(true);
    getTripDetailsDriver(props);
    setOpenCalendar(false);
  };

  const handlerBackAgain = () => {
    // setOpenCalendar(!openCalendar);
    // setDash(true);
    // handleBack();
    history.push("./dashboardForDriver")
  };

  const closeModelHandler = () => {
    setOpen(false);
    setOpenCalendar(false);
  };

  const buttonStyles = {
    marginBottom: "1rem",
    backgroundColor: "#3ECF8E",
    color: "white",
    fontSize: "16px",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker); // Toggle the visibility of the date picker
  };

  return (
    <div>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
      {/* {!getToDash && (
        <Button onClick={() => handlerBackAgain()}>
          <ArrowBackOutlinedIcon />
        </Button>
      )} */}
      {/* Display the Select Dates button if date picker is not shown */}
      {isInitialView && (
  <Button
    variant="outlined"
    onClick={toggleDatePicker}
    // sx={{ backgroundColor: '#3ECF8E', color: 'white' }}
  >
    {showDatePicker ? 'close' : 'Select Dates'}
  </Button>
)}
      </Box>

      {/* Date Picker will appear when the button is clicked */}
      {showDatePicker && !openCalendar && (
        <Box
          sx={{
            width: "70vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
              borderRadius: "12px",
              padding: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <Typography
              sx={{
                fontFamily: "auto",
                fontWeight: "700",
                margin: "1rem",
                fontSize: "2.5rem",
                color: "#333",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              CHOOSE A DATE
            </Typography>

            <div style={{ width: "100%", marginBottom: "1rem" }}>
              <label
                htmlFor="start_date"
                style={{
                  display: "block",
                  marginBottom: "0.5rem",
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#555",
                }}
              >
                Start Date:
              </label>
              <input
                id="start_date"
                style={{
                  width: "100%",
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  fontSize: "1rem",
                  outline: "none",
                  transition: "border-color 0.3s ease-in-out",
                  cursor: "pointer",
                }}
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
                onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                onClick={(e) => e.target.showPicker()}
              />
            </div>

            <Box sx={{ width: "100%", marginBottom: "1rem" }}>
              <label
                htmlFor="end_date"
                style={{
                  display: "block",
                  marginBottom: "0.5rem",
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#555",
                }}
              >
                End Date:
              </label>
              <input
                id="end_date"
                style={{
                  width: "100%",
                  height: "2.5rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  fontSize: "1rem",
                  outline: "none",
                  transition: "border-color 0.3s ease-in-out",
                  cursor: "pointer",
                }}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
                onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                onClick={(e) => e.target.showPicker()}
              />
            </Box>

            <Button onClick={toggleDatePicker} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        </Box>
      )}


      <Grid container spacing={2}>
        {!dash &&
         store?.tripList?.length > 0 &&
         store.tripList.map((ele) => (
            <Grid item key={ele.tripId} xs={12} sm={6} md={4} lg={3}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  transition: "transform 0.3s ease",
                  transform: "scale(1)",
                  display: "flex",
                  flex: 1,
                  flexWrap: "wrap",
                  alignItems: "center",
                  padding: "20px",
                  maxWidth: "600px",
                }}
              >
                <h1
                  style={{
                    margin: 0,
                    fontSize: "24px",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  {ele.tripId}
                </h1>
                <hr
                  style={{
                    width: "80%",
                    margin: "20px 0",
                    borderColor: "grey",
                  }}
                />
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                  <Button
                    variant="outlined"
                    onClick={() => tripDetailHandler(ele.tripId)}
                  >
                    TRIP DETAILS
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleClick(ele.tripId)}
                  >
                    TRIP BOOKINGS
                  </Button>
                </div>
              </Box>
            </Grid>
          ))}
      </Grid>

      <Grid container spacing={4} sx={{ p: "2rem" }}>
        {showTrips &&
          orderData.length > 0 &&
          orderData.map((ele) => (
            <Grid item key={ele.tripId} xs={12} sm={6} md={4} >
              <Box
                sx={{
                  backgroundColor: "whitesmoke",
                  borderRadius: "12px",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  transform: "scale(1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "24px",
                  width: "400px", // Increase the width as needed
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      textTransform: "capitalize",
                      textAlign: "center",
                      margin: "0 0 12px 0",
                    }}
                  >
                    {ele.tripId}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor:
                        ele.status === "COMPLETED"
                          ? "#3ECF8E"
                          : ele.status === "INPROGRESS"
                          ? "#f7b500"
                          : "#f44336",
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    component="strong"
                    sx={{ fontSize: "16px", color: "#555" }}
                  >
                    {ele.status.toUpperCase()}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      padding: "10px 16px",
                      borderRadius: "8px",
                      marginRight: "12px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0px 3px 5px 2px rgba(255, 105, 135, .3)",
                      transition: "0.3s ease",
                      "&:hover": {
                        background:
                          "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)",
                        boxShadow: "0px 6px 10px 4px rgba(255, 105, 135, .3)",
                      },
                    }}
                    onClick={() => tripDetailHandler(ele.tripId)}
                    aria-label={`View details for trip ${ele.tripId}`}
                  >
                    TRIP DETAILS
                  </Button>
                  <Button
                    sx={{
                      padding: "10px 16px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      color: "white",
                      boxShadow: "0px 3px 5px 2px rgba(33, 203, 243, .3)",
                      transition: "0.3s ease",
                      "&:hover": {
                        background:
                          "linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)",
                        boxShadow: "0px 6px 10px 4px rgba(33, 203, 243, .3)",
                      },
                    }}
                    onClick={() => handleClick(ele.tripId)}
                    aria-label={`View orders for trip ${ele.tripId}`}
                  >
                    TRIP BOOKINGS
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>

      <Modal
        open={open}
        onClose={() => closeModelHandler()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalContent}>
          <div className={classes.closeButton}>
            <Button onClick={() => closeModelHandler()}>
              <CloseOutlinedIcon />
            </Button>
          </div>

          <TripDetail data={data} />
        </Box>
      </Modal>

      {orderDetails && (
        <OrderDetails
          routeData={data}
          backHandler={backHandler}
          cardData={cardData}
        />
      )}
    </div>
  );
};

export default MyTrips;
