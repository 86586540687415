import React, { memo, useEffect, useState } from 'react';
import { Autocomplete, Box, Input, TextField, Typography } from '@mui/material';
import { Country, State, City } from 'country-state-city';

const AddressForm = ({ addressDetails, dispatch, isSubmited }) => {
  console.log('isSubmited', isSubmited);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', '&>*': { flex: 1 } }}>
        <Box sx={{ background: '#0000000f', p: 1 }}>
          <Input
            error={isSubmited && addressDetails?.name === ''}
            placeholder='Please enter Name'
            value={addressDetails?.name}
            name='name'
            onChange={(event) =>
              dispatch({
                type: 'address',
                payload: { name: event.target.name, value: event.target.value },
              })
            }
            multiline
            readOnly={false}
            sx={{ display: 'flex' }}
          />
          <Input
            error={isSubmited && addressDetails?.addressDetails === ''}
            placeholder='Please enter address'
            value={addressDetails?.addressDetails}
            name='addressDetails'
            onChange={(event) =>
              dispatch({
                type: 'address',
                payload: { name: event.target.name, value: event.target.value },
              })
            }
            multiline
            readOnly={false}
            sx={{ display: 'flex' }}
          />

          <Box sx={{ display: 'flex', gap: 1, '&>*': { flex: 1 } }}>
            <Autocomplete
              id='country'
              options={Country.getAllCountries()}
              autoHighlight
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                const countryName = value ? value.name : '';
                const countryIsoCode = value ? value.isoCode : '';
                dispatch({
                  type: 'address',
                  payload: { name: 'countryName', value: countryName },
                });
                dispatch({
                  type: 'address',
                  payload: { name: 'countryIsoCode', value: countryIsoCode },
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Box component='span' sx={{ pr: 1 }}>
                    {option.flag}
                  </Box>
                  {option.name} ({option.isoCode})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  error={isSubmited && !params?.inputProps?.ref?.current?.value}
                  {...params}
                  label='Choose a country'
                  variant='standard'
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: 'new-password',
                  }}
                />
              )}
            />

            <Autocomplete
              id='state'
              options={State.getStatesOfCountry(addressDetails?.countryIsoCode)}
              autoHighlight
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                const stateName = value ? value.name : '';
                const stateIsoCode = value ? value.isoCode : '';
                dispatch({
                  type: 'address',
                  payload: { name: 'stateName', value: stateName },
                });
                dispatch({
                  type: 'address',
                  payload: { name: 'stateIsoCode', value: stateIsoCode },
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.name} ({option.isoCode}) {option.phonecode}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  error={isSubmited && !params?.inputProps?.ref?.current?.value}
                  {...params}
                  label='Choose a state'
                  variant='standard'
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: 'new-password',
                  }}
                />
              )}
            />
            <Autocomplete
              id='city'
              options={City.getCitiesOfState(
                addressDetails?.countryIsoCode,
                addressDetails?.stateIsoCode
              )}
              autoHighlight
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                const cityName = value ? value.name : '';
                dispatch({
                  type: 'address',
                  payload: { name: 'cityName', value: cityName },
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Box component='span' sx={{ pr: 1 }}>
                    {option.flag}
                  </Box>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    error={
                      isSubmited && !params?.inputProps?.ref?.current?.value
                    }
                    {...params}
                    label='Choose a city'
                    variant='standard'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1 }}>
            <Typography>Contact No.</Typography>
            <Input
              error={isSubmited && addressDetails?.contactno === ''}
              value={addressDetails.contactno}
              name='contactno'
              type='number'
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
              placeholder='contact no'
              sx={{
                flex: 1,
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
              inputProps={{ min: 0 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography>GSTIN No.</Typography>
            <Input
             
              value={addressDetails?.gstin}
              name='gstin'
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
              placeholder='GSTIN no'
              sx={{ flex: 1 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AddressForm);
