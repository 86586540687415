import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainService from "../../Common/MainService";
import UploadKYCDocumentsComponent from "../../AssetDeveloperRole/UploadKYCDocuments/UploadKYCDocumentsComponent";
import AgreementComponent from "../../AssetDeveloperRole/Agreement/AgreementComponent";
import "./ProfilePageComponent.css";
import CustomizedSteppers from "../../Common/StepperCounter/StepperCounterComponent";
import {
  Box,
  styled,
  Container,
  Avatar,
  Typography,
  Grid,
  Button,
  Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import ProfileTradeDoc from "../../Common/profile/ProfileTradeDoc";
import UserTradeDocs from "../../Common/profile/UserTradeDocs";
import ParentDoc from "../../Common/profile/ParentDoc";
import BankPageComponent from "../Bank/BankPageComponent";
import WalletPageComponent from "../Wallet/WalletPageComponent";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { Person, Edit, Update } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import CustomerCareComponent from "../../AssetDeveloperRole/CustomerCare/CustomerCareComponent";
import SettingsInfoComponent from "../../AssetDeveloperRole/SettingsInfo/SettingsInfoComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  media: {
    width: 250,
    height: 250,
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.shadow,
      borderColor: theme.palette.danger.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
}));

export default function ProfilePageComponent() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [consumerUserid, setConsumerUserId] = useState("");
  const [consumerFirstName, setConsumerFirstName] = useState("");
  const [consumerLastName, setConsumerLastName] = useState("");
  const [consumerEmail, setConsumerEmail] = useState("");
  const [consumerPhone, setConsumerPhone] = useState("");
  const [consumerAddress, setConsumerAddress] = useState("");
  const [consumerState, setConsumerState] = useState("");
  const [consumerCity, setConsumerCity] = useState("");
  const [consumerNominee, setConsumerNominee] = useState("");
  const [consumerPancard, setConsumerPancard] = useState("");
  const [profilePhotoPopup, setShowProfilePhotoPopup] = useState(false);
  const [showManageProfileDetails, setshowManageProfileDetails] =
    useState(false);
  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
  const [, setConsumerMessage] = useState("");
  const [, setConsumerLatitude] = useState("");
  const [getProfileCondition, setProfileCondition] = useState(true);
  const [getProfileDocCondition, setProfileDocCondition] = useState(false);
  const [getProfileUserDoc, setProfileUserDoc] = useState(false);
  const [getProfileParentDoc, setProfileParentDoc] = useState(false);
  const [getProfileKycDoc, setProfileKycDoc] = useState(false);
  const [getProfileAgreement, setProfileAgreement] = useState(false);
  const [getWalletModel, setWalletModel] = useState(false);
  const [getBankModel, setBankModel] = useState(false);
  const [getSettingsInfoModel, setSettingsInfoModel] = useState(false);
  const [getCustomerCareModel, setCustomerCareModel] = useState(false);
  const [activeButton, setActiveButton] = useState("profile");
  const [userTypeRole, setUserTypeRole] = useState("");
  const [getUserType, setUserType] = useState("");

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  //const [, setConsumerLongitude] = useState("");
  const [state, setState] = useState({
    data: [],
    imgData: [],
  });
  const [getImageLink, setgetImageLink] = useState("");

  useEffect(() => {
    getProfile();
    getUserImage();
    getUserTypeRole();
  }, []);

  // useEffect(() => {
  //   if (!getUserType) return;
  //   roleResource();
  // }, [getUserType]);

  const getProfile = () => {
    MainService.getConsumerUserInfo(parentId, username)
      .then((data) => {
        setIsLoading(false);
        setState({ ...state, data: data });
        setUserType(data[0].usertype);
      })
      .catch((e) => {
        setIsLoading(false);
        return NotificationManager.error("No profile data found");
      });
  };

  const getUserImage = () => {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;
    MainService.getUserImag(parentId, username)
      .then((data) => {
        let imagedata = data[0].imagedata;
        let base64Img = "data:image/png;base64," + imagedata;
        var size = Object.keys(data[0]).length;
        if (data[0].imagedata === "null" || size === 0) {
          setgetImageLink("https://www.jquery-az.com/html/images/banana.jpg");
        } else setgetImageLink(base64Img);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getUserTypeRole = () => {
    MainService.getConsumerUserTypeRole(parentId, username)
      .then((data) => {
        if (data.length === 0) {
          setUserTypeRole("NULL");
        } else {
          setUserTypeRole(data[0].name);
        }
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  // const roleResource = () => {
  //   MainService.getRoleProfileBasedOnRoleName(username, getUserType)
  //     .then((res) => {
  //       console.log("ttttttt", res);
  //     })
  //     .catch((err) => {
  //       console.log("ggg", err);
  //     });
  // };

  const toggleManageProfileDetails = () => {
    setshowManageProfileDetails(!showManageProfileDetails);
  };

  const toggleUpdateProfilePhoto = () => {
    setShowProfilePhotoPopup(!profilePhotoPopup);
  };
  const doManageProfileDetails = (evt) => {
    evt.preventDefault();
    const firstname = document.getElementById("firstname").value;
    const lastname = document.getElementById("lastname").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const address = document.getElementById("address").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("state").value;
    const nominee = document.getElementById("nominee").value;
    const pancard = document.getElementById("pancard").value;

    if (!email || email === "") {
      return NotificationManager.warning("Enter valid Email.");
    }
    if (!firstname || firstname === "") {
      return NotificationManager.warning("Enter valid First Name.");
    }
    if (!lastname || lastname === "") {
      return NotificationManager.warning("Enter valid Last Name.");
    }
    if (!phone || phone === "") {
      return NotificationManager.warning("Enter valid Phone Number.");
    }
    if (!address || address === "") {
      return NotificationManager.warning("Enter valid Address.");
    }
    if (!city || city === "") {
      return NotificationManager.warning("Enter valid City.");
    }
    if (!state || state === "") {
      return NotificationManager.warning("Enter valid State.");
    }
    if (!nominee || nominee === "") {
      return NotificationManager.warning("Enter valid Nominee.");
    }
    if (!pancard || pancard === "") {
      return NotificationManager.warning("Enter valid Pancard.");
    }

    setIsLoading(true);

    const payload = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      address: address,
      city: city,
      state: state,
      nominee: nominee,
      pancard: pancard,
    };
    const payloadData = JSON.stringify(payload);
    console.log("user info payload:", payload);
    MainService.updateUserInfo(username, consumerUserid, payloadData)
      .then((data) => {
        console.log("res use info data:", consumerUserid);
        getProfile();
        setIsLoading(false);
        toggleManageProfileDetails();
        return NotificationManager.success("profile updated.");
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        toggleManageProfileDetails();
        return NotificationManager.error("profile not updated.");
      });
  };

  const uploadProfilePhotoClick = (evt) => {
    evt.preventDefault();

    if (profilePhotoFile) {
      setIsLoading(true);
      var profilePhotoBody = new FormData(); // Means body data for multipart use case

      profilePhotoBody.append("file", profilePhotoFile);

      MainService.uploadUSerImage(username, profilePhotoFile)
        .then((data) => {
          getProfile();
          setIsLoading(false);
          getUserImage();
          return NotificationManager.success("profile photo updated.");
        })
        .catch((e) => {
          setIsLoading(false);

          return NotificationManager.error("profile not updated");
        });
    } else {
      return NotificationManager.error("please choose file");
    }

    toggleUpdateProfilePhoto();
  };

  const updateProfileDetailsClick = (consumers) => {
    setConsumerUserId(consumers.userid);
    setConsumerAddress(consumers.address);
    setConsumerCity(consumers.city);
    setConsumerEmail(consumers.email);
    setConsumerFirstName(consumers.firstname);
    setConsumerLastName(consumers.lastname);
    setConsumerLatitude(consumers.lastname);
    setConsumerMessage(consumers.message);
    setConsumerNominee(consumers.nominee);
    setConsumerPancard(consumers.pancard);
    setConsumerPhone(consumers.phone);
    setConsumerState(consumers.state);
    toggleManageProfileDetails();
  };

  const updateProfilePhotoClick = () => {
    toggleUpdateProfilePhoto();
  };

  /* Update Profile Details */
  const manageProfileDetails = (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={5} md={4} lg={3} className="modalGridBody">
        <div className="updateProfileContainer">
          <span
            className="closeButton"
            style={{
              width: "100%",
              textAlign: "right",
              color: "red",
              fontSize: "23px",
              float: "right",
              padding: "0px 15px 0px 0px",
            }}
            onClick={toggleManageProfileDetails}
          >
            X
          </span>
          <form id="updateProfileForm" onSubmit={doManageProfileDetails}>
            <h2>Update Profile Details</h2>

            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email">Email/Username *</InputLabel>
              <Input
                disabled
                id="email"
                name="email"
                type="mail"
                value={consumerEmail}
                onChange={(e) => setConsumerEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> alternate_email </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="firstname">First Name </InputLabel>
              <Input
                id="firstname"
                name="firstname"
                type="text"
                value={consumerFirstName}
                onChange={(e) => setConsumerFirstName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="lastname">Last Name </InputLabel>
              <Input
                id="lastname"
                name="lastname"
                type="text"
                value={consumerLastName}
                onChange={(e) => setConsumerLastName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="phone">Phone </InputLabel>
              <Input
                id="phone"
                name="phone"
                type="tel"
                value={consumerPhone}
                onChange={(e) => setConsumerPhone(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> call </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="address">Address </InputLabel>
              <Input
                id="address"
                name="address"
                type="text"
                value={consumerAddress}
                onChange={(e) => setConsumerAddress(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="city">City </InputLabel>
              <Input
                id="city"
                name="city"
                type="text"
                value={consumerCity}
                onChange={(e) => setConsumerCity(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="state">State </InputLabel>
              <Input
                id="state"
                name="state"
                type="text"
                value={consumerState}
                onChange={(e) => setConsumerState(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="nominee">Nominee </InputLabel>
              <Input
                id="nominee"
                name="nominee"
                type="text"
                value={consumerNominee}
                onChange={(e) => setConsumerNominee(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="pancard">Pancard </InputLabel>
              <Input
                id="pancard"
                name="pancard"
                type="text"
                value={consumerPancard}
                onChange={(e) => setConsumerPancard(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <i className="material-icons"> verified_user </i>{" "}
                  </InputAdornment>
                }
                require="true"
              />
            </FormControl>

            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Save
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleManageProfileDetails}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  /*  Add Profile Photo */
  const profilePhotoBody = (
    <Grid container spacing={0}>
      <Grid
        item
        xs={11}
        sm={5}
        md={4}
        lg={3}
        className="modalGridBody"
        style={{ margin: "75px auto 0 !important" }}
      >
        <span
          className="closeButton"
          style={{
            width: "100%",
            textAlign: "right",
            color: "red",
            fontSize: "23px",
            float: "right",
            padding: "5px 15px 0px 0px",
          }}
          onClick={toggleUpdateProfilePhoto}
        >
          X
        </span>
        <div className="forgotPwdContainer">
          <form id="profilePhotoForm" onSubmit={uploadProfilePhotoClick}>
            <h2>Upload Profile Photo</h2>
            <FormControl fullWidth={true} className="loginFields">
              <Input
                name="profilePhoto"
                type="file"
                accept="image/gif, image/jpeg, image/png"
                onChange={(e) => {
                  setProfilePhotoFile(
                    e.target.files[0],
                    URL.createObjectURL(e.target.files[0])
                  );

                  console.log(
                    "selected file before uploading",
                    e.target.files[0]
                  );
                }}
                hidden={true}
              />
            </FormControl>
            <br />
            <FormControl fullWidth={true}>
              {isLoading === false && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="submit"
                >
                  Upload
                </Button>
              )}{" "}
              {isLoading === true && (
                <Button
                  variant="contained"
                  color="primary"
                  className="loginBtn"
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className="loginLoader" />
                </Button>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              className="forgotPwdLink"
              onClick={toggleUpdateProfilePhoto}
              style={{
                marginBottom: "15px",
              }}
            >
              Cancel
            </FormControl>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  const data = [
    {
      title: "Profile",
      type: "profile",
      image: <ManageAccountsRoundedIcon fontSize="large" />,
    },
    {
      title: "My Trade Doc's",
      type: "profiledoc",
      image: <FolderSharedRoundedIcon fontSize="large" />,
    },
    {
      title: "Child Doc's",
      type: "userdoc",
      image: <AssignmentIndRoundedIcon fontSize="large" />,
    },
    {
      title: "Parent Doc's",
      type: "parentdoc",
      image: <SupervisedUserCircleRoundedIcon fontSize="large" />,
    },
    {
      title: "Kyc Doc's",
      type: "kycdoc",
      image: <SummarizeRoundedIcon fontSize="large" />,
    },
    {
      title: "Agreements",
      type: "agreement",
      image: <GavelRoundedIcon fontSize="large" />,
    },
    {
      title: "Customer Care",
      type: "customer",
      image: <SupportAgentRoundedIcon fontSize="large" />,
    },
    {
      title: "Settings Info",
      type: "settings",
      image: <SettingsRoundedIcon fontSize="large" />,
    },
    {
      title: "Wallet",
      type: "wallet",
      image: <AccountBalanceWalletRoundedIcon fontSize="large" />,
      ddd: <div style={{ marginLeft: "auto" }} />,
    },
    {
      title: "Bank Account",
      type: "bank",
      image: <AccountBalanceRoundedIcon fontSize="large" />,
    },
  ];

  const showHandler = (Type) => {
    const typeMappings = {
      profile: setProfileCondition,
      profiledoc: setProfileDocCondition,
      userdoc: setProfileUserDoc,
      parentdoc: setProfileParentDoc,
      kycdoc: setProfileKycDoc,
      agreement: setProfileAgreement,
      wallet: setWalletModel,
      bank: setBankModel,
      customer: setCustomerCareModel,
      settings: setSettingsInfoModel,
    };

    setActiveButton(Type);

    Object.keys(typeMappings).forEach((key) => {
      const setterFunction = typeMappings[key];
      if (Type === key) {
        setterFunction(true);
      } else {
        setterFunction(false);
      }
    });
  };

  const componentMapping = [
    {
      condition: getProfileDocCondition,
      component: <ProfileTradeDoc />,
      key: "profileDoc",
    },
    {
      condition: getProfileUserDoc,
      component: <UserTradeDocs />,
      key: "userdoc",
    },
    {
      condition: getProfileParentDoc,
      component: <ParentDoc />,
      key: "parentdoc",
    },
    {
      condition: getProfileKycDoc,
      component: <UploadKYCDocumentsComponent />,
      key: "kycdoc",
    },
    {
      condition: getProfileAgreement,
      component: <AgreementComponent />,
      key: "agreement",
    },
    {
      condition: getWalletModel,
      component: <WalletPageComponent />,
      key: "wallet",
    },
    { condition: getBankModel, component: <BankPageComponent />, key: "bank" },
    {
      condition: getCustomerCareModel,
      component: <CustomerCareComponent />,
      key: "customer",
    },
    {
      condition: getSettingsInfoModel,
      component: <SettingsInfoComponent />,
      key: "settings",
    },
  ];

  const renderComponent = (condition, component) => {
    return condition ? component : null;
  };

  const StyledIconButton = styled(IconButton)(({ theme, isActive }) => ({
    // theme.palette.primary.main
    color: isActive ? "#002e34" : theme.palette.text.secondary,
    "&:hover": {
      color: "#002e34",
    },
  }));

  return (
    <>
      <Grid container spacing={1}>
        <Container
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexWrap: "wrap",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            background: "whitesmoke",
            margin: "2px 2px 12px 2px",
          }}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
            {data &&
              data?.map((val, i) => (
                <Tooltip key={val.title} title={val.title}>
                  <StyledIconButton
                    onClick={() => {
                      showHandler(val.type);
                    }}
                    isActive={activeButton === val.type}
                  >
                    {val.image}
                  </StyledIconButton>
                </Tooltip>
              ))}
          </Box>
        </Container>

        {getProfileCondition ? (
          <Grid md={12} xs={12}>
            <Grid item md={12} xs={12}>
              <Grid>
                {isLoading ? (
                  <Box sx={{ padding: 3 }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <Box>
                    {state?.data ? (
                      state.data.map((consumers, index) => (
                        <Grid item md={12} xs={12} p={2}>
                          <Paper
                            elevation={3}
                            sx={{ padding: 3, borderRadius: 5 }}
                          >
                            <Grid container spacing={3} alignItems="center">
                              <Grid item>
                                <Avatar
                                  alt="Profile Image"
                                  src={getImageLink}
                                  sx={{
                                    width: 100,
                                    height: 100,
                                    transform: "scale(1)",
                                  }}
                                >
                                  <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    wordWrap: "break-word",
                                    color: "#002e34",
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                    paddingTop: 1,
                                    textAlign: "center",
                                  }}
                                >
                                  {consumers.usertype}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    wordWrap: "break-word",
                                    color: "#002e34",
                                    fontSize: 12,
                                    fontWeight: 500,
                                    fontFamily: "sans-serif",
                                    paddingTop: 1,
                                    textAlign: "center",
                                  }}
                                >
                                  {userTypeRole}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      User Name:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.username}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      First Name:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.firstname}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      Last Name:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.lastname}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      Email:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.email}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      Phone:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.phone}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      State:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.state}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={1.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#002e34",
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      City:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4.5}>
                                    <Typography
                                      variant="body1"
                                      sx={{ wordWrap: "break-word" }}
                                    >
                                      {consumers.city}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              sx={{ marginTop: "16px" }}
                            >
                              <Grid item xs={12} sm={6} md={2.5} p={0.5}>
                                <Button
                                  sx={{
                                    bgcolor: "#002e34",
                                    "&:hover": {
                                      bgcolor: "#002e34",
                                    },
                                  }}
                                  variant="contained"
                                  startIcon={<Edit />}
                                  fullWidth
                                  onClick={() =>
                                    updateProfileDetailsClick(consumers)
                                  }
                                >
                                  Update
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={6} md={2.5} p={0.5}>
                                <Button
                                  sx={{
                                    color: "#002e34",
                                    border: "1px solid #002e34",
                                    "&:hover": {
                                      color: "#002e34",
                                      border: "1px solid #002e34",
                                    },
                                  }}
                                  variant="outlined"
                                  startIcon={<Update />}
                                  fullWidth
                                  onClick={() => updateProfilePhotoClick()}
                                >
                                  Photo
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ))
                    ) : (
                      <div>
                        <h4
                          style={{
                            float: "left",
                            marginTop: "7px",
                            marginBottom: "10px",
                          }}
                        >
                          Profile details not found.
                        </h4>
                      </div>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} p={2}>
              <CustomizedSteppers></CustomizedSteppers>
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12} xs={12}>
          {componentMapping.map(({ condition, component, key }) => (
            <Fragment key={key}>
              {renderComponent(condition, component)}
            </Fragment>
          ))}
        </Grid>
      </Grid>

      <Modal
        open={profilePhotoPopup}
        onClose={toggleUpdateProfilePhoto}
        aria-labelledby="profilephoto"
        aria-describedby="profilephoto-description"
      >
        {profilePhotoBody}
      </Modal>
      <Modal
        open={showManageProfileDetails}
        onClose={toggleManageProfileDetails}
        aria-labelledby="updateProfile"
        aria-describedby="updateProfile-description"
      >
        {manageProfileDetails}
      </Modal>
    </>
  );
}
