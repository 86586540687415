import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable from "material-table";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import { Button } from "@mui/material";
import LiveMonitor from "./Fleet/LiveMonitor";
import VehicleStatus from "./VehicleStatus/VehicleStatus";
import Main from "../../Common/MainService";



export default function LiveMonitoringComponent() {
  const [viewFleet, setViewFleet] = useState(false);
  const [viewVehicle, setViewVehicle] = useState(true);
  const [userTypeValue, setUserTypeValue] = useState("");
  const { user } = Main.getLocalData();
  const { username, parentId } = user;

  const UserRole = localStorage.getItem("UserRole");

  const fleetStatusHandler = () => {
    setViewFleet(true);
    setViewVehicle(false);
  };

  const vehicleStatusHandler = () => {
    setViewVehicle(true);
    setViewFleet(false);
  };

  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
  };
  useEffect(() => {
    getUserType();
  }, [userTypeValue]);
  return (
    <>
      {/* {userTypeValue === "LogisticsProvider" && ( */}
        <div className="topHeadersWrapper">
          
            <div className="topHeaders">
              <Button
                color="primary"
                size="large"
                style={{margin:'10px'}}
                variant={viewVehicle ? "contained" : "outlined"}
                onClick={() => {
                  vehicleStatusHandler();
                }}
              >
                ASSET
              </Button>
            </div>
          

            <div className="topHeaders">
              <Button
                color="primary"
                size="large"
                style={{margin:'10px'}}
                variant={viewFleet ? "contained" : "outlined"}
                onClick={() => {
                  fleetStatusHandler();
                }}
              >
                FLEET
              </Button>
            </div>
          
        </div>
    

      {viewFleet && <LiveMonitor/>}
      {viewVehicle && <VehicleStatus />}
    </>
  );
}
