import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import VehicleManagement from "./VehicleManagement/VehicleManagement";
import GeofenceDetails from "./geofencedetails/GeofenceDetails";
import UpdatedGeofenceDetails from "./UpdateGeofence/UpdatedGeofenceDetails";
import UpdateGeofenceOfVehicle from "./UpdateGeofence/UpdateGeofenceOfVehicle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Main from "../../Common/MainService";
import { NotificationManager } from "react-notifications";

const GeofenceMain = () => {
  const [viewAllVehicles, setViewAllVehicle] = useState(true);
  const [viewGeofencedVehicle, setViewGeofencedVehicle] = useState(false);
  const [userTypeValue, setUserTypeValue] = useState("");
  const { user } = Main.getLocalData();
  const { username, parentId } = user;

  const UserRole = localStorage.getItem("UserRole");
  console.log(UserRole, "USER ROLE::::::::::");
  const history = useHistory();

  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((res) => {
        setUserTypeValue(res.data.usertype);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
  };

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const handleShowVehicles = () => {
    setViewAllVehicle(true);
    setViewGeofencedVehicle(false);
  };

  const handleGeofencedVehicle = () => {
    setViewGeofencedVehicle(!viewGeofencedVehicle);
    setViewAllVehicle(false);
  };

  const handleModifyClick = (selectedItem) => {
    // Redirect to GeofenceDetails component
    console.log("Navigating to GeofenceDetails component");
    history.push(`/main/geofence/${selectedItem}`); // Replace "/geofence-details" with your actual route
  };

  const handleUpdateClick = (selectedItem) => {
    history.push(`/main/updateGeofence/${selectedItem}`);
  };

  return (
    <>
      {userTypeValue === "LogisticsProvider" && (
        <div className="topHeadersWrapper">
          {(UserRole === "PREMIUM" || UserRole === "ADMIN") && (
            <div className="topHeaders">
              <Button
                variant={viewAllVehicles ? "contained" : "outlined"}
                color="primary"
                style={{ margin: "10px" }}
                onClick={handleShowVehicles}
              >
                ALL ASSETS
              </Button>
            </div>
          )}

          {(UserRole === "PREMIUM" || UserRole === "ADMIN") && (
            <div className="topHeaders">
              <Button
                variant={viewGeofencedVehicle ? "contained" : "outlined"}
                color="primary"
                style={{ margin: "10px" }}
                onClick={handleGeofencedVehicle}
              >
                GEOFENCED ASSETS
              </Button>
            </div>
          )}
        </div>
      )}

      {viewAllVehicles && (
        <VehicleManagement
          onModifyClick={handleModifyClick}
          onUpdateClick={handleUpdateClick}
        />
      )}
      {viewGeofencedVehicle && <UpdatedGeofenceDetails />}
    </>
  );
};

export default GeofenceMain;
