import React, { useEffect, useState } from 'react'
import MainService from '../../../Common/MainService';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


const geofenceFields = ["Sr.No","Realm", "Tenant ID", "Vehicle ID", "Geofence Type"];
const styledTable = {
  backgroundColor: "lavender", 
  color: "#333",
  fontWeight: "bold",
  textAlign: "center",
};
const UpdatedGeofenceDetails = () => {

  const [geofenceDetails, setGeofenceDetails] = useState([]);
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;

  const getGeofenceDetailOfAllVehicle = async () => {
        try {
          const response = await MainService.geofenceDetailOfAllVehicle(username);
          console.log(response, "ALL GEOFENCED ASSETS DETAILS::::::");
          setGeofenceDetails(response);
        } catch (e) {
        
          console.log(e, "ERROR FETCHING GEOFENCED ASSETS:::::::")
        }
      };

      useEffect(()=>{
        getGeofenceDetailOfAllVehicle();
      },[])

  return (
    <>
    <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#f0f0f0",
          // padding: "10px",
          borderRadius: "8px",
          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          // margin: "20px",
        }}
      >
        <h1
          style={{
            color: "#333",
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            fontSize: "2rem",
          }}
        >
        GEOFENCED ASSETS OVERVIEW
        </h1>
      </Box>

       <TableContainer component={Paper}>
        <Table>
          <TableHead style={styledTable}>
            <TableRow>
              {geofenceFields.map((field) => (
                <TableCell key={field}>{field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {geofenceDetails.map((detail,index) => (
              <TableRow key={detail.vehicleId}>
                <TableCell>{index+1}</TableCell>
            
                <TableCell>{detail.realm}</TableCell>
                <TableCell>{detail.tenantId}</TableCell>
                <TableCell>{detail.vehicleId}</TableCell>
                <TableCell>{detail.fencingType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default UpdatedGeofenceDetails
