import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CChart } from "@coreui/react-chartjs";
import MainService from "../../Common/MainService";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { LocationOn, DirectionsCar, PeopleAlt } from "@mui/icons-material"; 
import DevicesIcon from "@mui/icons-material/Devices";
import DirectionsIcon from "@mui/icons-material/Directions";
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#4BC0C0",
    },
    secondary: {
      main: "#0a9ea3",
    },
  },
});

const ManagerDashboard = () => {
  const [assetCount, setAssetCount] = useState({});
  const [deviceCount, setDeviceCount] = useState({});
  const [driversInfo, setDriversInfo] = useState("");
  const [tripInfo, setTripInfo] = useState({});
  const [tripPlannerInfo, setTripPlannerInfo] = useState({});
  const [mainserviceInfo] = useState(MainService.getLocalData());
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  useEffect(() => {
    getAssetsCountInfo();
    getDeviceCountInfo();
    getDriversInfo();
    getTripPlannerInfo();
    getMonthlyTrips();
  }, []);

  // ... (your existing functions)

  const getAssetsCountInfo = async () => {
 

    const { assetCount } = await MainService.getAssetsCountInfo(parentId);
    setAssetCount(assetCount);
    console.log(assetCount, ".....assetCount...............");
  };

  // devices count
  const getDeviceCountInfo = async () => {
    

    const { deviceCount } = await MainService.getDeviceCountInfo(username);
    setDeviceCount(deviceCount);
    console.log(deviceCount, "getdevicescount...........");
  };

  // drivers count.....
  const getDriversInfo = async () => {
  
    const {
      userCount: { totalCount },
    } = await MainService.getDriversInfo(username);
    setDriversInfo(totalCount);
    console.log(totalCount, "driver1///////");
  };

  const getTripPlannerInfo = async () => {
   
    const { locationCount, routeCount, tripCount } =
      await MainService.getTripPlannerInfo(parentId, username);
    console.log(locationCount, "locationCount........");
    setTripPlannerInfo({ locationCount, routeCount, tripCount });
  };

  const getMonthlyTrips = async () => {
   

    const data = await MainService.getMonthlyTrips(parentId, username);
    setTripInfo(data);
    console.log(data, "summaryy////////////////");
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Item>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Vehicle Status
                </Typography>
                <CChart
                  style={{ height: "250px" }}
                  type="polarArea"
                  data={{
                    labels: ["Active", "Inactive"],
                    datasets: [
                      {
                        data: [
                          assetCount.activeCount,
                          assetCount.inactiveCount,
                        ],
                        backgroundColor: [
                          "#4BC0C0",
                          "#FFCE56",
                          "#E7E9ED",
                          "#36A2EB",
                        ],
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 80,
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Fleet Summary
                </Typography>
                <CChart
                  style={{ height: "250px" }}
                  type="bar"
                  data={{
                    datasets: [
                      {
                        label: "Monthly Trips",
                        backgroundColor: theme.palette.primary.main,
                        data: tripInfo,
                      },
                    ],
                  }}
                  labels="months"
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 80,
                  }}
                />
              </Item>
            </Grid>

            <Grid item xs={12}>
              <Item>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Fleet Info
                </Typography>
                <Grid container spacing={3} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1">
                      <DirectionsCar /> Vehicles
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {assetCount.totalCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <DevicesIcon /> Device
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {deviceCount.totalCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <PeopleAlt /> Driver
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {driversInfo}
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Trip Info
                </Typography>
                <Grid container spacing={3} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1">
                      <LocationOn /> Locations
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {tripPlannerInfo.locationCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <DirectionsIcon /> Routes
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {tripPlannerInfo.routeCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <ConnectingAirportsIcon /> Trips
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {tripPlannerInfo.tripCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ManagerDashboard;
