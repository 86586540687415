import React, { useState, useEffect, createContext } from 'react';

import { Box, Button } from '@mui/material';
import SellerProformaInvoiceManagement from './SellerProformaInvoiceManagement';
import SellerInvoiceManagement from './SellerInvoiceManagement';
import MainService from '../../../Common/MainService';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const componentDetailsContext = createContext('');

const SellerAccountManagement = ({
  proxyId = '',
  componentNames = [],
  activeComponent = 'invoice',
}) => {
  const queryClient = new QueryClient();
  const [activeButton, setActiveButton] = useState(
    componentNames.includes(activeComponent) ? activeComponent : ''
  );

  const [invoiceTypeDetails, setInvoiceTypeDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [proxyid, setProxyid] = useState(proxyId);

  // button active
  const buttonStatusHandler = (btnName) => {
    if (btnName !== 'challan') {
      setInvoiceTypeDetails({});
    }
    setActiveButton(btnName);
  };

  const {
    user: { parentId, username },
  } = MainService.getLocalData();

  const fetchUserData = async () => {
    const data = await MainService.getConsumerUserInfo(parentId, username);

    const {
      username: userName,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      pancard,
    } = data[0];

    const tempData = {
      buyerName: `${firstname} ${lastname}`,
      buyer_cell: {
        buyer_cell1: phone,
        buyer_cell2: '',
      },
      buyer_panNo: pancard,
      buyer_email: email,
      buyer_gstin: '',
      buyerAdressline1: address,
      buyerAdressline2: city,
      buyerAdressline3: state,
    };

    setUserDetails(tempData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <componentDetailsContext.Provider
      value={{ invoiceTypeDetails, setInvoiceTypeDetails, buttonStatusHandler }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '0.7rem',
            mb: '1rem',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          {componentNames?.length === 0 && (
            <>
              <Button
                variant={activeButton === 'proforma' ? 'contained' : 'outlined'}
                onClick={() => buttonStatusHandler('proforma')}
              >
                Proforma Invoice
              </Button>
              <Button
                variant={activeButton === 'invoice' ? 'contained' : 'outlined'}
                onClick={() => buttonStatusHandler('invoice')}
              >
                Tax Invoice
              </Button>
            </>
          )}

          {componentNames?.map((name, index) => (
            <Button
              key={index}
              variant={activeButton === name ? 'contained' : 'outlined'}
              onClick={() => buttonStatusHandler(name)}
            >
              {name === 'proforma' ? 'Proforma Invoice' : 'Tax Invoice'}
            </Button>
          ))}
        </Box>

        {activeButton === 'proforma' && (
          <QueryClientProvider client={queryClient}>
            <SellerProformaInvoiceManagement
              userDetails={userDetails}
              proxyId={proxyid}
            />
          </QueryClientProvider>
        )}
        {activeButton === 'invoice' && (
          <SellerInvoiceManagement
            userDetails={userDetails}
            buttonStatusHandler={buttonStatusHandler}
            proxyId={proxyid}
          />
        )}
        {/* {activeButton === 'challan' && (
          <DeliveryChallan userDetails={userDetails} proxyId={proxyid} />
        )}
        {activeButton === 'receipt' && (
          <DeliveryReceipt userDetails={userDetails} proxyId={proxyid} />
        )} */}
      </Box>
    </componentDetailsContext.Provider>
  );
};

export default SellerAccountManagement;
