


// import React from 'react';
// import PropTypes from 'prop-types';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import { styled } from '@mui/material';



// const StyledTableBody = styled(TableBody)`
//   /* Add your custom styles here */
  

  
// `;

// const StyledTableRow = styled(TableRow)`
//   /* Add your custom styles here */
  
// `;

// const StyledTableContainer = styled(TableContainer)`
//   max-height: 550px;
 
// `;

// const StyledTable = styled(Table)`
//   min-width: 750px;
// `;

// const StyledTableHead = styled(TableHead)`
//   /* background-color: #f5f5f5; */
// `;

// const StyledTableCell = styled(TableCell)`
//   font-weight: bold;
//   font-family: sans-serif;
//   /* background-color: aliceblue; */
// `;

// const StyledTypography = styled(Typography)`
//   font-size: 14px;
//   font-family: sans-serif;
//   font-weight: bold;
//   /* background-color: aliceblue; */
//   color:blue;
// `;

// const useStyles = styled((theme) => ({
//   root: {
//     width: '100%',
//   },
//   paper: {
//     marginBottom: theme.spacing(2),
//   },
//   table: {
//     minWidth: 750,
//     textTransform: 'none',
//   },
//   visuafleetFllyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//   },
//   typography: {
//     textTransform: 'none',
//   },
// }));

// function EnhancedTableHead(props) {
//   const {
//     classes,
//     onSelectAllClick,
//     order,
//     orderBy,
//     numSelected,
//     rowCount,
//     onRequestSort,
//     fields,
//   } = props;
//   const headCells = [];
//   fields.map((field, index) => {
//     return headCells.push({
//       id: index,
//       numeric: false,
//       disablePadding: true,
//       label: field,
//     });
//   });
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <StyledTableHead>
//       <TableRow>
//         {headCells.map((headCell) => (
//           <StyledTableCell
//             key={headCell.id}
//             align='center'
//             // sx={{background:'aliceblue'}}
//             padding={headCell.disablePadding ? 'none' : '1rem'}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             <TableSortLabel
//               active={headCell.label !== ''}
//               direction={orderBy === headCell.id ? order : 'asc'}
//               onClick={createSortHandler(headCell.id)}
//             >
//               <StyledTypography variant='button'>
//                 {headCell.label}
//               </StyledTypography>
//             </TableSortLabel>
//           </StyledTableCell>
//         ))}
//       </TableRow>
//     </StyledTableHead>
//   );
// }

// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// const StyledTotalRecord = styled(Typography)`
//   padding: 7px 14px;
//   border-radius: 5px;
//   /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//   /* background-color: #1976d2; */
//   /* color: white; */
// `;

// const StyledTablePagination = styled(TablePagination)`
//   margin-bottom: 16px;
// `;

// const StyledPaper = styled(Paper)`
//   width: 100%;
//   overflow: hidden;
// `;

// const StyledCheckbox = styled(Checkbox)`
//   /* padding: 5; */
// `;

// export default function EnhancedTable(props) {
//   const classes = useStyles();
//   const [order, setOrder] = React.useState('asc');
//   const [orderBy, setOrderBy] = React.useState('calories');
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [dense, setDense] = React.useState(false);
//   const { rows } = props;

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = rows.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       );
//     }

//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeDense = (event) => {
//     setDense(event.target.checked);
//   };

//   const checkBoxAction = (row) => {
//     props.onSelect(row);
//   };

//   const isSelected = (name) => selected.indexOf(name) !== -1;

//   return (
//     <>
//       <div style={{ float: 'left',marginBottom:'1rem' }}>
//         <StyledTotalRecord variant='subtitle1'>
//           Total record: {props.total}
//         </StyledTotalRecord>
//       </div>

//       <div>
//         <StyledPaper>
//           <StyledTableContainer>
//             <StyledTable stickyHeader aria-label='sticky table'>
//               <EnhancedTableHead
//                 className='enhancedTable'
//                 classes={classes}
//                 numSelected={selected.length}
//                 order={order}
//                 orderBy={orderBy}
//                 onSelectAllClick={handleSelectAllClick}
//                 onRequestSort={handleRequestSort}
//                 rowCount={rows.length}
//                 fields={props.fields}
//               />
//               <StyledTableBody>
//                 {rows.map((row, index) => {
//                   const isItemSelected = isSelected(row.name);
//                   const labelId = `enhanced-table-checkbox-${index}`;

//                   return (
//                     <StyledTableRow
//                       key={index}
//                       selected={isItemSelected}
//                       onClick={(event) => handleClick(event, row.name)}
//                       aria-checked={isItemSelected}
//                       tabIndex={-1}
//                     >
//                       <TableCell>
//                         <StyledCheckbox
//                           checked={props.selected === row[props.unique]}
//                           onClick={checkBoxAction.bind(
//                             this,
//                             row[props.unique]
//                           )}
//                         />
//                       </TableCell>

//                       {props.keys.map((key) => {
//                         return (
//                           <StyledTableCell
//                             component='td'
//                             key={key}
//                             id={labelId}
//                             scope='row'
//                             align='center'
//                           >
//                             {row[key]}
//                           </StyledTableCell>
//                         );
//                       })}
//                     </StyledTableRow>
//                   );
//                 })}
//               </StyledTableBody>
//             </StyledTable>
//           </StyledTableContainer>
//         </StyledPaper>
//       </div>
//     </>
//   );
// }




import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material";

const StyledTableBody = styled(TableBody)`


`;

const StyledTableRow = styled(TableRow)`

`;

const StyledTableContainer = styled(TableContainer)`
  max-height: 400px;
`;

const StyledTable = styled(Table)`
  min-width: 700px;
`;

const StyledTableHead = styled(TableHead)`
  /* background-color: #3498db; */
  /* color: white; */
`;

const StyledTableCell = styled(TableCell)`
 /* font-weight: bold; */
  font-family: 'Arial', sans-serif;
  padding: 10px;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  color: #1976d2;
  
`;

const useStyles = styled((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    textTransform: "none",
  },
  visuafleetFllyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  typography: {
    textTransform: "none",
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    fields,
  } = props;
  const headCells = [];
  fields.map((field, index) => {
    return headCells.push({
      id: index,
      numeric: false,
      disablePadding: true,
      label: field,
    });
  });
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "1rem"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.label !== ""}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <StyledTypography variant="button">
                {headCell.label}
              </StyledTypography>
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTotalRecord = styled(Typography)`
  padding: 7px 14px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  overflow: hidden;
`;

const StyledCheckbox = styled(Checkbox)``;

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const { rows } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const checkBoxAction = (row) => {
    props.onSelect(row);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <div
        style={{
          float: "left",
          marginBottom: "1rem",
          fontWeight: "600",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          
        }}
      >
        <StyledTotalRecord
          variant="subtitle1"
          style={{
            color: "white",
            background:'#2e7d32',
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
            fontSize: "1.1rem",
          }}
        >
          Total Records: {props.total}
        </StyledTotalRecord>
      </div>
      <div>
        <StyledPaper>
          <StyledTableContainer>
            <StyledTable stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                className="enhancedTable"
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                fields={props.fields}
              />
              <StyledTableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      key={index}
                      // selected={isItemSelected}
                      // onClick={(event) => handleClick(event, row.name)}
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      // className={isItemSelected ? '' : undefined}
                    >
                      <TableCell>
                        <StyledCheckbox
                          checked={props.selected === row[props.unique]}
                          onClick={checkBoxAction.bind(this, row[props.unique])}
                        />
                      </TableCell>

                      {props.keys.map((key) => {
                        return (
                          <StyledTableCell
                            component="td"
                            key={key}
                            id={labelId}
                            scope="row"
                            align="center"
                          >
                            {row[key]}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
        </StyledPaper>
      </div>
    </>
  );
}





