export function appendKeysWithValues(object, validKeyHandler) {
  const result = Object.keys(object).filter((key) => {
    // if (typeof object[key] === 'object') {
    //   appendKeysWithValues(object[key], validKeyHandler);
    // }
    const value = object[key].toString().trim();

    return value !== null && value !== undefined && value !== '';
  });

  console.log('result------------', result);

  validKeyHandler((prev) => {
    return Array.from(new Set([...prev, ...result]));
  });
}

export const removeKey = (obj, stateHandler) => {
  Object.keys(obj).forEach((objKey) => {
    stateHandler((prev) => prev.filter((key) => key !== objKey));
  });
};
