import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { InnerLeft, innerLeftContent } from './qrStyle';

const QRModelForm = ({
  formFields,
  qrData,
  generateQrcodeHandler,
  inputChangeHandler,
  qrCodeError,
  qrCodeSuccess,
  errorMesg,
}) => {
  return (
    <Box sx={InnerLeft}>
      <Box sx={innerLeftContent}>
        <Typography sx={{ fontWeight: 700, fontSize: '1.8rem' }}>
          QR Builder
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            gap: 3,
          }}
        >
          <FormControl variant='standard' sx={{ display: 'flex', flex: 1 }}>
            <InputLabel id='qr-code-select'>Document Type</InputLabel>
            <Select
              labelId='qr-code-select'
              id='qr-code-select-standard'
              value={qrData?.type || ''}
              name='type'
              onChange={(event) => inputChangeHandler(event)}
              label='Age'
            >
              <MenuItem value='product'>Product</MenuItem>
              <MenuItem value='asset'>Asset</MenuItem>
              <MenuItem value='site'>Site</MenuItem>
              <MenuItem value='model'>Model</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {qrData.type &&
          formFields[qrData?.type + 'Fields' || 'productFields'].map(
            ({ fieldName, fieldValue }) => {
              return (
                <TextField
                  error={fieldValue === errorMesg}
                  key={fieldName}
                  id={fieldName}
                  label={fieldValue}
                  variant='standard'
                  name={fieldName}
                  value={qrData[fieldName] || ''}
                  onChange={(event) => inputChangeHandler(event)}
                  helperText={
                    fieldValue === errorMesg && 'please enter valid input'
                  }
                />
              );
            }
          )}
        <Box
          sx={{
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant='outlined'
            sx={{ p: 1.5 }}
            onClick={() => generateQrcodeHandler()}
          >
            Generate QR Code
          </Button>
        </Box>
        <Box sx={{ marginTop: '1rem' }}>
          {qrCodeSuccess && (
            <Alert severity='success'>QRCode generated successfully.</Alert>
          )}

          {qrCodeError && (
            <Alert severity='error'>
              {errorMesg === 'duplicate version'
                ? 'duplicate version'
                : 'QRcode not generated'}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QRModelForm;
