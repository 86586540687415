import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import "./SettingsInfoComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import { Dialog } from "@mui/material";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function SettingsInfoComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [customerSupportList, setCustomerSupportList] = useState([]);
  const [filteredCustomerSupportList, setFilterdCustomerSupportList] = useState([]);
  const { user } = MainService.getLocalData();
  const { username } = user;
  const [searched, setSearched] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [createCustomerCareDialog, setCreateCustomerCareDialog] =
    useState(false);
    const [updateCustomerCareDialog, setUpdateCustomerCareDialog] =
    useState(false);
    const [deleteCustomerCareDialog, setDeleteCustomerCareDialog] =
    useState(false);
  const [setAboutUsLink, getAboutUsLink] = useState("");
  const [setCustomerCareNumber, getCustomerCareNumber] = useState("");
  const [setCustomerCareEmail, getCustomerCareEmail] = useState("");
  const [setCustomerCareFax, getCustomerCareFax] = useState("");
  const [setAddress, getAddress] = useState("");
  const [selectedCustomerCareAboutUsLink,setSelectedCustomerCareAboutUsLink] = useState("");
  const [selectedCustomerCareAddress,setSelectedCustomerCareAddress] = useState("");
  const [selectedCustomerCareEmail,setSelectedCustomerCareEmail] = useState("");
  const [selectedCustomerCareFax,setSelectedCustomerCareFax] = useState("");
  const [selectedCustomerCareNumber,setSelectedCustomerCareNumber] = useState("");
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);


  useEffect(() => {
    getCustomerSupportList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  /* View All Info */
  const getCustomerSupportList = () => {
    MainService.settingsInfoBasedonParent(username)
      .then((res) => {
        console.log("settings info res:",res);
        setCustomerSupportList(res);
        setFilterdCustomerSupportList(res);
      })
      .catch((err) => {
        console.log("errorrr:",err);
        return NotificationManager.error("err");
      });
  };
  /* End All Info */
  const handleClose = () => {};
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
      const filteredRows = filteredCustomerSupportList.filter((row) => {
        return row.CustomerCare_email.toLowerCase().includes(
          searchedVal.toLowerCase()
        );
      });
      setCustomerSupportList(filteredRows);

  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const updateCustomerCareDialogClick = (selectedCustomerCare)=>{

    setSelectedCustomerCareAboutUsLink(selectedCustomerCare.aboutusLink);
    setSelectedCustomerCareNumber(selectedCustomerCare.CustomerCare_number);
    setSelectedCustomerCareEmail(selectedCustomerCare.CustomerCare_email);
    setSelectedCustomerCareFax(selectedCustomerCare.CustomerCare_fax);
    setSelectedCustomerCareAddress(selectedCustomerCare.Address);

    setUpdateCustomerCareDialog(!updateCustomerCareDialog);
  }
  const deleteCustomerCareDialogClick = ()=>{
    setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
  }

  const createCustomerCareSubmit = () => {
    if(setAboutUsLink && setCustomerCareNumber&& setCustomerCareEmail && setCustomerCareFax && setAddress){
      let customerCareData = {
        parentid: username,
        aboutusLink: setAboutUsLink,
        CustomerCare_number: setCustomerCareNumber,
        CustomerCare_email: setCustomerCareEmail,
        CustomerCare_fax: setCustomerCareFax,
        Address: setAddress.replace(/\n/g, ''),
      };
      const customerCareDataString = JSON.stringify(customerCareData);
  
      MainService.createCustomerCare(customerCareDataString)
        .then((res) => {
          getCustomerSupportList();
          return NotificationManager.success(res);
          
        })
        .catch((err) => {
          return NotificationManager.error(err);
        });
    }else{
      return NotificationManager.error("please fill all fields");
    }
    
    setCreateCustomerCareDialog(!createCustomerCareDialog);
  };

const updateCustomerCareSubmit = () =>{
  let customerCareData = {
    parentid: username,
    aboutusLink: selectedCustomerCareAboutUsLink,
    CustomerCare_number: selectedCustomerCareNumber,
    CustomerCare_email: selectedCustomerCareEmail,
    CustomerCare_fax: selectedCustomerCareFax,
    Address: selectedCustomerCareAddress.replace(/\n/g, ''),
  };
  const customerCareDataString = JSON.stringify(customerCareData);

  MainService.updateCustomerCare(customerCareDataString)
    .then((res) => {
      getCustomerSupportList();
      return NotificationManager.success(res);
      
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  setUpdateCustomerCareDialog(!updateCustomerCareDialog);
}
const deleteCustomerCareSubmit =()=>{
  MainService.deleteCustomerCare(username)
    .then((res) => {
      getCustomerSupportList();
      return NotificationManager.success(res);
      
    })
    .catch((err) => {
      return NotificationManager.error(err);
    });
  setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
}

const listViewClick = () => {
  setListView(true);
  setGridView(false);
  
};
const gridViewClick = () => {
  setListView(false);
  setGridView(true);
  
};


  return (
    <>
      <div className="container" id="mainContentList">
        {customerSupportList.length == 0 &&
        <Fab
        color="primary"
        size={"large"}
        aria-label="add"
        title="Add Settings Info"
        className={classes.fab}
        onClick={() => {
          setCreateCustomerCareDialog(!createCustomerCareDialog);
        }}
      >
        <AddIcon></AddIcon>
      </Fab>
        }
        
        <div style={{ float: "right" }}>
          <Button
            startIcon={<ListIcon />}
            size="large"
            onClick={listViewClick}
          ></Button>
          <Button
            startIcon={<GridIcon />}
            size="large"
            onClick={gridViewClick}
          ></Button>
          
        </div>
        <h2>Settings Info</h2>
        {listView &&
        <Paper sx={{ width: "100%" }}>
        
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow align="center">
                <TableCell align="center" colSpan={1}>
                  About Us Link
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Number
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Email
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Customer Care Fax
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  Address
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Option
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerSupportList?.length > 0 ? (
                customerSupportList&&customerSupportList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customercare, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell align="center">
                          {customercare.aboutusLink}
                        </TableCell>

                        <TableCell align="center">
                          {customercare.CustomerCare_number}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.CustomerCare_email}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.CustomerCare_fax}
                        </TableCell>
                        <TableCell align="center">
                          {customercare.Address}
                        </TableCell>
                        <TableCell align="center">
                          <Button                              
                            variant="contained"
                            onClick={()=>{updateCustomerCareDialogClick(customercare)}}
                          >
                            Update
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button                              
                            variant="contained"
                            onClick={()=>{deleteCustomerCareDialogClick()}}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow align="center">
                  <TableCell align="center" colSpan={12}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={customerSupportList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
        }

        {gridView &&
        <div className="cardStyleForDashboardItems">
        {isLoading ? (
          <div className="cardStyleForDashboardItems">
            <CircularProgress />
          </div>
        ) : (
          <div>
          <div className="cardStyleForDashboardItems" style={{maxWidth: "30vw", overflow:"clip"}}>
            {customerSupportList?.length > 0 ? (
              <div className="cardStyleForDashboardItems">
                {customerSupportList&&customerSupportList?.map((customersupport, index) => (
                  <div
                    key={index}
                    className="cardStyleForDashboardItemsBody"
                  >
                    <span>
                      <p>
                        <b>About Us Link:</b>
                        {customersupport.aboutusLink}
                      </p>
                      <p>
                        <b>Customer Care Number:</b>
                        {customersupport.CustomerCare_number}
                      </p>
                    </span>
                    <p>
                      <b>Customer Care Email:</b>
                      {customersupport.CustomerCare_email}
                    </p>
                    <p>
                      <b> Customer Care Fax:</b>
                      {customersupport.CustomerCare_fax}
                    </p>
                    
                    <p>
                      <b>Address:</b>
                      {customersupport.Address}
                    </p>
                    <div>
                    <Button                              
                            variant="contained"
                            onClick={()=>{updateCustomerCareDialogClick(customersupport)}}
                          >
                            Update
                          </Button>
                          &nbsp;&nbsp;
                          <Button                              
                            variant="contained"
                            onClick={()=>{deleteCustomerCareDialogClick()}}
                          >
                            Delete
                          </Button>
                        </div>
                    
                  </div>
                ))}
              </div>
            ) : (
              <h4>Info not found.</h4>
            )}
          </div>
          </div>
        )}
      </div>
        }     
        <Dialog
          fullScreen={fullScreen}
          open={createCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="craete Customer Care"
        >
          <DialogTitle id="craeteCustomerCare">
            {"Create Customer Care"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="about"> About Us link:</InputLabel>
              <Input
                id="about"
                name="about"
                type="text"
                value={setAboutUsLink}
                onChange={(e) => {
                  getAboutUsLink(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="number"> Customer Care Number:</InputLabel>
              <Input
                id="number"
                name="number"
                type="text"
                value={setCustomerCareNumber}
                onChange={(e) => {
                  getCustomerCareNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email"> Customer Care Email:</InputLabel>
              <Input
                id="email"
                name="email"
                type="text"
                value={setCustomerCareEmail}
                onChange={(e) => {
                  getCustomerCareEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="fax"> Customer Care Fax:</InputLabel>
              <Input
                id="fax"
                name="fax"
                type="text"
                value={setCustomerCareFax}
                onChange={(e) => {
                  getCustomerCareFax(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <TextareaAutosize
                aria-label="empty textarea"
                minRows={8}
                placeholder="Address"
                value={setAddress}
                onChange={(e) => {
                  getAddress(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createCustomerCareSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCreateCustomerCareDialog(!createCustomerCareDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={updateCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="update Customer Care"
        >
          <DialogTitle id="updateCustomerCare">
            {"Update Settings Info"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="about"> About Us link:</InputLabel>
              <Input
                id="about"
                name="about"
                type="text"
                value={selectedCustomerCareAboutUsLink}
                onChange={(e) => {
                  setSelectedCustomerCareAboutUsLink(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="number"> Customer Care Number:</InputLabel>
              <Input
                id="number"
                name="number"
                type="text"
                value={selectedCustomerCareNumber}
                onChange={(e) => {
                  setSelectedCustomerCareNumber(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="email"> Customer Care Email:</InputLabel>
              <Input
                id="email"
                name="email"
                type="text"
                value={selectedCustomerCareEmail}
                onChange={(e) => {
                  setSelectedCustomerCareEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <InputLabel htmlFor="fax"> Customer Care Fax:</InputLabel>
              <Input
                id="fax"
                name="fax"
                type="text"
                value={selectedCustomerCareFax}
                onChange={(e) => {
                  setSelectedCustomerCareFax(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} className="loginFields">
              <TextareaAutosize
                aria-label="empty textarea"
                minRows={8}
                placeholder="Address"
                value={selectedCustomerCareAddress}
                onChange={(e) => {
                  setSelectedCustomerCareAddress(e.target.value);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateCustomerCareSubmit();
              }}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUpdateCustomerCareDialog(!updateCustomerCareDialog);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={deleteCustomerCareDialog}
          onClose={handleClose}
          aria-labelledby="update Customer Care"
        >
          <DialogTitle id="updateCustomerCare">
            {"Update Customer Care"}
          </DialogTitle>
          <DialogContent>
            Are you sure want to delete?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteCustomerCareSubmit();
              }}
            >
              Yes
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeleteCustomerCareDialog(!deleteCustomerCareDialog);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
