import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InvoiceTable from '../components/InvoiceTable';
import dayjs from 'dayjs';
import UserForm from '../components/UserForm';
import CommanTable from '../components/CommanTable';

import { uniqueNumber } from '../utils/genrateUniqueNumber';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from '../../../../../utils/formatTime';
import LoadingPage from '../../loadingpage/LoadingPage';
import MainService from '../../../Common/MainService';
import ConsigneeDetails from '../components/ConsigneeDetails';
import { mapPORequestTypeToValue } from './utils';
import { appendKeysWithValues, removeKey } from '../utils/validateData';

const tempPurchaseOrderDetails = [
  { id: 'orderId', label: 'Order ID' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'date', label: 'Date' },
  { id: 'status', label: 'Status' },
  { id: 'viewLink', label: 'View/Generate', type: 'button' },
];

const todayDate = new Date();
const formattedValue = dayjs(todayDate).format('YYYY-MM-DD');

const tempPurchaseDetails = [
  { id: 'logo', label: 'Logo', type: 'logo' },
  { id: 'purchaseOrderID', label: 'Purchase order ID' },
];

const tempQuotationDetails = [
  {
    purchaseOrderID: 'PUR-' + uniqueNumber(),
    purchaseOrderDate: formattedValue,
  },
];

const tempBuyerData = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const tempSupplier = {
  supplierName: '',
  supplierContact: '',
  sup_email: '',
  sup_gstin: '',
  sup_panNo: '',
  supplierAdressline1: '',
  supplierAdressline2: '',
  supplierAdressline3: '',
};

const tempPurchaseDescriptionColumnData = [
  { id: 'description', label: 'Description' },
  { id: 'quantity', label: 'Quantity', type: 'number', width: 100 },
  { id: 'unit', label: 'Unit', width: 50 },
  { id: 'reqDeliveryDate', label: 'Requested Delivery Date', type: 'date' },
  {
    id: 'price_per_unit',
    label: 'Requested Rate per Unit',
    type: 'number',
    width: 100,
  },
  { id: 'total', label: 'Total', type: 'number', width: 200 },
];

const tempPurchaseDescriptionRowData = [
  {
    description: '',
    quantity: '',
    unit: '',
    price_per_unit: '',
    reqDeliveryDate: '',
  },
];

const tempTermConditionLabel = [{ id: 'term', label: 'Terms & Conditions:' }];
const tempTermConditionValue = [];

const PoManagement = ({ userDetails, proxyId = '' }) => {
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([{}]);
  const [newPurchaseOrderStatus, setNewPurchaseOrderStatus] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [tempImage, setTempImage] = useState({});

  const [buyerDetails, setBuyerDetails] = useState(tempBuyerData);
  const [supplierDetails, setSupplierDetails] = useState(tempSupplier);

  const [purchaseOrderItemDetails, setPurchaseOrderItemDetails] = useState(
    tempPurchaseDescriptionRowData
  );

  const [termsConditions, setTermsConditions] = useState(
    tempTermConditionValue
  );

  const [isDocumentDetailsLoaded, setIsDocumentDetailsLoaded] = useState(true);

  const [documentDetailsError, setDocumentDetailsError] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');

  const [imageSize, setImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const [poType, setPoType] = useState('Asset');
  const [categoryType, setCategoryType] = useState('Asset');
  const [sameAddressCheck, setSameAddressCheck] = useState(false);
  const [consigneeDetails, setConsigneeDetails] = useState({});

  const [isFormDataFetched, setIsFormDataFetched] = useState(false);
  const [isValidKeyFetched, setIsValidKeyFetched] = useState([]);

  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  useEffect(() => {
    setPurchaseOrderDetails((prev) => [
      {
        ...prev[0],
        purchaseOrderID: proxyId,
        purchaseOrderDate: formattedValue,
      },
    ]);
    if (proxyId) {
      setNewPurchaseOrderStatus(true);
    }
  }, [proxyId]);

  //user details
  useEffect(() => {
    if (userDetails) {
      setBuyerDetails((prevSupplierDetails) => ({
        ...prevSupplierDetails,
        ...userDetails,
      }));
    }
  }, [userDetails, newPurchaseOrderStatus]);

  // fetch purchase order list
  const { isLoading, error, data } = useQuery({
    queryKey: ['purchaseOrderData'],
    queryFn: async () => {
      try {
        const result = await MainService.documentListApi(
          parentId,
          orgid,
          suborgid,
          username,
          'po'
        );

        if (result) {
          return result;
        } else {
          return {
            isLoading: true,
            error: false,
            data: [],
          };
        }
      } catch (error) {
        console.error('Error fetching purchase order data:', error);
        return [];
      }
    },
  });

  useEffect(() => {
    if (!isLoading && !error && data?.data?.docs !== undefined) {
      const initialData = data.data.docs.map((item) => {
        const formattedDate = formatDate(item.docCreateDate);
        return {
          orderId: item.docId,
          buyerName: item.docReceiverName,
          date: formattedDate,
          status: item.status,
          viewLink: item.LocationUrl,
        };
      });
      setPurchaseOrderList(initialData);
    }
    return () => {
      setPurchaseOrderList([]);
    };
  }, [isLoading, error, data]);

  //image file change handler
  const imageFileChangeHandler = (event, columnId) => {
    const { files } = event.target;

    Array.from(files).forEach((file) => {
      if (
        file.size > 1024 * 1024 ||
        !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
      ) {
        setImageSize(file.size > 1024 * 1024);
        setValidImageFormat(
          !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
        );
        setImageFile((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[columnId];
          return updatedData;
        });
        setTempImage((prevData) => {
          const updatedData = { ...prevData };
          updatedData[columnId] = '';
          return updatedData;
        });
        event.target.value = '';
        return;
      }

      setImageFile((prevData) => ({
        ...prevData,
        [columnId]: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setTempImage((prevData) => ({
          ...prevData,
          [columnId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const imageChangeHandler = (columnId) => {
    const fileInput = document.getElementById(columnId);
    fileInput.click();
  };

  // po type change
  const poTypeChangeHandler = (event) => {
    const { value } = event.target;
    setPoType(value);
    if (value === 'Asset') {
      setCategoryType('Asset');
    } else if (value === 'Agri Product') {
      setCategoryType('Product');
    } else {
      setCategoryType('Services');
    }
  };

  // address check box change handler
  const addressCheckboxChageHandler = (event) => {
    setSameAddressCheck(event.target.checked);
    if (event.target.checked) {
      setConsigneeDetails({
        consigneeContactNo: buyerDetails.buyer_cell.buyer_cell1,
        consigneeAdressline1: buyerDetails.buyerAdressline1,
        consigneeAdressline2: buyerDetails.buyerAdressline2,
        consigneeAdressline3: buyerDetails.buyerAdressline3,
      });
    } else {
      setConsigneeDetails({
        consigneeContactNo: '',
        consigneeAdressline1: '',
        consigneeAdressline2: '',
        consigneeAdressline3: '',
      });
    }
    removeKey(consigneeDetails, setIsValidKeyFetched);
  };

  //add new purchase order handler
  const addNewPurchaseOrderHandler = () => {
    setNewPurchaseOrderStatus(true);
  };

  //purchase order details change handler
  const purchaseOrderDetailsChangeHandler = (index, field, value) => {
    setPurchaseOrderDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // user form input change handler

  const formUserInputChangeHandler = (event, setFormDataInput) => {
    const { name, value } = event.target;
    console.log(name, value);

    setFormDataInput((formDataInput) => {
      if (name === 'buyer_cell1' || name === 'buyer_cell2') {
        return {
          ...formDataInput,
          buyer_cell: {
            ...formDataInput.buyer_cell,
            [name]: value,
          },
        };
      } else {
        return {
          ...formDataInput,
          [name]: value,
        };
      }
    });
  };

  //Purchase order item detials handler
  const purchaseOrderItemChangehandler = (index, field, value, type) => {
    const parsedValue = type === 'number' ? parseFloat(value) : value;

    setPurchaseOrderItemDetails((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: parsedValue,
          };
        }
        return item;
      })
    );
  };

  //Deleting description item handler
  const deleteItemHandler = (
    index,
    itemDetails,
    setItemDetails,
    isDescriptionItem
  ) => {
    if (itemDetails.length === 1 && isDescriptionItem) {
      return;
    }
    setItemDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      return newDetails;
    });
  };

  //purchase order add item handler
  const addPurchaseOrderItemHandler = () => {
    const data = {
      description: '',
      quantity: '',
      unit: '',
      price_per_unit: '',
      reqDeliveryDate: '',
    };
    setPurchaseOrderItemDetails([...purchaseOrderItemDetails, data]);
  };

  // construct the document data
  const documentFormDataConstructed = () => {
    console.log('documentformatdata');

    const isSecondObjectEmpty = (obj) =>
      Object.values(obj).some((value) => value === '');
    const isItemEmpty = (item) => {
      const excludedFields = ['buyer_cell2', 'buyer_website', 'sup_gstin'];
      const hasValidSupplierContact = (supplierContact) =>
        !(supplierContact.trim().length >= 10);
      return Object.entries(item).some(
        ([key, value]) =>
          !excludedFields.includes(key) &&
          (value === '' ||
            (typeof value === 'number' && isNaN(value)) ||
            (typeof value === 'string' && value === 'Invalid Date') ||
            (key === 'buyer_cell1' && hasValidSupplierContact(value)))
      );
    };

    const isSupplierDetailsEmpty = isItemEmpty(supplierDetails);
    const isPurchaseOrderItemDetailsEmpty =
      purchaseOrderItemDetails.some(isItemEmpty);
    const isConsigneeDetailsEmpty = isItemEmpty(consigneeDetails);

    const isPurchaseOrderDetailsEmpty =
      !purchaseOrderDetails[0].purchaseOrderID;

    const isBuyerDetailsNameEmpty = buyerDetails.buyerName === '';
    const isBuyerDetailsContactEmpty =
      buyerDetails.buyer_cell.buyer_cell1 === '' ||
      buyerDetails.buyer_cell.buyer_cell1?.length !== 10;
    const isBuyerDetailsEmailEmpty = buyerDetails.buyer_email === '';
    // const isBuyerDetailsGSTEmpty =
    //   buyerDetails.buyer_gstin === '' ||
    //   buyerDetails.buyer_gstin?.length !== 15;
    const isBuyerDetailsPanEmpty =
      buyerDetails.buyer_panNo === '' ||
      buyerDetails.buyer_panNo?.length !== 10;
    const isBuyerDetailsAddress1Empty = buyerDetails.buyerAdressline1 === '';
    const isBuyerDetailsAddress2Empty = buyerDetails.buyerAdressline2 === '';
    const isBuyerDetailsAddress3Empty = buyerDetails.buyerAdressline3 === '';
    // const isSupplierDetailsgstinValid =
    //   supplierDetails.sup_gstin === '' ||
    //   supplierDetails.sup_gstin?.length !== 15;
    const isSupplierDetailsgContactValid =
      supplierDetails.supplierContact === '' ||
      supplierDetails.supplierContact?.length !== 10;

    console.log(
      'requirefield',
      isBuyerDetailsNameEmpty,
      isBuyerDetailsContactEmpty,
      isBuyerDetailsEmailEmpty,
      // isBuyerDetailsGSTEmpty ,
      isBuyerDetailsPanEmpty,
      isBuyerDetailsAddress1Empty,
      isBuyerDetailsAddress2Empty,
      isBuyerDetailsAddress3Empty,
      isSupplierDetailsEmpty,
      supplierDetails,
      isPurchaseOrderItemDetailsEmpty,
      isPurchaseOrderDetailsEmpty,
      // isSupplierDetailsgstinValid ,
      isSupplierDetailsgContactValid,
      isConsigneeDetailsEmpty
    );

    if (
      isBuyerDetailsNameEmpty ||
      isBuyerDetailsContactEmpty ||
      isBuyerDetailsEmailEmpty ||
      // isBuyerDetailsGSTEmpty ||
      isBuyerDetailsPanEmpty ||
      isBuyerDetailsAddress1Empty ||
      isBuyerDetailsAddress2Empty ||
      isBuyerDetailsAddress3Empty ||
      isSupplierDetailsEmpty ||
      isPurchaseOrderItemDetailsEmpty ||
      isPurchaseOrderDetailsEmpty ||
      // isSupplierDetailsgstinValid ||
      isSupplierDetailsgContactValid ||
      isConsigneeDetailsEmpty
    ) {
      // Handle empty fields error, display a message or perform appropriate action

      setSaveButtonStatus(true);
      setDocumentDetailsError('Please fill the required field.');
      return { data: {}, formData: {} };
    }

    const poDataDetails = {
      items: purchaseOrderItemDetails,
      supplierDetails: {
        ...supplierDetails,
        sup_gstin: supplierDetails.sup_gstin || 'NA',
      },
      buyerDetails: {
        ...buyerDetails,
        buyer_gstin: buyerDetails.buyer_gstin || 'NA',
      },
      purchaseOrderDetails: purchaseOrderDetails[0],
      consigneeDetails: {
        consigneeContactNo: !sameAddressCheck
          ? consigneeDetails.consigneeContactNo
          : buyerDetails.buyer_cell.buyer_cell1,

        consigneeAdressline1: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline1
          : buyerDetails.buyerAdressline1,

        consigneeAdressline2: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline2
          : buyerDetails.buyerAdressline2,

        consigneeAdressline3: !sameAddressCheck
          ? consigneeDetails.consigneeAdressline3
          : buyerDetails.buyerAdressline3,
      },
      termsConditions: [...termsConditions.map((obj) => obj.term)],
      orderType: poType,
      categoryType: categoryType,
    };

    const data = {
      docId: purchaseOrderDetails[0].purchaseOrderID,
      networkId: window.AppConfigData.EnterpriseId,
      parentId: parentId,
      orgId: orgid,
      proxyId: proxyId,
      docCreatorId: username,
      type: 'po',
      docCreatorName: username,
      docCreateDate: purchaseOrderDetails[0].purchaseOrderDate,
      docReceiverName: buyerDetails.buyerName,
      status: 'created',
      poDataDetails,
    };

    const contentInfo = {
      contentname: `${purchaseOrderDetails[0].purchaseOrderID}`,
      contentcreator: username,
      contentownerid: username,
      contentdescription: 'Purchase order.',
      expirydate: purchaseOrderDetails[0].purchaseOrderDate,
      assetdeveloperid: window.AppConfigData.EnterpriseId,
    };

    const generatedDateInfo = {
      docGeneratedDate: formattedValue,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(poDataDetails));
    formData.append('generatedDateInfo', JSON.stringify(generatedDateInfo));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('file', imageFile.logo);

    return { data, formData };
  };

  //save purchase order handler
  const savePurchaseOrderHandler = async ({ data }) => {
    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );

      if (response?.status === 201) {
        const formattedDate = formatDate(
          response.data.docDetails.docCreateDate
        );

        const tempQutationList = {
          viewLink: response.data.docDetails.LocationUrl,
          orderId: purchaseOrderDetails[0].purchaseOrderID,
          buyerName: buyerDetails.buyerName,
          date: formattedDate,
          status: response.data.docDetails.status,
        };
        setPurchaseOrderList((prevList) => [...prevList, tempQutationList]);
        setDocumentDetailsError('Purchase order submitted.');
        setTimeout(() => {
          cancelPurchaseOrderHandler();
        }, 300);
        return response;
      } else {
        console.log('error in document response', response);
        // setErrorMesg(response);
        setDocumentDetailsError(response);
      }

      setIsDocumentDetailsLoaded(true);
      return response;
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
      }
      console.log('error', error.message);
      return error.message;
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  // fetching data from server based on document id
  const fetchDocumentDetails = async (docId) => {
    setIsDocumentDetailsLoaded(false);
    try {
      const response = await MainService.documentDetailsApi(
        parentId,
        orgid,
        suborgid,
        username,
        docId
      );
      if (!response) {
        console.log('error');
        setDocumentDetailsError(
          'Document details not found, try refreshing the page.'
        );
      }
      console.log('response.data.doc.poDataDetails.items', response);
      if (response?.status === 200) {
        const {
          buyerDetails,
          consigneeDetails,
          items,
          purchaseOrderDetails,
          supplierDetails,
          termsConditions,
        } = response.data.doc.poDataDetails;
        const transformedArray = termsConditions.map((item) => ({
          term: item,
        }));

        setBuyerDetails(buyerDetails);
        setPurchaseOrderDetails([purchaseOrderDetails]);
        setSupplierDetails(supplierDetails);
        setPurchaseOrderItemDetails(items);
        setTermsConditions(transformedArray);
        setConsigneeDetails(consigneeDetails);
        // setRawMaterialItemDetails(rawItems);
      }
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const getDocumentDetailsHander = async ({ orderId, status }) => {
    setNewPurchaseOrderStatus(true);
    setDocumentStatus(status);

    await fetchDocumentDetails(orderId);
  };

  const fetchGeneratedDocument = async (formData, type) => {
    const response = await MainService.generateDocumentsApi(
      parentId,
      orgid,
      suborgid,
      username,
      formData,
      type
    );

    if (response?.status === 200) {
      const tempPurchaseList = {
        viewLink: response.data.LocationUrl,
        status: response.data.status,
        orderId: purchaseOrderDetails[0].purchaseOrderID,
        buyerName: buyerDetails.buyerName,
        date: response.data.docGeneratedDate,
      };

      console.log('tempPurchaseList', tempPurchaseList);

      setPurchaseOrderList((prevList) =>
        prevList.map((item) =>
          item.orderId === tempPurchaseList.orderId ? tempPurchaseList : item
        )
      );
      setDocumentDetailsError('Purchase order generated.');
      handleDownload(response.data.LocationUrl, response.data.orderId);
      setTimeout(() => {
        cancelPurchaseOrderHandler();
      }, 300);
    } else {
      setDocumentDetailsError(response);
    }
    setIsDocumentDetailsLoaded(true);
    console.log('already created', response);
  };

  // generate purchase order
  const generateDocumentHandler = async ({ data, formData }) => {
    console.log('click', data, formData);

    if (Object.keys(data).length === 0) {
      return;
    }
    setIsDocumentDetailsLoaded(false);
    console.log('document status', documentStatus);
    try {
      if (documentStatus === 'created') {
        console.log('already created');
        const response = await fetchGeneratedDocument(formData, data.type);
      } else {
        console.log('need to create');
        // setDocumentStatus('created');
        const saveResponse = await savePurchaseOrderHandler({ data });
        console.log('saveResponse', saveResponse);
        if (saveResponse?.status === 201) {
          setIsDocumentDetailsLoaded(false);
          const response = await fetchGeneratedDocument(formData, data.type);
          console.log('response genrate', response);
        }
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(
          'Network connection issue. Please check your internet connection.'
        );
        setDocumentDetailsError(
          'Network connection issue. Please check your internet connection.'
        );
      }
      console.log('error', error.message);
      setDocumentDetailsError(error.message);
    } finally {
      setIsDocumentDetailsLoaded(true);
    }
  };

  const handleDownload = (url, contenId) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', contenId);

      // Open the new tab
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.body.appendChild(link);
        // Trigger the download
        link.click();
      } else {
        // Handle case where new tab couldn't be opened
        console.error('Failed to open new tab');
      }
    }, 100);
  };

  //cancel purchase order handler
  const cancelPurchaseOrderHandler = () => {
    setSaveButtonStatus(false);
    setShowAlert(false);
    setFormSubmitError(false);
    setPurchaseOrderDetails(tempQuotationDetails);
    setNewPurchaseOrderStatus(false);
    setImageFile({});
    setTempImage({});
    setBuyerDetails(tempBuyerData);
    setSupplierDetails(tempSupplier);
    setPurchaseOrderItemDetails(tempPurchaseDescriptionRowData);
    setDocumentDetailsError('');
    setDocumentStatus('');
    setTermsConditions([]);
    setConsigneeDetails({
      consigneeContactNo: '',
      consigneeAdressline1: '',
      consigneeAdressline2: '',
      consigneeAdressline3: '',
    });
  };

  // terms and condition add
  const addTermConditionHandler = () => {
    const data = {
      term: '',
    };
    setTermsConditions([...termsConditions, data]);
  };

  const termsConditionsChangeHandler = (index, field, value) => {
    setTermsConditions((prevDetails) =>
      prevDetails.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };

  // get details based on rfq id
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // fetch document based on id
  const fetchDetailsbyId = async (id) => {
    const response = await MainService.getDocumentsById(id, parentId, username);

    if (response?.status === 200) {
      setIsFormDataFetched(true);
      const {
        personalDetails,
        requestType,
        requirements,
        supplierDetails,
        deliveryAddress,
        pickUpAddress,
        manufacturingPlantAddress,
        growerFarmingSiteAddress,
        assetDetails,
      } = response.data.requestDetails ?? {};

      const requestTypeValue = mapPORequestTypeToValue(requestType);

      setPoType(requestTypeValue);

      setSupplierDetails({
        supplierName: supplierDetails.siteName ?? '',
        supplierContact: supplierDetails?.phoneNo?.toString() ?? '',
        sup_email: supplierDetails?.emailId ?? '',
        sup_gstin: supplierDetails?.gstin_Number,
        sup_panNo: supplierDetails?.panNumber.toString(),
        supplierAdressline1: `${supplierDetails?.siteId ?? ''} ${
          supplierDetails?.street ?? ''
        }`,
        supplierAdressline2: `${supplierDetails?.city ?? ''} ${
          supplierDetails?.state ?? ''
        }`,
        supplierAdressline3: `${supplierDetails?.country ?? ''} ${
          supplierDetails?.pincode ?? ''
        }`,
      });

      const newArray = assetDetails.map(({ description, quantity, unit }) => ({
        description,
        quantity,
        unit,
        reqDeliveryDate: '',
      }));
      setPurchaseOrderItemDetails((prev) => [...newArray]);

      if (
        requestTypeValue === 'Asset Contract Farming' ||
        requestTypeValue === 'Product Contract Farming'
      ) {
        setConsigneeDetails({
          consigneeContactNo: personalDetails.phoneNo ?? '',
          consigneeAdressline1: `${growerFarmingSiteAddress.siteName ?? ''}`,
          consigneeAdressline2: `${growerFarmingSiteAddress.street ?? ''} ${
            growerFarmingSiteAddress.city ?? ''
          }`,
          consigneeAdressline3: `${growerFarmingSiteAddress.country ?? ''} ${
            growerFarmingSiteAddress.pincode ?? ''
          }`,
        });
      }
    }
  };

  const debouncedFetchDetailsById = useCallback(
    debounce(fetchDetailsbyId, 500),
    []
  );

  useEffect(() => {
    if (proxyId && purchaseOrderDetails[0]?.purchaseOrderID) {
      debouncedFetchDetailsById(purchaseOrderDetails[0]?.purchaseOrderID);
    }
  }, [
    proxyId,
    purchaseOrderDetails[0]?.purchaseOrderID,
    debouncedFetchDetailsById,
  ]);

  // check for disable key
  useEffect(() => {
    const processObjects = (...objects) => {
      objects.forEach((object) =>
        appendKeysWithValues(object, setIsValidKeyFetched)
      );
    };

    processObjects(
      buyerDetails,
      supplierDetails,
      ...purchaseOrderItemDetails,
      ...purchaseOrderDetails,
      consigneeDetails
    );
  }, [isFormDataFetched]);

  // snackbar action handler
  const snackbarActionHandler = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => setDocumentDetailsError('')}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  );

  if (isLoading) return <LoadingPage />;
  // if (error) return 'An error has occurred: ' + error.message;

  return (
    <>
      {!newPurchaseOrderStatus && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Purchase Order Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />

          <InvoiceTable
            columnData={tempPurchaseOrderDetails}
            rowDataList={purchaseOrderList}
            documentDetailsHander={getDocumentDetailsHander}
          />

          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={addNewPurchaseOrderHandler}>
                Add New Purchase Order
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}

      {newPurchaseOrderStatus && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Purchase Order
            </Typography>
            <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
            }}
          >
            <CommanTable
              columnsData={tempPurchaseDetails}
              rowsData={purchaseOrderDetails}
              image={tempImage}
              saveButtonStatus={saveButtonStatus}
              validKey={isValidKeyFetched}
              imageChangeHandler={imageChangeHandler}
              imageFileChangeHandler={imageFileChangeHandler}
              inputFieldChangeHandler={purchaseOrderDetailsChangeHandler}
            />
          </Box>
          <Box
            sx={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              mb: '1rem',
              padding: '1rem',
              borderRadius: '0.5rem',
            }}
          >
            <Box sx={{ display: 'flex', '&>*': { flex: 1 }, gap: 3 }}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id='poType'>Quote Type</InputLabel>
                  {console.log(' poTypepoTypepoType', poType)}
                  <Select
                    labelId='poType'
                    id='poType'
                    value={poType}
                    label='poType'
                    onChange={poTypeChangeHandler}
                  >
                    <MenuItem value={'Asset'}>Asset</MenuItem>
                    <MenuItem value={'Agri Product'}>Agri Product</MenuItem>
                    <MenuItem value={'Asset Contract Farming'}>
                      Asset Contract Farming
                    </MenuItem>
                    <MenuItem value={'Product Contract Farming'}>
                      Product Contract Farming
                    </MenuItem>
                    <MenuItem value={'Contract Manufacturing'}>
                      Contract Manufacturing
                    </MenuItem>
                    <MenuItem value={'Asset Storage'}>Asset Storage</MenuItem>
                    <MenuItem value={'Product Storage'}>
                      Product Storage
                    </MenuItem>
                    <MenuItem value={'Asset Transport'}>
                      Asset Transport
                    </MenuItem>
                    <MenuItem value={'Product Transport'}>
                      Product Transport
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box>
            <UserForm
              saveButtonStatus={saveButtonStatus}
              buyerDetails={buyerDetails}
              supplierDetails={supplierDetails}
              validKey={isValidKeyFetched}
              userInputBuyerChangehandler={(event) =>
                formUserInputChangeHandler(event, setBuyerDetails)
              }
              userInputSupplierChangehandler={(event) =>
                formUserInputChangeHandler(event, setSupplierDetails)
              }
              po={true}
            />
            <Box
              sx={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                mb: '1rem',
                padding: '1rem',
                borderRadius: '0.5rem',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  // required
                  control={<Checkbox />}
                  label='Is the address the same as the current delivery location?'
                  onChange={addressCheckboxChageHandler}
                />
              </FormGroup>
              <ConsigneeDetails
                saveButtonStatus={saveButtonStatus}
                consigneeDetailsData={consigneeDetails}
                validKey={isValidKeyFetched}
                userInputBuyerChangehandler={(event) =>
                  formUserInputChangeHandler(event, setConsigneeDetails)
                }
                invoice={false}
              />
            </Box>

            <Box
              component='form'
              sx={{
                mb: '1.2rem',
              }}
              noValidate
              autoComplete='off'
            >
              <Box
                sx={{
                  mb: '1.2rem',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                  borderRadius: '5px',
                  padding: { xs: '0.5rem', md: '1rem' },
                }}
              >
                <CommanTable
                  columnsData={tempPurchaseDescriptionColumnData}
                  rowsData={purchaseOrderItemDetails}
                  tableType={'editable'}
                  inputFieldChangeHandler={purchaseOrderItemChangehandler}
                  // deleteItemHandler={deletePurchaseOrderItemHandler}
                  deleteItemHandler={(rowsData, index) =>
                    deleteItemHandler(
                      index,
                      purchaseOrderItemDetails,
                      setPurchaseOrderItemDetails,
                      true
                    )
                  }
                  addItemHandler={addPurchaseOrderItemHandler}
                  saveButtonStatus={saveButtonStatus}
                />

                <CommanTable
                  columnsData={tempTermConditionLabel}
                  rowsData={termsConditions}
                  tableType={'editable'}
                  addItemHandler={addTermConditionHandler}
                  inputFieldChangeHandler={termsConditionsChangeHandler}
                  deleteItemHandler={(rowData, index) =>
                    deleteItemHandler(
                      index,
                      termsConditions,
                      setTermsConditions,
                      false
                    )
                  }
                />
              </Box>
              {/* <Box>
                <Stack>
                  <ListItem
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button>Cancel</Button>
                    <Button>Save</Button>
                  </ListItem>
                </Stack>
              </Box> */}
            </Box>
          </Box>
          {showAlert && (
            <Alert severity='success' sx={{ mt: '0.5rem' }}>
              Quotation successfully submitted.
            </Alert>
          )}
          {formSubmitError && (
            <Alert severity='error' sx={{ mt: '0.5rem' }}>
              Please fill the required field.
            </Alert>
          )}
          <Box>
            <Stack>
              <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={cancelPurchaseOrderHandler}>Cancel</Button>
                {documentStatus !== 'created' && (
                  <Button
                    onClick={() =>
                      savePurchaseOrderHandler(documentFormDataConstructed())
                    }
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={() =>
                    generateDocumentHandler(documentFormDataConstructed())
                  }
                >
                  Generate
                </Button>
              </ListItem>
            </Stack>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={documentDetailsError ? true : false}
        autoHideDuration={5000}
        onClose={() => setDocumentDetailsError('')}
        message={documentDetailsError}
        action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={imageSize}
        autoHideDuration={2000}
        onClose={() => setImageSize(false)}
        message={'image size is too large, please select below 1 mb.'}
        // action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={validImageFormat}
        autoHideDuration={2000}
        onClose={() => setValidImageFormat(false)}
        message={
          ' Invalid image format. Please select a JPEG, PNG, or GIF image.'
        }
      />
      {!isDocumentDetailsLoaded && <LoadingPage />}
    </>
  );
};

export default PoManagement;
