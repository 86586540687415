import React from 'react';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import sha256 from 'sha256';
import MenuItem from '@material-ui/core/MenuItem';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import 'react-notifications/lib/notifications.css';
import MainService from '../../Main/Common/MainService';
import './SignupPageComponent.css';
import Modal from '@material-ui/core/Modal';

export default class SignupPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UnsignedAgreementDocumentLink: '',
      unsignedAgreementDocumentType: '',
      isLoading: false,
      privecyCheck: false,
      //agreementCheck: false,
      allUserTypes: '',
      allUserTypesList: [],
      signupObj: {},
      userType: '',
      viewUnsignedDocumentPopup: false,
      UnsignedAgreementList: [],
      open: false,
      agreementFile: null,
      extensionFile: '',
      userTypes: ['LogisticsProvider'],
      joinwithus: [],
      assetDeveloperValue: '',
      screenSizeAbove639: window.matchMedia('(min-width: 639px)').matches,
    };
  }

  //Commented by srini 21/4/22
  componentDidMount() {
    this.createAccessToken();
  }
  

  handleClose = () => {
    // this.setState({ open: false });
    this.setState({ viewUnsignedDocumentPopup: false });
  };
  createAccessToken() {
    const tokenData = {
      client_name: window.AppConfigData.ClientName, //"GBR1",
      client_secret: window.AppConfigData.ClientSecretKey, //"56b50768-f088-40e5-b015-9ec5eefdb213",
    };
    MainService.createAccessToken(tokenData)
      .then((result) => {
        this.setState({
          accessToken: result.access_token,
        });
        this.getAllUserTypes(result.access_token);
        this.getUsers(result.access_token);
      })
      .catch((err) => console.log('err', err));
  }
  getUsers(token) {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i], token);
    }
  }

  joinClubValues(userType, token) {
    MainService.getGlobalUsersFromOutside(userType, token)
      .then((res) => {
        this.setState((prevState) => ({
          joinwithus: [...prevState.joinwithus, res].flatMap((num) => num),
        }));
      })
      .catch((err) => console.log('err', err));
  }

  resultArrayFunction() {}
  getAllUserTypes(token) {
    MainService.getAllUserTypes(window.AppConfigData.EnterpriseId, window.AppConfigData.EnterpriseId, token)
      .then((res) => {
        this.setState({ allUserTypesList: res });
      })
      .catch((err) => {
        return NotificationManager.error(err);
      });
  }

  checkUnsignedAgreementLink() {
    const unsignedAgreementDocumentLink = this.state;
    if (unsignedAgreementDocumentLink !== '') {
      console.log('link  is set', this.state.unsignedAgreementDocumentLink);
      this.getFileExtension(unsignedAgreementDocumentLink);
    } else {
      console.log('link is not set');
    }
  }
  getUnsignedAgreementsClick = (e) => {
    e.preventDefault();
    this.setState({ viewUnsignedDocumentPopup: true });
    this.getUnsignedAgreements();
  };

  getFileExtension(filename) {
    // get file extension
    const extension = filename.split('.').pop();
    this.setState({
      extensionFile: extension,
    });
  }
  checkboxClick = (e) => {
    if (e === true) {
      this.getUnsignedAgreements();
      // this.getFileExtension(this.state.unsignedAgreementDocumentLink);
      var agreementCheckFormData = new FormData();
      agreementCheckFormData.append(
        'file',
        this.state.unsignedAgreementDocumentLink
      );
      agreementCheckFormData.append('mimetype', this.state.extensionFile);

      if (this.state.userType === 'AssetOEM') {
        fetch(
          window.newBaseUrl_oem +
            'Contract-Upload-Gw/updDocument/consumer2/' +
            this.state.signupObj['username'] +
            '/InvestorAgreementDocument',
          {
            body: agreementCheckFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + this.state.accessToken,
            },
            method: 'post',
          }
        )
          .then((data) => {
            return NotificationManager.success('Kyc Agreement Uploaded');
          })
          .catch((e) => {
            return NotificationManager.error('Kyc Agreement not Uploaded ');
          });
      } else if (this.state.userType === 'AssetDeveloper') {
        fetch(
          window.newBaseUrl_oem +
            'Contract-Upload-Gw/updDocument/consumer2/' +
            this.state.signupObj['username'] +
            '/AssetDeveloperAgreementDocument',
          {
            body: agreementCheckFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + this.state.accessToken,
            },
            method: 'post',
          }
        )
          .then((data) => {
            return NotificationManager.success('Kyc Agreement Uploaded');
          })
          .catch((e) => {
            return NotificationManager.error('Kyc Agreement not Uploaded ');
          });
      }
    }
  };
  getUnsignedAgreements = () => {
    if (this.state.userType === 'AssetOEM') {
      fetch(
        window.newBaseUrl_oem +
          'ContractGw/getDocument/consumer2/InvestorAgreementDocument',
        // "http://13.233.122.53:8080/ContractGw/getDocument/consumer2/InvestorAgreementDocument",
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.state.accessToken,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.documents.length > 0) {
            this.setState({ unsignedAgreementList: data.documents });
            this.setState({
              unsignedAgreementDocumentLink: data.documents[0].documentlink,
              unsignedAgreementDocumentType: data.documents[0].documenttype,
            });
            this.checkUnsignedAgreementLink();
          }
        }, console.log('unsigned agreement link:', this.state.unsignedAgreementList))
        .catch((err) => console.log('err', err));
    } else if (this.state.userType === 'AssetDeveloper') {
      fetch(
        window.newBaseUrl_oem +
          'ContractGw/getDocument/consumer2/AssetDeveloperAgreementDocument',
        // "http://13.233.122.53:8080/ContractGw/getDocument/consumer2/InvestorAgreementDocument",
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.state.accessToken,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.documents.length > 0) {
            this.setState({ unsignedAgreementList: data.documents });
            this.setState({
              unsignedAgreementDocumentLink: data.documents[0].documentlink,
              unsignedAgreementDocumentType: data.documents[0].documenttype,
            });
            this.checkUnsignedAgreementLink();
          }
        }, console.log('unsigned agreement link:', this.state.unsignedAgreementList))
        .catch((err) => console.log('err', err));
    }
  };

  uploadAgreementDataClick = () => {
    var agreementFormData = new FormData();
    agreementFormData.append('file', this.state.agreementFile);
    agreementFormData.append('mimetype', this.state.agreementFile.type);

    fetch(
      window.newBaseUrl_oem +
        'Contract-Upload-Gw/updDocument/consumer2/' +
        this.state.signupObj['username'] +
        '/InvestorAgreementDocument',
      {
        body: agreementFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.state.accessToken,
        },
        method: 'post',
      }
    )
      .then((data) => {
        this.setState({
          viewUnsignedDocumentPopup: false,
          agreementCheck: !this.state.agreementCheck,
        });

        return NotificationManager.success('Kyc Agreement Uploaded');
      })
      .catch((e) => {
        return NotificationManager.error('Kyc Agreement not Uploaded ');
      });
  };

  doSignup = (evt) => {
    evt.preventDefault();

    if (!this.state.signupObj.firstname) {
      NotificationManager.warning('Please enter valid firstname');
      return;
    }
    if (!this.state.signupObj.lastname) {
      NotificationManager.warning('Please enter valid lastname');
      return;
    }
    if (!this.state.signupObj.username) {
      NotificationManager.warning('Please enter Username/Email');
      return;
    }
    /* if (!this.state.signupObj.password) {
      NotificationManager.warning("Please enter Password");
      return;
    } */
    if (!this.state.signupObj.userphone) {
      NotificationManager.warning('Please enter Phone Number');
      return;
    }

    if (!this.state.privecyCheck) {
      NotificationManager.warning(
        'Please accept the Terms of Use & Privacy Policy.'
      );
      return;
    }

    if (this.props.match.params.code < 3 || this.props.match.params.code > 9) {
      NotificationManager.error('Invalid access type.');
      //invalid type
      console.log('Invalid type');
      return;
    }

    const { username, firstname, lastname, userphone } = this.state.signupObj;

    const _data = {
      username: username,
      firstname: firstname,
      lastname: lastname,
      phone: userphone,
      usertype: this.state.allUserTypes,
      domain: this.state.assetDeveloperValue,
      address: '',
      city: '',
      state: '',
      nominee: '',
      pancard: '',
      longitude: '',
      latitude: '',
      message: '',
      email: username,
      accountIs:"Local"
    };

    this.setState({ isLoading: true });
    console.log('dataa:', _data);
    MainService.createConsumerUser(
      _data,
      this.state.assetDeveloperValue,
      username,
      username,
      this.state.accessToken
    )
      .then((signupRes) => {
        NotificationManager.success(signupRes);
        setTimeout(() => {
          this.goToHome();
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
        NotificationManager.error(e);
      });
  };
  goToForgotPwd = (evt) => {
    this.props.history.push(
      '/auth/' + this.props.match.params.code + '/forgot'
    );
  };
  goToSignin = (evt) => {
    this.props.history.push(
      '/auth/' + this.props.match.params.code + '/signin'
    );
  };
  goToHome = (evt) => {
    this.props.history.push('/');
  };
  onFieldChange = (evt) => {
    let signupObj = this.state.signupObj;
    signupObj[evt.target.name] = evt.target.value;
    this.setState({ signupObj });
  };
  onPrivecyChange = (evt) => {
    this.setState({ privecyCheck: !this.state.privecyCheck });
  };

  render() {
    /* View Unsigned Document */
    const unsignedDocumentModalBody = (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} md={4} lg={3} className='modalGridBody'>
          <div className='updateProfileContainer'>
            <span
              className='closeButton'
              style={{
                width: '100%',
                textAlign: 'right',
                color: 'red',
                fontSize: '23px',
                float: 'right',
                padding: '0px 15px 0px 0px',
              }}
              onClick={() =>
                this.setState({
                  viewUnsignedDocumentPopup: false,
                })
              }
            >
              X
            </span>
            <h3 id='simple-modal-title'>Unsigned Agreement</h3>
            <div className='viewWidthHeight'>
              <iframe
                title='unsignedDocument'
                className='viewWidthHeight'
                type='application/pdf'
                src={this.state.unsignedAgreementDocumentLink}
              ></iframe>
              {/* <embed
                className="viewWidthHeight"
                type="application/pdf"
                src={this.state.unsignedAgreementDocumentLink}
              ></embed> */}

              <FormControl
                fullWidth={true}
                className='forgotPwdLink'
                onClick={this.handleClose}
                style={{
                  marginBottom: '20px',
                  textAlign: 'center',
                  boxOrient: '0',
                  display: 'block',
                  fontSize: '20',
                }}
              >
                Cancel
              </FormControl>
            </div>
          </div>
        </Grid>
      </Grid>
    );

    const _code = parseInt(this.props.match.params.code);
    const _class =
      _code === 3
        ? 'investment'
        : _code === 4
        ? 'veterinary'
        : _code === 6
        ? 'grower'
        : _code === 7
        ? 'buyer'
        : _code === 8
        ? 'agent'
        : _code === 10
        ? 'offtaker'
        : _code === 11
        ? 'technologyprovider'
        : _code === 12
        ? 'insuranceprovider'
        : _code === 13
        ? 'logisticsprovider'
        : _code === 9
        ? 'merchant'
        : 'default';

    return (
      <>
        <Container maxWidth={false} className={'signinPageContainer ' + _class}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={11}
              sm={5}
              md={4}
              lg={3}
              className='signupOuterContainer'
            >
              <div className='loginContainer'>
                <form onSubmit={this.doSignup}>
                  <h2>Grow & Become Rich</h2>

                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='abnId'>CBN Id *</InputLabel>
                    <Select
                      id='userType'
                      name='userType'
                      value={this.state.assetDeveloperValue}
                      onChange={(evt) => {
                        this.setState({
                          assetDeveloperValue: evt.target.value,
                        });
                      }}
                    >
                      {this.state.joinwithus.map((user, index) => {
                        return (
                          <MenuItem value={user.username} key={index}>
                            {user.username}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id='userType'>User Type *</InputLabel>
                    <Select
                      id='userType'
                      name='userType'
                      value={this.state.allUserTypes}
                      onChange={(evt) => {
                        this.setState({ allUserTypes: evt.target.value });
                      }}
                    >
                    
                      <MenuItem value='Logistics-Manager'>Logistics-Manager</MenuItem>

                      <MenuItem value='Logistics-TransportUser'>
                      Logistics-TransportUser
                      </MenuItem>
                      <MenuItem value='Driver'>Driver</MenuItem>
                      
                    </Select>
                  </FormControl>
                 {/*  <FormControl fullWidth={true} className="loginFields">
                    <InputLabel htmlFor="userType">User Type :</InputLabel>
                    <Select
                      id="userType"
                      name="userType"
                      type="text"
                      value={this.state.allUserTypes}
                      onChange={(evt) => {
                        
                        this.setState({ allUserTypes: evt.target.value });
                        
                      }}
                      
                    >
                      {this.state.allUserTypesList.length > 0 &&
                        this.state.allUserTypesList.map((users, index) => (
                          <MenuItem key={`${index}`} value={users.usertype}>
                            {users.usertype}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='username'>
                      Username/Email Address *
                    </InputLabel>
                    <Input
                      id='username'
                      name='username'
                      type='email'
                      startAdornment={
                        <InputAdornment position='start'>
                          <AccountCircleIcon />
                        </InputAdornment>
                      }
                      require='true'
                      onChange={this.onFieldChange}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='firstname'>First Name *</InputLabel>
                    <Input
                      id='firstname'
                      name='firstname'
                      type='text'
                      startAdornment={
                        <InputAdornment position='start'>
                          <AssignmentIndIcon />
                        </InputAdornment>
                      }
                      require='true'
                      onChange={this.onFieldChange}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='lastname'>Last Name *</InputLabel>
                    <Input
                      id='lastname'
                      name='lastname'
                      type='text'
                      startAdornment={
                        <InputAdornment position='start'>
                          <i className='material-icons'>face</i>
                        </InputAdornment>
                      }
                      require='true'
                      onChange={this.onFieldChange}
                    />
                  </FormControl>

                  <FormControl fullWidth={true} className='loginFields'>
                    <InputLabel htmlFor='userphone'>Phone Number *</InputLabel>
                    <Input
                      id='userphone'
                      name='userphone'
                      type='text'
                      startAdornment={
                        <InputAdornment position='start'>
                          <i className='material-icons'>phone</i>
                        </InputAdornment>
                      }
                      require='true'
                      onChange={this.onFieldChange}
                    />
                  </FormControl>

                  <FormControlLabel
                    className='rememberMe'
                    control={
                      <Checkbox
                        color='primary'
                        value={this.state.privecyCheck}
                        onChange={this.onPrivecyChange}
                      ></Checkbox>
                    }
                    label={
                      <a
                        className='anchorTagStyle'
                        href='http://localhost:3001/terms'
                      >
                        I accept the Terms of Use & Privacy Policy.
                      </a>
                    }
                  ></FormControlLabel>

                  <FormControl fullWidth={true}>
                    {this.state.isLoading === false && (
                      <Button
                        variant='contained'
                        color='primary'
                        className='loginBtn'
                        type='submit'
                        disabled={
                          (this.state.username !== '') &
                          (this.state.password !== '')
                            ? false
                            : true
                        }
                      >
                        Sign Up
                      </Button>
                    )}
                    {this.state.isLoading === true && (
                      <Button
                        variant='contained'
                        color='primary'
                        className='loginBtn'
                        type='button'
                        disabled={true}
                      >
                        <CircularProgress className='loginLoader' />
                      </Button>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth={true}
                    className='forgotPwdLink'
                    onClick={this.goToSignin}
                  >
                    Already have an account? SignIn here
                  </FormControl>

                  <FormControl
                    fullWidth={true}
                    className='forgotPwdLink'
                    onClick={this.goToHome}
                    style={{ marginBottom: '15px' }}
                  >
                    Back Home?
                  </FormControl>
                </form>
              </div>
            </Grid>
          </Grid>

          <NotificationContainer />
        </Container>
        <Modal
          open={this.state.viewUnsignedDocumentPopup}
          onClose={this.handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          {unsignedDocumentModalBody}
        </Modal>
      </>
    );
  }
}
