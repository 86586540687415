import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
} from '@mui/material';
import {
  colorBox,
  innerRight,
  innerRightContent,
  modalInnerBox,
  modalOuterBox,
} from './qrStyle';
import formFields from './formfields';
import QRModelForm from './QRModelForm';
import { ChromePicker } from 'react-color';
import { ColorPicker } from './ColorPicker';
import MainService from '../../Common/MainService';
import PropTypes from 'prop-types';

const QRBuilder = ({ qrFieldData, qrResponseHandler }) => {
  // Get profile details
  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  // State for modal
  const [open, setOpen] = useState(true);

  // user type
  const [userType, setUserType] = useState('');

  // State for color picker
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorType, setColorType] = useState('');
  const [color, setColor] = useState({ fColor: '#ffffff', bColor: '#000000' });

  // State for qr data
  const [qrData, setQrData] = useState({});

  // qr code error status
  const [qrCodeError, setQrcodeError] = useState(false);
  const [qrCodeSuccess, setQrcodeSuccess] = useState(false);
  const [errorMesg, setErrorMesg] = useState('');

  // get user type
  useEffect(() => {
    const getUserType = async () => {
      const response = await MainService.getConsumerUserType(
        parentId,
        username
      );
      setUserType(response);
    };
    getUserType();
  }, []);

  // state for qr data

  useEffect(() => {
    const defaultData = {
      iwidth: '200',
      fcolor: 'ffffff',
      bcolor: '000000',
      cadminid: parentId,
      bname: username,
      subOrg: suborgid ?? '',
    };
    const { type, ...rest } = qrFieldData || {};

    if (!qrData?.type || qrData.type === qrFieldData?.type) {
      setQrData({
        ...rest,
        ...defaultData,
        type: type,
      });
    } else if (qrFieldData?.type !== qrData?.type) {
      setQrData({
        ...defaultData,
        type: qrData?.type,
      });
    }
  }, [qrData.type, qrFieldData]);

  // Function to handle color picker toggle
  const toggleColorPicker = (event, props) => {
    event.stopPropagation();
    setColorType(props);
    setDisplayColorPicker(!displayColorPicker);
  };

  // Function to handle color change
  const colorChangeHandler = (newColor, colorType) => {
    setColor((prevColor) => ({ ...prevColor, [colorType]: newColor.hex }));
  };

  // Function to handle modal open
  const modalOpenHandler = () => setOpen(true);

  // Function to handle modal close
  const modalCloseHandler = () => setOpen(false);

  // Function to handle user input change
  const userInputChangeHandler = (event) => {
    const { name, value } = event.target;
    setErrorMesg('');
    setQrData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to generate QR code
  const generateQrcodeHandler = async () => {
    setQrcodeError(false);
    setQrcodeSuccess(false);
    setErrorMesg('');
    const { version, ...restData } = qrData;
    const qrPostData = {
      docId:
        qrData.type === 'asset'
          ? `${qrData.id}v${version}`
          : `${qrData[qrData.type + 'id']}v${version}`,

      ...restData,
    };

    const qrDataValidation = (obj, fieldArray) => {
      if (!fieldArray) return;
      for (let field of fieldArray) {
        if (!(field.fieldName in obj) || !obj[field.fieldName]) {
          console.log('error name', field.fieldValue);
          setErrorMesg(field.fieldValue);
          return false;
        }
        if (field.fieldName === 'link' || field.fieldName === 'url') {
          try {
            new URL(obj[field.fieldName]);
          } catch (error) {
            setErrorMesg(field.fieldValue);
            return false;
          }
        }
      }

      return true;
    };

    const isValidate = qrDataValidation(
      qrData,
      formFields[qrData.type + 'Fields']
    );

    if (isValidate) {
      try {
        const response = await MainService.QRcodeBuilder(
          parentId,
          username,
          userType,
          orgid,
          qrPostData
        );
        if (response.status === 200) {
          setQrcodeSuccess(true);
          qrResponseHandler(response);
        } else {
          console.log('qr code not generated', response.error);
          if (response.error === 409) {
            setErrorMesg('duplicate version');
          }
          throw new Error(response.message);
        }
      } catch (error) {
        console.log('error', error);
        setQrcodeError(true);
        qrResponseHandler(error);
      } finally {
        setTimeout(() => {
          setQrcodeError(false);
          setErrorMesg('');
        }, 5000);
      }
    }
  };

  // const qrResponseHandler = (response) => {
  //   console.log('response from qr', response);
  // };

  return (
    <Fragment>
      {/* <Button onClick={modalOpenHandler}>Open modal</Button> */}
      {/* <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={modalCloseHandler}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}> */}
      <Box sx={modalOuterBox}>
        <Box sx={modalInnerBox}>
          <QRModelForm
            formFields={formFields}
            qrData={qrData}
            qrCodeError={qrCodeError}
            qrCodeSuccess={qrCodeSuccess}
            errorMesg={errorMesg}
            generateQrcodeHandler={generateQrcodeHandler}
            inputChangeHandler={userInputChangeHandler}
          />
          {/* color picker  */}
          {/* <Box sx={innerRight} onClick={() => setDisplayColorPicker(false)}>
                <Box sx={innerRightContent}>
                  <TextField
                    id='standard-basic'
                    label='size'
                    variant='standard'
                  />
                  <TextField
                    id='standard-basic'
                    label='size'
                    variant='standard'
                  />
                  <ColorPicker
                    title={'Foreground Color'}
                    color={color.fColor}
                    toggleColorPicker={(event) =>
                      toggleColorPicker(event, 'fColor')
                    }
                  />
                  <ColorPicker
                    title={'Foreground Color'}
                    color={color.bColor}
                    toggleColorPicker={(event) =>
                      toggleColorPicker(event, 'bColor')
                    }
                  />
                  <Box sx={colorBox} onClick={(e) => e.stopPropagation()}>
                    {displayColorPicker && (
                      <ChromePicker
                        color={color[colorType]}
                        onChange={(newColor) =>
                          colorChangeHandler(newColor, colorType)
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Box> */}
        </Box>
      </Box>
      {/* </Fade>
      </Modal> */}
    </Fragment>
  );
};

// Define prop types
QRBuilder.propTypes = {
  qrFieldData: PropTypes.object.isRequired,
  qrResponseHandler: PropTypes.func,
};

// Define default props
QRBuilder.defaultProps = {
  qrResponseHandler: (response) => {
    console.log('QR Response:', response);
  },
};

export default QRBuilder;
