import React, { useState, useEffect } from "react";
import { 
  Paper, 
  Button, 
  Grid, 
  Container, 
  Typography 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { Dashboard as DashboardIcon } from "@material-ui/icons";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import MainService from "../../Common/MainService";
import MyTrips from "../DriverComponents/MyTrips";
import MyBookings from "../DriverComponents/MyBookings";
import { AppWidgetSummary } from "../../../sections/@dashboard/app";

// Define styles using makeStyles
const useStyles = makeStyles({
  paper: {
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  clickablePaper: {
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    height: "100px",
    background: "white",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      background: "linear-gradient(45deg, black, transparent)",
      color: "white",
      transition: "all 0.3s ease-out",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    "& svg": {
      marginRight: "0.5rem",
    },
  },
  dashboardTitle: {
    marginBottom: "2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  sectionTitle: {
    marginBottom: "1rem",
    fontWeight: "bold",
  },
  // Media queries
  "@media (max-width: 599px)": {
    sectionTitle: {
      fontSize: "1rem",
    },
  },
  backButton: {
    marginBottom: "1rem",
  },
});

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export default function DashbordForDriverComponent() {
  const theme = useTheme();
  const classes = useStyles();

  // Consolidated view state: 'dashboard', 'trips', 'bookings'
  const [view, setView] = useState("dashboard");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [store, setStore] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  // Initialize startDate and endDate to current date and seven days later
  useEffect(() => {
    const currentDate = new Date();
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(currentDate.getDate() + 7);

    const formattedStartDate = formatDate(currentDate);
    const formattedEndDate = formatDate(sevenDaysLater);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  // Fetch booking counts on component mount
  useEffect(() => {
    getAllOrderCount();
  }, []);

  // Function to fetch booking counts
  const getAllOrderCount = async () => {
    try {
      const data = await MainService.getBookingCount(username, parentId);
      console.log("Booking Count Data:", data);
      setHomeData(data.bookingCount);
    } catch (error) {
      console.error("Error fetching booking count:", error);
      setError("Failed to fetch booking counts.");
    }
  };

  // Function to fetch weekly driver schedule
  const getWeeklyDriverSchedule = async () => {
    setLoading(true);
    setError(null);
    try {
      const urls = `fromDate=${startDate}&toDate=${endDate}&start=0&limit=100`;
      const data = await MainService.getDriverSchedule(username, urls);
      console.log("Driver Schedule Data:", data);
      setStore(data.tripList || []);
    } catch (error) {
      console.error("Error fetching driver schedule:", error);
      setError("Failed to fetch driver schedule.");
    } finally {
      setLoading(false);
    }
  };

  // Handle switching views
  const handleViewChange = (newView) => {
    if (newView === "trips") {
      getWeeklyDriverSchedule();
    }
    setView(newView);
  };

  // Handle back action
  const handleBack = () => {
    setView("dashboard");
  };

  return (
    <Container maxWidth="xl">
      <div
        className="main-wrapper-container"
        style={{ display: "block" }}
      >
        <Grid container spacing={3}>
          {/* Render based on current view */}
          {view === "dashboard" && (
            <>
              {/* Welcome Paper */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <DashboardIcon fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ marginLeft: "0.5rem" }}>
                        Welcome to Dashboard
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* App Widget Summaries */}
              <Grid item xs={12} sm={4}>
                <AppWidgetSummary
                  title="Trip Count"
                  total={homeData.tripCount || 0}
                  icon={"ic:baseline-drive-eta"}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <AppWidgetSummary
                  title="Booking Count"
                  total={homeData.BookingCount || 0}
                  color="info"
                  icon={"material-symbols:order-approve-outline"}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <AppWidgetSummary
                  title="Total Earning"
                  total={homeData.totalAmountCollected || 0}
                  color="warning"
                  icon={"nimbus:money"}
                />
              </Grid>

              {/* Action Papers */}
              {/* <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.clickablePaper}
                  onClick={() => handleViewChange("trips")}
                >
                  <Typography
                    variant="h6"
                    className={classes.title}
                    gutterBottom
                  >
                    UPCOMING TRIPS FOR CURRENT WEEK
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.clickablePaper}
                  onClick={() => handleViewChange("bookings")}
                >
                  <Typography
                    variant="h6"
                    className={classes.title}
                    gutterBottom
                  >
                    ALL BOOKINGS
                  </Typography>
                </Paper>
              </Grid> */}
            </>
          )}

          {/* MyTrips View */}
          {view === "trips" && (
            <>
              {/* Back Button */}
              {/* <Grid item xs={12} className={classes.backButton}>
                <Button 
                  variant="outlined" 
                  startIcon={<ArrowBackOutlinedIcon />} 
                  onClick={handleBack}
                >
                  Back to Dashboard
                </Button>
              </Grid> */}

              {/* Display Loading or Error */}
              {loading && (
                <Grid item xs={12}>
                  <Typography variant="h6">Loading trips...</Typography>
                </Grid>
              )}
              {error && (
                <Grid item xs={12}>
                  <Typography variant="h6" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}

              {/* MyTrips Component */}
              {!loading && !error && (
                <Grid item xs={12}>
                  <MyTrips
                    startDate={startDate}
                    endDate={endDate}
                    store={store}
                    handleBack={handleBack}
                  />
                </Grid>
              )}
            </>
          )}

          {/* MyBookings View */}
          {view === "bookings" && (
            <>
              {/* Back Button */}
              {/* <Grid item xs={12} className={classes.backButton}>
                <Button 
                  variant="outlined" 
                  startIcon={<ArrowBackOutlinedIcon />} 
                  onClick={handleBack}
                >
                  Back to Dashboard
                </Button>
              </Grid> */}

              {/* MyBookings Component */}
              <Grid item xs={12}>
                <MyBookings handleBack={handleBack} />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Container>
  );
}
