import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NotificationManager from "react-notifications/lib/NotificationManager";
import MatTable from "../../../../../Containers/Table/Table";
import MainService from "../../../Common/MainService";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#bf42f5",
    },
  },
});

const fields = [
  "",
  "Asset Id",
  "Asset Status",
  "Fencing Type",
  "Last Modified",
];

const VehicleManagement = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState({ assets: [] });
  const [selectedItem, setSelectedItem] = useState("");
  const [assetCount, setAssetCount] = useState({});
  const [searchText, setSearchText] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("");
  const [loading, setLoading] = useState(true);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  useEffect(() => {
    getVehicleList();
    getAssetsCountInfo();
  }, []);

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = async () => {
    try {
      const data = await MainService.getConsumerUserType(parentId, username);
      setUserTypeValue(data);
    } catch (e) {
      NotificationManager.error(e);
    }
  };

  const getVehicleList = async () => {
    setLoading(true); // Set loading to true while fetching data
    try {
      const { assets } = await MainService.getVehicleList(parentId,username);
      const assetsWithMappedDevice = assets.map((asset) => ({
        ...asset,
        coordinates: `Lat: ${asset.latitude}, Lng: ${asset.longitude}`,
        mappedDevice: asset.points.length > 0 ? asset.points[0].endnode : "N/A",
      }));

      const filteredAssets = searchText
        ? assetsWithMappedDevice.filter((asset) =>
            asset.assetId.includes(searchText)
          )
        : assetsWithMappedDevice;

      setVehicleDetails({ assets: filteredAssets });
    } catch (e) {
      NotificationManager.error("Error fetching vehicle data");
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const onSelect = (item) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === item ? "" : item
    );
  };

  const handleModifyClick = () => {
    if (props.onModifyClick && selectedItem) {
      props.onModifyClick(selectedItem);
    }
  };

  const handleClick = () => {
    if (props.onUpdateClick && selectedItem) {
      props.onUpdateClick(selectedItem);
    }
  };

  const deleteGeofence = async () => {
    if (!selectedItem) {
      return;
    }

    setLoading(false); // Set loading to true while performing delete operation
    try {
      const response = await MainService.delGeofence(username, selectedItem);
      if (response === "Geo Fencing Removed") {
        const updateData = {
          geofence: "none",
        };
        await MainService.updateGeofenceOfAssets(
          parentId,
          username,
          updateData,
          selectedItem
        );
        NotificationManager.success("Geofence deleted successfully");
        // getVehicleList(); // Refresh the list after deletion
      }
    } catch (e) {
      NotificationManager.error("Error deleting geofence");
    } finally {
      setLoading(false); // Set loading to false after the operation
    }
  };

  const handleDelete = () => {
    deleteGeofence();
  };

  const getAssetsCountInfo = async () => {
    const { assetCount } = await MainService.getAssetsCountInfo(parentId,username);
    setAssetCount(assetCount);
    console.log(assetCount, ".....assetCount...........");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #ddd",
          marginBottom: theme.spacing(2),
          paddingBottom: theme.spacing(1),
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="#1976d2"
          sx={{
            textTransform: "uppercase",
            letterSpacing: "2px",
          }}
        >
           GEOFENCE MANAGEMENT
        </Typography>
      </Box>

      <div className="container" id="mainContentList">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Box>
            <Button
              id="basic-button"
              aria-controls={selectedItem ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={selectedItem ? "true" : undefined}
              onClick={handleModifyClick}
              variant="outlined"
              disabled={!selectedItem}
              style={{ margin: "5px" }}
            >
              Add
            </Button>

            <Button
              variant="outlined"
              onClick={handleClick}
              disabled={!selectedItem}
              style={{ margin: "5px" }}
            >
              Update
            </Button>

            <Button
              variant="outlined"
              onClick={handleDelete}
              disabled={!selectedItem}
              style={{ margin: "5px" }}
            >
              Delete
            </Button>
          </Box>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box sx={{ marginTop: "1rem" }}>
            <MatTable
              fields={fields}
              rows={vehicleDetails.assets}
              keys={["assetId", "assetStatus", "geoFence", "lastModifiedData"]}
              total={assetCount.totalCount}
              unique="assetId"
              onSelect={onSelect}
              selected={selectedItem}
            />
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default VehicleManagement;
