const formFields = {
  // Product fields

  productFields: [
    { fieldName: 'version', fieldValue: 'Version' },
    { fieldName: 'batchid', fieldValue: 'Batch ID' },
    { fieldName: 'productid', fieldValue: 'Product ID' },
    { fieldName: 'origin', fieldValue: 'Origin' },
    { fieldName: 'price', fieldValue: 'Price' },
    { fieldName: 'unit', fieldValue: 'Unit' },
    { fieldName: 'link', fieldValue: 'Link' },
  ],

  // // Site fields
  siteFields: [
    { fieldName: 'version', fieldValue: 'Version' },
    { fieldName: 'sitename', fieldValue: 'Site Name' },
    { fieldName: 'sitetype', fieldValue: 'Site Type' },
    { fieldName: 'siteid', fieldValue: 'Site ID' },
    { fieldName: 'url', fieldValue: 'Link' },
  ],

  // Asset fields
  assetFields: [
    { fieldName: 'version', fieldValue: 'Version' },
    { fieldName: 'assetverticalid', fieldValue: 'Assetvertical ID' },
    { fieldName: 'avname', fieldValue: 'Asset vertical Name' },
    { fieldName: 'atype', fieldValue: 'Asset Type' },
    { fieldName: 'modelid', fieldValue: 'Model ID' },
    { fieldName: 'id', fieldValue: 'Asset ID' },
    { fieldName: 'assetFriendlyName', fieldValue: 'Asset Friendly name' },
    { fieldName: 'acategory', fieldValue: 'Asset Category' },
    { fieldName: 'url', fieldValue: 'Link' },
  ],

  // // Model fields
  modelFields: [
    { fieldName: 'version', fieldValue: 'Version' },
    { fieldName: 'assetverticalid', fieldValue: 'Assetvertical ID' },
    { fieldName: 'avname', fieldValue: 'Asset vertical Name' },
    { fieldName: 'acategory', fieldValue: 'Asset Category' },
    { fieldName: 'atype', fieldValue: 'Asset Type' },
    { fieldName: 'modelid', fieldValue: 'Model ID' },
    { fieldName: 'url', fieldValue: 'Link' },
  ],
};

export default formFields;
