// import { format, getTime, formatDistanceToNow } from 'date-fns';
// import React from 'react';

// // ----------------------------------------------------------------------

// export function fDate(date, newFormat) {
//   const fm = newFormat || 'dd MMM yyyy';

//   return date ? format(new Date(date), fm) : '';
// }

// export function fDateTime(date, newFormat) {
//   const fm = newFormat || 'dd MMM yyyy p';

//   return date ? format(new Date(date), fm) : '';
// }

// export function fTimestamp(date) {
//   return date ? getTime(new Date(date)) : '';
// }

// export function fToNow(date) {
//   return date
//     ? formatDistanceToNow(new Date(date), {
//         addSuffix: true,
//       })
//     : '';
// }

import dayjs from 'dayjs';

export const formatDate = (props) => {
  const formattedvalue = dayjs(props).format('YYYY-MM-DD');
  return formattedvalue;
};
