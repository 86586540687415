import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import InvoiceTable from '../../Invoicecomponents/components/InvoiceTable';

import AddressForm from '../../Invoicecomponents/components/AddressForm';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { event } from 'react-ga';
import {
  formattedValue,
  uniqueNumber,
} from '../../Invoicecomponents/utils/genrateUniqueNumber';
import MainService from '../../../Common/MainService';
import dayjs from 'dayjs';
import { formatDate } from '../../../../../utils/formatTime';
import { transpoterReducer } from '../../../../../reducers/transpoterReducer';
import CommanTable from '../../Invoicecomponents/components/CommanTable';

const tempLorryReceiptListDetails = [
  { id: 'lrNo', label: 'Lorry Receipt' },
  { id: 'buyerName', label: 'Buyer Name' },
  { id: 'date', label: 'Date' },
  { id: 'status', label: 'Status' },
  { id: 'viewLink', label: 'View Link', type: 'button' },
];

const logisticProviderDetails = [
  { id: 'bankAccNo', label: 'Account No.', type: 'number' },
  { id: 'bankName', label: 'Bank Name' },
  { id: 'branchName', label: 'Branch Name' },
  { id: 'bank_IFSC_code', label: 'IFSC Code' },
  { id: 'bank_swift_code', label: 'SWIFT Code' },
];

const initialAddressDetails = {
  name: '',
  addressDetails: '',
  countryName: '',
  countryIsoCode: '',
  stateName: '',
  stateIsoCode: '',
  cityName: '',
  contactno: '',
  gstin: '',
};

const initialLogisticProviderDetails = {
  name: '',
  addressDetails: '',
  countryName: '',
  countryIsoCode: '',
  stateName: '',
  stateIsoCode: '',
  cityName: '',
  contactno: '',
  gstin: '',
  transporterId: '',
  tranPanNo: '',
  tranCin: '',
  transporterEmail: '',
  transporterWebsite: '',
  tranFssLicNo: '',
};

const initialBookingState = {
  bookingID: '',
  invoiceNo: '',
  lrNo: '',
};

const tempSupplier = {
  buyerName: '',
  buyer_cell: {
    buyer_cell1: '',
    buyer_cell2: '',
  },
  buyer_email: '',
  buyer_website: '',
  buyer_panNo: '',
  buyer_gstin: '',
  buyerAdressline1: '',
  buyerAdressline2: '',
  buyerAdressline3: '',
};

const LogisticProviderForm = ({ addressDetails, dispatch, isSubmited }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', '&>*': { flex: 1 } }}>
        <Box
          sx={{
            display: 'flex',
            '&>*': { flex: 1 },
            gap: 2,
          }}
        >
          <Box>
            <AddressForm
              addressDetails={addressDetails}
              dispatch={dispatch}
              isSubmited={isSubmited}
            />
          </Box>
          <Box sx={{ background: '#0000000f', p: 1 }}>
            <Input
              error={isSubmited && addressDetails?.transporterId === ''}
              placeholder='Please enter tranpoter id'
              name='transporterId'
              id='transporterId'
              value={addressDetails.transporterId}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />
            <Input
              error={isSubmited && addressDetails?.tranPanNo === ''}
              placeholder='Please enter PAN No'
              name='tranPanNo'
              id='tranPanNo'
              value={addressDetails.tranPanNo}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />
            <Input
              error={isSubmited && addressDetails?.tranCin === ''}
              placeholder='Please enter CIN'
              name='tranCin'
              id='tranCin'
              value={addressDetails.tranCin}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />
            <Input
              error={isSubmited && addressDetails?.tranFssLicNo === ''}
              placeholder='Please enter FSS LIC No'
              name='tranFssLicNo'
              id='tranFssLicNo'
              value={addressDetails.tranFssLicNo}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />
            <Input
              error={isSubmited && addressDetails.transporterEmail === ''}
              placeholder='Please enter email'
              name='transporterEmail'
              id='transporterEmail'
              value={addressDetails.transporterEmail}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />

            <Input
              placeholder='Please enter website'
              name='transporterWebsite'
              id='transporterWebsite'
              value={addressDetails.transporterWebsite}
              multiline
              readOnly={false}
              sx={{ display: 'flex' }}
              onChange={(event) =>
                dispatch({
                  type: 'address',
                  payload: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// hooks
// Fetch invoice list and handle loading and errors
export const useFetchChallanList = (parentId, username, orgid, suborgid) => {
  // fetching qauotation list
  const {
    isLoading,
    error,
    data: response,
  } = useQuery({
    queryKey: ['lorryReceipt'],
    queryFn: () =>
      MainService.documentListApi(
        parentId,
        orgid,
        suborgid,
        username,
        'lorryReceipt'
      ),
  });

  const { challanListData } = useMemo(() => {
    if (!response) {
      return { challanListData: [] };
    }
    if (response) {
      const responseData = response.data.docs.map((item) => {
        const formattedDate = format(
          new Date(item.docCreateDate),
          'dd-MM-yyyy'
        );
        return {
          lrNo: item.docId,
          buyerName: item.docReceiverName,
          date: formattedDate,
          status: item.status,
          viewLink: item.LocationUrl,
        };
      });
      return { challanListData: responseData };
    }
  });

  return useMemo(
    () => ({
      isLoading,
      error,
      challanListData,
    }),
    [isLoading, error, challanListData]
  );
};

const LorryReceipt = ({ userDetails }) => {
  const todayDate = new Date();
  const docGeneratedDate = dayjs(todayDate).format('YYYY-MM-DD');
  const {
    user: { parentId, username, orgid, suborgid },
  } = MainService.getLocalData();

  const [activeListContent, setActiveListContent] =
    useState('lorryreceiptlist');
  const [lorryReceiptList, setLorryReceiptList] = useState([]);

  const [consignorAddressDetails, consignorDispatch] = useReducer(
    transpoterReducer,
    initialAddressDetails
  );
  const [consigneeAddressDetails, consigneeDispatch] = useReducer(
    transpoterReducer,
    initialAddressDetails
  );
  const [logisticProviderDetails, logisticProviderDispatch] = useReducer(
    transpoterReducer,
    initialLogisticProviderDetails
  );

  const [bookingDetails, bookingProviderDispatch] = useReducer(
    transpoterReducer,
    initialBookingState
  );
  const [transportType, tranpotTypeDispatch] = useReducer(transpoterReducer, {
    transportType: 'Road Transport',
  });
  const [copyType, copyTypeDispatch] = useReducer(transpoterReducer, {
    copyType: 'all',
  });

  const [goodsItemsDetails, goodsItemsDetailsDispatch] = useReducer(
    transpoterReducer,
    {
      description: '',
      topayGodownValue: 0,
      quantity: 0,
      weight: 0,
      rate_per_kg: 0,
    }
  );

  const [deliveryChargeDetails, deliveryChargeDetailsDispatch] = useReducer(
    transpoterReducer,
    {
      other_charges: 0,
      stationaryCharges: 0,
      handlingCharges: 0,
      vsc_fov_Charges: 0,
      greenCharges: 0,
      tollCharges: 0,
    }
  );

  const activeContentSetHandler = (contentName) => {
    setActiveListContent(contentName);
  };

  const [totalChages, setTotalChages] = useState(0);

  const [errorName, setErrorName] = useState('');
  const [isSubmited, setIsSubmited] = useState(false);
  const [errorSnackBarMesg, setErrorSnackBarMesg] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [imageSize, setImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  useEffect(() => {
    const goodsCharges =
      parseFloat(goodsItemsDetails.weight) *
      parseFloat(goodsItemsDetails.rate_per_kg);
    const deliveryCharges = Object.values(deliveryChargeDetails).reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    const totalCharges = goodsCharges + deliveryCharges;
    setTotalChages(totalCharges);
  }, [goodsItemsDetails, deliveryChargeDetails]);

  const consignorAddressForm = useMemo(
    () => (
      <AddressForm
        addressDetails={consignorAddressDetails}
        dispatch={consignorDispatch}
        isSubmited={isSubmited}
      />
    ),
    [consignorAddressDetails, consignorDispatch, isSubmited]
  );

  const consigneeAddressForm = useMemo(
    () => (
      <AddressForm
        addressDetails={consigneeAddressDetails}
        dispatch={consigneeDispatch}
        isSubmited={isSubmited}
      />
    ),
    [consigneeAddressDetails, consigneeDispatch, isSubmited]
  );

  const logisticProviderForm = useMemo(
    () => (
      <LogisticProviderForm
        addressDetails={logisticProviderDetails}
        dispatch={logisticProviderDispatch}
        isSubmited={isSubmited}
      />
    ),
    [logisticProviderDetails, logisticProviderDispatch, isSubmited]
  );

  useEffect(() => {
    if (userDetails?.buyerName) {
      // Dispatch the new action type to update the logistic provider details
      logisticProviderDispatch({
        type: 'setLogisticProviderDetails',
        payload: { ...userDetails },
      });
    }
  }, [userDetails]);

  //image file change handler
  const imageFileChangeHandler = (event) => {
    console.log('click');

    setImageFile(event.target.files[0]);
    const file = event.target.files[0];
    setImageSize(false);
    if (file?.size > 1024 * 1024) {
      setImageSize(true);
      setTempImage(null);
      setImageFile(null);
      event.target.value = '';
      return;
    }

    // Check if file type is an image
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validImageTypes.includes(file.type)) {
      setValidImageFormat(true);
      setTempImage(null);
      setImageFile(null);
      event.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const imageChangeHandler = () => {
    const fileInput = document.getElementById('upload-photo');
    console.log('click', fileInput);
    fileInput.click();
  };

  const documentFormDataConstructed = () => {
    function hasEmptyValue(obj, objName) {
      for (const key in obj) {
        if (!obj[key]) {
          return objName;
        }
      }
      return null;
    }

    const hasInvalidValues = (obj, excludeKeys = []) => {
      console.log('object', obj);
      setErrorSnackBarMesg('');
      const excludeSet = new Set(excludeKeys);

      return Object.entries(obj).some(
        ([key, value]) =>
          !excludeSet.has(key) &&
          (value === '' || value === null || value === undefined)
      );
    };
    const optionalField = ['gstin', 'transporterWebsite'];

    // const emptyObjectName =
    //   hasEmptyValue(logisticProviderDetails, 'Logistic Provider Details') ||
    //   hasEmptyValue(consignorAddressDetails, 'Consignor Details') ||
    //   hasEmptyValue(consigneeAddressDetails, 'Consignee Details') ||
    //   hasEmptyValue(bookingDetails, 'Bookings Details') ||
    //   hasEmptyValue(goodsItemsDetails, 'Booking charges') ||
    //   hasEmptyValue(deliveryChargeDetails, 'Booking charges');

    const isconsignorAddressDetailsEmpty = hasInvalidValues(
      consignorAddressDetails,
      optionalField
    );
    const islogisticProviderDetailsEmpty = hasInvalidValues(
      logisticProviderDetails,
      optionalField
    );
    const isconsigneeAddressDetailsEmpty = hasInvalidValues(
      consigneeAddressDetails,
      optionalField
    );
    const isbookingDetailsEmpty = hasInvalidValues(
      bookingDetails,
      optionalField
    );
    const isgoodsItemsDetailsEmpty = hasInvalidValues(
      goodsItemsDetails,
      optionalField
    );
    const isdeliveryChargeDetailsEmpty = hasInvalidValues(
      deliveryChargeDetails,
      optionalField
    );

    if (
      isconsignorAddressDetailsEmpty ||
      islogisticProviderDetailsEmpty ||
      isconsigneeAddressDetailsEmpty ||
      isbookingDetailsEmpty ||
      isgoodsItemsDetailsEmpty ||
      isdeliveryChargeDetailsEmpty
    ) {
      // setErrorSnackBarMesg(`${emptyObjectName} is empty`);
      setErrorSnackBarMesg(`Please enter the valid field.`);
      setIsSubmited(true);
      return { data: {}, formData: {} };
    }
    const lorryReceiptDataDetails = {
      supplierDetails: {
        supplierName: consignorAddressDetails.name || '',
        sup_cell: consignorAddressDetails.contactno || '',
        sup_gstin: consignorAddressDetails.gstin || 'NA',
        supplierAdressline1: consignorAddressDetails.addressDetails || '',
        supplierAdressline2:
          `${consignorAddressDetails.cityName} ${consignorAddressDetails.stateName} ${consignorAddressDetails.countryName}` ||
          '',
      },
      buyerDetails: {
        buyerName: consigneeAddressDetails.name || '',
        buyer_cell: consigneeAddressDetails.contactno || '',
        buyer_gstin: consigneeAddressDetails.gstin || 'NA',
        buyerAdressline1: consigneeAddressDetails.addressDetails || '',
        buyerAdressline2:
          `${consigneeAddressDetails.cityName} ${consigneeAddressDetails.stateName} ${consigneeAddressDetails.countryName}` ||
          '',
      },
      transporterDetails: {
        transporterCompanyName: logisticProviderDetails.name || '',
        transporterId: logisticProviderDetails.transporterId || '',
        tranPanNo: logisticProviderDetails.tranPanNo || '',
        tranCin: logisticProviderDetails.tranCin || '',
        transporterEmail: logisticProviderDetails.transporterEmail || '',
        transporterWebsite: logisticProviderDetails.transporterWebsite || 'NA',
        transportDate: formattedValue(),
        transporter_cell: logisticProviderDetails.contactno || '',
        tranFssLicNo: logisticProviderDetails.tranFssLicNo || '',
        transporterAdressline1: logisticProviderDetails.addressDetails || '',
        transporterAdressline2:
          `${logisticProviderDetails.cityName} ${logisticProviderDetails.stateName} ${logisticProviderDetails.countryName}` ||
          '',
      },
      bookingDetails: {
        bookingID: bookingDetails.bookingID || '',
        invoiceNo: bookingDetails.invoiceNo || '',
        lrNo: bookingDetails.lrNo || '',
      },
      deliveryDetails: {
        deliveryDestinationPlace: consigneeAddressDetails.cityName || '',
        deliveryDestinationState: consigneeAddressDetails.stateName || '',
        deliveryBranchPlace: consigneeAddressDetails.cityName || '',
        deliveryBranchState: consigneeAddressDetails.stateIsoCode || '',
      },
      transportType: transportType.transportType,

      goodsItemsDetails: goodsItemsDetails,

      deliveryChargeDetails: deliveryChargeDetails,

      termsDetails: {
        specificInstruction:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Etperferendis laudantium recusandae inventore quaerat libero, soluta doloremque fuga nam eaque iste veniam similique dicta',
        specialNotes:
          'Consigement booked in favour of libero, soluta doloremque fuga nam eaque iste veniam similique dicta',
        doorDeliveryNotes:
          'If booked on Door Delivery basis Delivery at ground floor only.',
      },
    };

    const contentInfo = {
      contentname: bookingDetails.lrNo,
      contentcreator: username,
      contentownerid: username,
      contentdescription: 'lorry receipt',
      expirydate: formattedValue(),
      assetdeveloperid: window.AppConfigData.EnterpriseId,
    };
    const generatedDateInfo = {
      docGeneratedDate: docGeneratedDate,
    };
    const trackQRImg = '';
    const transporterLogo = '';
    const data = {
      docId: bookingDetails.lrNo,
      networkId: window.AppConfigData.EnterpriseId,
      parentId: parentId,
      orgId: orgid,
      proxyId: '',
      docCreatorId: username,
      type: 'lorryReceipt',
      docCreatorName: username,
      docCreateDate: lorryReceiptDataDetails.transporterDetails.transportDate,
      docReceiverName: lorryReceiptDataDetails.buyerDetails.buyerName,
      status: 'created',
      lorryReceiptDataDetails,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(lorryReceiptDataDetails));
    formData.append('contentInfo', JSON.stringify(contentInfo));
    formData.append('generatedDateInfo', JSON.stringify(generatedDateInfo));
    formData.append('trackQRImg', trackQRImg);
    formData.append('transporterLogo', transporterLogo);
    return { data, formData };
  };

  const { isLoading, error, challanListData } = useFetchChallanList(
    parentId,
    username,
    orgid,
    suborgid
  );

  useEffect(() => {
    if (!isLoading) {
      setLorryReceiptList((prev) => [...prev, ...challanListData]);
    }
  }, [challanListData.length > 0]);

  const addChallanToList = async ({ data, formData }) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    try {
      const response = await MainService.saveDocumentsApi(
        parentId,
        orgid,
        suborgid,
        username,
        data
      );
      if (response?.status === 201) {
        try {
          const genResponse = await MainService.generateDocumentsApi(
            parentId,
            orgid,
            suborgid,
            username,
            formData,
            data.type
          );

          if (genResponse?.status === 200) {
            const tempLorryReceiptList = {
              viewLink: genResponse.data.LocationUrl,
              lrNo: bookingDetails.lrNo,
              buyerName: consigneeAddressDetails.name,
              date: genResponse.data.docGeneratedDate,
              status: genResponse.data.status,
            };
            setLorryReceiptList((prevList) => [
              ...prevList,
              tempLorryReceiptList,
            ]);
            setActiveListContent('lorryreceiptlist');
            setIsSubmited(false);
          }
        } catch (error) {
          console.log('error', error);
          setIsSubmited(false);
        }
      }
    } catch (error) {
      console.log('error', error.message);
      setIsSubmited(false);
    }
  };

  // if (isLoading) return <LoadingPage />;

  useEffect(() => {
    const newLrno = 'LR-' + uniqueNumber();
    bookingProviderDispatch({
      type: 'bookingDetails',
      payload: {
        name: 'lrNo',
        value: newLrno,
      },
    });
  }, [activeListContent]);

  const getDocumentDetailsHander = useCallback(async ({ receiptNo }) => {
    // setActiveListContent('addchallan');
    console.log('click', receiptNo);

    // console.log(' setActiveListContent(', challanNo, invoiceTypeDetails);
    // if (invoiceTypeDetails.invNo) {
    //   setActiveListContent('addchallan');
    //   // setDocumentStatus(challanStatus);
    // }
    setActiveListContent('addlorryreceipt');
  }, []);

  return (
    <>
      {activeListContent === 'lorryreceiptlist' && (
        <Box>
          <Typography
            sx={{
              fontWeight: '800',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
              textAlign: 'center',
              mb: '0.5rem',
              color: '#1976d2',
            }}
          >
            Lorry Receipt Management
          </Typography>
          <Divider sx={{ mb: '2rem', background: '#1976d2' }} />
          <InvoiceTable
            columnData={tempLorryReceiptListDetails}
            rowDataList={lorryReceiptList}
            documentDetailsHander={getDocumentDetailsHander}
          />

          <Stack>
            <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => activeContentSetHandler('addlorryreceipt')}
              >
                Add Lorry Receipt
              </Button>
            </ListItem>
          </Stack>
        </Box>
      )}
      {activeListContent === 'addlorryreceipt' && (
        <Box sx={{ p: { xs: '1rem 0rem', md: '0' } }}>
          <Box>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                textAlign: 'center',
                mb: '0.5rem',
                color: '#1976d2',
              }}
            >
              Lorry Receipt
            </Typography>
            <Divider sx={{ mb: '1rem', background: '#1976d2' }} />
          </Box>

          <Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  color: '#1976d2',
                  borderBottom: '1px solid',
                  mb: 1,
                }}
              >
                Logistic Provider Details
              </Typography>
            </Box>
            <Box>{logisticProviderForm}</Box>
          </Box>
          <Box sx={{ display: 'flex', my: 1, '&>*': { flex: 1 }, gap: 2 }}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    color: '#1976d2',
                    borderBottom: '1px solid',
                    mb: 1,
                  }}
                >
                  Consignor Details
                </Typography>
              </Box>
              <Box>{consignorAddressForm}</Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    color: '#1976d2',
                    borderBottom: '1px solid',
                    mb: 1,
                  }}
                >
                  Consignee Details
                </Typography>
              </Box>
              <Box>{consigneeAddressForm}</Box>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography>Bookings Details</Typography>
            <Box sx={{ display: 'flex', '&>*': { flex: 1 }, gap: 2 }}>
              <Box>
                <Typography>LR No.</Typography>
                <TextField
                  error={isSubmited && !bookingDetails?.lrNo}
                  sx={{ display: 'flex' }}
                  name='lrNo'
                  id='lrNo'
                  value={bookingDetails.lrNo}
                  onChange={(event) =>
                    bookingProviderDispatch({
                      type: 'bookingDetails',
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Box>
              <Box>
                <Typography>Booking Id.</Typography>
                <TextField
                  error={isSubmited && !bookingDetails?.bookingID}
                  sx={{ display: 'flex' }}
                  value={bookingDetails.bookingID}
                  name='bookingID'
                  id='bookingID'
                  onChange={(event) =>
                    bookingProviderDispatch({
                      type: 'bookingDetails',
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Box>
              <Box>
                <Typography>Invoice No.</Typography>
                <TextField
                  error={isSubmited && !bookingDetails?.invoiceNo}
                  sx={{ display: 'flex' }}
                  value={bookingDetails.invoiceNo}
                  name='invoiceNo'
                  id='invoiceNo'
                  onChange={(event) =>
                    bookingProviderDispatch({
                      type: 'bookingDetails',
                      payload: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Box>
              <Box>
                <Typography>Transportation Mode</Typography>
                <FormControl fullWidth>
                  <InputLabel id='receipttype'>Road</InputLabel>
                  <Select
                    labelId='receipttype'
                    id='receipttype'
                    value={transportType.transportType}
                    label='Receipt'
                    onChange={(event) =>
                      tranpotTypeDispatch({
                        type: 'transportType',
                        payload: event.target.value,
                      })
                    }
                  >
                    <MenuItem value='Road Transport'>Road</MenuItem>
                    <MenuItem value='Air transport'>Air</MenuItem>
                    <MenuItem value='Railway transport'>Rail</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography>Booking charges</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box
                sx={{ display: 'flex', flex: 1, gap: 1, '&>*': { flex: 1 } }}
              >
                <Box>
                  <Box>
                    <Typography>D.Value</Typography>
                    <TextField
                      error={
                        isSubmited && !goodsItemsDetails?.topayGodownValue > 0
                      }
                      name='topayGodownValue'
                      id='topayGodownValue'
                      value={goodsItemsDetails.topayGodownValue}
                      type='number'
                      onChange={(event) => {
                        goodsItemsDetailsDispatch({
                          type: 'goodsItemsDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Packages</Typography>
                    <TextField
                      error={isSubmited && !goodsItemsDetails?.quantity > 0}
                      name='quantity'
                      id='quantity'
                      type='number'
                      value={goodsItemsDetails.quantity}
                      onChange={(event) => {
                        goodsItemsDetailsDispatch({
                          type: 'goodsItemsDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Ch Weight</Typography>
                    <TextField
                      error={isSubmited && !goodsItemsDetails?.weight > 0}
                      name='weight'
                      id='weight'
                      type='number'
                      value={goodsItemsDetails.weight}
                      onChange={(event) => {
                        goodsItemsDetailsDispatch({
                          type: 'goodsItemsDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Rate / kg</Typography>
                    <TextField
                      error={isSubmited && !goodsItemsDetails?.rate_per_kg > 0}
                      name='rate_per_kg'
                      id='rate_per_kg'
                      type='number'
                      value={goodsItemsDetails.rate_per_kg}
                      onChange={(event) => {
                        goodsItemsDetailsDispatch({
                          type: 'goodsItemsDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography>Stationary charges</Typography>
                    <TextField
                      error={
                        isSubmited &&
                        !deliveryChargeDetails?.stationaryCharges > 0
                      }
                      name='stationaryCharges'
                      id='stationaryCharges'
                      type='number'
                      value={deliveryChargeDetails.stationaryCharges}
                      onChange={(event) => {
                        deliveryChargeDetailsDispatch({
                          type: 'deliveryChargeDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Hamali/Handling</Typography>
                    <TextField
                      error={
                        isSubmited &&
                        !deliveryChargeDetails?.handlingCharges > 0
                      }
                      name='handlingCharges'
                      id='handlingCharges'
                      type='number'
                      value={deliveryChargeDetails.handlingCharges}
                      onChange={(event) => {
                        deliveryChargeDetailsDispatch({
                          type: 'deliveryChargeDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Vsc/Fov charges</Typography>
                    <TextField
                      error={
                        isSubmited &&
                        !deliveryChargeDetails?.vsc_fov_Charges > 0
                      }
                      name='vsc_fov_Charges'
                      id='vsc_fov_Charges'
                      type='number'
                      value={deliveryChargeDetails.vsc_fov_Charges}
                      onChange={(event) => {
                        deliveryChargeDetailsDispatch({
                          type: 'deliveryChargeDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Wp/Sms/Ack Oth</Typography>
                    <TextField
                      error={
                        isSubmited && !deliveryChargeDetails?.other_charges > 0
                      }
                      name='other_charges'
                      id='other_charges'
                      type='number'
                      value={deliveryChargeDetails.other_charges}
                      onChange={(event) => {
                        deliveryChargeDetailsDispatch({
                          type: 'deliveryChargeDetails',
                          payload: {
                            name: event.target.name,
                            value: event.target.value,
                          },
                        });
                      }}
                      sx={{
                        flex: 1,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            display: 'none',
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                      }}
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Box>
                      <Typography>Green charges</Typography>
                      <TextField
                        error={
                          isSubmited && !deliveryChargeDetails?.greenCharges > 0
                        }
                        name='greenCharges'
                        id='greenCharges'
                        type='number'
                        value={deliveryChargeDetails.greenCharges}
                        onChange={(event) => {
                          deliveryChargeDetailsDispatch({
                            type: 'deliveryChargeDetails',
                            payload: {
                              name: event.target.name,
                              value: event.target.value,
                            },
                          });
                        }}
                        sx={{
                          flex: 1,
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              display: 'none',
                            },
                          '& input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                        }}
                        inputProps={{ min: 0 }}
                      />
                    </Box>
                    <Box>
                      <Typography>Toll charges</Typography>
                      <TextField
                        error={
                          isSubmited && !deliveryChargeDetails?.tollCharges > 0
                        }
                        name='tollCharges'
                        id='tollCharges'
                        type='number'
                        value={deliveryChargeDetails.tollCharges}
                        onChange={(event) => {
                          deliveryChargeDetailsDispatch({
                            type: 'deliveryChargeDetails',
                            payload: {
                              name: event.target.name,
                              value: event.target.value,
                            },
                          });
                        }}
                        sx={{
                          flex: 1,
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              display: 'none',
                            },
                          '& input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                        }}
                        inputProps={{ min: 0 }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 500 }}>
                      Total Charges
                    </Typography>
                    <TextField disabled value={totalChages} />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <TextField
                    sx={{ display: 'flex', flex: 1 }}
                    id='description'
                    name='description'
                    label='Item description'
                    multiline
                    rows={5.5}
                    variant='filled'
                    value={goodsItemsDetails.description}
                    onChange={(event) => {
                      goodsItemsDetailsDispatch({
                        type: 'goodsItemsDetails',
                        payload: {
                          name: event.target.name,
                          value: event.target.value,
                        },
                      });
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid',
                        display: 'inline-block',
                        height: '5rem',
                        width: '5rem',
                        mt: 1,
                      }}
                    >
                      {tempImage && (
                        <img
                          src={tempImage}
                          style={{ width: '100%', height: '100%' }}
                          onClick={imageChangeHandler}
                        />
                      )}
                      {!tempImage && (
                        <label
                          style={{
                            display: 'flex',
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}
                          htmlFor='upload-photo'
                        >
                          QR Code
                        </label>
                      )}
                      <Input
                        id='upload-photo'
                        name='upload-photo'
                        type='file'
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onChange={imageFileChangeHandler}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}
                >
                  {/* <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id='receipttype'>All</InputLabel>
                      <Select
                        labelId='receipttype'
                        id='receipttype'
                        value={copyType.copyType}
                        label='Receipt'
                        onChange={(event) => {
                          copyTypeDispatch({
                            type: 'copyType',
                            payload: event.target.value,
                          });
                        }}
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='consignor'>Consignor</MenuItem>
                        <MenuItem value='consignee'>Consignee</MenuItem>
                        <MenuItem value='driver'>Driver</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  <Button
                    variant='outlined'
                    onClick={() => activeContentSetHandler('lorryreceiptlist')}
                  >
                    cancel
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() =>
                      addChallanToList(documentFormDataConstructed())
                    }
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={errorSnackBarMesg}
        autoHideDuration={2000}
        onClose={() => setErrorSnackBarMesg('')}
        message={errorSnackBarMesg}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={imageSize}
        autoHideDuration={2000}
        onClose={() => setImageSize(false)}
        message={'image size is too large, please select below 1 mb.'}
        // action={snackbarActionHandler}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={validImageFormat}
        autoHideDuration={2000}
        onClose={() => setValidImageFormat(false)}
        message={
          ' Invalid image format. Please select a JPEG, PNG, or GIF image.'
        }
      />
    </>
  );
};

export default memo(LorryReceipt);
