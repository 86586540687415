import React, { useEffect, useState } from "react";
import RouteDetailsMap from "./RouteDetailsMap";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import MainService from "../../../Common/MainService";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTheme } from "@mui/material/styles";
import viewlefticon from './viewrouteimages/left-arrow.png'


const ViewRoute = () => {
  const location = useLocation();
  const [routeDetails, setRouteDetails] = useState(null);
  const { user } = MainService.getLocalData();
  const { username ,parentId} = user;
  const [open, setOpen] = useState(false);



  const history = useHistory();

  const theme = useTheme();
  useEffect(() => {
    // Check if the routeDetails are present in the state
    const { routeId } = location.state || {};

    // Fetch route details based on routeId
    const fetchRouteDetails = async () => {
      try {
        const routeDetailsData = await MainService.getDetailsofRouteId(
          parentId,
          username,
          routeId
        );
        setRouteDetails(routeDetailsData);
        console.log(routeDetailsData, "ROUTE DETAILS::::///");
      } catch (error) {
        console.log("Error fetching route details", error);
      }
    };

    // If routeId is available, fetch the details
    if (routeId) {
      fetchRouteDetails();
    }
  }, [location.state]);

  if (!routeDetails) {
    // If routeDetails are not yet fetched, you might want to render a loading state
    return <div>Loading...</div>;
  }

  const closeModelHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button onClick={() => history.push('/main/logisticManagerTripPlanner', {routePath:'viewRoute'})} >
         <img src={viewlefticon} alt="back icon"
         style={{ width: "2.5rem", height: "2.5rem" }}
         />
          
         
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            component="h1"
            style={{
              borderBottom: "2px solid #007BFF",
              padding: "10px",
              textAlign: "center",
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            ROUTE DETAILS
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              Route ID:
            </Typography>
            <Typography>{routeDetails.routeId}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              Route Name:
            </Typography>
            <Typography>{routeDetails.routeName}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              Start Location ID:
            </Typography>
            <Typography>{routeDetails.startLocId}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              End Location ID:
            </Typography>{" "}
            {routeDetails.endLocId}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              Created Date:
            </Typography>{" "}
            {routeDetails.createdDate}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" style={{ color: "#3ECF8E" }}>
              Last Modified Date:
            </Typography>{" "}
            {routeDetails.lastModifiedDate}
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <RouteDetailsMap routeGeopath={routeDetails.geoPath} />
      </Box>
    </>
  );
};

export default ViewRoute;
