export const transpoterReducer = (state, action) => {
  console.log('action action', action);
  switch (action.type) {
    case 'address': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'bookingDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'deliveryDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'transportType': {
      return {
        ...state,
        transportType: action.payload,
      };
    }
    case 'copyType': {
      return {
        ...state,
        copyType: action.payload,
      };
    }
    case 'goodsItemsDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value || 0,
      };
    }
    case 'deliveryChargeDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value || 0,
      };
    }
    case 'termsDetails': {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case 'setLogisticProviderDetails': {
      return {
        ...state,
        name: action.payload.buyerName,
        addressDetails: `${action.payload.buyerAdressline1} ${action.payload.buyerAdressline2} ${action.payload?.buyerAdressline3}`,
        contactno: action.payload.buyer_cell?.buyer_cell1,
        gstin: action.payload.buyer_gstin,
        tranPanNo: action.payload.buyer_panNo,
        transporterEmail: action.payload.buyer_email,
      };
    }
    default:
      return state;
  }
};
