import React, { useEffect, useState } from "react";
import MainService from "../../Common/MainService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  IconButton,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "new request":
      return "blue";
    case "upgraded":
      return "green";
    default:
      return "#1976d2";
  }
};

// Status styles
const statusStyle = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  fontFamily: "auto",
  textAlign: "center",
};

// Field styles
const fieldStyle = {
  marginBottom: "1rem",
  fontWeight: "bold",
  fontSize: "1.1rem",
  color: "#333",
  width: "max-content",
};

// tableheader style:
const headerStyle = {
  fontSize: "1rem",
  backgroundColor: "#3ECF8E",
  textAlign: "center",
  color: "white",
};
//tablebody style:
const bodyStyle = {
  textAlign: "center",
};
// Modal styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "honeydew",
  border: "none",
  boxShadow:
    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
  p: 4,
  borderRadius: 4,
  outline: "none",
  cursor: "pointer",
};
const formStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 700,
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
  p: 4,
  borderRadius: 4,
  outline: "none",
  cursor: "pointer",
};

const AccountUpgradationComp = () => {
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [fetchDestination, setFetchDestination] = useState("");
  const [fetchEmail, setFetchEmail] = useState("");
  const [fetchUsername, setFetchUsername] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [formData, setFormData] = useState({
    destination: "",
    source: "",
    username: fetchUsername,
    firstname: "",
    lastname: "",
    usertype: "LogisticsProvider",
    role: "BASIC",
    requesttype: "accountCreation",
    email: "",
    phone: "",
    accountIs: "Global",
    json: {},
  });
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  // Fetch account upgradation list on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await MainService.getAccountUpgradationList(username);
        setAccountList(data.registeredUsers);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  // Handle "Request Info" button click
  const handleRequestInfoClick = async (requestId) => {
    try {
      const data = await MainService.getAccountUpgradationReqStatus(requestId);
      // Set fetched data and open modal
      setRequestData(data);
      setOpen(true);
    } catch (err) {
      console.error(err);
      alert("Failed to fetch request info");
    }
  };

  // Fetch consumer user info
  const handleFetchConsumerUserInfo = async () => {
    try {
      const data = await MainService.getConsumerUserInfo(parentId, username);
      console.log(data, "fetch user data................");
      if (data && data[0].enterpriseid) {
        const fetchEnterpriceid = data[0].enterpriseid;
        setFetchDestination(fetchEnterpriceid);
        setFormData((prevData) => ({
          ...prevData,
          destination: fetchEnterpriceid,
        }));
      }
      if (data && data[0].username) {
        const fetchUserName = data[0].username;
        setFetchUsername(fetchUserName);
        setFormData((prevData) => ({
          ...prevData,
          source: fetchUserName,
        }));
      }
      if (data && data[0].email) {
        const fetchEmail = data[0].email;
        setFetchEmail(fetchEmail);
        setFormData((prevData) => ({
          ...prevData,
          email: fetchEmail,
        }));
      }

      if (data && data[0].username) {
        const fetchName = data[0].username;
        setFormData((prevData) => ({
          ...prevData,
          username: fetchName,
        }));
      }
      // Handle data (you can update the state to display the data in your component)
    } catch (err) {
      console.error(err);
      alert("Failed to fetch consumer user info");
    }
  };
  useEffect(() => {
    handleFetchConsumerUserInfo();
  }, []);

  // Handle modal close
  const handleClose = () => setOpen(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submit to call the API
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await MainService.postAccountUpgradationReq(formData);

      setSnackbarMessage("Request submitted successfully!");
      setSnackbarOpen(true);
      setFormOpen(false);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 409) {
        setSnackbarMessage("Request already sent for the username");
        setSnackbarSeverity("error");
      } else {
        setSnackbarMessage("Failed to submit request");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true); // Show snackbar
    }
  };
  // Handle form modal open
  const handleFormOpen = () => setFormOpen(true);

  // Handle form modal close
  const handleFormClose = () => setFormOpen(false);

  // Handle snackbar close
  const handleSnackbarClose = () => setSnackbarOpen(false);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#6200ea",
            color: "#ffffff",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "30px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            textTransform: "none",
            transition:
              "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#3700b3",
              transform: "scale(1.05)",
            },
            "&:active": {
              transform: "scale(0.95)",
            },
          }}
          onClick={handleFormOpen}
        >
          Upgrade Account
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          borderBottom: "inset",
          color: "#007bff",
        }}
      >
        <Typography
          sx={{ fontSize: "xx-large", fontWeight: 600, fontFamily: "emoji" }}
        >
          List of Account Upgradation Request
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>Sr.No.</TableCell>

              <TableCell sx={headerStyle}>Request ID</TableCell>
              <TableCell sx={headerStyle}>Source</TableCell>
              <TableCell sx={headerStyle}>Destination</TableCell>
              {/* <TableCell>User Type</TableCell> */}

              <TableCell sx={headerStyle}>Timestamp</TableCell>
              <TableCell sx={headerStyle}>Status</TableCell>

              {/* <TableCell sx={headerStyle}>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountList.map((account, index) => (
              <TableRow
                key={account.requestid}
                sx={{
                  "&:nth-of-type(odd)": {
                    // backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell sx={bodyStyle}>{index + 1}</TableCell>

                <TableCell sx={bodyStyle}>{account.requestid}</TableCell>
                <TableCell sx={bodyStyle}>{account.source}</TableCell>
                <TableCell sx={bodyStyle}>{account.destination}</TableCell>
                {/* <TableCell>{account.usertype}</TableCell> */}
                <TableCell sx={bodyStyle}>{account.timestamp}</TableCell>
                <TableCell sx={bodyStyle}>
                  <Typography>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        borderRadius: "4rem",
                        fontWeight: "bold",

                        color:
                          account.status === "new request"
                            ? "blue"
                            : account.status === "created"
                            ? "green"
                            : "red",
                      }}
                    >
                      {account.status}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for displaying request info */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                sx={{
                  ...statusStyle,
                  color: requestData
                    ? getStatusColor(requestData.status)
                    : "#1976d2",
                }}
              >
                {requestData ? requestData.status : "Loading..."}
              </Typography>
            </Box>
            <IconButton onClick={handleClose} sx={{ alignSelf: "flex-start" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          {requestData ? (
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography variant="body1" sx={fieldStyle}>
                <strong>Request ID:</strong> {requestData.requestid}
              </Typography>
              <Typography variant="body1" sx={fieldStyle}>
                <strong>Source:</strong> {requestData.source}
              </Typography>
              <Typography variant="body1" sx={fieldStyle}>
                <strong>Destination:</strong> {requestData.destination}
              </Typography>

              <Typography variant="body1" sx={fieldStyle}>
                <strong>Timestamp:</strong> {requestData.timestamp}
              </Typography>
            </Box>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>

      {/* UPGRADE ACCOUNT FORM */}
      <Modal
        open={formOpen}
        aria-labelledby="form-modal-title"
        aria-describedby="form-modal-description"
      >
        <Box sx={formStyle}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleFormClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Typography
                id="form-modal-title"
                variant="h4"
                component="h2"
                sx={{
                  borderBottom: "ridge",
                  fontSize: "2rem",
                  fontWeight: "bold",
                  fontFamily: "auto",
                }}
              >
                Account Upgrade Form
              </Typography>
            </Box>
          </Box>
          <Box id="form-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleFormSubmit}>
              <TextField
                name="destination"
                label="Destination"
                value={formData.destination}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                name="source"
                label="Source"
                value={formData.source}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />

              <TextField
                name="firstname"
                label="First Name"
                value={formData.firstname}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                name="lastname"
                label="Last Name"
                value={formData.lastname}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                name="usertype"
                label="User Type"
                value={formData.usertype}
                onChange={handleInputChange}
                select
                fullWidth
                required
                sx={{ mb: 2 }}
              >
                <MenuItem value="LogisticsProvider">
                  Logistics Provider
                </MenuItem>
              </TextField>

              <TextField
                name="requesttype"
                label="Request Type"
                value={formData.requesttype}
                onChange={handleInputChange}
                select
                fullWidth
                required
                sx={{ mb: 2 }}
              >
                <MenuItem value="accountCreation">Account Creation</MenuItem>
              </TextField>

              <TextField
                name="role"
                label="Role"
                value={formData.role}
                onChange={handleInputChange}
                select
                fullWidth
                required
                sx={{ mb: 2 }}
              >
                <MenuItem value="BASIC">BASIC</MenuItem>
              </TextField>
              <TextField
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                name="phone"
                label="Phone"
                value={formData.phone}
                onChange={handleInputChange}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                name="accountIs"
                label="Account Type"
                value={formData.accountIs}
                onChange={handleInputChange}
                select
                fullWidth
                required
                sx={{ mb: 2 }}
              >
                <MenuItem value="Global">Global</MenuItem>
              </TextField>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountUpgradationComp;
