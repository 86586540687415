import React, { useEffect, useState } from "react";
import MainService from "../../../Common/MainService";
import { Snackbar, IconButton, Button, Paper, Typography } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import TripOrders from "./TripOrders/TripOrders";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const OrderStatus = ({
  detail,
  backPrevious,
  orderStatusHandler,
  closeModelHandle,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [statusPage, setStatusPage] = useState(false);

  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  const backOrderStatus = () => {
    setStatusPage(true);
    backPrevious();
  };

  const updateOrderStatus = async (detail) => {
    const data = {
      bookingStatus: selectedOption,
      bookingId: detail.bookingId,
      latitude: "",
      longitude: "",
      updatedTimestamp: "",
    };
    try {
      const response = await MainService.updateBookingStatusRakshitApi(
        username,
        detail.bookingId,
        data
      );
      console.log(response, "UPDATE BOOKING STATUS::::::::::;");
    } catch (error) {
      console.error("Failed to update order status:", error);
    }
  };

  const getOrderStatusDetails = async () => {
    try {
      const data = await MainService.getBookingDetailsBasedOnBookingId(
        parentId,
        username,
        detail.bookingId
      );

      console.log(data, "BOOKING DETAILS::::");
      setOrderDetails(data);
    } catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  };

  const handleOpen = async () => {
    setOpen(true);
    await updateOrderStatus(detail);
    await getOrderStatusDetails();
    orderStatusHandler(selectedOption, detail.bookingId);
    closeModelHandle();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getOrderStatusDetails();
  }, []);

  return (
    <>
      <div>
        {!statusPage && orderDetails && (
          <>
            <TableContainer
              component={Paper}
              style={{
                // margin: "20px 0",
                padding: "20px",
                maxHeight: "600px", 
                overflowY: "auto", 
                overflowX: "auto", 
                
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "2px solid #000",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  style={{ fontWeight: "bold" }}
                >
                  BOOKING DETAILS
                </Typography>
              </div>
              <Table>
                <TableBody>
                  {[
                    {
                      label: "CONSIGNOR",
                      value: orderDetails?.bookingDetails?.consignor,
                    },
                    {
                      label: "CONSIGNEE",
                      value: orderDetails?.bookingDetails?.consignee,
                    },
                    {
                      label: "VENDOR",
                      value: orderDetails?.bookingDetails?.vendor,
                    },
                    {
                      label: "CONSIGNOR ADDRESS",
                      value: orderDetails?.bookingDetails?.consignorAdddress,
                    },
                    {
                      label: "CONSIGNEE ADDRESS",
                      value: orderDetails?.bookingDetails?.consigneeAdddress,
                    },
                    {
                      label: "PICKUP SITE ADDRESS",
                      value: orderDetails?.bookingDetails?.pickupSiteAddress,
                    },
                    {
                      label: "DELIVERY SITE ADDRESS",
                      value: orderDetails?.bookingDetails?.deliverySiteAddress,
                    },
                    { label: "BOOKING ID", value: orderDetails.bookingId },
                    { label: "ORDER ID", value: orderDetails.bpoId },
                    { label: "TRIP ID", value: orderDetails.tripId },
                  ].map((row, idx) => (
                    <TableRow
                      key={idx}
                      style={{ borderBottom: "1px solid #eee" }}
                    >
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {row.label}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        BOOKING STATUS
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        style={{ color: "green", fontWeight: "bold" }}
                      >
                        {orderDetails.bookingStatus}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        UPDATE STATUS
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <select
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{
                          width: "150px",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          backgroundColor: "#fff",
                          fontSize: "14px",
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="Accepted">ACCEPTED</option>
                        <option value="Rejected">REJECTED</option>
                        <option value="Scheduled_For_Pickup">
                          SCHEDULED FOR PICKUP
                        </option>
                        <option value="Picked_Up">PICKED UP</option>
                        <option value="Loaded">LOADED</option>
                        <option value="In_Transit">IN TRANSIT</option>
                        <option value="Arrived_At_Destination">
                          ARRIVED AT DESTINATION
                        </option>
                        <option value="Unloaded">UNLOADED</option>
                        <option value="Out_For_Delivery">
                          OUT FOR DELIVERY
                        </option>
                        <option value="Delivered">DELIVERED</option>
                        <option value="Failed_Delivery">FAILED DELIVERY</option>
                        <option value="Returned">RETURNED</option>
                      </select>
                      &nbsp;&nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                      >
                        UPDATE STATUS
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Status updated!"
          action={
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    </>
  );
};

export default OrderStatus;
