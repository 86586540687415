import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AddAccountUpgradationComp = () => {

    const history = useHistory(); 
  return (
    <>
      <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'relative', 
        height: '50px' 
      }}
    >
      <Button 
        variant="contained" 
        sx={{ 
          position: 'absolute', 
          left: 0 
        }}

        onClick={()=>history.push('./accountupgradation')}
      >
        Back
      </Button>
      <Box sx={{ flex: 1, textAlign: 'center' }}>
        <Typography sx={{ fontSize: 'xx-large', fontWeight: 600, fontFamily: 'emoji' }}

        >Upgrade Account</Typography>
      </Box>
    </Box>
    </>
  )
}

export default AddAccountUpgradationComp
