import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MainService from "../../Common/MainService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./viewOrders.css";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationManager from "react-notifications/lib/NotificationManager";
import InputAdornment from "@mui/material/InputAdornment";
import AddBooking from "./AddBooking";
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 15,
    padding: "12px 24px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    fontSize: "large",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  card: {
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    padding: theme.spacing(2),
    transition: "box-shadow 0.3s ease",
    padding: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#f5f5f5",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

const StyledTableHead = {
  color: "#333",
  fontWeight: "bold",
  textAlign: "center",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ViewOrders = ({ addBookingHandler }) => {
  const [bookingDetailsList, setBookingDetailsList] = useState([]);
  const [bookingCardDetails, setBookingCardDetails] = useState({});
  const [tripMenuList, setTripMenuList] = useState([]);
  const [driverMenuList, setDriverMenuList] = useState([]);
  const [transportUserList, setTransportUserList] = useState([]);
  const { user } = MainService.getLocalData();
  const [mapButtonDisabled, setMapButtonDisabled] = useState(false);

  const { username, parentId } = user;
  const [tripdriverDatamapping, setTripdriverDatamapping] = useState({
    triplist: "",
    driverlist: "",
    transportuserlist: "",
  });
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [successAlret, setSuccessAlret] = useState({});
  const [open, setOpen] = useState(false);
  const [disabledCardIds, setDisabledCardIds] = useState([]);
  const [cardDataMapping, setCardDataMapping] = useState({});
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBookingData, setFilteredBookingData] = useState([]);
  const [unmapSuccessAlert, setUnmapSuccessAlert] = useState({
    bookingId: null,
    show: false,
  });
  const [mappingSuccess, setMappingSuccess] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [userTypeValue, setUserTypeValue] = useState("");
  const [driverResources, setDriverResources] = useState([]);
  const [roleName, setRoleName] = useState("");

//ROLE MANAGEMENT:
  useEffect(() => {
    const fetchRoleAndResources = async () => {
      try {
        // Fetch role name
        const role = await MainService.getRoleNameofOperator(
          parentId,
          username
        );
        console.log(role, "ROLE:::::::::")
        const roleName = role;
        setRoleName(role);

        // Fetch resources based on role name
        const resourceName = await MainService.getRoleResources(
          parentId,
          roleName
        );

        console.log(resourceName, "RESOURCE NAME::::::::")
        const resources = resourceName.role.resources;
        setDriverResources(resources);
      } catch (error) {
        console.log(error, "error fetching role and resource");
      }
    };

    fetchRoleAndResources();
  }, [parentId, username]);

  // const history = useHistory();
  const classes = useStyles();

  const UserRole = localStorage.getItem("UserRole");
  console.log(UserRole);

  useEffect(() => {
    getUserType();
  }, [userTypeValue]);

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);

        console.log("USERTYPE", userTypeValue);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
  };

  const getBookingDetails = async () => {
    try {
      const response = await MainService.getAllBookingDetailsApi(
        parentId,
        parentId,
        username
      );

      // Fetch order status and update the booking data
      const updatedBookingData = await Promise.all(
        response.data.bookings.map(async (booking) => {
          // Fetch status for each booking
          const statusResponse = await MainService.getOrderStatus(
            parentId,
            username,
            booking.orderId
          );
          const status =
            statusResponse?.data?.order[0]?.status || "Status Not Available";
          console.log(status, "ORDER STATS:::::::::");
          return {
            ...booking,
            driverId: cardDataMapping[booking.bookingid]?.driverId || "",
            tripId: cardDataMapping[booking.bookingid]?.tripId || "",
            status: status,
          };
        })
      );

      setBookingData(updatedBookingData);
      console.log(updatedBookingData, "UPDATED BOOKING DATA::::::::::::::");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching booking details:", error);
      setLoading(false);
    }
  };
  const fetchTripList = async () => {
    let urls = "fromDate=01-01-2022&toDate=29-12-2090&start=0&limit=100";
    const { tripList } = await MainService.getTripInfo(
      parentId,
      username,
      urls
    );

    setTripMenuList(tripList);
    console.log(tripMenuList, "trip list..................................");
  };

  const fetchDriverList = async () => {
    try {
      const response = await MainService.getDrivers(username, "Driver");
      setDriverMenuList(response);
      console.log("Driver list:", response);
    } catch (error) {
      console.error("Error fetching driver list:", error);
    }
  };

  const fetchTransportUserList = async () => {
    try {
      const response = await MainService.getDrivers(
        username,
        "LogisticsTransportUser"
      );
      setTransportUserList(response);
      console.log("Transport user list:", response);
    } catch (error) {
      console.error("Error fetching transport user list:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setIsMounted(true);
    // Get the stored data from local storage and parse it back to objects
    const storedCardDataMapping =
      JSON.parse(localStorage.getItem("cardDataMapping")) || {};
    const storedDisabledCardIds =
      JSON.parse(localStorage.getItem("disabledCardIds")) || [];

    // Set the retrieved data to the component state
    setCardDataMapping(storedCardDataMapping);
    setDisabledCardIds(storedDisabledCardIds);
    getBookingDetails();
    fetchTripList();
    fetchDriverList();
    fetchTransportUserList();

    return () => {
      setIsMounted(false);
    };
  }, []);

  const sitedriverMapHandler = async (orderId, bookingid, name) => {
    console.log("click", bookingid, name);

    setBookingCardDetails({
      bookingId: bookingid,
      orderId: orderId,
      bookingTypeName: name,
    });

    setSelectedCardId(bookingid);
    if (name === "Unmap") {
      // Automatically fill the Trip List dropdown with the Trip ID
      const tripId = cardDataMapping[bookingid]?.tripId || "";

      const driverId = cardDataMapping[bookingid]?.driverId || "";
      const transportUserId = cardDataMapping[bookingid]?.transportUserId || "";
      setTripdriverDatamapping((prev) => ({
        ...prev,
        triplist: tripId,
        driverlist: driverId,
        transportuserlist: transportUserId,
      }));
    } else {
      // Clear the triplist value when not "Unmap"
      setTripdriverDatamapping((prev) => ({
        ...prev,
        triplist: "",
        driverlist: "",
        transportuserlist: "",
      }));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTripdriverDatamapping({
      triplist: "",
      driverlist: "",
    });

    // Clear success alerts when closing the modal
    setSuccessAlret({
      bookingId: null,
      show: false,
    });

    // Clear the unmap success alert
    setUnmapSuccessAlert({
      bookingId: null,
      show: false,
    });
  };

  // model menu onchange handler
  const mapListChangeHandler = (name, value) => {
    setTripdriverDatamapping((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log("sdkfjskf", tripdriverDatamapping);

  // trip and driver map handler
  const tripdriverMapHandler = async (buttonType) => {
    const { bookingId, orderId } = bookingCardDetails;
    const { triplist, driverlist, transportuserlist } = tripdriverDatamapping;
    if (buttonType === "Map") {
      console.log(triplist, "triplist 12123123.................");

      let tripResponse, driverResponse, transportUserResponse;
      if (triplist) {
        tripResponse = await MainService.postTripMapRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          triplist
        );
      }
      if (driverlist) {
        driverResponse = await MainService.postDriverMapRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          driverlist
        );
      }
      if (transportuserlist) {
        transportUserResponse = await MainService.postDriverMapRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          transportuserlist
        );
      }
      if (
        tripResponse?.status === 200 ||
        driverResponse?.status === 200 ||
        transportUserResponse?.status === 200
      ) {
        setCardDataMapping((prevMapping) => ({
          ...prevMapping,
          [bookingCardDetails.bookingId]: {
            driverId: tripdriverDatamapping.driverlist,
            tripId: tripdriverDatamapping.triplist,
            transportUserId: tripdriverDatamapping.transportuserlist,
          },
        }));
        setDisabledCardIds((prevIds) => [
          ...prevIds,
          bookingCardDetails.bookingId,
        ]);
        setSuccessAlret({
          bookingId: bookingCardDetails.bookingId,
          show: true,
        });
      }

      // Set the driverlist value to the selected driver's ID
      setTripdriverDatamapping((prev) => ({
        ...prev,
        driverlist: tripdriverDatamapping.driverlist,
        transportuserlist: tripdriverDatamapping.transportuserlist,
      }));
    } else if (buttonType === "Unmap") {
      let tripUnmapResponse, driverUnmapResponse, transportUserUnmapResponse;

      if (triplist) {
        tripUnmapResponse = await MainService.postTripUnmappingRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          triplist
        );
      }

      if (driverlist) {
        driverUnmapResponse = await MainService.postDriverUnmappingRequestApi(
          parentId,
          username,
          orderId,
          bookingId,
          driverlist
        );
      }
      if (transportuserlist) {
        transportUserUnmapResponse =
          await MainService.postDriverUnmappingRequestApi(
            parentId,
            username,
            orderId,
            bookingId,
            transportuserlist
          );
      }

      if (
        (tripUnmapResponse?.status === 200 && triplist) ||
        (driverUnmapResponse?.status === 200 && driverlist) ||
        (transportUserUnmapResponse?.status === 200 && transportuserlist)
      ) {
        // Update the card data mapping and disabled card IDs
        setCardDataMapping((prevMapping) => ({
          ...prevMapping,
          [bookingCardDetails.bookingId]: {
            driverId: "",
            tripId: "",
            transportUserId: "",
          },
        }));
        setDisabledCardIds((prevIds) =>
          prevIds.filter((id) => id !== bookingCardDetails.bookingId)
        );

        // Set the state to show the unmap success message
        setUnmapSuccessAlert({
          bookingId: bookingCardDetails.bookingId,
          show: true,
        });

        // Clear the trip and driver mapping values
        setTripdriverDatamapping({
          triplist: "",
          driverlist: "",
        });
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("cardDataMapping", JSON.stringify(cardDataMapping));
    localStorage.setItem("disabledCardIds", JSON.stringify(disabledCardIds));
  }, [cardDataMapping, disabledCardIds]);

  useEffect(() => {
    const filteredData = bookingData.filter((card) =>
      card.orderId.includes(searchQuery)
    );
    setFilteredBookingData(filteredData);
  }, [searchQuery, bookingData]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <div className="container" id="mainContentList">
          <div className="topHeaders">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #ddd",

                marginBottom: theme.spacing(2),
                paddingBottom: theme.spacing(1),
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                color="#1976d2"
                sx={{
                  letterSpacing: "2px",
                }}
              >
                Booking Management
              </Typography>
            </Box>

            {/* Search Input and Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: theme.spacing(2),
                padding: theme.spacing(1),
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TextField
                label="Search Order ID"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ flex: 2, maxWidth: "20%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchQuery ? (
                        <IconButton
                          size="large"
                          sx={{ padding: 0 }}
                          onClick={() => setSearchQuery("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <IconButton size="large" sx={{ padding: 0 }}>
                          <SearchIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={addBookingHandler}
                sx={{ fontSize: "large" }}
                variant="text"
              >
                Add Booking
              </Button>
            </Box>

            {loading ? (
              <div className="loading-message">
                <div className="loading-spinner"></div>
                <p>Loading.....</p>
              </div>
            ) : (
              // ................CARD VIEW................
              <Box
                sx={{
                  p: "2rem",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow style={StyledTableHead}>
                        <TableCell style={StyledTableHead}>
                          <b>Order Id</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>Booking Id</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>Trip ID</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>Driver ID</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>Transport User ID</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>AssetId</b>
                        </TableCell>

                        <TableCell style={StyledTableHead}>
                          <b>Status</b>
                        </TableCell>

                        <TableCell style={StyledTableHead}>
                          <b>Booking Date</b>
                        </TableCell>
                        <TableCell style={StyledTableHead}>
                          <b>Actions</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredBookingData.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={11}>
                            <Typography variant="body1">
                              No matching orders found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredBookingData.map((card, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {card.orderId}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {card.bookingid}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cardDataMapping[card.bookingid]?.tripId || "N/A"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cardDataMapping[card.bookingid]?.driverId ||
                                "N/A"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {cardDataMapping[card.bookingid]
                                ?.transportUserId || "N/A"}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {card.assetId}
                            </TableCell>

                            <TableCell
                              sx={{
                                color: "#3ECF8E",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {card.status}
                            </TableCell>

                            <TableCell sx={{ textAlign: "center" }}>
                              {card.bookingDate}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Box className={classes.buttonContainer}>
                                <Button
                                  sx={{ marginRight: "5px" }}
                                  variant="contained"
                                  onClick={() =>
                                    sitedriverMapHandler(
                                      card.orderId,
                                      card.bookingid,
                                      "Map"
                                    )
                                  }
                                  disabled={disabledCardIds.includes(
                                    card.bookingid
                                  )}
                                >
                                  Map
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() =>
                                    sitedriverMapHandler(
                                      card.orderId,
                                      card.bookingid,
                                      "Unmap"
                                    )
                                  }
                                >
                                  Unmap
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 3,
                        background: "#fff",
                        minWidth: "32rem",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        textAlign="center"
                        borderBottom="1px solid"
                        pb={1}
                      >
                        {bookingCardDetails.bookingTypeName} Booking
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            textAlign="center"
                            pb={1}
                            sx={{ fontWeight: 500 }}
                            color="blue"
                          >
                            Order ID:
                          </Typography>
                          <Typography>{bookingCardDetails.orderId}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            textAlign="center"
                            pb={1}
                            sx={{ fontWeight: 500, color: "blue" }}
                          >
                            Booking ID:
                          </Typography>
                          <Typography>
                            {bookingCardDetails.bookingId}
                          </Typography>
                        </Box>
                      </Box>

                      {/* TRIP LIST */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Box sx={{ minWidth: 200 }}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="triplist">Trip List</InputLabel>
                            <Select
                              labelId="triplist"
                              id="triplist"
                              value={tripdriverDatamapping.triplist}
                              onChange={(event) =>
                                mapListChangeHandler(
                                  "triplist",
                                  event.target.value
                                )
                              }
                              MenuProps={MenuProps}
                              disabled={
                                bookingCardDetails.bookingTypeName === "Unmap"
                                  ? true
                                  : false
                              }
                            >
                              {tripMenuList.map((li) => (
                                <MenuItem key={li.tripId} value={li.tripId}>
                                  {li.tripId}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        {/* DRIVER LIST */}

                        <Box sx={{ minWidth: 200 }}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="driverlist">Driver List</InputLabel>
                            <Select
                              labelId="driverlist"
                              id="driverlist"
                              value={tripdriverDatamapping.driverlist}
                              onChange={(event) =>
                                mapListChangeHandler(
                                  "driverlist",
                                  event.target.value
                                )
                              }
                              MenuProps={MenuProps}
                              disabled={
                                bookingCardDetails.bookingTypeName === "Unmap"
                                  ? true
                                  : false
                              }
                            >
                              {driverResources?.map((li) => (
                                <MenuItem key={li.resourceid} value={li.resourcename}>
                                  {li.resourcename}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        {/* END OF DRIVER LIST */}

                     
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            ml: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              tripdriverMapHandler(
                                bookingCardDetails.bookingTypeName
                              );
                              setSuccessAlret(true);
                            }}
                          >
                            {bookingCardDetails.bookingTypeName}
                          </Button>
                        </Box>
                      </Box>

                      {successAlret.bookingId === selectedCardId &&
                        successAlret.show && (
                          <Box sx={{ pt: 2 }}>
                            <Alert severity="success" onClose={handleClose}>
                              {bookingCardDetails.bookingTypeName} successfully.
                            </Alert>
                          </Box>
                        )}

                      {unmapSuccessAlert.bookingId ===
                        bookingCardDetails.bookingId &&
                        unmapSuccessAlert.show && (
                          <Box sx={{ pt: 2 }}>
                            <Alert severity="success" onClose={handleClose}>
                              Unmap successfully.
                            </Alert>
                          </Box>
                        )}
                    </Box>
                  </Modal>
                </Box>
              </Box>
            )}
          </div>
        </div>
      </>
    </ThemeProvider>
  );
};

export default ViewOrders;
