import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { io } from "socket.io-client";
import Map from "../../googlemap/Map";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MainService from "../../../Common/MainService";
import {
  Box,
  Button,
  Modal,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const VehicleStatus = () => {
  const [markers, setMarkers] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [tripDetails, setTripDetails] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [selectedVehicleId, setSelectedVehicleId] = useState(""); // Use initial prop
  const { user } = MainService.getLocalData();
  const { username,parentId } = user;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getVehicleStatus, setVehicleStatus] = useState("");
  const [vehicleGeolocation, setVehicleGeolocation] = useState(null);
  
  const mapRef = useRef(null);

  // Function to set the map center and zoom
  const setMapCenterAndZoom = (latLng) => {
    if (mapRef.current && latLng) {
      const googleMap = mapRef.current.getMap();
      if (googleMap) {
        googleMap.setCenter(latLng);
        googleMap.setZoom(15);
      }
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId");
  console.log('ASSET IDDDDDDDDDDD:::::::::::::', assetId)

  useEffect(() => {
    if (assetId) {
      setSelectedVehicleId(assetId);
    }
  }, [assetId]);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const newSocket = io(
      "http://ec2-18-237-49-236.us-west-2.compute.amazonaws.com:9001"
    );
    newSocket.on("connect", () => {
      console.log("Connected to server!///////////",

      `tt/solution/consumer2/site/TestSite1/asset/${selectedVehicleId}/LOC`
      );
    });

    // Listen for asset location updates and update markers
    if (selectedVehicleId) {
      newSocket.on(
        `tt/solution/consumer2/site/TestSite1/asset/${selectedVehicleId}/LOC`,
        (data) => {
          console.log(".....DAAATAAA........", data);

        console.log(
          JSON.parse(data.payload).geoLocation,
          "..........GEOLOCATION DATA........."
        );
        setMarkers([{ geoLocation: JSON.parse(data.payload).geoLocation }]);
        }
      );
    }

    return () => {
      newSocket.disconnect();
    };
  }, [vehicleDetails.siteId, selectedVehicleId, username]);

  useEffect(() => {
    if (selectedVehicleId && username) {
      MainService.lastLocationDetails(username, selectedVehicleId)
        .then((locationDetails) => {
          if (locationDetails && locationDetails.geoLocation) {
            setVehicleGeolocation(locationDetails.geoLocation);
          }
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        });
    }
  }, [selectedVehicleId, username]);

  useEffect(() => {
    if (selectedVehicleId && vehicleGeolocation) {
      setMapCenterAndZoom(vehicleGeolocation);
    }
  }, [selectedVehicleId, vehicleGeolocation]);

  useEffect(() => {
    const getVehicleList = async () => {
      const data = await MainService.getVehicleList(parentId,username);
      setVehicle({ vehicle: data.assets });
    };
    getVehicleList();
  }, [username]);

  const getVehicleTripDetails = async (userName, vehicleId) => {
    try {
      const tripData = await MainService.getVehicleTripDetails(userName, vehicleId);
      setTripDetails(tripData);
    } catch (error) {
      console.error("Error fetching trip details:", error);
    }
  };

  const vehicleStatus = async (vehicleId) => {
    try {
      const updatedStatus = await MainService.vehicleStatus(username, vehicleId);
      setVehicleStatus(updatedStatus.status);
    } catch (error) {
      console.log("Error fetching vehicle status:", error);
    }
  };

  useEffect(() => {
    if (selectedVehicleId) {
      vehicleStatus(selectedVehicleId);
      getVehicleTripDetails(username, selectedVehicleId);
    }
  }, [selectedVehicleId, username]);

  const mapListChangeHandler = useCallback(
    (name, value) => {
      const filterData = vehicle.vehicle.find(
        (item) => item.assetId === value
      );

      if (filterData) {
        setVehicleDetails({
          assetId: filterData.assetId,
          siteId: filterData.siteId,
        });
        setSelectedVehicleId(filterData.assetId);
      } else {
        setVehicleDetails({
          assetId: "",
          siteId: "",
        });
        setSelectedVehicleId("");
      }
    },
    [vehicle.vehicle]
  );

 

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #ddd",
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="#1976d2"
            sx={{
              textTransform: "uppercase",
              letterSpacing: "2px",
            }}
          >
            Asset Monitoring
          </Typography>
        </Box>

        <Box
          sx={{
            padding: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 4,
            mb: 2,
            background: "aliceBlue",
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={4}>
              <Typography variant="h6">
                Selected Asset:{" "}
                <span style={{ color: "#3ECF8E" }}>{selectedVehicleId}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              Status:{" "}
              <span style={{ color: "#3ECF8E" }}>{getVehicleStatus}</span>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                onClick={() => {
                  getVehicleTripDetails(username, selectedVehicleId);
                  openModal();
                }}
              >
                Get On Going Trip Details
              </Button>
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="triplist">Select Asset</InputLabel>
              <Select
                variant="outlined"
                id="triplist"
                labelId="demo-simple-select-helper-label"
                value={selectedVehicleId}
                onChange={(event) =>
                  mapListChangeHandler("driverlist", event.target.value)
                }
                endAdornment={
                  selectedVehicleId && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => mapListChangeHandler("driverlist", "")}
                      >
                        {/* <CloseIcon /> */}
                      </IconButton>
                    </InputAdornment>
                  )
                }
                sx={{ width: 180, mr: 2 }}
                
              >
                {vehicle.vehicle?.map((li) => (
                  <MenuItem key={li.assetId} value={li.assetId}>
                    {li.assetId}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>

        <Map
          showSearchBox={false}
          routeLocationData={markers}
          vehicleGeolocation={vehicleGeolocation}
        />
      </Box>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="trip-details-modal"
      >
        <Box
          sx={{
            position: "absolute",
            width: "80%",
            maxWidth: 600,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            boxShadow: 24,
            borderRadius: 8,
          }}
        >
          {tripDetails ? (
            <>
              <Typography
                variant="h5"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Trip Details
              </Typography>
              <Typography variant="body1">Vehicle ID: {tripDetails.vehicleId}</Typography>
              <Typography variant="body1">Trip ID: {tripDetails.tripId}</Typography>
              <Typography variant="body1">Driver ID: {tripDetails.driverId}</Typography>
              <Typography variant="body1">Status: {tripDetails.status}</Typography>
              <Typography variant="body1">Start Time: {tripDetails.startTime}</Typography>
              <Typography variant="body1">Created Date: {tripDetails.createdDate}</Typography>
              <Typography variant="body1">Last Modified Date: {tripDetails.lastModifiedDate}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={closeModal}
                sx={{ marginTop: 2 }}
              >
                Close
              </Button>
            </>
          ) : (
            <Typography variant="body1">No trip details available.</Typography>
          )}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default VehicleStatus;
