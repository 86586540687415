import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import MainPage from "./MainPage/MainPageComponent";
import MainService from "./Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";

import PropTypes from "prop-types";
import ProfileComponent from "./AssetOEMRole/Profile/ProfilePageComponent";
import RoleUpdationRequest from "./AssetDeveloperRole/RoleUpdationRequest/RoleUpdationRequestComponent";
import Report from "./documentComponents/features/Report";
/*///////////// START OF  LOGISTIC-MANAGER//////////////  */
import ManagerDashboard from "./LogisticsManager/ManagerDashboard/ManagerDashboard";
import VehicleManagement from "./LogisticsManager/Geofence/VehicleManagement/VehicleManagement";
import TripPlannerComponent from "./LogisticsManager/TripPlanner/TripPlannerComponent";
import LocationManagement from "./LogisticsManager/TripPlanner/Location/LocationManagement";
import AddLocation from "./LogisticsManager/TripPlanner/Location/AddLocation";
import RouteManagement from "./LogisticsManager/TripPlanner/Route/RouteManagement";
import AddRoute from "./LogisticsManager/TripPlanner/Route/AddRoute";
import TripManagement from "./LogisticsManager/TripPlanner/Trip/TripManagement";
import AddTrip from "./LogisticsManager/TripPlanner/Trip/AddTrip";
import GeofenceDetails from "./LogisticsManager/Geofence/geofencedetails/GeofenceDetails";
import TransportManagementComponent from "./AssetDeveloperRole/TransportManagement/TransportManagementComponent";
import ViewRoute from "./LogisticsManager/TripPlanner/Route/ViewRoute";
import TripMonitoring from "./LogisticsManager/TripPlanner/TripMonitoring/TripMonitoring";
import LiveMonitoringComponent from "./LogisticsManager/LiveMonitoring/LiveMonitoringComponent";

import VehicleStatus from "./LogisticsManager/LiveMonitoring/VehicleStatus/VehicleStatus";
import ViewVehicleSchedule from "./LogisticsManager/TripPlanner/Trip/viewVehicleSchedule/ViewVehicleSchedule";
import ViewDriverSchedule from "./LogisticsManager/TripPlanner/Trip/ViewDriverSchedule/ViewDriverSchedule";
import AddDynamic from "./LogisticsManager/TripPlanner/Trip/addDynamic/AddDynamic";
import BookingMainComp from "./LogisticsManager/BookingManagement/BookingMainComp";
// import TransportManagementComponent from "./AssetDeveloperRole/TransportManagement/TransportManagementComponent";
//Geofence...
import UpdatedGeofenceDetails from "./LogisticsManager/Geofence/UpdateGeofence/UpdatedGeofenceDetails";
import UpdateGeofenceOfVehicle from "./LogisticsManager/Geofence/UpdateGeofence/UpdateGeofenceOfVehicle";
import GeofenceMain from "./LogisticsManager/Geofence/GeofenceMain";
////////////////*END OF LOGISTIC-MANAGER ROLE *//////////////////

/////////////////*START OF DRIVER ROLE*////////////////////
import DashbordForDriverComponent from "./LogisticUser/DashboardForDriver/DashbordForDriverComponent";
import OrderDetails from "./LogisticUser/TransportUser/components/TripOrders/TripOrders";
import MyTrips from "./LogisticUser/DriverComponents/MyTrips";
import MyBookings from "./LogisticUser/DriverComponents/MyBookings";
import MyOrders from "./LogisticUser/DriverComponents/MyOrders";
////////////////////* END OF DRIVER ROLE*//////////////////////

// start of Transport User
import NodeManagementComponent from "./LogisticTransportUser/nodemanagement/NodeManagementComponent";
import AccountUpgradationComp from "./LogisticTransportUser/accountUpgradationComponent/AccountUpgradationComp";
import AddAccountUpgradationComp from "./LogisticTransportUser/accountUpgradationComponent/AddAccountUpgradationComp";
import MappedAssetsComponent from "./LogisticTransportUser/nodemanagement/mappedAssets/MappedAssetsComponent";
import LegacyAssetManagement from "./AssetDeveloperRole/LegacyAssetManagement/LegacyAssetManagementComponent";
import SerializationComponent from "./AssetDeveloperRole/Serialization/SerializationComponent";
// import MapAssetWithNode from "./LogisticTransportUser/nodemanagement/MappingAssetwithNode/MapAssetWithNode";

/*Start Invoice Components */
import BuyerAccounts from "./documentComponents/Invoicecomponents/buyeraccountmanagement/BuyerAccounts";
import SellerAccounts from "./documentComponents/Invoicecomponents/selleraccountmanagement/SellerAccountManagement";

/* End Invoice components */

import TransportDocumentManagenment from "../Main/documentComponents/transportdocuments/TransportDocumentManagenment";

export default class MainRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      _isMounted: true,
      userTypeValue: "",
    };
    this.userType = this.userType.bind(this);
  }
  componentWillUnmount() {
    this.setState({
      _isMounted: false,
    });
  }
  componentDidMount() {
    this.userType();

    if (this._isMounted === true) {
      const timer = setTimeout(() => {
        if (_.isEqual(this.state.location, this.props.location)) return;
        this.setState({ location: this.props.location });
        this.setState({
          _isMounted: false,
        });
      });
      return () => clearTimeout(timer);
    }
  }

  userType() {
    const { user } = MainService.getLocalData();
    const { username, parentId } = user;

    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        this.setState({
          userTypeValue: data,
        });
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  }

  render() {
    return (
      <MainPage location={this.props.location}>
        <Switch>
          <Route
            exact
            path={this.props.match.url + "/profile"}
            component={(args) => <ProfileComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/roleUpdationRequest"}
            component={(args) => <RoleUpdationRequest {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/transportManagement"}
            component={(args) => <TransportManagementComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/buyerPurchaseOrder"}
            component={(args) => <BuyerAccounts {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/sellerInvoice"}
            component={(args) => <SellerAccounts {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/report"}
            component={(args) => <Report {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/transportdocuments"}
            component={(args) => <TransportDocumentManagenment {...args} />}
          />

          {/* ///////////////* START OF LOGISTICS-MANAGER ////////////////////////*/}

          {/* tripmonitoring */}
          <Route
            exact
            path={this.props.match.url + "/tripmonitoring"}
            component={(args) => <TripMonitoring {...args} />}
          />

          {/* dashboard: */}
          <Route
            exact
            path={this.props.match.url + "/dashboardManager"}
            component={(args) => <ManagerDashboard {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/logisticManagerTripPlanner"}
            component={(args) => <TripPlannerComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/viewroute"}
            component={(args) => <ViewRoute {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/getLocation"}
            component={(args) => <LocationManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/addLocation"}
            component={(args) => <AddLocation {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/getRoutes"}
            component={(args) => <RouteManagement {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/addRoutes"}
            component={(args) => <AddRoute {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/getTrips"}
            component={(args) => <TripManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/addTrips"}
            component={(args) => <AddTrip {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/addDynamic/:tripId/:routeId"}
            component={(args) => <AddDynamic {...args} />}
          />

          {/* VIEW SCHEDULES */}
          <Route
            exact
            path={this.props.match.url + "/viewdriverschedule"}
            component={(args) => <ViewDriverSchedule {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/viewVehicleschedule"}
            component={(args) => <ViewVehicleSchedule {...args} />}
          />

          {/* logistic manager GEOFENCING ROUTES********** */}

          <Route
            exact
            path={this.props.match.url + "/main/geofence"}
            component={(args) => <GeofenceMain {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/main/vehicle"}
            component={(args) => <VehicleManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/geofence/:id"}
            component={(args) => <GeofenceDetails {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/main/updateGeofence"}
            component={(args) => <UpdatedGeofenceDetails {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/updateGeofence/:id"}
            component={(args) => <UpdateGeofenceOfVehicle {...args} />}
          />

          {/* LIVE MONITORING FOR LOGISTIC MANAGER */}
          <Route
            exact
            path={this.props.match.url + "/logisticManagerLiveMonitoring"}
            component={(args) => <LiveMonitoringComponent {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/vehicleStatus/:assetId"}
            component={(args) => <VehicleStatus {...args} />}
          />

          {/* logistics manager booking management************** */}

          <Route
            exact
            path={this.props.match.url + "/bookings"}
            component={(args) => <BookingMainComp {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/assetmanagement"}
            component={(args) => <TransportManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/accountupgradation"}
            component={(args) => <AccountUpgradationComp {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/roleUpdationRequest"}
            component={(args) => <RoleUpdationRequest {...args} />}
          />
           <Route
            exact
            path={this.props.match.url + "/lmAssetManagement"}
            component={(args) => <LegacyAssetManagement {...args} />}
          />
          
          <Route
            exact
            path={this.props.match.url + "/lmModel"}
            component={(args) => <SerializationComponent {...args} />}
          />

          {this.state.userTypeValue === "LogisticsManager" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}
          {/*/////////////////// END OF LOGISTICS-MANAGER ///////////////////*/}

          {this.state.userTypeValue === "ACNManager" && (
            <Route
              component={(args) => (
                <Redirect
                  to={this.props.match.url + "/acnManagerTripPlanner"}
                />
              )}
            />
          )}

          {/* *******************START OF DRIVER AND TRANSPORT USER ROLE ************* */}
          <Route
            exact
            path={this.props.match.url + "/mybookings"}
            component={(args) => <MyBookings {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/myorders"}
            component={(args) => <MyOrders {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/mytrips"}
            component={(args) => <MyTrips {...args} />}
          />
          
          <Route
            exact
            path={this.props.match.url + "/orderDetails"}
            component={(args) => <OrderDetails {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/dashboardForDriver"}
            component={(args) => <DashbordForDriverComponent {...args} />}
          />

          {/* NODE MANAGEMENT ROUTES */}

          <Route
            exact
            path={this.props.match.url + "/nodeManagement"}
            component={(args) => <NodeManagementComponent {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/mappedAssets"}
            component={(args) => <MappedAssetsComponent {...args} />}
          />
          {/* ACCOUNT UPGRADATION ROUTES */}

          <Route
            exact
            path={this.props.match.url + "/addaccountupgradation"}
            component={(args) => <AddAccountUpgradationComp {...args} />}
          />
          <Route
            exact
            path={this.props.match.url + "/accountupgradation"}
            component={(args) => <AccountUpgradationComp {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/tuAssetManagement"}
            component={(args) => <LegacyAssetManagement {...args} />}
          />

          <Route
            exact
            path={this.props.match.url + "/modelRoute"}
            component={(args) => <SerializationComponent {...args} />}
          />

          {this.state.userTypeValue === "Driver" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/dashboardForDriver"} />
              )}
            />
          )}

          {this.state.userTypeValue === "LogisticsTransportUser" && (
            <Route
              component={(args) => (
                <Redirect to={this.props.match.url + "/profile"} />
              )}
            />
          )}

          {/* END OF DRIVER AND TRANSPORT USER */}
        </Switch>
      </MainPage>
    );
  }
}
MainRouteComponent.propTypes = {
  function: PropTypes.func,
};
