import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MainService from "../../../Common/MainService";
import { GoogleMap, Polyline, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "80vw",
  height: "70vh",
};

const center = {
  lat: 12.9716,
  lng: 77.5946,
};

const Map = ({
  location,
  MarkerMap = [],
  handleMapBack,
  cardData,
  startTripBtn,
  showBackButton,
}) => {
  console.log(MarkerMap, "markupppp......bbbb");
  console.log(location, "location///..........");

  const mapRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [locationData, setLocationData] = useState([]);
  const [dposition, setdPosition] = useState({ lat: "", lng: "" });
  const [liveLocation, setLiveLocation] = useState();
  const [delMap, setDelMap] = useState(false);
  const [backButton, setbackButton] = useState(false);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [endButtonDisabled, setEndButtonDisabled] = useState(true);

  // State to track the trip completion status
  const [tripCompleted, setTripCompleted] = useState(false);

  const onLoad = useCallback(
    (ref) => {
      setSearchBox(ref);
    },
    [locationData]
  );

  const mapLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPlacesChanged = useCallback(() => {
    console.log("hello search");
    setPosition({
      lat: searchBox.getPlaces()[0].geometry.location.lat(),
      lng: searchBox.getPlaces()[0].geometry.location.lng(),
    });
  }, [searchBox]);

  const getFinalTripStatus = async () => {
    const datta = await MainService.getFinalTripStatus(cardData);
    setStartButtonDisabled(true);
    setEndButtonDisabled(false);
  };

  const getFinalTripStatus1 = async () => {
    const datta = await MainService.getFinalTripStatus1(cardData);
    setEndButtonDisabled(true);

    // Mark the trip as completed
    setTripCompleted(true);
  };

  // Function to close the trip completion message
  const handleCloseMessage = () => {
    setTripCompleted(false);
  };

  return (
    <>
      {showBackButton && (
        <Button
          sx={{ margin: "1rem" }}
          variant="outlined"
          onClick={handleMapBack}
        >
          Back to Bookings
        </Button>
      )}

      {/* Buttons for starting and ending the trip */}
      {startTripBtn && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={getFinalTripStatus}
            disabled={startButtonDisabled}
            sx={{ marginRight: 2 }}
          >
            START TRIP
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={getFinalTripStatus1}
            disabled={endButtonDisabled}
          >
            END TRIP
          </Button>
        </Box>
      )}

      {console.log("adfjsfkjsdfjsdfj", MarkerMap)}
      {!delMap && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={
            MarkerMap.length > 0
              ? {
                  lat: parseFloat(MarkerMap[0]?.lat || MarkerMap?.lat),
                  lng: parseFloat(MarkerMap[0]?.lng || MarkerMap?.lng),
                }
              : { lat: 28.679079, lng: 77.06971 }
          }
          zoom={4}
          onLoad={mapLoad}
        >
          {/* Polylines and Markers */}
          <Polyline
            path={location}
            geodesic={true}
            options={{
              strokeColor: "#187bcd",
              strokeOpacity: 5.75,
              strokeWeight: 7,
            }}
          />

          {Array.isArray(MarkerMap) &&
            MarkerMap.map((item, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(item?.lat),
                  lng: parseFloat(item?.lng),
                }}
              />
            ))}
        </GoogleMap>
      )}

      {/* Trip completion message */}
      <Snackbar
        open={tripCompleted}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 9999,
        }}
      >
        <MuiAlert
          onClose={handleCloseMessage}
          severity="success"
          elevation={6}
          variant="filled"
          sx={{
            minWidth: "300px",
            textAlign: "center",
          }}
        >
          Your trip is completed successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default React.memo(Map);
