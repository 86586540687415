import React, { useState, useEffect } from "react";
import { Button, styled } from "@mui/material";
import LocationManagement from "./Location/LocationManagement";
import RouteManagement from "./Route/RouteManagement";
import TripManagement from "./Trip/TripManagement";
import AddLocation from "./Location/AddLocation";
import AddRoute from "./Route/AddRoute";
import AddTrip from "./Trip/AddTrip";
import Main from "../../Common/MainService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ViewRoute from "./Route/ViewRoute";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const StyledButton = styled(Button)`
  background-color: #2e7d32;
  color: white;
  margin-right: 5px;
`;

export default function TripPlannerComponent() {
  const [activeComponent, setActiveComponent] = useState("location");
  const [tripData, setTripData] = useState(null);
  const [locData, setLocData] = useState(null);
  const [routeData, setRouteData] = useState("");
  const [userTypeValue, setUserTypeValue] = useState("");

  const { user } = Main.getLocalData();
  const { parentId, username } = user;

  const history = useHistory();

  const UserRole = localStorage.getItem("UserRole");

  const getUserType = () => {
    Main.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        return NotificationManager.error("Internal Server Error");
      });
  };

  const locationHandler = () => {
    setActiveComponent("location");
  };

  const routeHandler = () => {
    setActiveComponent("route");
  };

  const tripHandler = () => {
    setActiveComponent("trip");
  };

  const locHandler = (locdata) => {
    console.log("ADD LOCATION;;;;;;;;;;;;;;;", locdata);
    setLocData(locdata);
    setActiveComponent("addLocation");
  };

  const rouHandler = (data) => {
    setRouteData(data);
    setActiveComponent("addRoute");
  };

  const tripHandler1 = (data) => {
    setTripData(data);
    setActiveComponent("addTrip");
  };

  // Call getUserType when the component mounts
  useEffect(() => {
    getUserType();
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.routePath) {
      console.log("route history:::", history.location.state.routePath);
      setActiveComponent("route");
    }
  }, [history.location.state]);

  useEffect(() => {
    if (history.location.state && history.location.state.tripPath) {
      console.log("trip history:::", history.location.state.tripPath);
      setActiveComponent("trip");
    }
  }, [history.location.state]);

  return (
    <>
      <div className="container" id="mainContentList">
        {/* {UserRole === "LogisticsProvider" && ( */}
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              color="primary"
              size="large"
              variant={
                activeComponent === "location" ||
                activeComponent === "addLocation"
                  ? "contained"
                  : "outlined"
              }
              style={{ margin: "10px" }}
              onClick={locationHandler}
            >
              Location
            </Button>
          </div>
          <div className="topHeaders">
            <Button
              color="primary"
              size="large"
              style={{ margin: "10px" }}
              variant={
                activeComponent === "route" || activeComponent === "addRoute"
                  ? "contained"
                  : "outlined"
              }
              onClick={routeHandler}
            >
              Route
            </Button>
          </div>
          <div className="topHeaders">
            <Button
              color="primary"
              size="large"
              style={{ margin: "10px" }}
              variant={
                activeComponent === "trip" || activeComponent === "addTrip"
                  ? "contained"
                  : "outlined"
              }
              onClick={tripHandler}
            >
              Trip
            </Button>
          </div>
        </div>
      </div>

      {/* Render the appropriate component based on activeComponent */}
      {activeComponent === "location" && (
        <LocationManagement locHandler={locHandler} />
      )}
      {activeComponent === "route" && (
        <RouteManagement rouHandlers={rouHandler} />
      )}
      {activeComponent === "trip" && (
        <TripManagement tripHandler1={tripHandler1} />
      )}
      {activeComponent === "addLocation" && (
        <AddLocation locationHandler={locationHandler} locData={locData} />
      )}
      {activeComponent === "addRoute" && (
        <AddRoute routeHandler={routeHandler} routeData={routeData} />
      )}
      {activeComponent === "addTrip" && (
        <AddTrip tripHandler={tripHandler} tripData={tripData} />
      )}
    </>
  );
}
