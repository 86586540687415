import React, { useEffect, useState } from "react";
import MainService from "../../../../Common/MainService";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Paper,
  Checkbox,
  styled,
  Modal,
  Tooltip,
  Select,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ConsciousnessChecker from "../ConsiousnessChecker/ConsciousnessChecker";
import OrderStatus from "../OrderStatus";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import OrderStatusIcon from "../../../DriverComponents/MyIcons/delivery-status.png";
import InvoiceIcon from "../../../DriverComponents/MyIcons/invoice_12266888 (1).png";
import MapView from "../../../DriverComponents/MyIcons/map_717498.png";
import BookingStatus from "../../../DriverComponents/MyIcons/online.png";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "70vh",
  },
  backButton: {
    backgroundColor: "#3ECF8E",
    color: "#FFFFFF",
    marginRight: "10px",
  },
  orderDetails: {
    marginLeft: theme.spacing(2),
  },
  status: {
    fontWeight: "bold",
    color: "#ff5722",
    marginBottom: theme.spacing(1),
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
}));

const orderStatusStyle = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-25%, -40%)",
  overflow: "auto",
  borderRadius: "0.25rem",
  width: "unset",
  bgcolor: "background.paper",

  boxShadow:
    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
  // p: 4,
};

const BookingstatusOptions = [
  "Accepted",
  "Rejected",
  "Scheduled_For_Pickup",
  "Loaded",
  "Picked_Up",
  "In_Transit",
  "Arrived_At_Destination",
  "Unloaded",
  "Out_For_Delivery",
  "Delivered",
  "Failed_Delivery",
  "Returned",
];

const orderStatusOptions = [
  "UNLOADED",
  "DISPATCH",
  "LOADED",
  "IN_TRANSIT",
  "DELIVERED",
];

const TripOrders = ({ cardData, backHandler, routeData, bpoId }) => {
  console.log(bpoId, "::::BPOID AS PROPS::::::::");

  const classes = useStyles();
  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState("");
  const [pageSecond, setPageSecond] = useState(false);
  const [showNot, setShowNot] = useState(false);
  const [check, setCheck] = useState(false);
  const [orderStatusArray, setOrderStatusArray] = useState([]);
  const [location, setLocation] = useState();
  const [markerPostion, setMarkerPosition] = useState([]);
  const [rute, setRute] = useState(true);
  const [order, setOrder] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);

  const [lastUpdatedStatus, setLastUpdatedStatus] = useState("");
  const [lastUpdatedOrderStatus, setlastUpdatedOrderStatus] = useState("");
  const [selectedOrderLatitude, setSelectedOrderLatitude] = useState("");
  const [selectedOrderLongitude, setSelectedOrderLongitude] = useState("");
  const [selectedOrderTimestamp, setSelectedOrderTimestamp] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [selectedTimestamp, setSelectedTimestamp] = useState("");
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedLongitude, setSelectedLongitude] = useState("");
  const [mapCoordinates, setMapCoordinates] = useState({
    fromLocationlat: null,
    fromLocationlng: null,
    lat: null,
    lng: null,
  });

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [markers, setMarkers] = useState([]);

  const handleMapViewClick = async (bookingId) => {
    try {
      const bookingDetails =
        await MainService.getBookingDetailsBasedOnBookingId(
          parentId,
          username,
          bookingId
        );
      if (bookingDetails && bookingDetails.bookingDetails) {
        const { fromLocationlat, fromLocationlng, lat, lng } =
          bookingDetails.bookingDetails;
        setMapCenter({
          lat: fromLocationlat || lat,
          lng: fromLocationlng || lng,
        });
        setMarkers([
          { lat: fromLocationlat, lng: fromLocationlng, label: "A" },
          { lat, lng, label: "B" },
        ]);
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const handleOpenMapModal = (bookingId) => {
    setSelectedBookingId(bookingId);
    setOpenMapModal(true);
    handleMapViewClick(bookingId); // Call API on modal open
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
    setSelectedBookingId(null);
  };

  useEffect(() => {
    if (openModal) {
      // Get current date and time in IST (UTC+5:30)
      const currentISTDateTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 16); // Format to 'YYYY-MM-DDTHH:mm'
      setSelectedTimestamp(currentISTDateTime);
    }
  }, [openModal]);

  useEffect(() => {
    if (openOrderModal) {
      const currentISTDateTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 16);
      setSelectedOrderTimestamp(currentISTDateTime);
    }
  }, [openOrderModal]);

  const handleInvoiceClick = async (bpoId) => {
    try {
      const response = await MainService.getDocLinksForOrder(
        parentId,
        username,
        bpoId
      );
      if (response?.url) {
        window.open(response.url, "_blank");
      } else {
        console.error("No URL found in response");
      }
    } catch (error) {
      console.error("Error fetching document link:", error);
    }
  };

  const handleOrderModalOpen = async (bpoId) => {
    setSelectedOrderId(bpoId);
    // setSelectedOrderStatus(orderStatus);
    // setLastUpdatedStatus(orderStatus);
    try {
      const latestOrderStatus = await MainService.getLatestOrderStatus(
        parentId,
        username,
        bpoId
      );
      setlastUpdatedOrderStatus(latestOrderStatus.orderStatus); // Assuming the API returns the orderStatus field
    } catch (error) {
      console.error("Error fetching latest order status:", error);
      setlastUpdatedOrderStatus("Error fetching status");
    }
    setOpenOrderModal(true);
  };

  const handleOrderModalClose = () => {
    setOpenOrderModal(false);
    setSelectedOrderId(null);
    setSelectedOrderStatus("");
    setSelectedOrderLatitude("");
    setSelectedOrderLongitude("");
    setSelectedOrderTimestamp("");
  };

  //............ UPDATE ORDER STATUS...........
  const handleUpdateOrderStatus = async () => {
    try {
      const data = {
        orderId: selectedOrderId,
        orderStatus: selectedOrderStatus,
        latitude: selectedOrderLatitude,
        longitude: selectedOrderLongitude,
        updatedTimestamp: selectedOrderTimestamp,
      };

      await MainService.updateOrderStatus(username, selectedOrderId, data);
      setOrderDetails((prevDetails) =>
        prevDetails.map((order) =>
          order.orderId === selectedOrderId
            ? { ...order, orderStatus: selectedOrderStatus }
            : order
        )
      );
      handleOrderModalClose();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // Handle updating the booking status
  const updateBookingStatus = async () => {
    try {
      const data = {
        bookingStatus: selectedStatus,
        bookingId: selectedBookingId,
        latitude: selectedLatitude,
        longitude: selectedLongitude,
        updatedTimestamp: selectedTimestamp,
      };
      await MainService.updateBookingStatusRakshitApi(
        username,
        selectedBookingId,
        data
      );
      setOrderDetails((prevDetails) =>
        prevDetails.map((order) =>
          order.bookingId === selectedBookingId
            ? { ...order, bookingStatus: selectedStatus }
            : order
        )
      );
      handleCloseModal();
    } catch (error) {
      console.error("Error updating booking status:", error);
    }
  };

  const getDriverOrder = async (cardData) => {
    const { user } = MainService.getLocalData();

    const { username, parentId } = user;
    const data = await MainService.getAllBookingIds(
      parentId,
      username,
      cardData
    );
    console.log(data, "get  all booking ids:::::");
    setOrderDetails(data.bookings);
  };

  // Handle opening the modal with selected booking details
  const handleOpenModal = (bookingId, bookingStatus) => {
    setSelectedBookingId(bookingId);
    setSelectedStatus(bookingStatus);
    setOpenModal(true);
    setLastUpdatedStatus(bookingStatus);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBookingId(null);
    setSelectedStatus("");
  };
  const fetchRoute = async () => {
    try {
      const data = await MainService.getMyRoute(routeData);
      console.log(data, "FetchRoute:::");
      setLocation(data.geoPath);
      console.log(location, "GEOPATH:::::");
      const markData = [data.geoPath[0], data.geoPath[data.geoPath.length - 1]];
      setMarkerPosition(markData);
      console.log("Marker positions:", markData);
    } catch (error) {
      console.error("Error fetching route data:", error);
    }
  };

  useEffect(() => {
    getDriverOrder(cardData)
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchRoute();
  }, [routeData]);

  const handleChange = (event) => {
    console.log(event.currentTarget.name);

    const filterData = orderDetails.filter(
      (item) => item.bookingId === event.currentTarget.value
    );
    filterData[0].isChecked = !filterData[0].isChecked;
    setOrderDetails([...orderDetails]);

    if (filterData[0].isChecked) {
      setOrderStatusArray([...orderStatusArray, event.currentTarget.name]);
      if (event.currentTarget.name === "Loaded") {
        setCheck(true);
      }
    } else {
      // ... existing code ...
      setOrderStatusArray(filterData);
      if (event.currentTarget.name === "Loaded") {
        setCheck(false);
      }
    }
  };

  const orderStatusHandler = (bookingStatus, bookingId) => {
    console.log(bookingStatus, bookingId);
    const filterData = orderDetails.filter((li) => li.bookingId === bookingId);

    filterData[0].bookingStatus = bookingStatus;
  };

  const handleRoute = () => {
    const isStatusLoadedChecked = orderDetails.some(
      (ele) => ele.isChecked && ele.bookingStatus === "Loaded"
    );

    const areOtherStatusesChecked = orderDetails.some(
      (ele) => ele.isChecked && ele.bookingStatus !== "Loaded"
    );

    if (isStatusLoadedChecked && !areOtherStatusesChecked) {
      // setPageSecond(true);
      backPrevious();

      setShowNot(false);
      setRute(false);
    } else {
      console.log("Not all checkboxes with 'loaded' status are checked.");
    }
  };

  const handleStand = (prop) => {
    console.log("Booking detail passed to modal::::", prop);

    setDetail(prop);
    setShowNot(false);
    setOpen(true);
  };

  const closeModelHandle = () => {
    setOpen(false);
  };

  const backPrevious = () => {
    setPageSecond(true);
    setShowNot(false);
  };

  return (
    <>
      {!showNot && (
        <div>
          <Button onClick={backHandler}>
            <ArrowBackOutlinedIcon />
          </Button>
        </div>
      )}

      <div
        style={{ margin: "1rem", display: "flex", justifyContent: "center" }}
      >
        <h3
          style={{
            fontSize: "2rem",
            color: "#3ECF8E",
            textTransform: "uppercase",
            fontWeight: "bold",
            padding: "0.5rem",
            margin: "0",
            fontFamily: "Arial, sans-serif",
          }}
        >
          {cardData}
        </h3>
      </div>

      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {orderDetails.length === 0 && !isLoading && (
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
            marginTop: "2rem",
            color: "#757575",
            fontStyle: "italic",
            fontSize: "1.2rem",
          }}
        >
          Sorry, there are no bookings for this trip.
        </Typography>
      )}

      {!pageSecond && orderDetails.length > 0 && (
        <>
          <Grid container spacing={3}>
            {orderDetails.map((ele, i) => (
              <Grid item xs={12} sm={4} key={i}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <Paper
                    elevation={6}
                    sx={{
                      padding: "25px",
                      borderRadius: "15px",
                      "&:hover": {
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
                      },
                      transition: "box-shadow 0.3s ease-in-out",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "350px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                      >
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            color: ele.isChecked ? "primary.main" : "grey[400]",
                          }}
                          name={ele.bookingStatus}
                          value={ele.bookingId}
                          checked={ele.isChecked || false}
                          onChange={(event) => handleChange(event)}
                        />
                        <div
                          className={classes.orderDetails}
                          style={{ marginLeft: "20px", flex: 1 }}
                        >
                          <Typography variant="h6">
                            <strong>Booking Id:</strong> {ele.bookingId}
                          </Typography>
                          <Typography variant="h6">
                            <strong>Order Id:</strong> {ele.bpoId}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color:
                                ele.bookingStatus === "completed"
                                  ? "green"
                                  : ele.bookingStatus === "pending"
                                  ? "orange"
                                  : "red",
                              fontWeight: 500,
                            }}
                          >
                            <strong>Booking Status:</strong> {ele.bookingStatus}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <strong>Timestamp:</strong> {ele.timeStamp}
                          </Typography>
                        </div>
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          alignSelf: "flex-end",
                          textTransform: "none",
                          width: "max-content",
                          padding: "10px 20px",
                        }}
                        onClick={() => handleStand(ele)}
                      >
                        View Details
                      </Button>

                      {/* ALL NEW ACTIONS */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1.5rem",
                          width: "100%",
                        }}
                      >
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>Map View</span>
                          }
                          arrow
                        >
                          <Button variant="text" sx={{ padding: 0 }}>
                            <img
                              src={MapView}
                              alt="view map"
                              style={{
                                height: "3rem",
                                width: "3rem",
                                cursor: "pointer",
                                transition: "transform 0.3s ease",
                                "&:hover": { transform: "scale(1.1)" },
                              }}
                              onClick={() => handleOpenMapModal(ele.bookingId)}
                            />
                          </Button>
                        </Tooltip>

                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Update Booking Status
                            </span>
                          }
                          arrow
                        >
                          <Button variant="text" sx={{ padding: 0 }}>
                            <img
                              src={BookingStatus}
                              alt="update booking status"
                              style={{
                                height: "3rem",
                                width: "3rem",
                                cursor: "pointer",
                                transition: "transform 0.3s ease",
                                "&:hover": { transform: "scale(1.1)" },
                              }}
                              onClick={() =>
                                handleOpenModal(
                                  ele.bookingId,
                                  ele.bookingStatus
                                )
                              }
                            />
                          </Button>
                        </Tooltip>

                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              View Invoice
                            </span>
                          }
                          arrow
                        >
                          <Button variant="text" sx={{ padding: 0 }}>
                            <img
                              src={InvoiceIcon}
                              alt="view invoice"
                              style={{
                                height: "3rem",
                                width: "3rem",
                                cursor: "pointer",
                                transition: "transform 0.3s ease",
                                "&:hover": { transform: "scale(1.1)" },
                              }}
                              onClick={() => handleInvoiceClick(ele.bpoId)}
                            />
                          </Button>
                        </Tooltip>

                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Update Order Status
                            </span>
                          }
                          arrow
                        >
                          <Button variant="text" sx={{ padding: 0 }}>
                            <img
                              src={OrderStatusIcon}
                              alt="update order status"
                              style={{
                                height: "3rem",
                                width: "3rem",
                                cursor: "pointer",
                                transition: "transform 0.3s ease",
                                "&:hover": { transform: "scale(1.1)" },
                              }}
                              onClick={() =>
                                handleOrderModalOpen(ele.bpoId, ele.orderStatus)
                              }
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Button
            sx={{ margin: "2rem" }}
            variant="contained"
            onClick={handleRoute}
            disabled={orderDetails.some(
              (ele) => ele.isChecked && ele.bookingStatus !== "Loaded"
            )}
          >
            Get Routes
          </Button>
        </>
      )}

      <Modal
        open={open}
        onClose={() => closeModelHandle()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={orderStatusStyle}>
          <div className={classes.closeBtn}>
            <Button onClick={() => closeModelHandle()}>
              <CloseOutlinedIcon />
            </Button>
          </div>
          <OrderStatus
            detail={detail}
            backPrevious={backPrevious}
            orderStatusHandler={orderStatusHandler}
            closeModelHandle={closeModelHandle}
          />
        </Box>
      </Modal>

      {/* MAP MODAL  */}

      <Dialog
        open={openMapModal}
        onClose={handleCloseMapModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Map View for Booking ID:{" "}
          <b style={{ color: "blue" }}>{selectedBookingId}</b>{" "}
        </DialogTitle>
        <DialogContent>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={5}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                label={marker.label}
              />
            ))}
          </GoogleMap>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Updating Booking Status */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="update-booking-status-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Button onClick={handleCloseModal} color="error">
            <CloseIcon />
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              id="update-booking-status-modal"
              variant="h6"
              component="h2"
              color="blue"
            >
              UPDATE BOOKING STATUS
            </Typography>
          </Box>

          <Typography variant="body2" color="textSecondary">
            Last Updated Status:{" "}
            <strong style={{ color: "green" }}>{lastUpdatedStatus}</strong>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            Booking Id:
            <strong style={{ color: "green" }}>{selectedBookingId}</strong>
          </Typography>

          <Select
            fullWidth
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          >
            {BookingstatusOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
                disabled={option === lastUpdatedStatus}
              >
                {option}
              </MenuItem>
            ))}
          </Select>

          {/* Latitude Input */}
          <TextField
            label="Latitude"
            type="number"
            value={selectedLatitude}
            onChange={(e) => setSelectedLatitude(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />

          {/* Longitude Input */}
          <TextField
            label="Longitude"
            type="number"
            variant="outlined"
            value={selectedLongitude}
            onChange={(e) => setSelectedLongitude(e.target.value)}
            fullWidth
            margin="normal"
          />

          {/* Updated Timestamp Input */}
          <TextField
            id="datetime-local"
            type="datetime-local"
            label="Updated Timestamp"
            value={selectedTimestamp}
            onChange={(e) => setSelectedTimestamp(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              color="error"
              onClick={handleCloseModal}
              // startIcon={<CloseOutlinedIcon />}
            >
              close
            </Button>
            <Button
              onClick={updateBookingStatus}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal for updating order status */}
      <Modal
        open={openOrderModal}
        onClose={handleOrderModalClose}
        aria-labelledby="update-booking-status-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Button color="error" onClick={handleOrderModalClose}>
            <CloseIcon />
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              id="update-order-status-modal"
              variant="h6"
              component="h2"
              color="blue"
            >
              UPDATE ORDER STATUS
            </Typography>
          </Box>

          {/* <DialogContent> */}
          <Typography variant="body2" color="textSecondary">
            Last Updated Status:{" "}
            <b style={{ color: "green" }}> {lastUpdatedOrderStatus}</b>{" "}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Order Id: <b style={{ color: "green" }}>{selectedOrderId}</b>{" "}
          </Typography>

          <Select
            fullWidth
            value={selectedOrderStatus} // Display the selected status
            onChange={(e) => setSelectedOrderStatus(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          >
            {/* Render available status options */}
            {orderStatusOptions.map((status) => (
              <MenuItem
                key={status}
                value={status}
                disabled={status === lastUpdatedOrderStatus} // Disable the last updated status
              >
                {status}
              </MenuItem>
            ))}
          </Select>

          <TextField
            fullWidth
            label="Latitude"
            type="number"
            value={selectedOrderLatitude}
            onChange={(e) => setSelectedOrderLatitude(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            type="number"
            label="Longitude"
            value={selectedOrderLongitude}
            onChange={(e) => setSelectedOrderLongitude(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            id="datetime-local"
            type="datetime-local"
            label="Updated Timestamp"
            value={selectedOrderTimestamp}
            onChange={(e) => setSelectedOrderTimestamp(e.target.value)}
            sx={{ mt: 2 }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleOrderModalClose} color="error">
              Close
            </Button>

            <Button onClick={handleUpdateOrderStatus} variant="contained">
              Update Status
            </Button>
          </Box>

          {/* </DialogContent> */}
        </Box>
      </Modal>

      {!rute && check && orderStatusArray.length > 0 && (
        <ConsciousnessChecker
          location={location}
          markerPostion={markerPostion}
          cardData={cardData}
        />
      )}
    </>
  );
};

export default TripOrders;
