import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { NotificationManager } from "react-notifications";
import MainService from "../../Common/MainService";
import "./TransportManagementComponent.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CustomPopup from "../../Common/CustomPopup/CustomPopupComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@material-ui/core/Box";
import SearchBar from "material-ui-search-bar";
import ListIcon from "@material-ui/icons/List";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MapIcon from "@material-ui/icons/MapRounded";
import { createTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import socketIOClient from "socket.io-client";
import LocationMap from "../../Common/LiveMonitorMap";
import Map from "../../Common/googleMap/Map";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    //maxWidth: 350,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 10,
    color: "#fff",
  },
}));

export default function TransportManagementComponent(props) {
  const classes = useStyles();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;
  const [isLoading, setIsLoading] = useState(true);
  const [verticalsIdList, setVerticalsIdList] = useState([]);
  const [selectedVerticalsId, setSelectedVerticalsId] = useState("");
  const [categoryTypesList, setCategoryTypesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assertTypesList, setAssertTypesList] = useState([]);
  const [selectedAssertTypes, setSelectedAssertTypes] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [modelList, setModelsList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [assetPopup, setShowAssetPopup] = useState(false);
  const [getUserTypeValue, setUserTypeValue] = useState("");
  const [assetIdValue, setAssetIdValue] = useState("");
  const [legacyAssetFriendlyNameValue, setLegacyAssetFriendlyNameValue] =
    useState("");
  const [selectedVerticalName, setSelectedVerticalName] = useState("");
  const [assetDomainValue, setAssetDomainValue] = useState("");
  const [siteNameValue, setSiteNameValue] = useState("");
  const [zoneValue, setZoneValue] = useState("");
  const [lotNameValue, setLotNameValue] = useState("");
  const [manufacturerIdValue, setManufacturerIdValue] = useState(username);
  const [manufacturerUrlValue, setManufacturerUrlValue] = useState("");
  const [modelFriendlyNameValue, setModelFriendlyNameValue] = useState("");
  const [modelUrlValue, setModelUrlValue] = useState("");
  const [serialNoValue, setSerialNoValue] = useState("");
  const [udnValue, setUdnValue] = useState("");
  const [upcValue, setUpcValue] = useState("");
  const [motherBreedValue, setMotherBreedValue] = useState("");
  const [fatherBreedValue, setFatherBreedValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [depthValue, setDepthValue] = useState("");
  const [whiteLabelCostValue, setWhiteLabelCostValue] = useState("");
  const [sellingCostValue, setSellingCostValue] = useState("");
  const [acquireCostValue, setAcquireCostValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [altitudeValue, setAltitudeValue] = useState("");
  const [mapPopUp, setMapPopUp] = useState(false);
  const [mapSiteId, setMapSiteId] = useState("");
  const [mapAssetId, setMapAssetId] = useState("");
  const [mapCategoryType, setMapCategoryType] = useState("");
  const [legacyUpdate, setLegacyUpdate] = useState(false);
  const [assetVeriticalId, setAssetVerticalId] = useState("");
  const [assetTypeVal, setAssetTypeVal] = useState("");
  const [assetIdVal, setAssetIdVal] = useState("");
  const [assetFriName, setAssetFriName] = useState("");
  const [modelIdVal, setModelIdVal] = useState("");
  const [categoryVal, setCatgeryVal] = useState("");
  const [udnVal, setUdnVal] = useState("");
  const [whiteLabelVal, setWhiteLabelVal] = useState("");
  const [sellingCostVal, setSellingCostVal] = useState("");
  const [exAssetStatus, setExAssetStatus] = useState("");
  const [updateAssetStatus, setUpdateAssetStatus] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assetsListView, setAssetsListView] = useState(false);
  const [assetsGridView, setAssetsGridView] = useState(true);
  const [assetsMapView, setAssetsMapView] = useState(false);
  const [searched, setSearched] = useState("");
  const [setFilterUsers, getFilterUsers] = useState([]);
  const [farmgridsearched, setFarmGridSearched] = useState("");
  const [assetInfoUpdatePopup, setAssetInfoUpdatePopup] = useState(false);
  /* Asset Live Monoitoring */
  const [liveMonitorMapPopUp, setLiveMonitorMapPopUp] = useState(false);
  const [ssetMonitoringStatus, setAssetMonitoringStatus] = useState("");
  const [marker_position, setmarker_position] = useState({
    lat: 0,
    lng: 0,
  });
  const [assetPostionList, setAssetPositionList] = useState({});
  const [ssetMonitorTimestamp, setAssetMonitorTimestamp] = useState("");
  const [assetMonitoringView, setAssetMonitoringView] = useState(false);
  const [markers, setMarkers] = useState([]);
  /*****************      Asset Info                  ***************/
  const [getAssetInfoObj, setAssetInfoObj] = useState({});
  const [assetInfoPopup, setAssetInfoPopUp] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");

  /* QR Code */

  const [fgcolor, setFgcolor] = useState("000");
  const [bgcolor, setBgcolor] = useState("f0ffff");
  const [qrSizeValue, setQrSizeValue] = useState("400");
  const [assetDeletePopup,setAssetDeletePopup] = useState(false);
  /* Live Monitoring */

  /* Asset Env */
  const [assetEnvData, setAssetEnvData] = useState({});
  const [assetEnvPopup, setAssetEnvPopup] = useState(false);

  const getAssetVerticalsIdList = () => {
    MainService.getAssetVerticalsIdList("consumer2")
      .then((data) => {
        if (data.length > 0) {
          const filteredAssetVerticals = data.filter((row) => {
            return row.friendlyName === "TRANSPORTATION";
          });
          setVerticalsIdList(filteredAssetVerticals);
          setSelectedVerticalsId(filteredAssetVerticals[0].assetVerticalId);
          setSelectedVerticalName(filteredAssetVerticals[0].friendlyName);
        } else {
          setSelectedVerticalsId("");
          setSelectedVerticalName("");
          setVerticalsIdList([]);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  useMemo(() => {
    getAssetVerticalsIdList();
  }, []);

  const getCategoryTypesList = () => {
    if (selectedVerticalsId !== "") {
      MainService.getCategoryTypesList(
        selectedVerticalsId.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedCategory(data[0].categoryType);
            setCategoryTypesList(data);
          } else {
            setSelectedCategory("");
            setCategoryTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setSelectedCategory("");
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useMemo(() => {
    getCategoryTypesList();
  }, [selectedVerticalsId]);

  const getAssetTypesList = () => {
    if (selectedVerticalsId !== "" && selectedCategory !== "") {
      MainService.getAssetTypesList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedAssertTypes(data[0].assetType);
            setAssertTypesList(data);
          } else {
            setSelectedAssertTypes("");
            setAssertTypesList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setSelectedAssertTypes("");
      setSelectedModel("");
    }
  };

  useMemo(() => {
    getAssetTypesList();
  }, [selectedVerticalsId, selectedCategory]);

  const getModelsList = () => {
    if (
      selectedVerticalsId !== "" &&
      selectedCategory !== "" &&
      selectedAssertTypes !== ""
    ) {
      MainService.getModelsList(
        selectedVerticalsId.replace(" ", ""),
        selectedCategory.replace(" ", ""),
        selectedAssertTypes.replace(" ", ""),
        "consumer2"
      )
        .then((data) => {
          if (data.length > 0) {
            setSelectedModel(data[0].modelid);
            setModelsList(data);
          } else {
            setSelectedModel("");
            setModelsList([]);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    }
  };

  useMemo(() => {
    getModelsList();
  }, [selectedVerticalsId, selectedCategory, selectedAssertTypes]);

  useEffect(() => {
    getAllAssetsBasedonModel();
  }, [
    selectedVerticalsId,
    selectedCategory,
    selectedAssertTypes,
    selectedModel,
  ]);

  const getAllAssetsBasedonModel = () => {
    if (
      selectedVerticalsId &&
      selectedCategory &&
      selectedAssertTypes &&
      selectedModel
    ) {
      MainService.getAllAssetsBasedonModel(
        username,
        selectedAssertTypes,
        selectedModel,
        selectedVerticalsId,
        selectedCategory
      )
        .then((data) => {
          if (data.length > 0) {
            console.log("asssets data:", data);
            setAssetsList(data);
            getFilterUsers(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAssetsList([]);
          }
        })
        .catch((e) => {
          console.error(e);
          return NotificationManager.error(e);
        });
    } else {
      setIsLoading(false);
      setAssetsList([]);
    }
  };

  const getAllSites = () => {
    MainService.getAllSites(username)
      .then((data) => {
        if (data.length > 0) {
          setSelectedSite(data[0].siteid);
          setSitesList(data);
        } else {
          setSelectedSite("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const getUserType = () => {
    MainService.getConsumerUserType(parentId, username)
      .then((data) => {
        setUserTypeValue(data);
      })
      .catch((e) => {
        console.error(e);
        return NotificationManager.error(e);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const setSelectedSiteHandler = (e) => {
    setSelectedSite(e.target.value);
    let catname = sitesList.filter((data) => {
      return data.siteid === e.target.value;
    });
    setSelectedSiteName(catname[0].sitename);
  };
  const setSelectedVerticalsIdHandler = (e) => {
    setSelectedVerticalsId(e.target.value);
    let catname = verticalsIdList.filter((data) => {
      return data.assetVerticalId === e.target.value;
    });
    setSelectedVerticalName(catname[0].friendlyName);
  };

  const setSelectedCategoryHandler = (event) => {
    setSelectedCategory(event.target.value);
  };

  const setSelectedAssertTypeHandler = (event) => {
    setSelectedAssertTypes(event.target.value);
  };

  const setSelectedModelHandler = (event) => {
    setSelectedModel(event.target.value);
  };

  const createAssetClick = () => {
    let createAssetData = {
      assetFriendlyName: legacyAssetFriendlyNameValue,
      assetVerticalId: selectedVerticalsId,
      categoryType: selectedCategory,
      legacyAssetType: selectedAssertTypes,
      assetDomain: assetDomainValue,
      siteId: selectedSite,
      siteName: siteNameValue,
      lotId: lotNameValue,
      lotName: lotNameValue,
      manufacturerId: manufacturerIdValue,
      manufacturUrl: manufacturerUrlValue,
      modelId: selectedModel,
      modelName: modelFriendlyNameValue,
      modelType: selectedModel,
      modelUrl: modelUrlValue,
      serialNo: serialNoValue,
      udn: udnValue,
      upc: upcValue,
      motherBreed: motherBreedValue,
      fatherBreed: fatherBreedValue,
      description: descriptionValue,
      whitelableCost: whiteLabelCostValue,
      sellingcost: sellingCostValue,
      acquireCost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      actorType: getUserTypeValue,
      consumerAdminId: parentId,
    };
    const legacyAssetData = JSON.stringify(createAssetData);
    if (legacyAssetFriendlyNameValue && assetIdValue && acquireCostValue) {
      MainService.createLegacyAsset(legacyAssetData, username, assetIdValue)
        .then((res) => {
          getAllAssetsBasedonModel();
          return NotificationManager.success(res);
        })
        .catch((e) => {
          return NotificationManager.error(e);
        });
      setShowAssetPopup(!assetPopup);
    } else {
      return NotificationManager.error("Please fill all required fields");
    }
  };

  const assetCreateClcik = () => {
    getAllSites();
    getUserType();
    setShowAssetPopup(!assetPopup);
  };

  /*List Filter */
  const requestSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  /*Grid Filter */
  const requestFarmGridSearch = (searchedVal) => {
    const filteredRows = setFilterUsers.filter((row) => {
      return row.assetType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setAssetsList(filteredRows);
  };

  const cancelFarmGridSearch = () => {
    setFarmGridSearched("");
    requestFarmGridSearch(farmgridsearched);
  };

  /*Create Asset */
  const createAssetModal = (
    <span
      onClick={() => {
        setShowAssetPopup(!assetPopup);
      }}
    >
      X
    </span>
  );
  const titleCreateAsset = "Create Asset";
  const assetCreationBody = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId">Legacy Asset ID* :</InputLabel>
        <Input
          id="assetId"
          name="assetId"
          type="text"
          placeholder="GBRBB00501"
          value={assetIdValue}
          onChange={(e) => {
            setAssetIdValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="legacyAssetFriendlyName">
          Legacy Asset Friendly Name *:
        </InputLabel>
        <Input
          id="legacyAssetFriendlyName"
          name="legacyAssetFriendlyName"
          placeholder="Betal Sheep"
          type="text"
          value={legacyAssetFriendlyNameValue}
          onChange={(e) => {
            setLegacyAssetFriendlyNameValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost"> Acquire Cost *:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="number"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Name*</InputLabel>
        <Select
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedSite}
          onChange={(e) => {
            setSelectedSiteHandler(e);
          }}
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>AssetVerticalId:</InputLabel>
        <Select
          disabled
          id="assetVerticalID"
          name="assetVerticalID"
          type="text"
          value={selectedVerticalsId}
          onChange={(event) => {
            setSelectedVerticalsIdHandler(event);
          }}
        >
          {verticalsIdList.length > 0 ? (
            verticalsIdList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                {idObj.friendlyName}
              </MenuItem>
            ))
          ) : (
            <h4></h4>
          )}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Category Type*</InputLabel>
        <Select
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          {categoryTypesList &&
            categoryTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.categoryType}>
                {idObj.categoryType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Model</InputLabel>
        <Select
          disabled
          id="selectedModel"
          value={selectedModel}
          onChange={(event) => setSelectedModel(event.target.value)}
          label="Select Model"
        >
          {modelList &&
            modelList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.modelid}>
                {idObj.modelfriendlyname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelType"> Model Type:</InputLabel>
        <Input
          disabled
          id="modelType"
          name="modelType"
          type="text"
          value={selectedModel}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Asset Type</InputLabel>
        <Select
          disabled
          id="selectedAssertTypes"
          value={selectedAssertTypes}
          onChange={(event) => setSelectedAssertTypes(event.target.value)}
          label="Select Type"
        >
          {assertTypesList &&
            assertTypesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.assetType}>
                {idObj.assetType}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={(e) => createAssetClick()}
        >
          Create
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setShowAssetPopup(!assetPopup);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* Asset Live Monitoring */

  const assetMonitoringHandler = (siteId,assetId, lat1, lng1) => {
    setSelectedAssetId(assetId);
    setAssetsListView(false);
    setAssetsGridView(false);
    setAssetMonitoringView(true);
    setAssetsMapView(false);

    MainService.assetCurrentLocation(parentId, username, assetId)
      .then((res) => {
        console.log("current Location monitor:", res);
        setSelectedAssetId(res.assetId);
        setAssetMonitoringStatus(res.status);
        setMarkers([...markers, { geoLocation: res.geoLocation }]);
        setAssetMonitorTimestamp(res.ts);

        if (marker_position !== undefined) {
          const ENDPOINT = window.socketUrl;
          const socket = socketIOClient(ENDPOINT);
          socket.on(`tt/solution/${username}/site/${siteId}/asset/${assetId}/LOC`, (data) => {
            console.log(JSON.parse(data.payload).geoLocation);
            setMarkers([
              ...markers,
              { geoLocation: JSON.parse(data.payload).geoLocation },
            ]);
          });
          return () => {
            socket.disconnect();
          };
        }
      })
      .catch((e) => {
        return NotificationManager.error("location not found");
      });
  };

  const AssetLiveGoogleMap = withGoogleMap(() => {
    return marker_position.lat === 0 && marker_position.lng === 0 ? (
      <GoogleMap
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker>
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    ) : (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={marker_position}
        center={marker_position}
      >
        <Marker
          position={{
            lat: parseFloat(marker_position.lat),
            lng: parseFloat(marker_position.lng),
          }}
        >
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{selectedAssetId}</div>
          </InfoWindow>
        </Marker>

        {Object.entries(assetPostionList).map(([key, value]) => {
          return value ? (
            <Marker position={{ lat: value.lat, lng: value.lng }}>
              <InfoWindow options={{ disableAutoPan: false }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            <Marker
              position={{
                lat: parseFloat(marker_position.lat),
                lng: parseFloat(marker_position.lng),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{selectedAssetId}</div>
              </InfoWindow>
            </Marker>
          );
        })}
      </GoogleMap>
    );
  });

  /* End of Asset Monitoring */
  /* Asset Env */

  const assetEnvClick = (assetId, deviceType) => {
    MainService.getAssetEnvInfo(parentId, username, assetId, deviceType)
      .then((res) => {
        setAssetEnvData(res);
      })
      .catch((e) => {
        setAssetEnvData({});
        //return NotificationManager.error("Env data not found");
      });

    console.log("socket url:", window.socketUrl);
    const ENDPOINT = window.liveEnvUrl;
    const socket = socketIOClient(ENDPOINT);
    console.log("socket :", socket);
    socket.on("livedata", (data) => {
      console.log("from api data:", data);
      setAssetEnvData(data);
    });
    setAssetEnvPopup(!assetEnvPopup);
  };
  /* End ofAsset Env */
  /* Mapping Functionality */

  const mapPopUpHandler = (assetId, categoryType) => {
    getAllSites();
    setMapAssetId(assetId);
    setMapCategoryType(categoryType);
    setMapPopUp(!mapPopUp);
  };
  const closeMapModal = (
    <span
      onClick={() => {
        setMapPopUp(!mapPopUp);
      }}
    >
      X
    </span>
  );
  const createMapping = () => {
    const siteIdData = {
      siteid: mapSiteId,
    };
    const dataSiteId = JSON.stringify(siteIdData);

    MainService.createMapping(username, mapAssetId, mapCategoryType, dataSiteId)
      .then((res) => {
        setMapPopUp(!mapPopUp);
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };

  const titleMapAsset = "Map Model";
  const mapModelBody = (
    <div>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>Site Id</InputLabel>
        <Select
          id="selectedModel"
          value={mapSiteId}
          onChange={(event) => setMapSiteId(event.target.value)}
          label="Select Model"
        >
          {sitesList &&
            sitesList.map((idObj, index) => (
              <MenuItem key={`${index} `} value={idObj.siteid}>
                {idObj.sitename}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetId"> Asset Id:</InputLabel>
        <Input
          disabled
          id="assetId"
          name="assetId"
          type="text"
          value={mapAssetId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="categoryType"> Category Type:</InputLabel>
        <Input
          disabled
          id="categoryType"
          name="categoryType"
          type="text"
          value={mapCategoryType}
        />
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={createMapping}
        >
          Create Mapping
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setMapPopUp(!mapPopUp);
        }}
        style={{
          marginBottom: "20px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );
  /* End Mapping Functionality */

  /* UnMap Asset Handler Functionality */
  const unMapAssetHandler = (assetId, categoryType, siteIds) => {
    const siteId = {
      siteid: siteIds,
    };
    const siteIdData = JSON.stringify(siteId);

    MainService.createUnMapping(assetId, categoryType, siteIdData, username)
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
  };
  /* End UnMap Asset Handler Functionality */

  /* Update Legacy Asset */
  const closeUpdateModel = (
    <span
      onClick={() => {
        setLegacyUpdate(!legacyUpdate);
      }}
    >
      X
    </span>
  );

  const updateAssetHandler = () => {
    updateWhitelabelCostHandler();

    updateSellingCostHandler();

    /*  const updateAssetStatusData = {
        status: updateAssetStatus,
      };
      const statusData = JSON.stringify(updateAssetStatusData);
  
      MainService.assetStatusUpdate(assetIdVal, statusData)
        .then((res) => {
          setLegacyUpdate(!legacyUpdate);
          getAllAssetsBasedonModel();
          return NotificationManager.success(res);
        })
        .catch((e) => {
          setUpdateAssetStatus(null);
          return NotificationManager.error(e);
        }); */
  };

  const updateLegacyAssetHandler = (assets) => {
    setAssetVerticalId(assets.assetverticalId);
    setAssetTypeVal(assets.assetType);
    setAssetIdVal(assets.assetId);
    setAssetFriName(assets.friendlyName);
    setModelIdVal(assets.modelId);
    setCatgeryVal(assets.categoryType);
    setUdnVal(assets.udn);
    setAcquireCostValue(assets.acquireCost);
    setWhiteLabelVal(assets.whitelabelCost);
    setSellingCostVal(assets.sellingCost);
    setExAssetStatus(assets.assetStatus);

    setLegacyUpdate(!legacyUpdate);
  };

  const udateAssetInfoClick = (assets) => {
    setAssetVerticalId(assets.assetverticalId);
    setAssetTypeVal(assets.assetType);
    setAssetIdVal(assets.assetId);
    setAssetFriName(assets.friendlyName);
    setModelIdVal(assets.modelId);
    setCatgeryVal(assets.categoryType);
    setUdnVal(assets.udn);
    setUpcValue(assets.upc);
    setManufacturerUrlValue(assets.manufacturUrl);
    setSerialNoValue(assets.serialNo);
    setLatitudeValue(assets.latitude);
    setLongitudeValue(assets.longitude);
    setAltitudeValue(assets.altitude);
    setAcquireCostValue(assets.acquireCost);
    setWhiteLabelVal(assets.whitelabelCost);
    setSellingCostVal(assets.sellingCost);

    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  const updateAssetInfoSubmitClick = () => {
    const updateAssetInfoData = {
      friendlyname: assetFriName,
      udn: udnVal,
      upc: upcValue,
      whitelabelcost: whiteLabelCostValue,
      sellingcost: sellingCostVal,
      acquirecost: acquireCostValue,
      latitude: latitudeValue,
      longitude: longitudeValue,
      altitude: altitudeValue,
      manufacturerurl: manufacturerUrlValue,
      serialno: serialNoValue,
    };
    const data = JSON.stringify(updateAssetInfoData);

    MainService.updateAssetInfo(data, assetIdVal)
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error(e);
      });
    setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
  };
  /* End of asset status update */
  /* update selling cost */
  const updateSellingCostHandler = () => {
    const updateAssetSellingCostData = {
      assetid: assetIdVal,
      assetfriendlyname: assetFriName,
      parentid: parentId,
      userid: username,
      sellingcost: sellingCostVal,
    };
    const data = JSON.stringify(updateAssetSellingCostData);

    MainService.updateSellingCost(updateAssetSellingCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error(e);
      });
  };
  /* End of update selling cost */
  /* update Whitelabel cost */
  const updateWhitelabelCostHandler = () => {
    const updateAssetSellignCostData = {
      assetid: assetIdVal,
      whitelabelcost: whiteLabelVal,
      assetstatus: updateAssetStatus,
    };
    const data = JSON.stringify(updateAssetSellignCostData);

    MainService.updateWhitelabelCost(updateAssetSellignCostData)
      .then((res) => {
        setLegacyUpdate(!legacyUpdate);
        getAllAssetsBasedonModel();
        return NotificationManager.success(res);
      })
      .catch((e) => {
        setUpdateAssetStatus(null);
        return NotificationManager.error(e);
      });
  };
  /* End of update whitelabel cost */
  /* Asset Info */
  const getAssetInfo = (assetid) => {
    MainService.getAssetInfo(username, assetid)
      .then((res) => {
        setAssetInfoObj(res);
      })
      .catch((err) => {
        return NotificationManager.error("error");
      });
  };

  const assetInfoDialogClick = (assetid) => {
    getAssetInfo(assetid);
    setAssetInfoPopUp(!assetInfoPopup);
  };
  const closeSitAssetInfoModal = (
    <span
      onClick={() => {
        setAssetInfoPopUp(!assetInfoPopup);
      }}
    >
      X
    </span>
  );
  const assetInfoHeader = "Asset Details";
  const assetInfoBody = (
    <div style={{ paddingBottom: 50 }}>
      <p>
        <b>Asset ID:</b>
        {getAssetInfoObj.assetId}
      </p>
      <p>
        <b>Site ID:</b>
        {getAssetInfoObj.siteid}
      </p>
      <p>
        <b>Site Name:</b>
        {getAssetInfoObj.sitename}
      </p>
      <p>
        <b>AssetverticalId:</b>
        {getAssetInfoObj.assetverticalId}
      </p>
      <p>
        <b>Category:</b>
        {getAssetInfoObj.category}
      </p>

      <p>
        <b>AssetDomain:</b>
        {getAssetInfoObj.assetDomain}
      </p>
      <p>
        <b>Asset Type:</b>
        {getAssetInfoObj.assetType}
      </p>
      <p>
        <b>Friendly Name:</b>
        {getAssetInfoObj.friendlyName}
      </p>
      <p>
        <b>Manufacturer Id:</b>
        {getAssetInfoObj.manufacturerId}
      </p>
      <p>
        <b>Manufacturer:</b>
        {getAssetInfoObj.manufacturer}
      </p>
      <p>
        <b>Manufacturer URL:</b>
        {getAssetInfoObj.manufacturerURL}
      </p>
      <p>
        <b>ModelId:</b>
        {getAssetInfoObj.modelId}
      </p>

      <p>
        <b>Model Type:</b>
        {getAssetInfoObj.modelType}
      </p>
      <p>
        <b>Model URL:</b>
        {getAssetInfoObj.modelURL}
      </p>
      <p>
        <b>Serial Number:</b>
        {getAssetInfoObj.serialNumber}
      </p>
      <p>
        <b>UDN:</b>
        {getAssetInfoObj.udn}
      </p>
      <p>
        <b>UPC:</b>
        {getAssetInfoObj.upc}
      </p>
      <p>
        <b>Whitelabel Cost:</b>
        {getAssetInfoObj.whitelabelcost}
      </p>
      <p>
        <b>Selling Cost:</b>
        {getAssetInfoObj.sellingcost}
      </p>
      <p>
        <b>Image Data:</b>
        {getAssetInfoObj.imagedata}
      </p>
      <p>
        <b>Asset Status:</b>
        {getAssetInfoObj.assetstatus}
      </p>
      <p>
        <b>Latitude:</b>
        {getAssetInfoObj.latitude}
      </p>
      <p>
        <b>Longitude:</b>
        {getAssetInfoObj.longitude}
      </p>
      <br />
      <Button
        variant="outlined"
        style={{ left: "45%" }}
        onClick={() => {
          setAssetInfoPopUp(!assetInfoPopup);
        }}
      >
        cancel
      </Button>
    </div>
  );

  /* End of Asset Info */
  /* Update Legacy Asset Status*/
  const closeUpdateAssetInfoModel = (
    <span
      onClick={() => {
        setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
      }}
    >
      X
    </span>
  );
  const updateLegacyAsset = "Asset Information";
  const updateLegacyAssetData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          disabled
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="manufacturerid">MANUFACTURER ID:</InputLabel>
        <Input
          disabled
          id="manufacturerid"
          name="manufacturerid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input disabled id="udn" name="udn" type="text" value={udnVal} />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ACQUIRE COST:</InputLabel>
        <Input
          disabled
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
        />
      </FormControl>
      {updateAssetStatus === "readyForOnboard" ||
      updateAssetStatus === "null" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
            onChange={(e) => {
              setWhiteLabelVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
          <Input
            disabled
            id="whitecost"
            name="whitecost"
            type="text"
            value={whiteLabelVal}
          />
        </FormControl>
      )}
      {updateAssetStatus === "prematureSale" ||
      updateAssetStatus === "leased" ||
      updateAssetStatus === "verified" ? (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
            onChange={(e) => {
              setSellingCostVal(e.target.value);
            }}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth={true} className="loginFields">
          <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
          <Input
            disabled
            id="sellingcost"
            name="sellingcost"
            type="text"
            value={sellingCostVal}
          />
        </FormControl>
      )}

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetstatus">EXISTING ASSET STATUS:</InputLabel>
        <Input
          disabled
          id="assetstatus"
          name="assetstatus"
          type="text"
          value={exAssetStatus}
        />
      </FormControl>
      <FormControl
        variant="standard"
        fullWidth={true}
        className={classes.formControl}
      >
        <InputLabel>UPDATE ASSET STATUS</InputLabel>
        <Select
          id="selectedModel"
          value={updateAssetStatus}
          onChange={(event) => setUpdateAssetStatus(event.target.value)}
          label="Select Model"
        >
          <MenuItem key="readyForOnboard" value="readyForOnboard">
            ReadyForOnboard --- initial state(aquired)
          </MenuItem>
          <MenuItem key="null" value="null">
            Null --- Ready for whitelabel(onboarded)
          </MenuItem>
          <MenuItem key="whitelabeled" value="whitelabeled">
            Whitelabeled
          </MenuItem>
          <MenuItem key="prematureSale" value="prematureSale">
            Premature Sale
          </MenuItem>
          <MenuItem key="verified" value="verified">
            Verified
          </MenuItem>
          <MenuItem key="sold" value="sold">
            Sold
          </MenuItem>
          <MenuItem key="leased" value="leased">
            Leased
          </MenuItem>
          <MenuItem key="lost" value="lost">
            Lost
          </MenuItem>
          <MenuItem key="damaged" value="damaged">
            Damaged
          </MenuItem>
          <MenuItem key="underMaintenance" value="underMaintenance">
            Under maintenance
          </MenuItem>
          <MenuItem key="deboard" value="deboard">
            Deboard
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetHandler}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setLegacyUpdate(!legacyUpdate);
          setUpdateAssetStatus(null);
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset Status*/

  /* Update Legacy Asset Info*/
  const updateLegacyAssetInfo = "Update Asset Information";
  const updateLegacyAssetInfoData = (
    <div>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="solutionid">SOLUTION ID:</InputLabel>
        <Input
          disabled
          id="solutionid"
          name="solutionid"
          type="text"
          value={username}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetverticalid">ASSET VERTICAL ID:</InputLabel>
        <Input
          disabled
          id="assetverticalid"
          name="assetverticalid"
          type="text"
          value={assetVeriticalId}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="category">CATEGORY:</InputLabel>
        <Input
          disabled
          id="category"
          name="category"
          type="text"
          value={categoryVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assettype">ASSET TYPE:</InputLabel>
        <Input
          disabled
          id="assettype"
          name="assettype"
          type="text"
          value={assetTypeVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="modelid">MODEL ID:</InputLabel>
        <Input
          disabled
          id="modelid"
          name="modelid"
          type="text"
          value={modelIdVal}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetid">ASSET ID:</InputLabel>
        <Input
          disabled
          id="assetid"
          name="assetid"
          type="text"
          value={assetIdVal}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="assetfname">ASSET FRIENDLY NAME:</InputLabel>
        <Input
          id="assetfname"
          name="assetfname"
          type="text"
          value={assetFriName}
          onChange={(e) => {
            setAssetFriName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UDN:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={udnVal}
          onChange={(e) => {
            setUdnVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">UPC:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={upcValue}
          onChange={(e) => {
            setUpcValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">MANUFACTURER URL:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={manufacturerUrlValue}
          onChange={(e) => {
            setManufacturerUrlValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="udn">SERIAL NO:</InputLabel>
        <Input
          id="udn"
          name="udn"
          type="text"
          value={serialNoValue}
          onChange={(e) => {
            setSerialNoValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ACQUIRE COST:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={acquireCostValue}
          onChange={(e) => {
            setAcquireCostValue(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="whitecost">WHITELABEL COST:</InputLabel>
        <Input
          id="whitecost"
          name="whitecost"
          type="text"
          value={whiteLabelVal}
          onChange={(e) => {
            setWhiteLabelVal(e.target.value);
          }}
        />
      </FormControl>

      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="sellingcost">SELLING COST:</InputLabel>
        <Input
          id="sellingcost"
          name="sellingcost"
          type="text"
          value={sellingCostVal}
          onChange={(e) => {
            setSellingCostVal(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">LATITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={latitudeValue}
          onChange={(e) => {
            setLatitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">Longitude:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={longitudeValue}
          onChange={(e) => {
            setLongitudeValue(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} className="loginFields">
        <InputLabel htmlFor="acquireCost">ALTITUDE:</InputLabel>
        <Input
          id="acquireCost"
          name="acquireCost"
          type="text"
          value={altitudeValue}
          onChange={(e) => {
            setAltitudeValue(e.target.value);
          }}
        />
      </FormControl>

      <br />
      <FormControl>
        <Button
          variant="contained"
          color="primary"
          className="registerBtn"
          type="submit"
          onClick={updateAssetInfoSubmitClick}
        >
          Update Asset
        </Button>
      </FormControl>
      <FormControl
        fullWidth={true}
        className="forgotPwdLink"
        onClick={() => {
          setAssetInfoUpdatePopup(!assetInfoUpdatePopup);
        }}
        style={{
          marginBottom: "50px",
          textAlign: "center",
          boxOrient: "0",
          display: "block",
          fontSize: "20",
        }}
      >
        Cancel
      </FormControl>
    </div>
  );

  /* End Update Legacy Asset Info */

  /* Asset Delete Handler */

  const deleteAssetHandler = (
    assetIds,
    assetTypes,
    modelIds,
    assetverticalIds,
    categoryTypes
  ) => {
   
    setAssetDeletePopup(!assetDeletePopup);

    setSelectedAssertTypes(assetTypes);
    setSelectedModel(modelIds);
    setSelectedVerticalsId(assetverticalIds);
    setSelectedAssetId(assetIds);
    setSelectedCategory(categoryTypes);
 
    
  };
  const deleteAssetSubmitHandler =()=>{
    MainService.assetDelete(
      selectedAssetId,
      selectedAssertTypes,
      selectedModel,
      selectedVerticalsId,
      selectedCategory,
      username
    )
      .then((res) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success("successfully deleted");
      })
      .catch((e) => {
        return NotificationManager.error(e);
      });
      setAssetDeletePopup(!assetDeletePopup);
  }
  /*End Asset Delete Handler */

  /* backToHome */
  const backToHome = () => {
    setAssetsListView(false);
    setAssetsGridView(true);
    setAssetMonitoringView(false);
    setAssetsMapView(false);
  };
  /* Generate QR Code */
  const submitGenerateQr = (asset) => {
    let qrBody = {
      iwidth: qrSizeValue,
      fcolor: fgcolor,
      bcolor: bgcolor,
      cadminid: parentId,
      assetverticalid: asset.assetverticalId,
      avname: selectedVerticalName,
      acategory: asset.category,
      atype: asset.assetType,
      modelid: asset.modelId,
      bname: username,
      id: asset.assetId,
    };
    console.log("qr body:", qrBody);
    const qrBodyStringify = JSON.stringify(qrBody);
    MainService.generateQrCodeWithId(qrBodyStringify)
      .then((data) => {
        getAllAssetsBasedonModel();
        return NotificationManager.success("QR Code generated successfully");
      })
      .catch((err) => {
        console.error(err);
        return NotificationManager.error(err);
      });
  };

  /* End of Generate QR Code */
  const AssetManagementGoogleMap = withGoogleMap(() => {
    return (
      <GoogleMap
        key={assetsList.index}
        defaultZoom={2}
        defaultCenter={marker_position}
        center={marker_position}
      >
        {assetsList.map((value, index) => {
          return value ? (
            <Marker
              key={index}
              position={{
                lat: parseFloat(value.latitude),
                lng: parseFloat(value.longitude),
              }}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <div>{value.assetId}</div>
              </InfoWindow>
            </Marker>
          ) : (
            ""
          );
        })}
      </GoogleMap>
    );
  });

  return (
    <>
      <div className="container" id="mainContentList">
        {assetsListView || assetsGridView ? (
          <Fab
            color="primary"
            size={"large"}
            aria-label="add"
            title="Add Asset"
            className={classes.fab}
            onClick={() => assetCreateClcik()}
          >
            <AddIcon ></AddIcon>
          </Fab>
        ) : (
          <></>
        )}
        <div className="topHeadersWrapper">
          <div className="topHeaders">
            <Button
              startIcon={<GridIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(true);
                setAssetsListView(false);
                setAssetMonitoringView(false);
                setAssetsMapView(false);
              }}
            ></Button>
            <Button
              startIcon={<ListIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(false);
                setAssetsListView(true);
                setAssetMonitoringView(false);
                setAssetsMapView(false);
              }}
            ></Button>
            <Button
              startIcon={<MapIcon />}
              size="large"
              onClick={() => {
                setAssetsGridView(false);
                setAssetsListView(false);
                setAssetMonitoringView(false);
                setAssetsMapView(true);
              }}
            ></Button>
          </div>
          <div className="topHeaders"></div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Sector
              </InputLabel>
              <Select
                id="selectedVerticalsId"
                value={selectedVerticalsId}
                onChange={(event) => {
                  setSelectedVerticalsIdHandler(event);
                }}
                label="Select Verticals"
              >
                {verticalsIdList.length > 0 ? (
                  verticalsIdList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetVerticalId}>
                      {idObj.friendlyName}
                    </MenuItem>
                  ))
                ) : (
                  <h4></h4>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Category
              </InputLabel>
              <Select
                id="selectedCategory"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategoryHandler(event);
                }}
                label="Select Category"
              >
                {categoryTypesList &&
                  categoryTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.categoryType}>
                      {idObj.categoryType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Asset Type
              </InputLabel>
              <Select
                id="selectedAssertTypes"
                value={selectedAssertTypes}
                onChange={(event) => {
                  setSelectedAssertTypeHandler(event);
                }}
                label="Select Type"
              >
                {assertTypesList &&
                  assertTypesList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.assetType}>
                      {idObj.assetType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="topHeaders">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{
                  transform: "translate(14px, -6px) scale(0.75)",
                  color: "rgb(43, 144, 99)",
                }}
              >
                Model
              </InputLabel>
              <Select
                id="selectedModel"
                value={selectedModel}
                onChange={(event) => {
                  setSelectedModelHandler(event);
                }}
                label="Select Model"
              >
                {modelList &&
                  modelList.map((idObj, index) => (
                    <MenuItem key={`${index} `} value={idObj.modelid}>
                      {idObj.modelfriendlyname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="MuiGrid-root">
          {/* Asset List */}
          {assetsListView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <div className="cardStyleForDashboardItems">
                {isLoading ? (
                  <div className="cardStyleForDashboardItems">
                    <CircularProgress />
                  </div>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchBar
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                    {assetsList.length > 0 ? (
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={16}>
                                  Assets
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell align="center" colSpan={1}>
                                  Asset Id
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetType
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Asset Friendly Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  AssetverticalId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Blocking Status
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Category Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Gender
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  ModelId
                                </TableCell>

                                <TableCell align="center" colSpan={1}>
                                  Model Type
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Model Url
                                </TableCell>

                                <TableCell align="center" colSpan={1}>
                                  SiteId
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Site Name
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  UDN
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Weight
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Acquire Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Selling Cost
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  Whitelabel Cost
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                  QR Code
                                </TableCell>
                                <TableCell align="center" colSpan={5}>
                                  View
                                </TableCell>
                                <TableCell align="center" colSpan={7}>
                                  Operate
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {assetsList.length > 0 ? (
                                assetsList
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((assets, index) => {
                                    return (
                                      <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align="center">
                                          {assets.assetId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.friendlyName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.assetverticalId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.blockingStatus}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.categoryType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.gender}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.modelUrl}
                                        </TableCell>

                                        <TableCell align="center">
                                          {assets.siteId}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.siteName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.udn}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.weight}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.acquireCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.sellingCost}
                                        </TableCell>
                                        <TableCell align="center">
                                          {assets.whitelabelCost}
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          {assets.assetqrcodelink !== "" &&
                                          assets.assetqrcodelink !== "null" ? (
                                            <div>
                                              <img
                                                src={assets.assetqrcodelink}
                                                alt="img"
                                              ></img>
                                              <br />
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                className="registerBtn"
                                              >
                                                <a
                                                  className="downloadDocument"
                                                  href={assets.assetqrcodelink}
                                                  target="_blank"
                                                  download
                                                >
                                                  Download
                                                </a>
                                              </Button>
                                            </div>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="registerBtn"
                                              type="submit"
                                              onClick={(e) =>
                                                submitGenerateQr(assets)
                                              }
                                            >
                                              Generate
                                            </Button>
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Asset Info
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              assetMonitoringHandler(
                                                assets.siteId,
                                                assets.assetId,
                                                assets.latitude,
                                                assets.longitude
                                              )
                                            }
                                          >
                                            Asset Monitoring
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() => {
                                              assetEnvClick(
                                                assets.assetId,
                                                assets.assetType
                                              );
                                            }}
                                          >
                                            Env
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              mapPopUpHandler(
                                                assets.assetId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Map
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              unMapAssetHandler(
                                                assets.assetId,
                                                assets.categoryType,
                                                assets.siteId
                                              )
                                            }
                                          >
                                            UnMap
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              updateLegacyAssetHandler(assets)
                                            }
                                          >
                                            Update Status
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              udateAssetInfoClick(assets)
                                            }
                                          >
                                            Update Info
                                          </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                              deleteAssetHandler(
                                                assets.assetId,
                                                assets.assetType,
                                                assets.modelId,
                                                assets.assetverticalId,
                                                assets.categoryType
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (
                                <TableRow align="center">
                                  <TableCell align="center" colSpan={12}>
                                    Assets not found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={assetsList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <h4>Assets not found.</h4>
                    )}
                  </Box>
                )}
              </div>
            </Box>
          ) : null}

          {assetsGridView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              <SearchBar
                value={farmgridsearched}
                onChange={(searchVal) => requestFarmGridSearch(searchVal)}
                onCancelSearch={() => cancelFarmGridSearch()}
              />
              {isLoading ? (
                <div className="cardStyleForDashboardItems">
                  <CircularProgress />
                </div>
              ) : (
                <div className="cardStyleForDashboardItems">
                  <h4 style={{ width: "100%", textAlign: "center" }}>
                    List Of Assets
                  </h4>
                  {assetsList.length > 0 ? (
                    <div className="cardStyleForDashboardItems">
                      {assetsList.map((assets, index) => (
                        <div
                          key={index}
                          className="cardStyleForDashboardItemsBody"
                        >
                          <span>
                            <p>
                              <b>Asset Id:</b>
                              {assets.assetId}
                            </p>
                            <p>
                              <b>Asset Status:</b>
                              {assets.assetStatus}
                            </p>
                            <p>
                              <b>AssetType:</b>
                              {assets.assetType}
                            </p>
                            <p>
                              <b>Asset Friendly Name:</b>
                              {assets.friendlyName}
                            </p>
                            <p>
                              <b>AssetverticalId:</b>
                              {assets.assetverticalId}
                            </p>
                            <p>
                              <b>Blocking Status:</b>
                              {assets.blockingStatus}
                            </p>
                            <p>
                              <b>Category Type:</b>
                              {assets.categoryType}
                            </p>
                            <p>
                              <b>Gender:</b>
                              {assets.gender}
                            </p>
                            <p>
                              <b>ModelId:</b>
                              {assets.modelId}
                            </p>

                            <p>
                              <b>Model Type:</b>
                              {assets.modelType}
                            </p>
                            <p>
                              <b>Model Url:</b>
                              {assets.modelUrl}
                            </p>

                            <p>
                              <b>SiteId:</b>
                              {assets.siteId}
                            </p>
                            <p>
                              <b>Site Name:</b>
                              {assets.siteName}
                            </p>
                            <p>
                              <b>UDN:</b>
                              {assets.udn}
                            </p>
                            <p>
                              <b>Weight:</b>
                              {assets.weight}
                            </p>
                            <p>
                              <b>Acquire Cost:</b>
                              {assets.acquireCost}
                            </p>
                            <p>
                              <b>Whitelabel Cost:</b>
                              {assets.whitelabelCost}
                            </p>
                            <p>
                              <b>Selling Cost:</b>
                              {assets.sellingCost}
                            </p>
                            <p>
                              <b>Latitude:</b>
                              {assets.latitude}
                            </p>
                            <p>
                              <b>Longitude:</b>
                              {assets.longitude}
                            </p>
                            <img
                              alt=" "
                              src={`data:image/jpeg;base64,${assets.image}`}
                              width="200"
                              height="200"
                            />
                            {assets.assetqrcodelink !== "" &&
                            assets.assetqrcodelink !== "null" ? (
                              <div>
                                <img
                                  src={assets.assetqrcodelink}
                                  alt="img"
                                ></img>
                                <br />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="registerBtn"
                                >
                                  <a
                                    className="downloadDocument"
                                    href={assets.assetqrcodelink}
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </Button>
                              </div>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                className="registerBtn"
                                type="submit"
                                onClick={(e) => submitGenerateQr(assets)}
                              >
                                Generate
                              </Button>
                            )}

                            <h4 className="cardactiontitle">View</h4>
                            <div>
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetInfoDialogClick(assets.assetId)
                                  }
                                >
                                  Asset Info
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() =>
                                    assetMonitoringHandler(
                                      assets.siteId,
                                      assets.assetId,
                                      assets.latitude,
                                      assets.longitude
                                    )
                                  }
                                >
                                  Asset Monitoring
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  variant="contained"
                                  type="button"
                                  onClick={() => {
                                    assetEnvClick(
                                      assets.assetId,
                                      assets.assetType
                                    );
                                  }}
                                >
                                  Env
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                            </div>
                            <div style={{ width: "100%", margin: "5px" }}>
                              <h4 className="cardactiontitle">Operate</h4>
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    mapPopUpHandler(
                                      assets.assetId,
                                      assets.categoryType
                                    )
                                  }
                                >
                                  Map
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    unMapAssetHandler(
                                      assets.assetId,
                                      assets.categoryType,
                                      assets.siteId
                                    )
                                  }
                                >
                                  UnMap
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    updateLegacyAssetHandler(assets)
                                  }
                                >
                                  Update Status
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => udateAssetInfoClick(assets)}
                                >
                                  Update Info
                                </Button>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    deleteAssetHandler(
                                      assets.assetId,
                                      assets.assetType,
                                      assets.modelId,
                                      assets.assetverticalId,
                                      assets.categoryType
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4>No Assets Found.</h4>
                  )}
                </div>
              )}
            </Box>
          ) : null}

          {assetsMapView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
              {assetsList.length > 0 ? (
                <AssetManagementGoogleMap
                  containerElement={
                    <div style={{ height: `500px`, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              ) : (
                <h4>Assets Not Found</h4>
              )}
            </Box>
          ) : null}
          {/* Asset Monitoring */}
          {assetMonitoringView === true ? (
            <Box sx={{ width: "100%", marginBottom: 30 }}>
              <h3>Asset Live Monitoring</h3>
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  backToHome();
                }}
              >
                Back
              </Button>

              {/* <AssetLiveGoogleMap
                containerElement={
                  <div style={{ height: "800px", width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%" }} />}
              /> */}
              <Map routeLocationData={markers} />
              {/* <LocationMap marker_position={marker_position} assetPostionList={assetPostionList}/>  */}
            </Box>
          ) : null}
        </div>
      </div>

      {/* Asset Monitoring */}
      <Dialog
        fullScreen={fullScreen}
        open={liveMonitorMapPopUp}
        onClose={() => {
          setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Live Monitoring"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          {/* <AssetLiveGoogleMap
            containerElement={
              <div style={{ height: `500px`, width: "100%" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          /> */}
          <Map routeLocationData={markers}></Map>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setLiveMonitorMapPopUp(!liveMonitorMapPopUp);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset Env  */}
      <Dialog
        fullScreen={fullScreen}
        open={assetEnvPopup}
        onClose={() => {
          setAssetEnvPopup(!assetEnvPopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Environment Info"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
        {Object.keys( assetEnvData).length>0 ? (
            <>
            <p>
              <b>Asset Id :</b>
              {assetEnvData.assetid}
            </p>
            <p>
              <b>Device Id :</b>
              {assetEnvData.deviceid}
            </p>
            <p>
              <b>Device Type :</b>
              {assetEnvData.devicetype}
            </p>
            <p>
              <b>Network Id :</b>
              {assetEnvData.networkid}
            </p>
            <p>
              <b>Site Id :</b>
              {assetEnvData.siteid}
            </p>
            <p>
              <b>Updated Date :</b>
              {assetEnvData.updatedat}
            </p>
            <p>
              <b>Number of Properties : </b>
              {assetEnvData.NumberofProperties}
            </p>
  
            {assetEnvData.properties !== undefined ? (
              assetEnvData.properties.map((prop, index) => {
                return (
                  <div key={`${index} `}>
                    <p>
                      <b>Property : &nbsp;</b>
                      {prop.property} &nbsp;&nbsp;{prop.value}
                    </p>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            </>
          ):(
            <h4>Data not found</h4>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setAssetEnvPopup(!assetEnvPopup);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Asset Delete  */}
      <Dialog
        fullScreen={fullScreen}
        open={assetDeletePopup}
        onClose={() => {
          setAssetDeletePopup(!assetDeletePopup);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Asset Delete"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <h4>Are you sure want to delete?</h4>
        </DialogContent>
        <DialogActions>
        <Button
            color="default"
            variant="contained"
            onClick={() => {
              deleteAssetSubmitHandler()
            }}
          >
            Delete
          </Button> &nbsp;
          <Button
            color="default"
            variant="contained"
            onClick={() => {
              setAssetDeletePopup(!assetDeletePopup);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPopup
        closeButton={closeSitAssetInfoModal}
        isOpen={assetInfoPopup}
        closeModal={assetInfoPopup}
        content={assetInfoBody}
        title={assetInfoHeader}
      />
      <CustomPopup
        closeButton={createAssetModal}
        isOpen={assetPopup}
        closeModal={assetPopup}
        content={assetCreationBody}
        title={titleCreateAsset}
      />
      <CustomPopup
        closeButton={closeMapModal}
        isOpen={mapPopUp}
        closeModal={mapPopUp}
        content={mapModelBody}
        title={titleMapAsset}
      />
      <CustomPopup
        closeButton={closeUpdateModel}
        isOpen={legacyUpdate}
        closeModal={legacyUpdate}
        content={updateLegacyAssetData}
        title={updateLegacyAsset}
      />
      <CustomPopup
        closeButton={closeUpdateAssetInfoModel}
        isOpen={assetInfoUpdatePopup}
        closeModal={assetInfoUpdatePopup}
        content={updateLegacyAssetInfoData}
        title={updateLegacyAssetInfo}
      />
    </>
  );
}
